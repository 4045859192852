// DEPS
import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";
import moment from "moment";

// COMPONENTS
import { Modal as AntModal, notification } from "antd";
import {
  ReactForm,
  ReactInput,
  ReactInputNumber,
  ReactDatepicker,
  ReactTimepicker
} from "../Form";

// APIS
import { GET_LOAN_BY_ID } from "../../pages/loans/graphql";

const INSERT_LOAN_DISBURSAL_DETAILS = gql`
  mutation insertLoanDisbursalPayment(
    $loan_id: Int!
    $utr: String!
    $amount: float8!
    $payment_timestamp: timestamptz!
    $transaction_type: String!
  ) {
    insert_payments_one(
      object: {
        loan_id: $loan_id
        unique_transaction_reference: $utr
        paid_amount: $amount
        payment_date_time: $payment_timestamp
        payment_status: "Success"
        payment_type: "Manual"
        transaction_type: $transaction_type
      }
    ) {
      id
      loan_id
      unique_transaction_reference
      payment_info
    }
  }
`;

const UPDATE_PAYMENT_IS_COMPLTED = gql`
  mutation updatePaymentIsCompleted($payment_id: Int!) {
    update_payments_by_pk(
      pk_columns: { id: $payment_id }
      _set: { is_completed: true }
    ) {
      id
      is_completed
    }
  }
`;

const PaymentValidationSchema = Yup.object({
  utr: Yup.string()
    .ensure()
    .required("Required"),
  amount: Yup.number()
    .typeError("Please enter a number")
    .required("Required"),
  date: Yup.string()
    .trim()
    .typeError("Please choose a date")
    .required("Required"),
  time: Yup.string()
    .trim()
    .typeError("Please choose a time")
    .required("Required")
});

const RecordFundTransfer = props => {
  const [insertLoanDisbursalDetails] = useMutation(
    INSERT_LOAN_DISBURSAL_DETAILS,
    {
      onError(error) {
        error.graphQLErrors.forEach(error => {
          notification.error({
            message: "Error!",
            description:
              "Something went wrong. Please try again or contact support for help."
          });
        });
      },
      onCompleted(data) {
        notification.success({
          message: "Success!",
          description: "Transaction Saved"
        });

        if (props.type === "Oro Repayment")
          updatePaymentIsCompleted({
            variables: {
              payment_id: props.payment.id
            }
          });
        else props.setLoanDisbursalComplete(true);

        props.setOpen(false);
      }
    }
  );

  const [updatePaymentIsCompleted] = useMutation(UPDATE_PAYMENT_IS_COMPLTED, {
    onError(error) {
      error.graphQLErrors.forEach(error => {
        notification.error({
          message: "Error!",
          description:
            "Something went wrong. Customer payment status was not updated. Contact support for help"
        });
      });
    },
    refetchQueries: [
      {
        query: GET_LOAN_BY_ID,
        variables: {
          id: props.loan_id
        }
      }
    ],
    onCompleted(data) {
      //   notification.success({
      //     message: "Success!",
      //     description: "Fund Transfer Details Saved"
      //   });
      //   props.setOpen(false);
    }
  });

  const formRef = React.useRef();

  const handleSubmit = values => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleReset = props => {
    if (formRef.current) {
      formRef.current.handleReset();
    }
  };

  const dummy = () => {
    return true;
  };

  return (
    <AntModal
      width="640px"
      className="modal-title-center"
      //   title="Store gold"
      visible={props.isOpen}
      footer={null}
    >
      <div className="space-y-8 mx-auto text-center">
        <h1>Enter transaction details</h1>

        {props.payment ? (
          <div className="flex space-x-2">
            <p>Customer payment: ₹{props.payment?.customer_paid_amount}</p>
            <p>Cashback: ₹{props.payment?.cashback}</p>
            <p>Amount to be paid to bank: ₹{props.payment?.bank_amount}</p>
          </div>
        ) : null}

        <ReactForm
          formCls="flex flex-col content-center justify-center space-y-4"
          innerRef={formRef}
          initialValues={{
            utr: "",
            amount: 0,
            date: null,
            time: null
          }}
          validationSchema={PaymentValidationSchema}
          onSubmit={async values => {
            // console.log("values", values);

            insertLoanDisbursalDetails({
              variables: {
                loan_id: props.loan_id,
                utr: values.utr,
                amount: values.amount,
                payment_timestamp: moment(
                  values.date + values.time,
                  "DD-MM-YYYY HH:mm"
                ),
                transaction_type: props.type
              }
            });
          }}
          onReset={dummy}
        >
          <ReactInput
            name="utr"
            type="text"
            label="UTR"
            placeholder=""
          ></ReactInput>

          <ReactInputNumber
            name="amount"
            label="Amount"
            min={0}
            precision={2}
          />

          <div className="w-full space-y-3">
            <p className="text-gray-800 font-semibold text-lg text-left">
              Payment date and time
            </p>
            <div className="flex space-x-8">
              <div className="w-1/2">
                <ReactDatepicker
                  name="date"
                  // disabledDate={function disabledDate(current) {
                  //   return (
                  //     current && current.valueOf() < moment().subtract(1, "d")
                  //   );
                  // }}
                />
              </div>
              <div className="w-1/2">
                <ReactTimepicker name="time" />
              </div>
            </div>
          </div>
        </ReactForm>

        <div className="flex space-x-4 justify-center">
          <button
            className="btn-primary"
            //   onClick={() => props.approveLoan()}
            onClick={() => handleSubmit()}
          >
            Confirm
          </button>
          <button
            className="btn-danger"
            onClick={() => {
              handleReset();
              props.setOpen(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </AntModal>
  );
};

export default RecordFundTransfer;
