import gql from "graphql-tag";

export const GET_PLAN_DATA = gql`
  query getPlanDate {
    rates(where: { is_active: { _eq: true } }) {
      id
      active_from
    }
    loan_types(order_by: { order: asc }, where: { is_active: { _eq: true } }) {
      id
      loan_type
    }
  }
`;
export const EXPORT_PLANS = gql`
  query exportPlans(
    $where: plans_bool_exp = {}
    $order_by: [plans_order_by!] = {}
  ) {
    plans(order_by: $order_by, where: $where) {
      id
      scheme_id
      plan_display_id
      plan_start_date
      plan_end_date
      is_active
      plan_name
      ltv
      created_at
      updated_at
      features
      tncs
      maximum_amount
      minimum_amount
      tenure
      interest
      interest_slab_2
      interest_slab_3
      bank_interest
      plans_x_loanTypes {
        id
        loan_type {
          id
          loan_type
        }
      }
    }
  }
`;
export const GET_PLANS = gql`
  query plans(
    $limit: Int
    $offset: Int
    $where: plans_bool_exp = {}
    $order_by: [plans_order_by!] = {}
  ) {
    plans_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    plans(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
      id
      scheme_id
      plan_display_id
      plan_start_date
      plan_end_date
      is_active
      plan_name
      ltv
      created_at
      updated_at
      features
      tncs
      maximum_amount
      minimum_amount
      tenure
      interest
      plans_x_loanTypes {
        id
        loan_type {
          id
          loan_type
        }
      }
    }
  }
`;
export const GET_PLAN_BY_ID = gql`
  query plans($where: plans_bool_exp = {}) {
    plans(where: $where) {
      id
      scheme_id
      plan_display_id
      plan_start_date
      plan_end_date
      is_active
      plan_name
      plan_type
      ltv
      created_at
      updated_at
      features
      tncs
      maximum_amount
      minimum_amount
      tenure
      interest
      interest_slab_2
      interest_slab_3
      bank_interest
      created_by {
        id
        first_name
        last_name
      }
      updated_by {
        id
        first_name
        last_name
      }
      plans_x_loanTypes {
        id
        loan_type {
          id
          loan_type
        }
      }
      plans_x_partner_branches {
        id
        partner_branch {
          id
          partner_branches_display_id
          partner_branch_name
          partner {
            id
            partner_display_id
            partner_name
          }
        }
      }
    }
  }
`;

export const MAKE_PLAN_ACTIVE = gql`
  mutation MakePlanActive($id: Int!, $status: Boolean) {
    update_plans_by_pk(pk_columns: { id: $id }, _set: { is_active: $status }) {
      id
      plan_display_id
      plan_start_date
      plan_end_date
      is_active
      plan_name
      created_at
      updated_at
      features
      tncs
      maximum_amount
      minimum_amount
      tenure
      interest
      plans_x_loanTypes {
        id
        loan_type {
          id
          loan_type
        }
      }
    }
  }
`;

const GET_RATES = gql`
  query getRates {
    rates(where: { is_active: { _eq: true } }) {
      id
      active_till
    }
  }
`;

const GET_LOAN_TYPES = gql`
  query getLoanTypes {
    loan_types(order_by: { order: asc }, where: { is_active: { _eq: true } }) {
      id
      loan_type
    }
  }
`;

const INSERT_PLAN = gql`
  mutation insertPlan($obj: [plans_insert_input!]!) {
    insert_plans(objects: $obj) {
      returning {
        id
        scheme_id
        plan_display_id
        plan_name
        plan_start_date
        plan_end_date
        maximum_amount
        minimum_amount
        interest
        ltv
        tenure
        features
        tncs
        plans_x_loanTypes {
          id
          plan_id
          loan_type_id
          is_active
        }
      }
    }
  }
`;

const GET_ACTIVE_PARTNER_BRANCHES = gql`
  query getActivePartnerBranches {
    partner_branches(where: { is_active: { _eq: true } }) {
      id
      partner_branch_name
      partners_branches_x_loanTypes {
        id
        loan_type_id
      }
    }
  }
`;

export { INSERT_PLAN, GET_LOAN_TYPES, GET_RATES, GET_ACTIVE_PARTNER_BRANCHES };
