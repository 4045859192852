import React from "react";
import { Link } from "react-router-dom";

const DisplayTable = (props) => {
  // Name - table header
  // Data - {
  //  type
  //  header
  //  data
  //  width
  // }
  return (
    <div>
      <h4 className="mb-2 uppercase">{props.name}</h4>
      <div
        className={props.orientation === "vertical" ? "flex shadow-lg" : null}
      >
        <div
          className={
            props.orientation === "vertical"
              ? "flex flex-col w-1/4 bg-green-primary"
              : "flex bg-green-primary"
          }
        >
          {props.data.map((item, i) => {
            return item.data ? (
              <div
                key={i}
                className={
                  props.orientation === "vertical"
                    ? "font-semibold text-white p-3"
                    : `font-semibold text-white p-3 w-${item.width}`
                }
              >
                {item.header}
              </div>
            ) : null;
          })}
        </div>

        <div
          className={
            props.orientation === "vertical"
              ? "flex flex-col w-3/4 bg-white"
              : "flex bg-white shadow-lg"
          }
        >
          {props.data.map((item, i) => {
            if (item.data) {
              if (item.header === "Address") {
                return (
                  <div
                    key={i}
                    className={
                      props.orientation === "vertical"
                        ? "font-semibold p-3"
                        : `font-semibold p-3 break-all w-${item.width}`
                    }
                  >
                    {item.data.addr_line_1}, {item.data.addr_line_2},{" "}
                    {item.data.city}, {item.data.state} - {item.data.pincode}
                  </div>
                );
              } else if (item.type === "Data") {
                return (
                  <div
                    key={i}
                    className={
                      props.orientation === "vertical"
                        ? "font-semibold p-3"
                        : `font-semibold p-3 break-all w-${item.width}`
                    }
                  >
                    {item.data}
                  </div>
                );
              } else if (item.type === "Link") {
                return (
                  <div
                    key={i}
                    className={
                      item.link
                        ? `font-semibold text-green-primary hover:underline cursor-pointer p-3 break-all w-${item.width}`
                        : `font-semibold p-3 break-all w-${item.width}`
                    }
                  >
                    {/* <Link to={item.link}>{item.data}</Link> */}
                    <Link
                      to={{
                        pathname: item.link,
                        // state: {
                        //   loan_status: data.loan.loan_status,
                        // },
                        state: item.state,
                      }}
                    >
                      {item.data}
                    </Link>
                  </div>
                );
              } else if (item.type === "External Link") {
                return (
                  <div
                    key={i}
                    className={
                      item.link
                        ? `font-semibold text-green-primary hover:underline cursor-pointer p-3 break-all w-${item.width}`
                        : `font-semibold p-3 break-all w-${item.width}`
                    }
                  >
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.data}
                    </a>
                  </div>
                );
              } else {
                return null;
              }
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default DisplayTable;

// .toString()
//               .split(",")
//               .join(", ")}
