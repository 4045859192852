import gql from "graphql-tag";
import { BANK_ACCOUNT_DETAILS_FRAGMENT } from "../../graphqlFragments";

const GET_AGENTS = gql`
  query agents(
    $limit: Int
    $offset: Int
    $where: users_bool_exp = {}
    $order_by: [users_order_by!] = {}
  ) {
    users_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    users(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
      id
      first_name
      last_name
      mobile_number
      is_active
      role_name
      auth_id
      agent {
        id
        agent_display_id
      }
    }
  }
`;

const EXPORT_AGENTS = gql`
  ${BANK_ACCOUNT_DETAILS_FRAGMENT}
  query agents($where: users_bool_exp = {}, $order_by: [users_order_by!] = {}) {
    users(order_by: $order_by, where: $where) {
      id
      first_name
      last_name
      email_id
      profile_picture
      mobile_number
      auth_id
      is_active
      role_name
      agent {
        id
        agent_display_id
        address
        first_name
        last_name
        secondary_mobile_number
        relation
        secondary_address
        id_proof
        address_proof
        created_at
        updated_at
        created_by {
          id
          first_name
          last_name
        }
        updated_by {
          id
          first_name
          last_name
        }
      }
      user_bank_account_details {
        ...userBankAccountDetails
      }
    }
  }
`;

const GET_AGENT_DATA_FOR_EDIT = gql`
  ${BANK_ACCOUNT_DETAILS_FRAGMENT}
  query getAgentDataForEdit($id: Int!) {
    users(where: { id: { _eq: $id } }) {
      id
      first_name
      last_name
      profile_picture
      user_bank_account_details {
        ...userBankAccountDetails
      }
      agent {
        id
        id_card_photos
      }
    }
  }
`;

const UPDATE_AGENT = gql`
  ${BANK_ACCOUNT_DETAILS_FRAGMENT}
  mutation updateAgent(
    $id: Int!
    $updated_by_id: String!
    $agent_id_card_photos: jsonb!
    $user_data: users_set_input!
    $bank_data: user_bank_account_details_set_input!
  ) {
    update_users(where: { id: { _eq: $id } }, _set: $user_data) {
      returning {
        id
        first_name
        last_name
        profile_picture
      }
    }
    update_agents(
      where: { user: { id: { _eq: $id } } }
      _set: {
        updated_by_auth_id: $updated_by_id
        id_card_photos: $agent_id_card_photos
      }
    ) {
      returning {
        id
        updated_by_auth_id
      }
    }
    update_user_bank_account_details(
      where: { user: { id: { _eq: $id } } }
      _set: $bank_data
    ) {
      returning {
        ...userBankAccountDetails
      }
    }
  }
`;

const GET_AGENT_DETAILS_BY_ID = gql`
  ${BANK_ACCOUNT_DETAILS_FRAGMENT}
  query getAgentDetailsById($id: Int!) {
    users_by_pk(id: $id) {
      id
      first_name
      last_name
      email_id
      profile_picture
      mobile_number
      auth_id
      is_active
      created_at
      updated_at
      role_name
      agent {
        id
        agent_display_id
        address
        first_name
        last_name
        secondary_mobile_number
        relation
        secondary_address
        id_proof
        address_proof
        id_card_photos
        created_at
        updated_at
        created_by {
          id
          first_name
          last_name
        }
        updated_by {
          id
          first_name
          last_name
        }
      }
      user_bank_account_details {
        ...userBankAccountDetails
      }
    }
  }
`;

const GET_LATEST_USER_ID = gql`
  query getLatestUserId {
    users(limit: 1, order_by: { id: desc }) {
      id
    }
  }
`;

// const CREATE_AGENT = gql`
//   mutation insertFieldAgent($obj: InsertFieldAgentOrocorpUsersInsertInput!) {
//     insert_field_agent(obj: $obj) {
//       id
//     }
//   }
// `;

const CREATE_AGENT = gql`
  mutation createAgent(
    $address: jsonb
    $address_proof: jsonb
    $created_by_auth_id: String
    $id_card_photos: jsonb
    $first_name: String
    $id_proof: jsonb
    $last_name: String
    $relation: String
    $secondary_address: jsonb
    $secondary_mobile_number: String
    $user: CreateFieldAgentUsersObjRelInsertInput
  ) {
    create_agent(
      address: $address
      address_proof: $address_proof
      created_by_auth_id: $created_by_auth_id
      id_card_photos: $id_card_photos
      first_name: $first_name
      id_proof: $id_proof
      last_name: $last_name
      relation: $relation
      secondary_address: $secondary_address
      secondary_mobile_number: $secondary_mobile_number
      user: $user
    ) {
      code
      message
    }
  }
`;

export {
  GET_AGENTS,
  GET_AGENT_DATA_FOR_EDIT,
  UPDATE_AGENT,
  GET_AGENT_DETAILS_BY_ID,
  EXPORT_AGENTS,
  GET_LATEST_USER_ID,
  CREATE_AGENT,
};
