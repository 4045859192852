// DEPS
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";
import Carousel, { Modal, ModalGateway } from "react-images";
import { EyeOutlined, LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";

// COMPONENTS
import Loading from "../../components/loading";
import ErrorPage from "../../components/ErrorPage";
import DisplayTable from "../../components/displayTable";

// VIEWS
import UserBankAccountDetails from "../../views/userBankAccountDetails";

// APIS
import { GET_CUSTOMER_DETAILS_BY_ID } from "./graphql";
import { get_image_url } from "../../utils";

const UserDetails = (props) => {
  const {
    data: customer,
    loading: customerDataLoading,
    error: customerDataQueryError,
  } = useQuery(GET_CUSTOMER_DETAILS_BY_ID, {
    variables: {
      id: parseInt(props.match.params.id),
    },
  });

  const [goldImageModalIsOpen, setGoldImageModalIsOpen] = useState(false);
  const [goldImages, setGoldImages] = useState([]);

  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [images, setImages] = useState([]);

  if (customerDataLoading) return <Loading />;

  if (customerDataQueryError) return <ErrorPage />;

  return (
    <div className="space-y-8">
      <div className="flex justify-between">
        <div className="flex items-center pl-3">
          <div className="relative">
            <LeftOutlined
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "#16A085",
              }}
            />
          </div>

          <Button
            type="link"
            onClick={() => {
              props.history.push("/users");
            }}
          >
            All users
          </Button>
        </div>
      </div>

      {/* USER DETAILS  */}
      <DisplayTable
        name="User Details"
        data={[
          {
            type: "Data",
            header: "Customer ID",
            data: customer.users[0].customer.customer_display_id,
            width: "1/6",
          },
          {
            type: "Data",
            header: "Legal name",
            data: customer.users[0]?.kycs[0]?.legal_name || "-",
            width: "1/6",
          },
          {
            type: "Data",
            header: "User entered name",
            data: `${customer.users[0].name}`,
            width: "1/6",
          },
          {
            type: "Data",
            header: "Mobile number",
            data: customer.users[0].mobile_number,
            width: "1/6",
          },
          {
            type: "Data",
            header: "Email ID",
            data: customer.users[0].email_id,
            width: "1/6",
          },
          {
            type: "Data",
            header: "City",
            data: customer.users[0].city,
            width: "1/6",
          },
          {
            type: "Data",
            header: "Signed up on",
            data: moment(customer.users[0].created_at).format("DD-MM-YYYY"),
            width: "1/6",
          },
        ]}
      />
      {/* USER DETAILS  */}

      {/* LOANS  */}
      {customer.users[0].loans.length > 0 ? (
        <div>
          <h4 className="uppercase mb-4">LOANS</h4>
          {/* HEADERS  */}
          <div>
            {" "}
            <div className="flex bg-green-primary">
              <div className="font-semibold text-white p-3 w-1/6">Loan ID</div>
              <div className="font-semibold text-white p-3 w-1/6">
                Loan Type
              </div>
              <div className="font-semibold text-white p-3 w-1/6">
                Customer ID
              </div>
              <div className="font-semibold text-white p-3 w-1/6">Tenure</div>
              <div className="font-semibold text-white p-3 w-1/6">
                Partner branch
              </div>
              <div className="font-semibold text-white p-3 w-1/6">
                Start date
              </div>
              <div className="font-semibold text-white p-3 w-1/6">Amount</div>
            </div>
          </div>
          {/* HEADERS  */}
          {/* DATA  */}
          {customer.users[0].loans.map((loan) => {
            return (
              <>
                <div className="flex bg-white">
                  <div className="font-semibold p-3 break-all w-1/6">
                    {loan.loan_number || "-"}
                  </div>
                  <div className="font-semibold p-3 break-all w-1/6">
                    {loan.loan_type.loan_type || "-"}
                  </div>
                  <div className="font-semibold p-3 break-all w-1/6">
                    {loan.customer_id_from_bank || "-"}
                  </div>
                  <div className="font-semibold p-3 break-all w-1/6">
                    {loan.tenure} months
                  </div>
                  <div className="font-semibold p-3 break-all w-1/6">
                    {loan.partner_branch
                      ? loan.partner_branch.partner_branch_name
                      : "N/A"}
                  </div>
                  <div className="font-semibold p-3 break-all w-1/6">
                    {loan.orocorp_approved_at
                      ? moment(loan.orocorp_approved_at).format("DD-MM-YYYY")
                      : "N/A"}
                  </div>
                  <div className="font-semibold p-3 break-all w-1/6">
                    {loan.od_amount
                      ? (
                          loan.od_amount.credit_limit +
                          (loan.support_loan?.sl_amount.loan_amount || 0)
                        ).toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : loan.gl_amount
                      ? (
                          loan.gl_amount.loan_amount +
                          (loan.support_loan?.sl_amount.loan_amount || 0)
                        ).toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "N/A"}
                  </div>
                </div>
              </>
            );
          })}
          {/* DATA  */}
        </div>
      ) : null}
      {/* LOANS  */}
      {/* GOLDS  */}
      {customer.users[0].customer.golds.length > 0 ? (
        <div>
          <h4 className="uppercase mb-4">GOLDS</h4>
          <div className="flex bg-green-primary">
            <div className=" font-semibold text-white p-3 w-2/6">Item ID</div>
            <div className=" font-semibold text-white p-3 w-2/6">Item type</div>
            <div className=" font-semibold text-white p-3 w-2/6">Quality</div>
            <div className=" font-semibold text-white p-3 w-2/6">Quantity</div>
            <div className=" font-semibold text-white p-3 w-2/6">
              Gross weight
            </div>
            <div className=" font-semibold text-white p-3 w-2/6">
              Actual Net weight
            </div>
            <div className=" font-semibold text-white p-3 w-2/6">
              Equivalent Net weight
            </div>
            <div className=" font-semibold text-white p-3 w-2/6">Seal ID</div>
            <div className=" font-semibold text-white p-3 w-1/6">
              Gold Photo
            </div>
            <div className=" font-semibold text-white p-3 w-1/6">
              Seal Photo
            </div>
          </div>
          <div className="bg-white">
            {customer.users[0].customer.golds.map((goldItem) => {
              return (
                <div className="flex">
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {goldItem.gold_display_id || "-"}
                  </div>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {goldItem.gold_type || "-"}
                  </div>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {goldItem.actual_quality}
                  </div>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {goldItem.quantity} nos.
                  </div>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {goldItem.gross_weight.toLocaleString("en-IN", {
                      maximumFractionDigits: 2,
                    })}
                    g
                  </div>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {goldItem.actual_net_weight.toLocaleString("en-IN", {
                      maximumFractionDigits: 2,
                    })}
                    g
                  </div>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {goldItem.net_weight.toLocaleString("en-IN", {
                      maximumFractionDigits: 2,
                    })}
                    g
                  </div>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {goldItem.pickup_seal_id || "-"}
                  </div>
                  <div className="font-semibold p-3 w-1/6 break-all">
                    <EyeOutlined
                      onClick={async () => {
                        let gold_ornaments = await get_image_url({
                          url: goldItem.gold_images.gold_ornaments,
                        });

                        if (
                          goldItem.gold_images.used_touchstone !== undefined &&
                          goldItem.gold_images.clean_touchstone !== undefined
                        ) {
                          let used_touchstone = await get_image_url({
                            url: goldItem.gold_images.used_touchstone,
                          });

                          let clean_touchstone = await get_image_url({
                            url: goldItem.gold_images.clean_touchstone,
                          });
                          setGoldImages([
                            {
                              source: gold_ornaments,
                              caption: "Gold ornaments",
                            },
                            {
                              source: used_touchstone,
                              caption: "Used touchstone",
                            },
                            {
                              source: clean_touchstone,
                              caption: "Clean touchstone",
                            },
                          ]);
                          setGoldImageModalIsOpen(true);
                        } else {
                          setGoldImages([
                            {
                              source: gold_ornaments,
                              caption: "Gold ornaments",
                            },
                          ]);
                          setGoldImageModalIsOpen(true);
                        }
                      }}
                    />
                  </div>

                  <div className="font-semibold p-3 w-1/6 break-all">
                    <EyeOutlined
                      onClick={async () => {
                        let sealed_gold = await get_image_url({
                          url: goldItem.pickup_sealed_gold_images.sealed_gold,
                        });

                        if (
                          goldItem.pickup_sealed_gold_images
                            .sealed_gold_seal_ID !== undefined &&
                          goldItem.pickup_sealed_gold_images
                            .sealed_gold_cover_wt !== undefined
                        ) {
                          let sealed_gold_seal_ID = await get_image_url({
                            url:
                              goldItem.pickup_sealed_gold_images
                                .sealed_gold_seal_ID,
                          });

                          let sealed_gold_cover_wt = await get_image_url({
                            url:
                              goldItem.pickup_sealed_gold_images
                                .sealed_gold_cover_wt,
                          });

                          setGoldImages([
                            { source: sealed_gold, caption: "Sealed gold" },
                            {
                              source: sealed_gold_seal_ID,
                              caption: "Seal ID",
                            },
                            {
                              source: sealed_gold_cover_wt,
                              caption: "Sealed cover weight",
                            },
                          ]);
                          setGoldImageModalIsOpen(true);
                        } else {
                          setGoldImages([
                            { source: sealed_gold, caption: "Sealed gold" },
                          ]);
                          setGoldImageModalIsOpen(true);
                        }
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
      {/* GOLDS  */}

      <div className="flex space-x-32">
        <div className="block space-y-4">
          <h3 className="text-lg">ID PROOF</h3>
          <div className="border bg-white">
            {customer.users[0].kycs[0]?.id_proof.map((proof) => {
              return (
                <p
                  className="cursor-pointer hover:bg-gray-200 py-1 px-2"
                  onClick={async () => {
                    let images = [];

                    let index = customer.users[0].kycs[0].id_proof.findIndex(
                      (data) => {
                        return data.type === proof.type;
                      }
                    );

                    const valid_url = await get_image_url({
                      url: [
                        customer.users[0].kycs[0].id_proof[index].image_url,
                      ],
                    });

                    images.push({
                      caption: `ID proof - ${proof.type}`,
                      source: valid_url,
                    });

                    setImageModalOpen(!isImageModalOpen);

                    setImages(images);
                  }}
                >
                  {proof.type}
                </p>
              );
            })}
          </div>
        </div>

        <div className="block space-y-4">
          <h3 className="text-lg">ADDRESS PROOF</h3>
          <div className="border bg-white">
            {customer.users[0].kycs[0]?.address_proof.map((proof) => {
              return (
                <p
                  className="cursor-pointer hover:bg-gray-200 py-1 px-2"
                  onClick={async () => {
                    let images = [];

                    let index = customer.users[0].kycs[0].address_proof.findIndex(
                      (data) => {
                        return data.type === proof.type;
                      }
                    );

                    const valid_url = await get_image_url({
                      url: [
                        customer.users[0].kycs[0].address_proof[index]
                          .image_url,
                      ],
                    });

                    images.push({
                      caption: `Address proof - ${proof.type}`,
                      source: valid_url,
                    });

                    setImageModalOpen(!isImageModalOpen);

                    setImages(images);
                  }}
                >
                  {proof.type}
                </p>
              );
            })}
          </div>
        </div>

        {customer.users[0].kycs[0]?.live_photo.length > 0 ? (
          <div className="w-1/4 space-y-2">
            <h3>Recent Photo</h3>
            <button
              className="cta hover:shadow-lg"
              style={{ height: "38px", minWidth: "150px" }}
              onClick={async () => {
                const valid_url = await get_image_url({
                  url: [customer.users[0].kycs[0].live_photo],
                });

                setImageModalOpen(!isImageModalOpen);

                setImages([
                  {
                    caption: "User photo",
                    source: valid_url,
                  },
                ]);
              }}
            >
              View
            </button>
          </div>
        ) : null}
      </div>
      {/* BANK ACCOUNT DETAILS */}
      <UserBankAccountDetails user_auth_id={customer.users[0].auth_id} />
      {/* BANK ACCOUNT DETAILS */}
      <ModalGateway>
        {isImageModalOpen && images.length > 0 ? (
          <Modal
            onClose={() => {
              setImageModalOpen(!isImageModalOpen);
            }}
          >
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
      {/* KYC  */}
      <ModalGateway>
        {goldImageModalIsOpen ? (
          <Modal
            onClose={() => {
              setGoldImageModalIsOpen(false);
            }}
          >
            <Carousel views={goldImages} />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default UserDetails;
