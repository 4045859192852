import { CloseCircleOutlined, DownOutlined } from "@ant-design/icons";
import { Button, Checkbox, DatePicker, Input, Popover, Select } from "antd";
import React from "react";
const debounce = require("lodash.debounce");

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;
function tagRender(props) {
  const { label } = props;

  return <div style={{ pointerEvents: "none" }}>{label}</div>;
}
const TableFilter = props => {
  const searchDebounce = debounce(props.onSearch, 1000);
  return (
    <div className="flex justify-between">
      <div className="flex flex-wrap space-x-4">
        {(props.filters || []).map((filter, index) => {
          if (filter.show || filter.isFixed) {
            return (
              <div className="flex items-center" key={`Filter-${index}`}>
                <span className="">{filter.title}:</span>
                {filter.type === "select" && (
                  <React.Fragment>
                    <Select
                      showSearch={false}
                      mode="multiple"
                      placeholder="All"
                      onChange={value => {
                        if (props.onChangeSelectFilter) {
                          props.onChangeSelectFilter(index, filter.key, value);
                        }
                      }}
                      // autoFocus
                      style={{
                        minWidth: (filter.selected || []).length ? 110 : 60
                      }}
                      value={filter.selected || []}
                      tagRender={tagRender}
                      bordered={false}
                      maxTagTextLength={(filter.selected || []).length ? 20 : 5}
                      maxTagCount={1}
                      maxTagPlaceholder={",..."}
                      dropdownMatchSelectWidth={false}
                      showArrow
                      dropdownClassName={"filter"}
                      dropdownRender={menu => <div>{menu}</div>}
                    >
                      {filter.data.map((option, index) => {
                        return (
                          <Option className="capitalize" key={`${option.id}`}>
                            {option.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </React.Fragment>
                )}
                {filter.type === "date" && (
                  <RangePicker
                    separator="-"
                    bordered={false}
                    style={{
                      maxWidth: 200
                    }}
                    // autoFocus
                    suffixIcon={<DownOutlined />}
                    format={"DD/MMM/YY"}
                    onChange={date => {
                      if (props.onChangeDate) {
                        props.onChangeDate(index, filter.key, date);
                      }
                    }}
                  />
                )}
                {!filter.isFixed && (
                  <CloseCircleOutlined
                    className="cursor-pointer"
                    onClick={() => {
                      if (props.onChangeMore) {
                        props.onChangeMore(index, false);
                      }
                    }}
                  />
                )}
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
      <div style={{ minWidth: 300 }} className="flex">
        {props.isMoreFilter && (
          <Popover
            content={
              <div>
                {(props.filters || []).map((config, index) => {
                  if (!config.isFixed) {
                    return (
                      <div key={`columns-filter-${index}`}>
                        <Checkbox
                          onChange={e => {
                            if (props.onChangeMore) {
                              props.onChangeMore(index, e.target.checked);
                            }
                          }}
                          checked={config.show !== false}
                        >
                          {config.title}
                        </Checkbox>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            }
            title="Columns"
            trigger="click"
          >
            <div>
              <Button type="primary">+ More</Button>
            </div>
          </Popover>
        )}

        {props.isSearch && (
          <div className="w-full">
            <Search
              placeholder={props.placeholder ? props.placeholder : "search"}
              onChange={e => {
                searchDebounce(e.target.value);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TableFilter;
