import gql from "graphql-tag";

// const GET_RATE_DATA = gql`
//   query getRateDate {
//     loan_types(order_by: { order: asc }, where: { is_active: { _eq: true } }) {
//       id
//       loan_type
//     }
//   }
// `;

const GET_RATES = gql`
  query getRates(
    $limit: Int
    $offset: Int
    $where: rates_bool_exp = {}
    $order_by: [rates_order_by!] = {}
  ) {
    rates_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    rates(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
      id
      is_active
      rate_display_id
      ma_rate
      gold_quality
      active_from
      active_till
    }
    plans_x_rates {
      id
      plan {
        plan_display_id
        plan_name
        is_active
      }
    }
  }
`;

const EXPORT_RATES = gql`
  query getRates(
    $where: rates_bool_exp = {}
    $order_by: [rates_order_by!] = {}
  ) {
    rates(order_by: $order_by, where: $where) {
      id
      is_active
      rate_display_id
      ma_rate
      gold_quality
      active_from
      active_till
      plans_x_rates {
        plan {
          plan_display_id
          plan_name
          is_active
        }
      }
    }
  }
`;

const GET_RATE_BY_ID = gql`
  query getRates($where: rates_bool_exp = {}) {
    rates(where: $where) {
      id
      is_active
      rate_display_id
      ma_rate
      gold_quality
      active_from
      active_till
      plans_x_rates {
        plan {
          plan_display_id
          plan_name
          is_active
        }
      }
    }
  }
`;

const GET_LOAN_TYPES = gql`
  query getLoanTypes {
    loan_types(order_by: { order: asc }) {
      id
      loan_type
    }
  }
`;

export {
  // GET_RATE_DATA,
  GET_RATES,
  EXPORT_RATES,
  GET_RATE_BY_ID,
  GET_LOAN_TYPES
};
