// DEPS
import React, { useState } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import * as Yup from "yup";
import moment from "moment";
import { ErrorBoundary } from "react-error-boundary";
import { Form, Formik } from "formik";

// COMPONENTS
import {Modal as AntModal, Select, notification, Collapse} from "antd";
import { ReactInputNumber, ReactSelect } from "../Form";
import PrimaryButton from "../../components/primaryBtn";
import ErrorWrapper from "../../components/ErrorWrapper";

// ASSETS
import Warning from "../../assets/warning.png";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

const validationSchema = Yup.object({
  partner_id: Yup.number().typeError("Required").required("Required"),
  partner_branch_id: Yup.number().typeError("Required").required("Required"),
  plan_id: Yup.number().typeError("Required").required("Required"),
  support_loan_amount: Yup.number().typeError("Required").required("Required"),
});

const ADD_SUPPORT_LOAN_APPROVE_PRIMARY_LOAN = gql`
  mutation addSupportLoanAndApprovePrimaryLoan(
    $support_loan: sl_amounts_insert_input!
    $primary_loan_id: Int!
    $gp_visit_id: Int!
  ) {
    insert_sl_amounts_one(object: $support_loan) {
      id
      support_loan {
        id
        customer_auth_id
        plan_id
        primary_loan_id
        support_loan_id
        partner_branch_id
        support_loan_id
        sl_amount {
          id
          loan_amount
          paid_loan_amount
        }
        partner_branch {
          id
          partner_branch_name
          partner {
            id
            partner_name
          }
        }
        plan {
          id
          plan_name
          interest
          tenure
        }
        primary_loan {
          id
          loan_number
          orocorp_approved_at
          plan {
            id
          }
          partner_branch {
            partner {
              id
            }
          }
          loan_type {
            loan_type
          }
          gl_amount {
            loan_amount
          }
          customer {
            user {
              id
              mobile_number
              email_id
            }
          }
          kyc {
            legal_name
          }
        }
      }
    }
    update_loans_by_pk(
      pk_columns: { id: $primary_loan_id }
      _set: { loan_status: "OROCORP_APPROVED", orocorp_approved_at: "now()" }
    ) {
      id
      loan_status
      orocorp_approved_by {
        id
        first_name
        last_name
      }
      orocorp_approved_at
    }
    update_visit_intermediate_status(
      new_status: "OD_SUPPORT_LOAN_ADDED"
      visit_id: $gp_visit_id
    ) {
      id
      intermediate_status
    }
  }
`;

const ADD_LOAN_WITH_SUPPORT_LOAN_TO_LEDGER = gql`
  mutation addLoanWithSupportLoanToLedger(
    $user: usertype!
    $loan: loantype!
    $supportLoan: loantype
  ) {
    ledger_loans_create(
      user: $user
      loan: $loan
      supportLoan: $supportLoan
    ) {
      code
      message
    }
  }
`;

const GET_PARTNERS_AND_BRANCHES_AND_PLANS_FOR_SL = gql`
  query getPartnerAndBranchesAndPlansForSL {
    partners(
      where: {
        partner_x_loan_types: { loan_type: { loan_type: { _eq: "SL" } } }
        is_active: { _eq: true }
      }
    ) {
      partner_id: id
      partner_name
      partner_branches(where: { is_active: { _eq: true } }) {
        partner_branch_id: id
        partner_branch_name: partner_branch_name
        plans_x_partner_branches(
          where: { plan: { is_active: { _eq: true } } }
        ) {
          id
          plan {
            plan_id: id
            plan_name: plan_name
          }
        }
      }
    }
  }
`;

const { Panel } = Collapse;

const ApproveLoan = (props) => {
  
  const formRef = React.useRef();

  const handleSubmit = (values) => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const [addLoanWithSupportLoanToLedger] = useMutation(ADD_LOAN_WITH_SUPPORT_LOAN_TO_LEDGER, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description: "Something went wrong. Please check all details and try again",
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Support loan created and primary loan approved",
      });
      props.setOpen(false);
    },
  });

  const [addSupportLoanApprovePrimaryLoan, { loading: addingSupportLoan, data: addLoanWithSupportLoanData }] = useMutation(ADD_SUPPORT_LOAN_APPROVE_PRIMARY_LOAN, {
    onError: (error) => {
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description: "Something went wrong. Please check all details and try again",
        });
      });
    }
  });

  React.useEffect(() => {
    console.log("addLoanWithSupportLoanData", addLoanWithSupportLoanData);
    if(addLoanWithSupportLoanData) {
      addLoanWithSupportLoanToLedger({
        variables: {
          user: {
            oro_id: addLoanWithSupportLoanData?.insert_sl_amounts_one?.support_loan?.primary_loan?.customer?.user?.id,
            name: addLoanWithSupportLoanData?.insert_sl_amounts_one?.support_loan?.primary_loan?.kyc?.legal_name,
            phone: addLoanWithSupportLoanData?.insert_sl_amounts_one?.support_loan?.primary_loan?.customer?.user?.mobile_number,
            email: addLoanWithSupportLoanData?.insert_sl_amounts_one?.support_loan?.primary_loan?.customer?.user?.email_id,
          },
          loan: {
            // oro_id: addLoanWithSupportLoanData?.insert_sl_amounts_one?.support_loan?.primary_loan?.id,
            oro_loan_number: addLoanWithSupportLoanData?.insert_sl_amounts_one?.support_loan?.primary_loan?.loan_number,
            plan_id: addLoanWithSupportLoanData?.insert_sl_amounts_one?.support_loan?.primary_loan?.plan?.id,
            lender_id: addLoanWithSupportLoanData?.insert_sl_amounts_one?.support_loan?.primary_loan?.partner_branch?.partner?.id,
            type: addLoanWithSupportLoanData?.insert_sl_amounts_one?.support_loan?.primary_loan?.loan_type?.loan_type,
            sanctioned_amount: addLoanWithSupportLoanData?.insert_sl_amounts_one?.support_loan?.primary_loan?.gl_amount?.loan_amount,
            loan_booking_date: moment().format('YYYY-MM-DD'),
          },
          supportLoan: {
            // oro_id: addLoanWithSupportLoanData?.insert_sl_amounts_one?.support_loan?.id,
            oro_loan_number: addLoanWithSupportLoanData?.insert_sl_amounts_one?.support_loan?.support_loan_id,
            plan_id: addLoanWithSupportLoanData?.insert_sl_amounts_one?.support_loan?.plan?.id,
            lender_id: addLoanWithSupportLoanData?.insert_sl_amounts_one?.support_loan?.partner_branch?.partner?.id,
            type: "SL",
            sanctioned_amount: addLoanWithSupportLoanData?.insert_sl_amounts_one?.support_loan?.sl_amount?.loan_amount,
          },
        },
      });
    }
  }, [addLoanWithSupportLoanData, addLoanWithSupportLoanToLedger]);

  let initialValues = {
    partner_id: null,
    partner_branch_id: null,
    plan_id: null,
    support_loan_amount: null,
  };

  return (
    <AntModal
      width="768px"
      className="modal-title-center"
      visible={props.isOpen}
      footer={null}
    >
      <div className="space-y-6 mx-auto text-center">
        <div>
          <h1>Approve Loan</h1>
        </div>
        <div>
          <img
            src={Warning}
            alt="warning"
            style={{ height: "8rem", width: "0 auto", margin: "0 auto" }}
          />
        </div>

        <p>Are you sure you want to approve this loan?</p>

        <Collapse
          onChange={() => {
            var x = document.getElementById("approve-loan-div");
            console.log("x", x.style, x.style.display === "none");
            if (x.style.display === "none") {
              x.style.display = "block";
            } else {
              x.style.display = "none";
            }
          }}
          defaultActiveKey={props.support_loan_applicable ? 1 : null}
        >
          <Panel header="Add support loan" key="1">
            <Formik
              enableReinitialize={false}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values, { resetForm }) => {
                let support_loan_obj = {
                  loan_amount: values.support_loan_amount,
                  support_loan: {
                    data: {
                      customer_auth_id: props.customer_auth_id,
                      plan_id: values.plan_id,
                      partner_branch_id: values.partner_branch_id,
                      primary_loan_id: props.loan_id,
                    },
                  },
                };

                let primary_loan_id = props.loan_id;

                let gp_visit_id = props.visit_id;

                addSupportLoanApprovePrimaryLoan({
                  variables: {
                    support_loan: support_loan_obj,
                    primary_loan_id: primary_loan_id,
                    gp_visit_id: gp_visit_id,
                  },
                });
              }}
              innerRef={formRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              formCls="flex flex-col content-center justify-center space-y-2"
            >
              {({ values }) => (
                <Form>
                  <ReactInputNumber name="support_loan_amount" label="Amount" />

                  <ReactSelect
                    name="partner_id"
                    type="text"
                    label="Partner"
                    placeholder="Choose partner"
                    resetKeys={["partner_branch_id", "plan_id"]}
                  >
                    {props.partnerData.partners.map((branch) => {
                      return (
                        <Option value={branch.partner_id}>
                          {branch.partner_name}
                        </Option>
                      );
                    })}
                  </ReactSelect>

                  <ReactSelect
                    name="partner_branch_id"
                    type="text"
                    label="Partner branch"
                    placeholder="Choose partner branch"
                  >
                    {props.partnerData.partners.filter((datapoint) => {
                      return datapoint.partner_id === values.partner_id;
                    }).length > 0
                      ? props.partnerData.partners
                          .filter((datapoint) => {
                            return datapoint.partner_id === values.partner_id;
                          })[0]
                          .partner_branches.map((branch) => {
                            return (
                              <Option value={branch.partner_branch_id}>
                                {branch.partner_branch_name}
                              </Option>
                            );
                          })
                      : null}
                  </ReactSelect>

                  <ReactSelect
                    name="plan_id"
                    type="text"
                    label="Plan"
                    placeholder="Choose plan"
                  >
                    {props.partnerData.partners.filter((datapoint) => {
                      return datapoint.partner_id === values.partner_id;
                    }).length > 0
                      ? props.partnerData.partners
                          .filter((datapoint) => {
                            return datapoint.partner_id === values.partner_id;
                          })[0]
                          .partner_branches.filter(
                            (branch) =>
                              branch.partner_branch_id ===
                              values.partner_branch_id
                          ).length > 0
                        ? props.partnerData.partners
                            .filter((datapoint) => {
                              return datapoint.partner_id === values.partner_id;
                            })[0]
                            .partner_branches.filter(
                              (branch) =>
                                branch.partner_branch_id ===
                                values.partner_branch_id
                            )[0]
                            .plans_x_partner_branches.map((plan) => {
                              return (
                                <Option value={plan.plan.plan_id}>
                                  {plan.plan.plan_name}
                                </Option>
                              );
                            })
                        : null
                      : null}
                  </ReactSelect>
                </Form>
              )}
            </Formik>

            <div className="flex justify-center w-full space-x-8">
              <button
                className="btn-danger hover:shadow-lg"
                onClick={() => props.setOpen(false)}
              >
                Cancel
              </button>
              <PrimaryButton
                handleSubmit={handleSubmit}
                disabled={addingSupportLoan}
                loading={addingSupportLoan}
                buttonText="Create support loan"
              />
            </div>
          </Panel>
        </Collapse>

        <div
          className="flex space-x-4 justify-center"
          id="approve-loan-div"
          style={props.support_loan_applicable ? { display: "none" } : null}
        >
          <button
            className="btn-primary"
            onClick={() =>
              props.approveLoan({
                variables: {
                  loan_id: props.loan_id,
                  visit_id: props.visit_id,
                  approved_at: moment(),
                  user: {
                    oro_id: props.loans_by_pk.customer.user.id,
                    name: props.loans_by_pk.kyc.legal_name,
                    phone: props.loans_by_pk.customer.user.mobile_number,
                    email: props.loans_by_pk.customer.user.email_id,
                  },
                  loan: {
                    // oro_id: props.loans_by_pk.id,
                    oro_loan_number: props.loans_by_pk.loan_number,
                    plan_id: props.loans_by_pk.plan.id,
                    lender_id: props.loans_by_pk.partner_branch.partner.id,
                    type: props.loans_by_pk.loan_type.loan_type,
                    sanctioned_amount: props.loans_by_pk.gl_amount.loan_amount,
                    loan_booking_date: moment().format('YYYY-MM-DD'),
                  },
                },
              })
            }
          >
            Approve Loan
          </button>
          <button
            className="btn-danger"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </AntModal>
  );
};

const UI = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const {data: partnerDataFromQuery, loading: partnerDataLoading, error: partnerDataError} = useQuery(GET_PARTNERS_AND_BRANCHES_AND_PLANS_FOR_SL, {
    skip: !isModalOpen,
  });

  if (partnerDataLoading)
    return (
      <button className="btn-disabled hover:shadow-lg flex" disabled>
        <svg
          class="animate-spin -ml-1 mr-1 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          />
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
        Approve loans
      </button>
    );

  if (partnerDataError) {
    return (
      <ErrorWrapper
        error={partnerDataError}
        productionErrorMessage="Error in fetching SL partner details"
      />
    );
  }

  return (
    <ErrorBoundary
      fallbackRender={({ error }) => (
        <div
          role="alert"
          className="p-8 bg-red-100 rounded text-red-500 space-y-2 mt-10"
        >
          <div className="flex space-x-1 items-center text-lg">
            <ExclamationCircleOutlined />
            <p>Error!</p>
          </div>

          {process.env.ENV !== "UAT" || process.env.ENV !== "PRODUCTION" ? (
            <pre>{error.message}</pre>
          ) : (
            <p>Failed to fetch SL partner details</p>
          )}
        </div>
      )}
    >
      <button
        className="btn-primary hover:shadow-lg"
        onClick={() => setModalOpen(true)}
      >
        Approve loan
      </button>
      {isModalOpen ? (
        <ApproveLoan
          {...props}
          isOpen={isModalOpen}
          setOpen={setModalOpen}
          partnerData={partnerDataFromQuery}
        />
      ) : null}
    </ErrorBoundary>
  );
};

export default UI;
