// import { Spin } from "antd";
import React, { useState, useEffect } from "react";
import { hot } from "react-hot-loader";
// import './less/index.css'
// import { hot } from "react-hot-loader";
import AuthenticatedRoutes from "./routes";
import UnauthenticatedRoutes from "./routes/unauthenticated";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const App = () => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isAuthenticated && localStorage.getItem("token")) {
      setAuthenticated(true);
    }
    setLoading(false);
  }, [isAuthenticated]);

  return (
    <Spin indicator={antIcon} spinning={loading || false}>
      <div className="App Container bg-gray-100">
        {isAuthenticated ? (
          <AuthenticatedRoutes />
        ) : (
          <UnauthenticatedRoutes setAuthenticated={setAuthenticated} />
        )}
      </div>
    </Spin>
  );
};

export default hot(module)(App);
// export default App;
