import { useLazyQuery, useQuery } from "@apollo/client";
import { Button, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ErrorPage from "../../components/ErrorPage";
import ReactTable from "../../components/Table";
import { CSVLink } from "react-csv";
import { EXPORT_LOANS, GET_LOANS } from "./graphql";
import { EyeOutlined } from "@ant-design/icons";

const columns = [
  {
    title: "Loan ID",
    dataIndex: "loan_number",
    key: "loan_id",
    show: true,
    width: 150,
    fixed: 'left',
    sorter: (a, b) => {},
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Date / time",
    dataIndex: "created_at",
    key: "created_at",
    show: true,
    width: 200,
    sorter: (a, b) => {},
    sortDirections: ["descend", "ascend"],
    render: (data, text, index) => {
      return <div>{moment(data).format("DD-MM-YYYY HH:mm")}</div>;
    },
  },
  {
    title: "Loan Type",
    dataIndex: "loan_type",
    key: "loan_type",
    show: true,
    width: 100,
    render: (text, data, index) => {
      return <p>{text.loan_type}</p>;
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    show: true,
    width: 150,
    // sorter: (a, b) => {},
    // sortDirections: ["descend", "ascend"],
    // render: (text, data, index) => <div>{text ? `₹ ${text}` : null}</div>
    render: (text, data, index) => {
      return <div>{text ? `₹ ${text}` : null}</div>;
    },
  },
  {
    title: "User",
    dataIndex: "user",
    key: "user",
    show: true,
    width: 250,
  },
  {
    title: "GL Partner",
    dataIndex: "gl_partner",
    key: "gl_partner",
    show: true,
    width: 300,
  },
  {
    title: "Tenure",
    dataIndex: "tenure",
    key: "tenure",
    show: true,
    width: 150,
    sorter: (a, b) => {},
    sortDirections: ["descend", "ascend"],
    render: (text, data, index) => {
      return <div>{text} months</div>;
    },
  },
  {
    title: "Approver",
    dataIndex: "approver",
    key: "approver",
    show: true,
    width: 250,
  },
  {
    title: "Visit ID",
    dataIndex: "visit_display_id",
    key: "visit_id",
    show: true,
    width: 150,
    render: (text, data, index) => data.visit_display_id ? (
      <Link to={{pathname: `/visits/view/${data.visit_id}`}}>
        <p className="text-green-primary hover:underline cursor-pointer">
          {data?.visit_display_id || ""}
        </p>
      </Link>
    ) : null,
  },
  {
    title: "Agent",
    dataIndex: "agent_name",
    key: "agent_name",
    show: true,
    width: 250,
  },
  {
    title: "Status",
    dataIndex: "loan_status",
    key: "loan_status",
    show: true,
    width: 280,
    fixed: 'right',
    render: (text, data, index) => {
      return (
        <div>
          {data.gold_pickup_visit_status === "OD_LOAN_RECEIPTS_ADDED" ? (
            <Tag color={"red"} key="PENDING FUND TRANSFER" className="relative">
              <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-300 opacity-75"/>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-red-400"/>
              </span>
              PENDING FUND TRANSFER
            </Tag>
          ) : text ? (
            text === "PENDING_OROCORP_APPROVAL" ? (
              <Tag color={"orange"} key={text} className="relative">
                <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-300 opacity-75"/>
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-red-400"/>
                </span>
                {text}
              </Tag>
            ) : text === "OROCORP_APPROVED" ||
              text === "LOAN_AMOUNT_TRANSFERRED" ||
              text === "CST_APPROVED" ||
              text === "BLC_VERIFIED" ||
              text === "BLV_CONFIRMED" ||
              text === "GOLD_STORED" ||
              text === "GOLD_RELEASED" ||
              text === "LOAN_ACCOUNT_CLOSURE_INITIATED" ||
              text === "LOAN_CLOSED" ? (
              <Tag color={"green"} key={text}>
                {text}
              </Tag>
            ) : text === "OROCORP_REJECTED" ||
              text === "CST_REJECTED" ||
              text === "BLC_ABORTED" ||
              text === "BLV_ABORTED" ? (
              <Tag color={"red"} key={text}>
                {text}
              </Tag>
            ) : null
          ) : null}
        </div>
      );
    },
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    show: true,
    fixed: "right",
    width: 80,
    render: (text, data, index) => {
      return (
        <Link
          to={{
            pathname: `/loans/view/${data.id}`,
            state: {
              loan_status: data.loan_status,
            },
          }}
        >
          <EyeOutlined />
        </Link>
      );
    },
  },
];

const LoanListing = (props) => {
  const [filter, setFilter] = useState([
    {
      title: "Loan Type",
      dataIndex: "loan_type",
      key: "loan_type",
      type: "select",
      show: false,
      data: [
        // { id: "OD", name: "OD" },
        { id: "GL", name: "GL" },
      ],
      isFixed: true,
      filterType: "loan_type"
      //filter: { loan_type: { id: { _in: "$selected" } } },
    },
    {
      title: "Status",
      dataIndex: "loan_status",
      key: "loan_status",
      type: "select",
      show: false,
      data: [
        { id: "PENDING_OROCORP_APPROVAL", name: "Pending Orocorp Approval" },
        { id: "OROCORP_APPROVED", name: "Orocorp Approved" },
        { id: "OROCORP_REJECTED", name: "Orocorp Rejected" },
        { id: "CST_APPROVED", name: "CST Approved" },
        { id: "CST_REJECTED", name: "CST Rejected" },
        { id: "BLC_VERIFIED", name: "BLC Verified" },
        { id: "BLC_ABORTED", name: "BLC Aborted" },
        { id: "BLV_CONFIRMED", name: "BLV Confirmed" },
        { id: "BLV_ABORTED", name: "BLV Aborted" },
        { id: "GOLD_STORED", name: "Gold Stored" },
        { id: "GOLD_RELEASED", name: "Gold Released" },
        {
          id: "LOAN_ACCOUNT_CLOSURE_INITIATED",
          name: "Loan Account Closure Initiated",
        },
        { id: "LOAN_CLOSED", name: "Loan Closed" },
      ],
      isFixed: true,
      // filter: { _in: "$selected" }
    },
    {
      title: "Requested for",
      dataIndex: "created_at",
      key: "created_at",
      show: true,
      type: "date",
      data: [],
      isFixed: true,
      minDate: true,
      fromDate: "",
      toDate: "",
    },
  ]);
  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    // position: ["none", "none"],
    pageSize: 10,
    current: 1,
    total: 30,
  });
  const [filterObj, setFilterObj] = useState({});

  useEffect(() => {
    let objectFilters = {};
    // var re = new RegExp("$selected", "g");

    (filter || []).forEach((filter) => {
      if (filter.show || filter.isFixed) {
        if (filter.type === "select") {
          if ((filter.selected || []).length) {
            if (filter.filterType) {
              objectFilters[filter.key] = {
                [filter.filterType]: { _in: filter.selected },
              };
            } else {
              objectFilters[filter.key] = { _in: filter.selected };
            }
          }
        } else if (filter.type === "date") {
          if (filter.fromDate !== "" && filter.toDate !== "")
            objectFilters[filter.key] = {
              _gte: filter.fromDate,
              _lte: filter.toDate,
            };
        }
      }
    });

    // resetState();

    setFilterObj(objectFilters);
  }, [filter]);

  const [tableData, setTableData] = useState({
    page: 1,
    data: [],
    hasMoreItems: true,
  });

  const [searchText, SetSearchText] = useState("");
  
  const resetState = () => {
    setTableData({
      page: 1,
      data: [],
      hasMoreItems: true,
    });
    setPagination({
      ...pagination,
      total: 0,
      current: 1,
    });
  };

  const [sort, setSort] = useState(null);

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;

    if (sorter.field === "loanId" || sorter.field === "loan_number") {
      let order = sorter.order
        ? sorter.order === "descend"
          ? "desc"
          : "asc"
        : null;
      setSort({ id: order });
    } else if (sorter.field === "tenure") {
      let order = sorter.order
        ? sorter.order === "descend"
          ? "desc"
          : "asc"
        : null;

      setSort({ tenure: order });
    } else if (sorter.field === "created_at") {
      let order = sorter.order
        ? sorter.order === "descend"
          ? "desc"
          : "asc"
        : null;

      setSort({ created_at: order });
      // setSort({ odAmountByOdAmount: { credit_limit: order } });
    } else {
      setSort({ created_at: "desc" });
    }
    setTableData({
      ...tableData,
      page: pagination.current,
    });

    setPagination(pager);
  };

  const loanQueryOnComplete = (loanData) => {
    if (!loanData.loans) {
      return;
    }
    if (loanData.loans_aggregate) {
      setPagination({
        ...pagination,
        total: loanData.loans_aggregate.aggregate.count,
      });
    }
    let hasMoreItems = true;
    if ((loanData.plans || []).length === 0) {
      hasMoreItems = false;
    }

    let loans = (loanData.loans || []).map((loan) => {
      return {
        id: loan.id,
        loan_number: loan.loan_number,
        created_at: loan.created_at,
        loan_type: loan.loan_type,
        amount: loan.od_amount
          ? (
              loan.od_amount.credit_limit +
              (loan.support_loan?.sl_amount?.loan_amount || 0)
            ).toLocaleString("en-IN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : loan.gl_amount
          ? (
              loan.gl_amount.loan_amount +
              (loan.support_loan?.sl_amount?.loan_amount || 0)
            ).toLocaleString("en-IN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : null,
        user: loan.customer?.user?.kycs[0]?.legal_name || `${loan.customer.user.name}`,
        tenure: loan.tenure,
        approver: loan.orocorp_approved_by ? `${loan.orocorp_approved_by.first_name} ${loan.orocorp_approved_by.last_name}` : null,
        loan_status: loan.loan_status,
        gold_pickup_visit_status: loan.gold_pickup_visit.length > 0 ? loan.gold_pickup_visit[0].intermediate_status : null,
        visit_id: loan.visits.length > 0 ? loan.visits[0]?.id : null,
        visit_display_id: loan.visits.length > 0 ? loan.visits[0]?.visit_display_id : null,
        agent_name: loan.visits.length > 0 ? `${loan.visits[0]?.agent?.agent?.user?.first_name || ""} ${loan.visits[0]?.agent?.agent?.user?.last_name || ""}` : null,
        gl_partner: `${loan.partner_branch?.partner?.partner_name}, ${loan.partner_branch?.partner_branch_name}`,
      };
    });

    setTableData({
      ...tableData,
      data: loans,
      hasMoreItems: hasMoreItems,
    });
  };

  const [CSVData, setCSVData] = useState([]);

  const formatCSVData = (agents) => {
    // let CSVData = [];

    let CSVData = (exportData.loans || []).map((data) => {
      return {
        loanId: data.loan_number,
        date: moment(data.created_at).format(process.env.REACT_APP_DATE_FORMAT),
        loan_type: (data.loan_type || {}).loan_type || "",
        amount: "₹ " + (data.gl_amount || {}).loan_amount,
        user: ((data.customer || {}).user || {}).name || "",
        customer_mobile_number: ((data.customer || {}).user || {}).mobile_number,
        address:
          data.visits.length > 0
            ? `${
                data.visits[0].address["address line 1"] ||
                data.visits[0].address["addr_line_1"] ||
                ""
              }, ${
                data.visits[0].address["address line 2"] ||
                data.visits[0].address["addr_line_2"] ||
                ""
              }, ${data.visits[0].address["city"] || ""}, ${
                data.visits[0].address["state"] || ""
              }-${data.visits[0].address["pincode"] || ""}`
            : "",
        plan_name: (data.plan || {}).name,
        tenure: data.tenure + "months",
        total_net_wt:
          data.golds.length > 0
            ? `${data.golds
                .reduce((total, goldItem) => {
                  return total + goldItem.actual_net_weight;
                }, 0)
                .toFixed(2)} g`
            : "",
        total_gross_wt:
          data.golds.length > 0
            ? `${data.golds
                .reduce((total, goldItem) => {
                  return total + goldItem.gross_weight;
                }, 0)
                .toFixed(2)} g`
            : "",
        total_eq_net_wt:
          data.golds.length > 0
            ? `${data.golds
                .reduce((total, goldItem) => {
                  return total + goldItem.net_weight;
                }, 0)
                .toFixed(2)} g`
            : "",
        bank_account_number:
          (
            ((data.customer || {}).user || {}).user_bank_account_details[0] ||
            {}
          ).bank_account_number || "",
        bank_account_name:
          (
            ((data.customer || {}).user || {}).user_bank_account_details[0] ||
            {}
          ).bank_account_name || "",
        bank_name:
          (
            ((data.customer || {}).user || {}).user_bank_account_details[0] ||
            {}
          ).bank_name || "",
        ifsc:
          (
            ((data.customer || {}).user || {}).user_bank_account_details[0] ||
            {}
          ).ifsc || "",
        approver: data.orocorp_approved_by
          ? `${data.orocorp_approved_by.first_name} ${data.orocorp_approved_by.last_name}`
          : null,
        loan_status: data.loan_status,
        gl_partner: `${data.partner_branch?.partner?.partner_name}, ${data.partner_branch?.partner_branch_name}`,
        created_at: moment(data.created_at).format(
          process.env.REACT_APP_DATE_FORMAT
        ),
      };
    });

    setTimeout(function () {
      setCSVData(CSVData);
      triggerCSVDownload();
    }, 3000);
  };

  const triggerCSVDownload = () => {
    document.getElementById("react-csv").click();
  };

  const [
    exportLoanCSV,
    { data: exportData, loading: exportLoading, error: exportError },
  ] = useLazyQuery(EXPORT_LOANS, {
    variables: {
      where: {
        loan_number: {
          _ilike: searchText !== "" ? `%${searchText}%` : "%%",
        },
        ...filterObj,
      },
      order_by: {
        created_at: "asc",
      },
    },
    fetchPolicy: "network-only",
    onCompleted: formatCSVData,
  });
  
  const { loading: loanLoading, error: loanError } = useQuery(
    GET_LOANS,
    {
      variables: {
        limit: parseInt(process.env.REACT_APP_API_PAGE_LIMIT),
        offset: process.env.REACT_APP_API_PAGE_LIMIT * (tableData.page - 1),
        order_by: sort || { created_at: "desc" },
        where: {
          _and: [
            {
              _or: [
                { customer: { user: { name: { _ilike: `%${searchText}%` } } } },
                { loan_number: { _ilike: `%${searchText}%` } },
              ],
            },
            filterObj,
          ],
        },
      },
      fetchPolicy: "network-only",
      onCompleted: loanQueryOnComplete,
    }
  );

  const loadMoreItems = (value) => {
    if (!loanLoading && tableData.hasMoreItems) {
      setTableData((data) => {
        return { ...data, page: data.page + 1 };
      });
    }
  };

  if (loanError || exportError) {
    return <ErrorPage/>;
  }

  return (
    <div>
      <CSVLink
        data={CSVData}
        headers={[
          { label: "Loan ID", key: "loanId" },
          { label: "Date", key: "date" },
          { label: "Type", key: "loan_type" },
          { label: "Amount", key: "amount" },
          { label: "GL partner", key: "gl_partner" },
          { label: "Customer Name", key: "user" },
          { label: "Customer Mobile Number", key: "customer_mobile_number" },
          { label: "Addess", key: "address" },
          { label: "Plan Name", key: "plan_name" },
          { label: "Tenure", key: "tenure" },
          { label: "Total Net Wt.", key: "total_net_wt" },
          { label: "Total Eq. Net Wt.", key: "total_eq_net_wt" },
          { label: "Total Gross Wt.", key: "total_gross_wt" },
          { label: "Bank Account Name", key: "bank_account_name" },
          { label: "Bank Account Number", key: "bank_account_number" },
          { label: "Bank Name", key: "bank_name" },
          { label: "IFSC", key: "ifsc" },
          { label: "Approver", key: "approver" },
          { label: "Status", key: "loan_status" },
          { label: "Created At", key: "created_at" },
        ]}
        filename={`Loans - ${moment().format("DD-MM-YYYY HH:mm")}.csv`}
        className="hidden"
        target="_blank"
        id="react-csv"
      >
        Download CSV
      </CSVLink>
      <div className="flex justify-between align-center mb-4">
        <h2 className="text-green-primary">All loans</h2>
        <div className="flex">
          <Button
            className=" rounded-lg flex align-center ml-3 mr-3"
            type="primary"
            ghost
            size={"large"}
            onClick={() => {
              exportLoanCSV();
            }}
            loading={exportLoading}
          >
            Export
          </Button>
        </div>
      </div>
      <div className="fixed" style={{ width: "calc(100% - 168px - 2rem)" }}>
        <ReactTable
          columns={columns}
          data={tableData.data}
          table={{
            pagination: pagination,
            scroll: { x: 700 }
          }}
          filter={filter}
          page={tableData.page}
          hasMoreItems={tableData.hasMoreItems}
          loadItems={() => {
            loadMoreItems(0);
          }}
          handleTableChange={handleTableChange}
          isToggleFullScreen={false}
          isToggleColumn={true}
          isMoreFilter={false}
          isSearch={true}
          loading={loanLoading}
          onToolbarSearch={(value) => {
            if (parseInt(value.replace("OMGL", ""))) {
              resetState();
            }
            SetSearchText(value);
          }}
          onToolbarMore={(index, value) => {
            const filterData = [...filter];
            filterData[index].show = value;
            resetState();

            setFilter(filterData);
          }}
          onFilterSelectChange={(index, key, value) => {
            // console.log("filter value", value);
            const filterData = [...filter];
            filterData[index].selected = value;
            resetState();
            setFilter(filterData);
          }}
          onFilterDateChange={(index, dataKey, value) => {
            const filterData = [...filter];
            // console.log(filterData[index]);
            filterData[index].fromDate = moment(value[0]).toISOString();
            filterData[index].toDate = moment(value[1]).toISOString();
            resetState();

            setFilter(filterData);
          }}
        />
      </div>
    </div>
  );
};

export default LoanListing;
