// DEPS
import React, {useState} from "react";
import {useLazyQuery, useQuery} from "@apollo/client";
import { Select, Input, DatePicker } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import moment from "moment";
import {CSVLink} from "react-csv";

// COMPONENTS
import GlobalTable from "../../components/Table/UpgradedTable";
import ActionButton from '../../components/Button/ActionButton';
import GlobalButton from "../../components/Button/";
import ErrorPage from "../../components/ErrorPage";

//APIs
import {GET_ORO_OFFERS, EXPORT_ORO_OFFERS} from "./graphql";

const { Option } = Select;

const OroOffer = (props) => {
  const [CSVData, setCSVData] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [filter, setFilter] = React.useState({
    lender_type: "",
    start_date: null,
    end_date: null,
  });
  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    // position: ["none", "none"],
    pageSize: 10,
    current: 1,
    total: 30,
  });
  
  const resetState = () => {
    setPagination({
      ...pagination,
      total: 0,
      current: 1,
    });
  };
  
  const [sort, setSort] = useState({ created_at: "desc" });
  
  const { data: offerData, loading, error } = useQuery(GET_ORO_OFFERS, {
    fetchPolicy: "network-only",
    variables: {
      limit: parseInt(process.env.REACT_APP_API_PAGE_LIMIT),
      offset: process.env.REACT_APP_API_PAGE_LIMIT * (pagination.current - 1),
      where: {
        _and: [
          {
            _or: [
              { id: { _eq: parseInt(searchText.replace("OGL", "")) || 0 } },
              { customer_name: { _ilike: `%${searchText}%` } },
              { lender_name: { _ilike: `%${searchText}%` } }
            ]
          },
          {
            lender_type: { _ilike: `%${filter.lender_type}%` }
          },
          {
            created_at: filter.start_date ? { _gte: filter.start_date.utc().format() } : {}
          },
          {
            created_at: filter.end_date ? { _gte: filter.end_date.utc().format() } : {}
          }
        ]
      },
      order_by: { ...sort },
    },
    onCompleted: (data) => {
      setPagination({
        ...pagination,
        total: data?.oro_offers_aggregate?.aggregate?.count || 0,
        current: 1,
      });
    }
  });
  
  const handleExport = (data) => {
    let CSVData = (data.oro_offers || []).map(offer => ({
      created_at: moment(data.created_at).format(process.env.REACT_APP_DATE_FORMAT),
      offer_display_id: offer.offer_display_id,
      customer_name: offer.customer_name,
      customer_mobile_number: offer.customer_mobile_number,
      lender_name: offer.lender_name,
      lender_type: offer.lender_type,
      release_amount: offer?.offers_pledge_cards_aggregate?.aggregate?.sum?.release_amount || 0,
      oro_offer_amount: offer?.offer_plans_aggregate?.aggregate?.sum?.total_eligible_amount || 0,
    }));
    
    setTimeout(function () {
      setCSVData(CSVData);
      triggerCSVDownload();
    }, 3000);
  };
  
  const triggerCSVDownload = () => {
    document.getElementById("react-csv").click();
  };
  
  const [exportOffers] = useLazyQuery(EXPORT_ORO_OFFERS, {
    variables: {
      where: {
        _and: [
          {
            _or: [
              { id: { _eq: parseInt(searchText.replace("OGL", "")) || 0 } },
              { customer_name: { _ilike: `%${searchText}%` } },
              { lender_name: { _ilike: `%${searchText}%` } }
            ]
          },
          {
            lender_type: { _ilike: `%${filter.lender_type}%` }
          },
          {
            created_at: filter.start_date ? { _gte: filter.start_date.utc().format() } : {}
          },
          {
            created_at: filter.end_date ? { _gte: filter.end_date.utc().format() } : {}
          }
        ]
      },
      order_by: { created_at: "asc" },
    },
    onCompleted: handleExport
  });
  
  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    
    if (sorter.field === "created_at") {
      let order = sorter.order ? sorter.order === "descend" ? "desc" : "asc" : null;
      setSort({ created_at: order });
    } else if (sorter.field === "offer_display_id") {
      let order = sorter.order ? sorter.order === "descend" ? "desc" : "asc" : null;
      setSort({ id: order });
    } else if (sorter.field === "release_amount") {
      let order = sorter.order ? sorter.order === "descend" ? "desc" : "asc" : null;
      setSort({ offers_pledge_cards_aggregate: { sum: { release_amount: order } } });
    } else {
      setSort({ created_at: "desc" });
    }
    setPagination(pager);
  };
  
  const columns = [
    {
      key: 'created_at',
      title: 'Date/time',
      dataIndex: 'created_at',
      sorter: () => {},
      sortDirections: ['descend','ascend'],
      width: '10%',
      defaultSortOrder: "descend",
      render: (data, text, index) => moment(data).format("DD-MM-YYYY HH:mm"),
    },
    {
      key: 'offer_display_id',
      title: 'Offer ID',
      dataIndex: 'offer_display_id',
      sorter: () => {},
      sortDirections: ['descend','ascend'],
      width: '10%',
    },
    {
      key: 'customer_name',
      title: 'Customer name',
      dataIndex: 'customer_name',
      width: '10%',
    },
    {
      key: 'lender_type',
      title: 'Lender/Type',
      dataIndex: 'lender_type',
      width: '13%',
    },
    {
      key: 'release_amount',
      title: 'Release amount',
      dataIndex: 'release_amount',
      sorter: () => {},
      sortDirections: ['descend','ascend'],
      width: '10%',
      render: (data, record, index) => `₹ ${record?.offers_pledge_cards_aggregate?.aggregate?.sum?.release_amount || 0}`,
    },
    {
      key: 'amounts',
      title: 'Oro offer amounts',
      dataIndex: 'amounts',
      width: '13%',
      render: (data, record, index) => `₹ ${record?.offer_plans_aggregate?.aggregate?.sum?.total_eligible_amount || 0}`,
    },
    {
      key: 'actions',
      title: 'Actions',
      render: (data, record, index) => (
        <div className="flex items-center">
          <ActionButton
            label="Edit"
            handleClick={() => {
              props.history.push(`/oro-offer/edit/${record.id}`);
            }}
            type="secondary"
          />
          <ActionButton
            label="View"
            handleClick={() => props.history.push(`/oro-offer/view/${record.id}`)}
            type="primary"
          />
        </div>
      ),
      width: '5%',
    }
  ];
  
  if (error) {
    return <ErrorPage/>;
  }
  
  return(
    <div className="space-y-4">
      <CSVLink
        data={CSVData}
        headers={[
          { label: "Offer ID", key: "offer_display_id" },
          { label: "Customer Name", key: "customer_name" },
          { label: "Customer Mobile Number", key: "customer_mobile_number" },
          { label: "Lender Type", key: "lender_type" },
          { label: "Lender Name", key: "lender_name" },
          { label: "Release amount", key: "release_amount" },
          { label: "Oro offer amounts", key: "oro_offer_amount" },
          { label: "Date / Time", key: "created_at" },
        ]}
        filename={`Oro Offers - ${moment().format("DD-MM-YYYY HH:mm")}.csv`}
        className="hidden"
        target="_blank"
        id="react-csv"
      >
        Download CSV
      </CSVLink>
      <div className="w-full px-2 flex flex-row items-center py-3 justify-between">
        <h4 className="text-left font-bold text-lg">All Oro offers</h4>
        <GlobalButton
          handleClick={() => props.history.push("/oro-offer/create")}
          label="Generate a new Oro Offer"
        />
      </div>
      <div className="w-full flex flex-col lg:flex-row items-start lg:items-center py-3 lg:justify-between">
        <Select
          className="lg:w-1/6 outline-none focus:outline-none"
          placeholder="Filter by Lender type"
          value={filter.lender_type || undefined}
          allowClear
          onChange={(value) => {
            setFilter({ ...filter, lender_type: value || "" });
            resetState();
          }}
        >
          {offerData?.filterData.map((filter, i) => (
            <Option value={filter.lender_type}>{filter.lender_type}</Option>
          ))}
        </Select>
        <div className="w-full lg:w-2/3 flex flex-col lg:flex-row lg:items-center lg:justify-end items-start py-2 lg:py-0">
          <Input
            size="large"
            placeholder="Search by offer ID, customer name, lender"
            className="my-2 lg:my-0 focus:outline-none rounded-md w-full lg:w-1/3 mr-4"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              resetState();
            }}
          />
          <DatePicker.RangePicker
           className="mr-4"
           size="large"
           onChange={(dates, dateStrings) => {
             setFilter({
               ...filter,
               start_date: dates[0],
               end_date: dates[1],
             });
             resetState();
           }}
           value={[filter.start_date, filter.end_date]}
          />
          <GlobalButton
            className="my-2 lg:my-0"
            size="sm"
            label="CSV Export data"
            handleClick={() => exportOffers()}
          />
        </div>
      </div>
      <GlobalTable
        columns={columns}
        data={offerData?.oro_offers}
        bordered={true}
        loading={loading}
        rowKey={record => record.id}
        handleTableChange={handleTableChange}
        pagination={pagination}
      />
    </div>
  )
}

export default OroOffer;
