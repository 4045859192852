import React from "react";

import {
  ReactForm,
  ReactInput,
  ReactRelationalCheckboxGroup,
  ImageUpload,
} from "../../components/Form";

import { useQuery, useMutation } from "@apollo/client";
import Loading from "../../components/loading";
import { notification, Popconfirm } from "antd";
import { partnerEditValidationSchema } from "./validationSchema";
import {
  GET_PARTNER_DETAILS_FOR_EDIT,
  GET_PARTNER_TYPES,
  UPDATE_PARTNER,
} from "./graphql";
import ErrorPage from "../../components/ErrorPage";

const EditPartner = (props) => {
  const formRef = React.useRef();

  const {
    data: partnerData,
    loading: partnerDataLoading,
    error: partnerDataError,
  } = useQuery(GET_PARTNER_DETAILS_FOR_EDIT, {
    variables: {
      id: parseInt(props.match.params.id),
    },
  });

  const {
    data: partnerTypesData,
    loading: loadingPartnerTypes,
    error: partnerTypesQueryError,
  } = useQuery(GET_PARTNER_TYPES);

  const [updatePartner] = useMutation(UPDATE_PARTNER, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description: "Something went wrong!",
        });
      });
    },
    onCompleted() {
      notification.success({
        message: "Success!",
        description: "Partner details updated!",
      });

      props.history.push("/partners");
    },
  });

  if (partnerDataLoading || loadingPartnerTypes) return <Loading />;

  if (partnerDataError || partnerTypesQueryError) return <ErrorPage />;

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  // let PartnerTypesOptions = partnerTypesData.partner_types.map(
  //   (partner_type) => {
  //     let obj = {
  //       label: partner_type.partner_type,
  //       value: partner_type.id,
  //     };

  //     return obj;
  //   }
  // );

  return (
    <div className="space-y-4 py-16">
      <div className="flex justify-between">
        <h1>Edit partner</h1>
      </div>

      <ReactForm
        innerRef={formRef}
        onSubmit={(values) => {
          values.partner_type_id = values.partner_type_id.map((type) => {
            let temp = {
              partner_type_id: type.partner_type_id,
              is_active: type.is_active,
              partners_id: parseInt(props.match.params.id),
            };
            return temp;
          });

          updatePartner({
            variables: {
              id: parseInt(props.match.params.id),
              partner_data: {
                partner_name: values.name,
                partner_logo: values.partner_logo,
              },
              partner_types: values.partner_type_id,
            },
          });
        }}
        initialValues={{
          name: partnerData.partners[0].partner_name,
          partner_type_id: partnerData.partners[0].partners_x_partnerTypes,
          partner_logo: partnerData.partners[0].partner_logo,
        }}
        validationSchema={partnerEditValidationSchema}
        formCls="flex flex-col content-center justify-center space-y-8"
      >
        {/* Partner overview div*/}
        <div className="border">
          {/* Partner overview header */}
          <div className="border-b w-full px-4 py-2 bg-green-primary">
            <h2 className="text-white">Plan overview</h2>
          </div>
          {/* Partner overview header */}

          {/* Partner overview form */}
          <div className="p-4 space-y-4">
            {/* Partner overview form - line 1*/}
            <div className="flex space-x-8 items-center">
              <div className="w-1/3">
                <ReactInput
                  name="name"
                  type="text"
                  label="Bank Name"
                  placeholder=""
                ></ReactInput>
              </div>

              <div className="w-1/3">
                <ReactRelationalCheckboxGroup
                  name="partner_type_id"
                  label="Bank type"
                  // options={[
                  //   { label: "Personal Lender", value: 4 },
                  //   { label: "NBFC", value: 2 },
                  // ]}
                  options={partnerTypesData.partner_types.map((type) => {
                    return {
                      label: type.partner_type,
                      value: type.id,
                    };
                  })}
                  relation_type="partner_type_id"
                  mode="Update"
                  // defaultValue={["Pear"]}
                />
              </div>
            </div>
            {/* Partner overview form - line 1*/}
          </div>
          {/* Partner overview form */}
        </div>
        {/* Partner overview div*/}

        <div className="space-y-2 relative">
          <h2 className="text-gray-800 font-semibold">Partner logo:</h2>
          {/* <button className="cta hover:shadow-lg">Upload</button> */}
          <ImageUpload
            buttonText="Upload"
            multiple={false}
            name="partner_logo"
            showError={true}
            pre_files={partnerData.partners[0].partner_logo}
            editMode={true}
            maxLength={1}
          />
        </div>
      </ReactForm>

      <div className="flex justify-center w-full space-x-8">
        <Popconfirm
          title={`Are you sure you want to cancel?`}
          onConfirm={() => {
            props.history.push("/partners");
          }}
          onCancel={() => {}}
          okText="Yes"
          placement="bottomRight"
          cancelText="No"
        >
          <button className="btn-danger hover:shadow-lg">Cancel</button>
        </Popconfirm>
        {/* <button className="btn-primary hover:shadow-lg">Create Partner</button> */}
        <button
          onClick={() => handleSubmit()}
          type="submit"
          className="btn-primary hover:shadow-lg"
        >
          Update Partner
        </button>
      </div>
    </div>
  );
};

export default EditPartner;
