import { useLazyQuery, useQuery } from "@apollo/client";
import { Button } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ErrorPage from "../../components/ErrorPage";
import ReactTable from "../../components/Table";
import { CSVLink } from "react-csv";
import { EXPORT_SUPPORT_LOANS, GET_SUPPORT_LOANS } from "./graphql";

const columns = [
  {
    title: "Support Loan ID",
    dataIndex: "support_loan_id",
    key: "support_loan_id",
    show: true,
    width: 150,
    sorter: (a, b) => {},
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Primary Loan ID",
    dataIndex: "primary_loan_number",
    key: "primary_loan_number",
    show: true,
    width: 150,
    sorter: (a, b) => {},
    sortDirections: ["descend", "ascend"],
    render: (text, data, index) => {
      return (
        <Link
          to={{
            pathname: `/loans/view/${data.primary_loan_id}`,
            state: {
              loan_status: data.loan_status,
            },
          }}
        >
          <p className="text-green-primary hover:underline cursor-pointer">
            {text}
          </p>
        </Link>
      );
    },
  },
  {
    title: "Created at",
    dataIndex: "created_at",
    key: "created_at",
    show: true,
    sorter: (a, b) => {},
    width: 150,
    sortDirections: ["descend", "ascend"],
    render: (data, text, index) => {
      return <div>{moment(data).format("DD-MM-YYYY HH:mm")}</div>;
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    show: true,
    width: 100,
    // sorter: (a, b) => {},
    // sortDirections: ["descend", "ascend"],
    // render: (text, data, index) => <div>{text ? `₹ ${text}` : null}</div>
    render: (text, data, index) => {
      return <div>{text ? `₹ ${text}` : null}</div>;
    },
  },
  {
    title: "Customer",
    dataIndex: "customer",
    key: "customer",
    show: true,
    width: 100,
  },
  {
    title: "Tenure",
    dataIndex: "tenure",
    key: "tenure",
    show: true,
    // sorter: (a, b) => {},
    width: 100,
    // sortDirections: ["descend", "ascend"],
    render: (text, data, index) => {
      return <div>{text} months</div>;
    },
  },
  {
    title: "Approver",
    dataIndex: "approver",
    key: "approver",
    show: true,
    width: 200,
  },
  {
    title: "Partner",
    dataIndex: "partner",
    key: "partner",
    show: true,
    width: 200,
  },
  // {
  //   title: "Actions",
  //   dataIndex: "actions",
  //   key: "actions",
  //   show: true,
  //   fixed: "right",
  //   width: 100,
  //   render: (text, data, index) => {
  //     return (
  //       <Link
  //         to={{
  //           pathname: `/support-loans/view/${data.id}`,
  //         }}
  //       >
  //         <EyeOutlined />
  //       </Link>
  //     );
  //   },
  // },
];

const SupportLoanListing = (props) => {
  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    // position: ["none", "none"],
    pageSize: 10,
    current: 1,
    total: 30,
  });

  const [tableData, setTableData] = useState({
    page: 1,
    data: [],
    hasMoreItems: true,
  });

  const [searchText, SetSearchText] = useState("");
  const resetState = () => {
    setTableData({
      page: 1,
      data: [],
      hasMoreItems: true,
    });
    setPagination({
      ...pagination,
      total: 0,
      current: 1,
    });
  };

  const [sort, setSort] = useState(null);

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;

    if (
      sorter.field === "support_loan_id" ||
      sorter.field === "support_loan_id"
    ) {
      let order = sorter.order
        ? sorter.order === "descend"
          ? "desc"
          : "asc"
        : "desc";
      setSort({ id: order });
    } else if (sorter.field === "created_at") {
      let order = sorter.order
        ? sorter.order === "descend"
          ? "desc"
          : "asc"
        : "desc";

      setSort({ created_at: order });
    } else {
      setSort({ created_at: "asc" });
    }
    setTableData({
      ...tableData,
      page: pagination.current,
    });

    setPagination(pager);
  };

  const loanQueryOnComplete = (loanData) => {
    if (!loanData.support_loans) {
      return;
    }
    if (loanData.support_loans_aggregate) {
      setPagination({
        ...pagination,
        total: loanData.support_loans_aggregate.aggregate.count,
      });
    }
    let hasMoreItems = true;
    // if ((loanData.plans || []).length === 0) {
    //   hasMoreItems = false;
    // }

    let loans = (loanData.support_loans || []).map((loan) => {
      return {
        support_loan_id: loan.support_loan_id,
        primary_loan_number: loan.primary_loan.loan_number,
        primary_loan_id: loan.primary_loan.id,
        loan_status: loan.primary_loan.loan_status,
        created_at: loan.created_at,
        amount: loan.sl_amount.loan_amount,
        customer: loan.primary_loan?.kyc?.legal_name,
        tenure: loan.plan.tenure,
        approver: `${loan.primary_loan?.orocorp_approved_by?.first_name} ${loan.primary_loan?.orocorp_approved_by?.last_name}`,
        partner: loan.partner_branch.partner.partner_name,
      };
    });

    setTableData({
      ...tableData,
      data: loans,
      hasMoreItems: hasMoreItems,
    });
  };

  const [CSVData, setCSVData] = useState([]);

  const formatCSVData = (supportLoans) => {
    // let CSVData = [];

    let CSVData = (supportLoans.support_loans || []).map((loan) => {
      return {
        support_loan_id: loan.support_loan_id,
        primary_loan_number: loan.primary_loan.loan_number,
        primary_loan_id: loan.primary_loan.id,
        loan_status: loan.primary_loan.loan_status,
        created_at: moment(loan.created_at).format(
          process.env.REACT_APP_DATE_FORMAT
        ),
        amount: "₹ " + loan.sl_amount.loan_amount,
        customer: loan.primary_loan?.kyc?.legal_name,
        tenure: loan.plan.tenure + " month(s)",
        approver: `${loan.primary_loan?.orocorp_approved_by?.first_name} ${loan.primary_loan?.orocorp_approved_by?.last_name}`,
        partner: loan.partner_branch.partner.partner_name,
        plan_name: loan.plan.plan_name,
      };
    });

    setTimeout(function () {
      setCSVData(CSVData);
      triggerCSVDownload();
    }, 3000);
  };

  const triggerCSVDownload = () => {
    document.getElementById("react-csv").click();
  };

  const [exportLoanCSV, { loading: exportLoading, error: exportError }] = useLazyQuery(EXPORT_SUPPORT_LOANS, {
    variables: {
      where: {
        _and: [
          {
            _or: [
              {
                customer: {
                  name: { _ilike: `%${searchText}%` },
                },
              },
              {
                support_loan_id: {
                  _ilike: searchText !== "" ? `%${searchText}%` : "",
                },
              },
            ],
          },
        ],
      },
      order_by: {
        created_at: "asc",
      },
    },
    fetchPolicy: "network-only",
    onCompleted: formatCSVData,
  });

  const { loading: loanLoading, error: loanError } = useQuery(GET_SUPPORT_LOANS, {
    variables: {
      limit: parseInt(process.env.REACT_APP_API_PAGE_LIMIT),
      offset: process.env.REACT_APP_API_PAGE_LIMIT * (tableData.page - 1),
      order_by: sort || { created_at: "desc" },
      where: {
        _and: [
          {
            _or: [
              {
                customer: {
                  name: { _ilike: `%${searchText}%` },
                },
              },
              {
                support_loan_id: {
                  _ilike: searchText !== "" ? `%${searchText}%` : "",
                },
              },
            ],
          },
        ],
      },
    },
    fetchPolicy: "network-only",
    onCompleted: loanQueryOnComplete,
  });

  const loadMoreItems = (value) => {
    if (!loanLoading && tableData.hasMoreItems) {
      setTableData((data) => {
        return { ...data, page: data.page + 1 };
      });
    }
  };

  if (loanError || exportError) {
    return <ErrorPage></ErrorPage>;
  }

  if (loanLoading) {
    // console.log("loading");
  }

  return (
    <div>
      <CSVLink
        data={CSVData}
        headers={[
          { label: "Loan ID", key: "support_loan_id" },
          { label: "Primary loan ID", key: "primary_loan_number" },
          { label: "Start Date", key: "created_at" },
          { label: "Amount", key: "amount" },
          { label: "Customer Name", key: "customer" },
          // { label: "Customer Mobile Number", key: "customer_mobile_number" },
          // { label: "Addess", key: "address" },
          { label: "Plan Name", key: "plan_name" },
          { label: "Tenure", key: "tenure" },
          { label: "Approver", key: "approver" },
          { label: "Partner", key: "partner" },
        ]}
        filename={`Support loans - ${moment().format("DD-MM-YYYY HH:mm")}.csv`}
        className="hidden"
        target="_blank"
        id="react-csv"
      >
        Download CSV
      </CSVLink>
      <div className="flex justify-between  align-center mb-4">
        <h2 className="text-green-primary">All support loans</h2>
        <div className="flex">
          <Button
            className=" rounded-lg flex align-center ml-3 mr-3"
            type="primary"
            ghost
            size={"large"}
            onClick={() => {
              exportLoanCSV();
            }}
            loading={exportLoading}
          >
            Export
          </Button>
        </div>
      </div>
      <div className="w-100 position-relatives">
        <ReactTable
          columns={columns}
          data={tableData.data}
          table={{
            pagination: pagination,
          }}
          // filter={filter}
          page={tableData.page}
          hasMoreItems={tableData.hasMoreItems}
          loadItems={() => {
            loadMoreItems(0);
          }}
          handleTableChange={handleTableChange}
          isToggleFullScreen={false}
          isToggleColumn={true}
          isMoreFilter={false}
          isSearch={true}
          loading={loanLoading}
          onToolbarSearch={(value) => {
            if (parseInt(value.replace("OMGL", ""))) {
              resetState();
            }
            SetSearchText(value);
          }}
          // onToolbarMore={(index, value) => {
          //   const filterData = [...filter];
          //   filterData[index].show = value;
          //   resetState();

          //   setFilter(filterData);
          // }}
          // onFilterSelectChange={(index, key, value) => {
          //   // console.log("filter value", value);
          //   const filterData = [...filter];
          //   filterData[index].selected = value;
          //   resetState();
          //   setFilter(filterData);
          // }}
          // onFilterDateChange={(index, dataKey, value) => {
          //   const filterData = [...filter];
          //   // console.log(filterData[index]);
          //   filterData[index].fromDate = moment(value[0]).toISOString();
          //   filterData[index].toDate = moment(value[1]).toISOString();
          //   resetState();

          //   setFilter(filterData);
          // }}
        ></ReactTable>
      </div>
    </div>
  );
};

export default SupportLoanListing;
