import { TimePicker } from "antd";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import "./form.css";

const ReactTimepicker = props => {
  const { errors, setFieldValue } = useFormikContext();

  return (
    <div className="text-red-500 text-left space-y-2">
      {props.label && (
        <label className="text-gray-800 font-semibold text-lg">
          {props.label}
        </label>
      )}
      <div>
        <TimePicker
          // {...field}
          {...props}
          format="HH:mm"
          // onChange={time => {
          //   return setValue(time);
          // }}
          onChange={time =>
            setFieldValue(props.name, moment(time).format("HH:mm"))
          }
          className="w-full text-center"
        />
      </div>

      {/* {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null} */}
      <div style={{ minHeight: 21 }}>
        {errors[props.name] ? (
          <div className="error">{errors[props.name]}</div>
        ) : null}
      </div>
    </div>
  );
};

ReactTimepicker.propTypes = {
  label: PropTypes.string
};
export default ReactTimepicker;
