import React from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { Tag, notification } from "antd";

import ErrorPage from "../../components/ErrorPage";
// import Loading from "../../components/loading";
import ReactTable from "../../components/Table";

const GET_SUPPORT_CALLBACK_REQUESTS = gql`
  query getCallbackRequests {
    callback_requests(order_by: { created_at: desc }) {
      id
      message
      is_resolved
      user {
        id
        first_name
        last_name
        mobile_number
      }
    }
  }
`;

const RESOLVE_REQUEST = gql`
  mutation resolveRequest($id: Int!) {
    update_callback_requests_by_pk(
      pk_columns: { id: $id }
      _set: { is_resolved: true }
    ) {
      id
      is_resolved
    }
  }
`;

const SupportCallbackRequests = props => {
  const {
    loading: callbackRequestsLoading,
    data: callbackRequests,
    error: callBackRequestQueryError
  } = useQuery(GET_SUPPORT_CALLBACK_REQUESTS, {
    fetchPolicy: "network-only"
  });

  const [resolveRequest] = useMutation(RESOLVE_REQUEST, {
    onError(error) {
      error.graphQLErrors.forEach(error => {
        notification.error({
          message: "Error!",
          description: "Something went wrong. Please try again later"
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Resolved"
      });
    },
    refetchQueries: [
      {
        query: GET_SUPPORT_CALLBACK_REQUESTS
      }
    ]
  });

  //   if (callbackRequestsLoading) return <Loading />;

  if (callBackRequestQueryError) return <ErrorPage />;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      show: true,
      width: 150
      //   sorter: (a, b) => {},
      //   sortDirections: ["descend", "ascend"]
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      show: true
    },
    {
      title: "Contact number",
      dataIndex: "contact_number",
      key: "contact_number",
      show: true,
      render: (text, data) => {
        return <p>{data.user.mobile_number}</p>;
      }
    },
    {
      title: "Request from",
      dataIndex: "from",
      key: "from",
      show: true,
      render: (text, data, index) => {
        return (
          <p>
            {data.user.first_name} {data.user.last_name}
          </p>
        );
      }
    },
    {
      title: "Resolved",
      dataIndex: "is_resolved",
      key: "message",
      show: true,
      render: (text, data, index) => {
        return (
          <div>
            {text ? (
              <Tag color={"green"} key={text}>
                Resolved
              </Tag>
            ) : (
              <Tag color={"red"} key={text}>
                Unresolved
              </Tag>
            )}
          </div>
        );
      }
    },
    {
      title: "Resolve",
      dataIndex: "resolve",
      key: "resolve",
      show: true,
      render: (text, data, index) => {
        return (
          <button
            className={
              !data.is_resolved
                ? "btn-primary"
                : "py-2 px-4 border bg-gray-200 cursor-not-allowed rounded text-white"
            }
            disabled={data.is_resolved}
            onClick={() => {
              resolveRequest({
                variables: {
                  id: data.id
                }
              });
            }}
          >
            Resolve
          </button>
        );
      }
    }
  ];

  return (
    <div className="space-y-8">
      <h2 className="text-green-primary">Callback requests</h2>

      <ReactTable
        columns={columns}
        data={callbackRequests ? callbackRequests.callback_requests : []}
        table={{
          rowKey: record => record.id
        }}
        isToggleFullScreen={false}
        isToggleColumn={true}
        isMoreFilter={false}
        isSearch={false}
        loading={callbackRequestsLoading}
        //   onToolbarSearch={value => {
        //     resetState();
        //     SetSearchText(value);
        //   }}
        //   onToolbarMore={(index, value) => {
        //     const filterData = [...filter];
        //     filterData[index].show = value;
        //     resetState();

        //     setFilter(filterData);
        //   }}
        //   onFilterSelectChange={(index, key, value) => {
        //     const filterData = [...filter];
        //     filterData[index].selected = value;
        //     resetState();
        //     setFilter(filterData);
        //   }}
        //   handleTableChange={handleTableChange}

        // onChangeDate={(index, dataKey, value) => {
        //   const filter = [...filter];
        //   //filter[index].fromDate = moment(value[0]).toISOString();
        //   // filter[index].toDate = moment(value[1]).toISOString();
        //   resetState();

        //   setFilter(filterData);
        // }}
      ></ReactTable>
    </div>
  );
};

export default SupportCallbackRequests;
