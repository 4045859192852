import * as React from "react";

import {
  ReactForm,
  ReactInput,
  // ReactRelationalCheckboxGroup,
  // ReactWorkingHourspickerEditMode,
  ReactSelect,
  // ReactGeocoding,
} from "../../components/Form";
import Loading from "../../components/loading";
import ErrorPage from "../../components/ErrorPage";
import { LeftOutlined } from "@ant-design/icons";
// import { GET_LOAN_TYPES } from "../Plan/graphql";
import { GET_SL_PARTNERS_LIST } from "./graphql";
import { useQuery, useMutation } from "@apollo/client";
import { editBranchValidationSchema } from "./validationSchema";

import {
  GET_PARTNER_BRANCH_DETAILS_FOR_EDIT,
  UPDATE_PARTNER_BRANCH,
} from "./graphql";
import { Select, notification, Popconfirm, Button } from "antd";
const { Option } = Select;

const EditBranch = (props) => {
  const formRef = React.useRef();

  // const {
  //   data: loanTypesData,
  //   loading: loadingLoanTypes,
  //   error: loanTypesQueryError,
  // } = useQuery(GET_LOAN_TYPES);

  const {
    data: branchData,
    loading: branchDataLoading,
    error: branchDataError,
  } = useQuery(GET_PARTNER_BRANCH_DETAILS_FOR_EDIT, {
    variables: {
      id: parseInt(props.match.params.id),
    },
  });

  const {
    data: partnersData,
    loading: loadingPartners,
    error: partnersQueryError,
  } = useQuery(GET_SL_PARTNERS_LIST);

  const [updateParnterBranch] = useMutation(UPDATE_PARTNER_BRANCH, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description: "Something went wrong!",
        });
      });
    },
    onCompleted() {
      notification.success({
        message: "Success!",
        description: "Partner branch details updated!",
      });

      props.history.push("/partners");
    },
  });

  // const [checkedDays, setCheckedDays] = React.useState({
  //   Monday: false,
  //   Tuesday: false,
  //   Wednesday: false,
  //   Thursday: false,
  //   Friday: false,
  //   Saturday: false,
  //   Sunday: false
  // });

  if (branchDataLoading || loadingPartners) return <Loading />;

  if (branchDataError || partnersQueryError) return <ErrorPage />;

  // let LoanTypesoptions = loanTypesData.loan_types.map((loan_type) => {
  //   let obj = {
  //     label: loan_type.loan_type,
  //     value: loan_type.id,
  //     is_active: loan_type.is_active,
  //   };

  //   return obj;
  // });

  let initialValues = {
    branch_name: branchData.partner_branches[0].partner_branch_name,
    partner: branchData.partner_branches[0].partners_id,
    // loan_types: branchData.partner_branches[0].partners_branches_x_loanTypes,
    // name: branchData.partner_branches[0].poc_name,
    // number: branchData.partner_branches[0].poc_phone_number,
    // address: {
    //   addr_line_1: branchData.partner_branches[0].address.addr_line_1,
    //   addr_line_2: branchData.partner_branches[0].address.addr_line_2,
    //   city: branchData.partner_branches[0].address.city,
    //   state: branchData.partner_branches[0].address.state,
    //   pincode: branchData.partner_branches[0].address.pincode,
    //   country: "India",
    //   label: "Home",
    //   preferred: "yes",
    // },
    // lat: branchData.partner_branches[0].lat,
    // lng: branchData.partner_branches[0].long,
    // isChecked: {
    //   Monday: false,
    //   Tuesday: false,
    //   Wednesday: false,
    //   Thursday: false,
    //   Friday: false,
    //   Saturday: false,
    //   Sunday: false
    // },
    // hours: {
    //   Monday: branchData.partner_branches[0].working_hours.Monday || {},
    //   Tuesday: branchData.partner_branches[0].working_hours.Tuesday || {},
    //   Wednesday: branchData.partner_branches[0].working_hours.Wednesday || {},
    //   Thursday: branchData.partner_branches[0].working_hours.Thursday || {},
    //   Friday: branchData.partner_branches[0].working_hours.Friday || {},
    //   Saturday: branchData.partner_branches[0].working_hours.Saturday || {},
    //   Sunday: branchData.partner_branches[0].working_hours.Sunday || {}
    // }
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <div className="space-y-4 py-8">
      {props.location.state !== undefined ? (
        !props.location.state.fromBranch ? (
          <div className="flex items-center justify-start">
            <Button
              type="link"
              icon={<LeftOutlined />}
              className="flex items-center mr-4"
              onClick={() => {
                props.history.push("/partners");
              }}
            >
              All Partners
            </Button>
          </div>
        ) : null
      ) : null}

      <div className="flex justify-between">
        <h1> Edit branch</h1>
      </div>

      <ReactForm
        innerRef={formRef}
        onSubmit={(values) => {
          // let partner_loan_types = values.loan_types.map((type) => {
          //   let obj = {
          //     loan_type_id: type.loan_type_id,
          //     is_active: type.is_active,
          //     partner_branches_id: props.match.params.id,
          //   };
          //   return obj;
          // });

          console.log("values.partner", values.partner);

          updateParnterBranch({
            variables: {
              id: parseInt(props.match.params.id),
              partner_details: {
                partner_branch_name: values.branch_name,
                partners_id: values.partner,
                // poc_name: values.name,
                // poc_phone_number: values.number,
                // address: values.address,
                // lat: values.lat,
                // long: values.lng,
                // working_hours: values.hours
              },
              // partner_loan_types: partner_loan_types,
            },
          });
        }}
        initialValues={initialValues}
        validationSchema={editBranchValidationSchema}
        formCls="flex flex-col content-center justify-center space-y-8"
      >
        {/* Branch overview section */}
        <div className="border">
          {/* Branch overview header */}
          <div className="border-b w-full px-4 py-2 bg-green-primary">
            <h2 className="text-white">Branch overview</h2>
          </div>
          {/* Branch overview header */}

          {/* Branch overview form */}
          <div className="p-4 space-y-4">
            {/* Branch overview form - line 1*/}
            <div className="flex space-x-8 items-center">
              <div className="w-1/3">
                <ReactInput
                  name="branch_name"
                  type="text"
                  label="Branch Name"
                  placeholder=""
                />
              </div>

              <div className="w-1/3">
                <ReactSelect
                  name="partner"
                  type="text"
                  label="Partner"
                  placeholder="Choose partner"
                  // disabled={true}
                >
                  {partnersData.partners.map((partner) => {
                    return (
                      <Option value={partner.id}>{partner.partner_name}</Option>
                    );
                  })}
                </ReactSelect>
              </div>

              {/* <div className="w-1/3">
                <ReactRelationalCheckboxGroup
                  name="loan_types"
                  label="Available loan types"
                  options={LoanTypesoptions}
                  relation_type="loan_type_id"
                  mode="Update"
                />
              </div> */}
            </div>
          </div>
        </div>

        {/* Partner details section */}
        {/* <div className="border"> */}
        {/* Partner details header */}
        {/* <div className="border-b w-full px-4 py-2 bg-green-primary">
            <h2 className="text-white">Branch details</h2>
          </div> */}
        {/* Partner details header */}

        {/* Partner details form */}
        {/* <div className="p-4 space-y-4"> */}
        {/* Partner details form - line 1*/}
        {/* <div className="flex space-x-8 items-center">
              <div className="w-2/3 flex space-x-8">
                <div className="w-1/2">
                  <ReactInput
                    name="name"
                    type="text"
                    label="POC Name"
                    placeholder=""
                  ></ReactInput>
                </div>
              </div>

              <div className="w-1/3">
                <ReactInput
                  name="number"
                  type="text"
                  label="POC contact number"
                  placeholder=""
                ></ReactInput>
              </div>
            </div> */}
        {/* Partner details form - line 1*/}

        {/* Partner details form - line 2*/}
        {/* <div className="space-y-2">
              <p className="text-gray-800 font-semibold text-lg">Address</p>
              <div className="w-2/3">
                <ReactInput
                  name="address.addr_line_1"
                  type="text"
                  placeholder="Address line 1"
                />
              </div>
              <div className="w-2/3">
                <ReactInput
                  name="address.addr_line_2"
                  type="text"
                  placeholder="Address line 2"
                />
              </div>
              <div className="w-2/3 flex space-x-8">
                <div className="w-1/3">
                  <ReactInput
                    name="address.city"
                    type="text"
                    placeholder="City"
                  />
                </div>

                <div className="w-1/3">
                  <ReactSelect
                    name="address.state"
                    type="text"
                    placeholder="State"
                  >
                    <Option value="Andhra Pradesh">Andhra Pradesh</Option>
                    <Option value="Andaman and Nicobar Islands">
                      Andaman and Nicobar Islands
                    </Option>
                    <Option value="Arunachal Pradesh">Arunachal Pradesh</Option>
                    <Option value="Assam">Assam</Option>
                    <Option value="Bihar">Bihar</Option>
                    <Option value="Chandigarh">Chandigarh</Option>
                    <Option value="Chhattisgarh">Chhattisgarh</Option>
                    <Option value="Dadra and Nagar Haveli and Daman and Diu">
                      Dadra and Nagar Haveli and Daman and Diu
                    </Option>
                    <Option value="Delhi">Delhi</Option>
                    <Option value="Goa">Goa</Option>
                    <Option value="Gujarat">Gujarat</Option>
                    <Option value="Haryana">Haryana</Option>
                    <Option value="Himachal Pradesh">Himachal Pradesh</Option>
                    <Option value="Jammu and Kashmir">Jammu and Kashmir</Option>
                    <Option value="Jharkhand">Jharkhand</Option>
                    <Option value="Karnataka">Karnataka</Option>
                    <Option value="Kerala">Kerala</Option>
                    <Option value="Ladakh">Ladakh</Option>
                    <Option value="Lakshadweep">Lakshadweep</Option>
                    <Option value="Madhya Pradesh">Madhya Pradesh</Option>
                    <Option value="Maharashtra	">Maharashtra </Option>
                    <Option value="Manipur">Manipur</Option>
                    <Option value="Meghalaya">Meghalaya</Option>
                    <Option value="Mizoram">Mizoram</Option>
                    <Option value="Nagaland">Nagaland</Option>
                    <Option value="Odisha">Odisha</Option>
                    <Option value="Puducherry">Puducherry</Option>
                    <Option value="Punjab">Punjab</Option>
                    <Option value="Rajasthan">Rajasthan</Option>
                    <Option value="Sikkim">Sikkim</Option>
                    <Option value="Tamil Nadu">Tamil Nadu</Option>
                    <Option value="Telangana">Telangana</Option>
                    <Option value="Tripura">Tripura</Option>
                    <Option value="Uttar Pradesh">Uttar Pradesh</Option>
                    <Option value="Uttarakhand">Uttarakhand</Option>
                    <Option value="West Bengal">West Bengal</Option>
                  </ReactSelect>
                </div>

                <div className="w-1/3">
                  <ReactInput
                    name="address.pincode"
                    type="text"
                    placeholder="Pincode"
                  />
                </div>
              </div>
            </div> */}
        {/* Partner details form - line 2*/}

        {/* <ReactGeocoding /> */}

        {/* Partner details form - line 3*/}
        {/* <div className="flex space-x-8 items-center">
              <div className="w-1/3 space-y-2">
                <p className="text-gray-800 font-semibold text-lg">
                  Address lat, long
                </p>

                <div className="w-full flex space-x-8">
                  <div className="w-1/2">
                    <ReactInput name="lat" type="text"></ReactInput>
                  </div>

                  <div className="w-1/2">
                    <ReactInput name="lng" type="text"></ReactInput>
                  </div>
                </div>
              </div>
            </div> */}
        {/* Partner details form - line 3*/}

        {/* Partner details form - line 4*/}
        {/* <div className="flex space-x-8 w-2/3">
              <div className="space-y-4 w-full">
                <p className="text-gray-800 font-semibold text-lg">
                  Working hours
                </p>

                <div className="flex space-x-8 items-center w-full">
                  <ReactWorkingHourspickerEditMode
                    // day={day}
                    checkedDays={checkedDays}
                    setCheckedDays={setCheckedDays}
                  />
                </div>
              </div>
            </div> */}
        {/* Partner details form - line 4*/}
        {/* </div> */}
        {/* Partner details form */}
        {/* </div> */}
        {/* Partner details section */}
      </ReactForm>

      <div className="flex justify-center w-full space-x-8">
        <Popconfirm
          title={`Are you sure you want to cancel?`}
          onConfirm={() => {
            props.history.push("/partners");
          }}
          onCancel={() => {}}
          okText="Yes"
          placement="bottomRight"
          cancelText="No"
        >
          <button className="btn-danger hover:shadow-lg">Cancel</button>
        </Popconfirm>
        {/* <button className="btn-primary hover:shadow-lg">Create Partner</button> */}
        <button
          onClick={() => handleSubmit()}
          type="submit"
          className="btn-primary hover:shadow-lg"
        >
          Update Branch
        </button>
      </div>
    </div>
  );
};

export default EditBranch;
