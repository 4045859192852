// DEPS
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Input } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import moment from "moment";

// COMPONENTS
import ActionButton from "../../components/Button/ActionButton";
import GlobalButton from "../../components/Button/";
import GlobalTable from "../../components/Table/UpgradedTable";
import HorizontalTable from "../../components/Table/HorizontalTable";
import Loading from "../../components/loading";
import ErrorPage from "../../components/ErrorPage";

// APIs
import { GET_ORO_OFFER_BY_ID } from "./graphql";

function View(props) {
  const [pledgeCardSummary, setPledgeCardSummary] = useState([
    {
      no: 0,
      total_gross_weight: 0,
      total_stones_weight: 0,
      total_net_weight: 0,
      total_releases_amount: 0,
    },
  ]);

  const {
    loading: offerLoading,
    error: offerError,
    data: offerData,
  } = useQuery(GET_ORO_OFFER_BY_ID, {
    fetchPolicy: "network-only",
    variables: {
      id: parseInt(props.match.params.id),
    },
    onCompleted: (data) => {
      setPledgeCardSummary([
        {
          no: data.oro_offers_by_pk.offers_pledge_cards.length,
          total_gross_weight: data.oro_offers_by_pk.offers_pledge_cards.reduce(
            (sum, val) => sum + Number(val.gross_weight),
            0
          ),
          total_stones_weight: data.oro_offers_by_pk.offers_pledge_cards.reduce(
            (sum, val) => sum + Number(val.stone_deduction),
            0
          ),
          total_net_weight: data.oro_offers_by_pk.offers_pledge_cards.reduce(
            (sum, val) => sum + Number(val.net_weight),
            0
          ),
          total_releases_amount:
            data.oro_offers_by_pk.offers_pledge_cards.reduce(
              (sum, val) => sum + Number(val.release_amount),
              0
            ),
        },
      ]);
    },
  });

  const handleDownload = () => {
    fetch(`${process.env.REACT_APP_IMAGE_DOWNLOAD_URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "*",
      },
      body: JSON.stringify({
        customer_name: offerData?.oro_offers_by_pk?.customer_name,
        // customer_no: offerData?.oro_offers_by_pk?.customer_mobile_number,
        customer_no: "***",
        oro_offer_date: moment(offerData?.oro_offers_by_pk?.created_at).format(
          "DD-MM-YYYY"
        ),
        agent_name: offerData?.oro_offers_by_pk?.agent.agent.user.first_name,
        agent_no: offerData?.oro_offers_by_pk?.agent.agent.user.mobile_number,
        actual_weight: `${offerData?.oro_offers_by_pk.offers_pledge_cards?.reduce(
          (sum, val) => sum + Number(val.gross_weight),
          0
        )} grams`,
        stone_deductions: `${offerData?.oro_offers_by_pk.offers_pledge_cards?.reduce(
          (sum, val) => sum + Number(val.stone_deduction),
          0
        )} grams`,
        net_weight: `${offerData?.oro_offers_by_pk.offers_pledge_cards?.reduce(
          (sum, val) => sum + Number(val.net_weight),
          0
        )} grams`,
        plan_1_amount: offerData?.oro_offers_by_pk?.offer_plans[1]
          ?.total_eligible_amount
          ? `₹ ${offerData?.oro_offers_by_pk?.offer_plans[1]?.total_eligible_amount}`
          : "",
        plan_2_amount: offerData?.oro_offers_by_pk?.offer_plans[0]
          ?.total_eligible_amount
          ? `₹ ${offerData?.oro_offers_by_pk?.offer_plans[0]?.total_eligible_amount}`
          : "",
        plan_1_name: offerData?.oro_offers_by_pk?.offer_plans[1]?.gl_plan_name
          ? `${offerData?.oro_offers_by_pk?.offer_plans[1]?.gl_plan_name}`
          : "",
        plan_2_name: offerData?.oro_offers_by_pk?.offer_plans[0]?.gl_plan_name
          ? `${offerData?.oro_offers_by_pk?.offer_plans[0]?.gl_plan_name}`
          : "",
        plan_1_interest: offerData?.oro_offers_by_pk?.offer_plans[1]
          ?.total_interest
          ? `${
              offerData?.oro_offers_by_pk?.offer_plans[1]?.total_interest
            }% p.a / ${parseFloat(
              Number(
                offerData?.oro_offers_by_pk?.offer_plans[1]?.total_interest
              ) / 12
            ).toFixed(2)}% p.m`
          : "",
        plan_2_interest: offerData?.oro_offers_by_pk?.offer_plans[0]
          ?.total_interest
          ? `${
              offerData?.oro_offers_by_pk?.offer_plans[0]?.total_interest
            }% p.a / ${parseFloat(
              Number(
                offerData?.oro_offers_by_pk?.offer_plans[0]?.total_interest
              ) / 12
            ).toFixed(2)}% p.m`
          : "",
        plan_1_month_1: offerData?.oro_offers_by_pk?.offer_plans[1]
          ?.total_interest
          ? `₹ ${Math.round(
              Number(
                offerData?.oro_offers_by_pk?.offer_plans[1]
                  ?.total_eligible_amount
              ) *
                parseFloat(
                  Number(
                    offerData?.oro_offers_by_pk?.offer_plans[1]?.total_interest
                  ) / 12
                ).toFixed(2) *
                0.01
            )}`
          : "",
        plan_2_month_1: offerData?.oro_offers_by_pk?.offer_plans[0]
          ?.total_interest
          ? `₹ ${Math.round(
              Number(
                offerData?.oro_offers_by_pk?.offer_plans[0]
                  ?.total_eligible_amount
              ) *
                parseFloat(
                  Number(
                    offerData?.oro_offers_by_pk?.offer_plans[0]?.total_interest
                  ) / 12
                ).toFixed(2) *
                0.01
            )}`
          : "",
        plan_1_month_3: offerData?.oro_offers_by_pk?.offer_plans[1]
          ?.total_interest
          ? `₹ ${Math.round(
              Number(
                offerData?.oro_offers_by_pk?.offer_plans[1]
                  ?.total_eligible_amount
              ) *
                parseFloat(
                  Number(
                    offerData?.oro_offers_by_pk?.offer_plans[1]?.total_interest
                  ) / 12
                ).toFixed(2) *
                0.01 *
                3
            )}`
          : "",
        plan_2_month_3: offerData?.oro_offers_by_pk?.offer_plans[0]
          ?.total_interest
          ? `₹ ${Math.round(
              Number(
                offerData?.oro_offers_by_pk?.offer_plans[0]
                  ?.total_eligible_amount
              ) *
                parseFloat(
                  Number(
                    offerData?.oro_offers_by_pk?.offer_plans[0]?.total_interest
                  ) / 12
                ).toFixed(2) *
                0.01 *
                3
            )}`
          : "",
        plan_1_month_6: offerData?.oro_offers_by_pk?.offer_plans[1]
          ?.total_interest
          ? `₹ ${Math.round(
              Number(
                offerData?.oro_offers_by_pk?.offer_plans[1]
                  ?.total_eligible_amount
              ) *
                parseFloat(
                  Number(
                    offerData?.oro_offers_by_pk?.offer_plans[1]?.total_interest
                  ) / 12
                ).toFixed(2) *
                0.01 *
                6
            )}`
          : "",
        plan_2_month_6: offerData?.oro_offers_by_pk?.offer_plans[0]
          ?.total_interest
          ? `₹ ${Math.round(
              Number(
                offerData?.oro_offers_by_pk?.offer_plans[0]
                  ?.total_eligible_amount
              ) *
                parseFloat(
                  Number(
                    offerData?.oro_offers_by_pk?.offer_plans[0]?.total_interest
                  ) / 12
                ).toFixed(2) *
                0.01 *
                6
            )}`
          : "",
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        let file = new Blob([blob], { type: "image/png" });
        return URL.createObjectURL(file);
      })
      .then((url) => {
        console.log(url);
        let link = document.createElement("a");
        link.href = url;
        link.download = `Oro Offer - ${offerData?.oro_offers_by_pk?.offer_display_id}.png`;
        link.click();
      })
      .catch((err) => console.error(err));
  };

  const offerOverviewColumns = [
    {
      key: "created_at",
      dataIndex: "created_at",
      title: "Offer date / time",
      width: "13%",
      render: (data, text, index) => moment(data).format("DD-MM-YYYY HH:mm"),
    },
    {
      key: "customer_name",
      dataIndex: "customer_name",
      title: "Customer Name",
      width: "12%",
    },
    {
      key: "customer_mobile_number",
      dataIndex: "customer_mobile_number",
      title: "Customer Mobile No.",
      width: "10%",
    },
    {
      key: "agent",
      dataIndex: "agent",
      title: "Oro Agent",
      width: "13%",
      render: (data, record, index) =>
        `${record?.agent?.agent?.user?.first_name} | ${record?.agent?.agent?.user?.mobile_number}`,
    },
    {
      key: "rate",
      dataIndex: "rate",
      title: "Offer gold rate",
      width: "10%",
      render: (text) => `₹ ${text}`,
    },
    {
      key: "owner",
      dataIndex: "owner",
      title: "Offer generated by",
      width: "10%",
    },
  ];

  const existingLoanColumns = [
    {
      id: 1,
      key: "no",
      title: "No. of pledge cards",
      dataIndex: "no",
      width: "10%",
    },
    {
      id: 2,
      key: "total_gross_weight",
      title: "Total gross weight",
      dataIndex: "total_gross_weight",
      width: "10%",
      render: (text) => `${text} gms`,
    },
    {
      id: 3,
      key: "total_stones_weight",
      title: "Stones/Deductions",
      dataIndex: "total_stones_weight",
      width: "10%",
      render: (text) => `${text} gms`,
    },
    {
      id: 4,
      key: "total_net_weight",
      title: "Total Net weight",
      dataIndex: "total_net_weight",
      width: "10%",
      render: (text) => `${text} gms`,
    },
    {
      id: 5,
      key: "total_releases_amount",
      title: "Total Release amount",
      dataIndex: "total_releases_amount",
      width: "10%",
      render: (text) => `₹ ${text}`,
    },
  ];

  const pledgeCardColumns = [
    {
      id: 1,
      key: "pledge_card_identifier",
      title: "Pledge car no.",
      dataIndex: "pledge_card_identifier",
      width: "10%",
      render: (text, record) => (
        <Link
          to={{ pathname: record.pledge_card_url }}
          target="_blank"
          style={{
            textDecoration: "underline",
            textDecorationColor: "#F6CE89",
            textDecorationThickness: 2,
          }}
        >
          {text}
        </Link>
      ),
    },
    {
      id: 2,
      key: "gross_weight",
      title: "Gross weight",
      dataIndex: "gross_weight",
      width: "10%",
      render: (text) => `${text} gms`,
    },
    {
      id: 3,
      key: "stone_deduction",
      title: "Stones/Deductions",
      dataIndex: "stone_deduction",
      width: "10%",
      render: (text) => `${text} gms`,
    },
    {
      id: 4,
      key: "net_weight",
      title: "Net weight",
      dataIndex: "net_weight",
      width: "10%",
      render: (text) => `${text} gms`,
    },
    {
      id: 5,
      key: "release_amount",
      title: "Release amount",
      dataIndex: "release_amount",
      width: "10%",
      render: (text) => `₹ ${text}`,
    },
  ];

  const planColumns = [
    {
      id: 1,
      key: "gl_plan_name",
      title: "Plan Name / type",
      dataIndex: "gl_plan_name",
      width: "12%",
    },
    {
      id: 2,
      key: "gl_eligible_amount",
      title: "Eligible GL amount",
      dataIndex: "gl_eligible_amount",
      width: "10%",
      render: (text) => `₹ ${text}`,
    },
    {
      id: 3,
      key: "gl_interest",
      title: "Interest % p.a / p.m",
      dataIndex: "gl_interest",
      width: "10%",
      render: (text) =>
        `${text}% p.a / ${parseFloat(Number(text) / 12).toFixed(2)}% p.m`,
    },
    {
      id: 4,
      key: "gl_tenure",
      title: "Tenure",
      dataIndex: "gl_tenure",
      width: "7%",
      render: (text) => `${text} months`,
    },
    {
      id: 5,
      key: "gl_ltv",
      title: "LTV %",
      dataIndex: "gl_ltv",
      width: "5%",
      render: (text) => `${text}%`,
    },
  ];

  const slPlanColumns = [
    {
      id: 1,
      key: "sl_plan_name",
      title: "Plan Name / type",
      dataIndex: "sl_plan_name",
      width: "12%",
    },
    {
      id: 2,
      key: "sl_eligible_amount",
      title: "Eligible SL amount",
      dataIndex: "sl_eligible_amount",
      width: "10%",
      render: (text) => `₹ ${text}`,
    },
    {
      id: 3,
      key: "sl_interest",
      title: "Interest % p.a / p.m",
      dataIndex: "sl_interest",
      width: "10%",
      render: (text) =>
        `${text}% p.a / ${parseFloat(Number(text) / 12).toFixed(2)}% p.m`,
    },
    {
      id: 4,
      key: "sl_tenure",
      title: "Tenure",
      dataIndex: "sl_tenure",
      width: "7%",
      render: (text) => `${text} months`,
    },
    {
      id: 5,
      key: "sl_ltv",
      title: "LTV %",
      dataIndex: "sl_ltv",
      width: "5%",
      render: (text) => `${text}%`,
    },
  ];

  const planSummaryColumns = [
    {
      id: 1,
      key: "total_eligible_amount",
      title: "Max Eligible amount",
      dataIndex: "total_eligible_amount",
      width: "12%",
      render: (text) => `₹ ${text}`,
    },
    {
      id: 2,
      key: "net_ltv",
      title: "Net LTV %",
      dataIndex: "net_ltv",
      width: "10%",
      render: (text) => `${text}%`,
    },
    {
      id: 3,
      key: "total_interest",
      title: "Interest % p.a / p.m",
      dataIndex: "total_interest",
      width: "10%",
      render: (text) =>
        `${text}% p.a / ${parseFloat(Number(text) / 12).toFixed(2)}% p.m`,
    },
    {
      id: 4,
      key: "total_eligible_amount",
      title: "Total Release amount",
      dataIndex: "total_eligible_amount",
      width: "7%",
      render: (text) => `₹ ${pledgeCardSummary[0].total_releases_amount}`,
    },
    {
      id: 5,
      key: "total_eligible_amount",
      title: "Excese/Difference",
      dataIndex: "total_eligible_amount",
      width: "5%",
      render: (text, record) => {
        let value = Math.round(
          Number(record.total_eligible_amount) -
            pledgeCardSummary[0].total_releases_amount
        );
        return `${value < 0 ? "- " : ""}₹ ${parseFloat(Math.abs(value)).toFixed(
          2
        )}`;
      },
    },
  ];

  if (offerLoading) return <Loading />;

  if (offerError) return <ErrorPage />;

  return (
    <div className="space-y-4">
      <div className="flex flex-row items-center">
        <Button
          style={{ color: "#000", fontWeight: 800 }}
          type="link"
          icon={<LeftOutlined style={{ color: "#FFC351" }} />}
          onClick={() => {
            props.history.push("/oro-offer/");
          }}
        >
          All Oro offers
        </Button>
        <ActionButton
          label="New Oro offer"
          type="secondary"
          handleClick={() => props.history.push("/oro-offer/create")}
        />
      </div>
      <div className="px-4" id="oro-offer-download-area">
        <div className="w-full flex flex-col lg:flex-row items-start lg:items-center justify-between py-2">
          <div className="flex flex-row items-center">
            <h3 className="text-left text-md lg:text-lg font-bold text-black py-2">
              Oro offer successfully generated |{" "}
              {offerData?.oro_offers_by_pk?.offer_display_id}
            </h3>

            <ActionButton
              label="Edit offer"
              handleClick={() =>
                props.history.push(`/oro-offer/edit/${props.match.params.id}`)
              }
              className="w-1/2 lg:w-auto"
              type="secondary"
            />
          </div>

          <GlobalButton
            handleClick={() => handleDownload()}
            className="px-6"
            label="Download Oro offer"
          />
        </div>
        <p className="w-full text-left text-md font-bold py-1">
          OFFER OVERVIEW
        </p>
        <GlobalTable
          columns={offerOverviewColumns}
          data={[
            {
              created_at: offerData?.oro_offers_by_pk?.created_at,
              customer_name: offerData?.oro_offers_by_pk?.customer_name,
              customer_mobile_number:
                offerData?.oro_offers_by_pk?.customer_mobile_number,
              agent: offerData?.oro_offers_by_pk?.agent,
              rate: offerData?.oro_offers_by_pk?.gold_rate,
              owner: `${offerData?.oro_offers_by_pk?.user?.first_name || ""} ${
                offerData?.oro_offers_by_pk?.user?.last_name || ""
              }`,
            },
          ]}
          bordered={true}
        />
        {offerData?.oro_offers_by_pk?.offer_plans.length !== 0 && (
          <div className="w-full flex flex-col lg:flex-row items-start py-3">
            {offerData?.oro_offers_by_pk?.offer_plans.map((plan, i) => (
              <HorizontalTable
                title={`Oro Plan: ${plan.gl_plan_name}`}
                columns={[
                  "Eligible Loan amount",
                  "Interest % p.a",
                  "Interest % p.m",
                  "Net LTV %",
                  "Tenure",
                  "Plan type",
                  "Difference amount",
                ]}
                data={[
                  `${plan.total_eligible_amount}`,
                  `${plan.total_interest}%`,
                  `${parseFloat(Number(plan.total_interest) / 12).toFixed(2)}%`,
                  `${plan.net_ltv}%`,
                  `${plan.gl_tenure} months`,
                  `${plan.gl_plan_type}`,
                  `₹ ${Math.round(
                    Number(plan.total_eligible_amount) -
                      pledgeCardSummary[0].total_releases_amount
                  )}`,
                ]}
                className={i !== 0 ? "lg:ml-4 lg:mt-0 mt-3" : ""}
              />
            ))}
          </div>
        )}
        <p className="w-full text-left text-md font-bold py-1 mt-4">
          EXISTING LOAN SUMMARY
        </p>
        <GlobalTable
          columns={existingLoanColumns}
          data={[
            {
              no: offerData?.oro_offers_by_pk.offers_pledge_cards?.length,
              total_gross_weight:
                offerData?.oro_offers_by_pk.offers_pledge_cards?.reduce(
                  (sum, val) => sum + Number(val.gross_weight),
                  0
                ),
              total_stones_weight:
                offerData?.oro_offers_by_pk.offers_pledge_cards?.reduce(
                  (sum, val) => sum + Number(val.stone_deduction),
                  0
                ),
              total_net_weight:
                offerData?.oro_offers_by_pk.offers_pledge_cards?.reduce(
                  (sum, val) => sum + Number(val.net_weight),
                  0
                ),
              total_releases_amount:
                offerData?.oro_offers_by_pk.offers_pledge_cards?.reduce(
                  (sum, val) => sum + Number(val.release_amount),
                  0
                ),
            },
          ]}
          bordered={true}
        />
        <p className="w-full text-left text-md font-bold py-1 mt-4">
          PLEDGE CARD DETAILS
        </p>
        <GlobalTable
          columns={pledgeCardColumns}
          data={offerData?.oro_offers_by_pk.offers_pledge_cards}
          bordered={true}
        />
        {offerData?.oro_offers_by_pk?.offer_plans.length !== 0 && (
          <React.Fragment>
            {offerData?.oro_offers_by_pk?.offer_plans.map((plan, i) => (
              <React.Fragment key={i}>
                <p className="w-full text-left text-md font-bold py-1 mt-4">
                  APPLICABLE GOLD LOAN PLAN {i + 1}
                </p>
                <GlobalTable
                  columns={planColumns}
                  data={[{ ...plan }]}
                  bordered={true}
                />
                <p className="w-full text-left text-md font-bold py-1 mt-4">
                  SUPPORT LOAN FOR GOLD LOAN PLAN {i + 1}
                </p>
                <GlobalTable
                  columns={slPlanColumns}
                  data={[{ ...plan }]}
                  bordered={true}
                />
                <p className="w-full text-left text-md font-bold py-1 mt-4">
                  OFFER {i + 1} SUMMARY - GL+SL
                </p>
                <GlobalTable
                  type="primary"
                  columns={planSummaryColumns}
                  data={[{ ...plan }]}
                  bordered={true}
                />
              </React.Fragment>
            ))}
          </React.Fragment>
        )}
        <div className="my-3 flex flex-col items-center">
          <p className="w-full text-left text-md font-bold py-1">
            Comments or remarks, if any
          </p>
          <Input
            readOnly
            value={offerData?.oro_offers_by_pk?.comments}
            size="large"
            placeholder="Eg: Purity detection as per NBFC policy"
            className="w-full focus:outline-none rounded-md"
          />
        </div>
      </div>
    </div>
  );
}

export default View;
