import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";

function ReactForm(props) {
  return (
    <Formik
      initialValues={props.initialValues}
      {...props}
      validationSchema={props.validationSchema}
      enableReinitialize={false}
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={props.innerRef}
    >
      <Form className={props.formCls}>{props.children}</Form>
    </Formik>
  );
}
ReactForm.defaultProps = {
  formCls: ""
};
ReactForm.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  formCls: PropTypes.string
};
export default ReactForm;
