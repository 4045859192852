import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import {ReactInput, ReactInputNumber, ReactDatepicker, ReactTimepicker, ReactTextarea, ReactRadioGroup, ReactSelect} from "../../components/Form";
import Loading from "../../components/loading";
import moment from "moment";
import { planValidationSchema } from "./validationSchema";
import { LeftOutlined } from "@ant-design/icons";
import { notification, Popconfirm, Button, Radio, Select } from "antd";
import {INSERT_PLAN, GET_LOAN_TYPES, GET_ACTIVE_PARTNER_BRANCHES} from "./graphql";
import { useFormikContext } from "formik";

const { Option } = Select;

const areArraysEqualSets = (a1, a2) => {
  const superSet = {};
  for (const i of a1) {
    const e = i + typeof i;
    superSet[e] = 1;
  }

  for (const i of a2) {
    const e = i + typeof i;
    if (!superSet[e]) {
      return false;
    }
    superSet[e] = 2;
  }

  for (let e in superSet) {
    if (superSet[e] === 1) {
      return false;
    }
  }

  return true;
};

const checker = (arr, target) => target.every((v) => arr.includes(v));

const InterestType = (props) => {
  const { values, setFieldValue } = useFormikContext();
  
  // eslint-disable-next-line
  let matchingPartnerBranches = props.activePartnerBranches.partner_branches.filter((branch) => {
    let arr = branch.partners_branches_x_loanTypes.map((loan_type) => loan_type.loan_type_id);
    let exactMatch = checker(arr, [values.plan_x_loan_type]);
    if (exactMatch) return branch;
  });

  if (
    !areArraysEqualSets(
      props.partnerBranchesList.length > 0
        ? props.partnerBranchesList.map((branch) => branch.id)
        : [],
      matchingPartnerBranches.length > 0
        ? matchingPartnerBranches.map((branch) => branch.id)
        : []
    )
  )
    props.setPartnerBranchesList(matchingPartnerBranches);

  if (
    values.plan_x_loan_type === parseInt(process.env.REACT_APP_SL_LOAN_TYPE_ID)
  ) {
    if (values.min_amt !== 1) setFieldValue("min_amt", 1);
    if (values.max_amt !== 100000000) setFieldValue("max_amt", 100000000);
    if (values.ltv !== 100) setFieldValue("ltv", 100);
  } else {
    if (values.min_amt === 1) setFieldValue("min_amt", null);
    if (values.max_amt === 100000000) setFieldValue("max_amt", null);
    if (values.ltv === 100) setFieldValue("ltv", null);
  }

  return values.plan_x_loan_type ===
    parseInt(process.env.REACT_APP_GL_LOAN_TYPE_ID) ? (
    <div className="w-full">
      <ReactRadioGroup name="plan_type" label="Plan type">
        <Radio value="Fixed Interest Plan">Fixed Interest Plan</Radio>
        <Radio value="Monthly Repayment Plan">Monthly Repayment Plan</Radio>
      </ReactRadioGroup>
    </div>
  ) : null;
};

const InterestFields = (props) => {
  const { values } = useFormikContext();
  // const [field, meta] = useField(props);

  return values.plan_x_loan_type ===
    parseInt(process.env.REACT_APP_GL_LOAN_TYPE_ID) &&
    values.plan_type === "Monthly Repayment Plan" ? (
    <>
      <div className="w-2/3 flex space-x-4">
        <div className="w-1/4">
          <ReactInputNumber
            name="interest"
            label="Interest % p.a"
            min={0}
            precision={2}
          />
        </div>

        <div className="w-1/4">
          <ReactInputNumber
            name="interest_slab_2"
            label="Interest % p.a after first default"
            min={0}
            precision={2}
          />
        </div>

        <div className="w-1/4">
          <ReactInputNumber
            name="interest_slab_3"
            label="Interest % p.a after second default"
            min={0}
            precision={2}
          />
        </div>

        <div className="w-1/4">
          <ReactInputNumber
            name="bank_interest"
            label="Bank interest % p.a"
            min={0}
            precision={2}
            disabled={
              values.plan_x_loan_type ===
              parseInt(process.env.REACT_APP_SL_LOAN_TYPE_ID)
            }
          />
        </div>
      </div>

      <div className="w-1/3">
        <ReactInputNumber
          name="tenure"
          label="Tenure (in months)"
          min={0}
          precision={0}
        />
      </div>
    </>
  ) : (
    <>
      <div className="w-1/3">
        <ReactInputNumber
          name="interest"
          label="Interest % p.a"
          min={0}
          precision={2}
        />
      </div>

      <div className="w-1/3">
        <ReactInputNumber
          name="bank_interest"
          label="Bank interest % p.a"
          min={0}
          precision={2}
          disabled={
            values.plan_x_loan_type ===
            parseInt(process.env.REACT_APP_SL_LOAN_TYPE_ID)
          }
        />
      </div>

      <div className="w-1/3">
        <ReactInputNumber
          name="tenure"
          label="Tenure (in months)"
          min={0}
          precision={0}
        />
      </div>
    </>
  );
};

const CreatePlan = (props) => {
  const formRef = React.useRef();

  const [partnerBranchesList, setPartnerBranchesList] = useState([]);

  const {data: loanTypesData, loading: loadingLoanTypes} = useQuery(GET_LOAN_TYPES);

  const {data: activePartnerBranches, loading: activePartnerBranchesLoading} = useQuery(GET_ACTIVE_PARTNER_BRANCHES);

  const [insertPlan] = useMutation(INSERT_PLAN, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        if (error.extensions.code === "constraint-violation") {
          if (
            error.message.split("constraint")[1].includes("plans_plan_name_key")
          ) {
            notification.error({
              message: "Error!",
              description: "Plan with the same name already exists",
            });
          }
        } else {
          notification.error({
            message: "Error!",
            description: "Something went wrong",
          });
        }
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Plan created",
      });
      props.history.push(`/plans/view/${data.insert_plans.returning[0].id}`);
    },
  });

  if (loadingLoanTypes || activePartnerBranchesLoading) return <Loading />;

  // if (loanTypesQueryError) console.log("error");

  let LoanTypesoptions = loanTypesData.loan_types.map((loan_type) => {
    let obj = {
      label: loan_type.loan_type,
      value: loan_type.id,
      disabled:
        loan_type.loan_type === "AL" ||
        loan_type.loan_type === "TL" ||
        loan_type.loan_type === "OD",
    };

    return obj;
  });

  const handleSubmit = () => {
    if (formRef.current) {
      let timeA = moment(
        formRef.current.values.plan_start_date +
          formRef.current.values.plan_start_time,
        "DD-MM-YYYY HH:mm"
      );
      let timeB = moment(
        formRef.current.values.plan_end_date +
          formRef.current.values.plan_end_time,
        "DD-MM-YYYY HH:mm"
      );

      if (
        timeB.diff(timeA) <= 0 ||
        formRef.current.values.min_amt > formRef.current.values.max_amt
      ) {
        if (timeB.diff(timeA) <= 0) {
          notification.error({
            message: "Error!",
            description: "Plan expiry date cannot be before plan start date",
          });
        }

        if (formRef.current.values.min_amt > formRef.current.values.max_amt) {
          notification.error({
            message: "Error!",
            description:
              "Minimum amount cannot be less greater than maximum amount",
          });
        }

        return;
      } else {
        formRef.current.handleSubmit();
      }
    }
  };

  return (
    <div className="space-y-4">
      <Button
        type="link"
        icon={<LeftOutlined />}
        className="flex items-center mr-4"
        onClick={() => {
          props.history.push("/plans");
        }}
      >
        All Plans
      </Button>
      <div className="flex justify-between">
        <h1> Create new plan</h1>
      </div>

      <Formik
        enableReinitialize={false}
        validateOnChange={false}
        validateOnBlur={false}
        innerRef={formRef}
        onSubmit={(values, { resetForm }) => {
          // notification.success({
          //   message: "Success!",
          //   description: "Plan created"
          // });

          let plan_x_loan_types = {
            loan_type_id: values.plan_x_loan_type,
            is_active: true,
          };

          let plan_x_partner_branches = values.partner_branches.map((type) => {
            let obj = {
              partner_branch_id: type,
              is_active: true,
            };

            return obj;
          });

          let obj = {
            plan_name: values.plan_name,
            scheme_id: values.scheme_id,
            scheme_name: values.scheme_name,
            plan_type: values.plan_type,
            plan_start_date: moment(
              values.plan_start_date + values.plan_start_time,
              "DD-MM-YYYY HH:mm"
            ),
            plan_end_date: moment(
              values.plan_end_date + values.plan_end_time,
              "DD-MM-YYYY HH:mm"
            ),
            minimum_amount: values.min_amt,
            maximum_amount: values.max_amt,
            bank_interest: values.bank_interest,
            interest: values.interest,
            interest_slab_2: values.interest_slab_2,
            interest_slab_3: values.interest_slab_3,
            ltv: values.ltv,
            tenure: values.tenure,
            features: values.features,
            tncs: values.tc,
            plans_x_loanTypes: {
              data: plan_x_loan_types,
              on_conflict: {
                constraint: "plans_x_loanTypes_plan_id_loan_type_key",
                update_columns: "is_active",
              },
            },
            plans_x_partner_branches: {
              data: plan_x_partner_branches,
              on_conflict: {
                constraint: "plans_x_partner_branches_pkey",
                update_columns: "is_active",
              },
            },
          };

          insertPlan({
            variables: {
              obj: obj,
            },
          });
        }}
        initialValues={{
          plan_name: "",
          scheme_id: "",
          scheme_name: "",
          plan_x_loan_type: [],
          plan_start_date: "",
          plan_start_time: "",
          plan_end_date: "",
          plan_end_time: "",
          min_amt: null,
          max_amt: null,
          interest: null,
          interest_slab_2: null,
          interest_slab_3: null,
          plan_type: "Fixed Interest Plan",
          ltv: null,
          tenure: null,
          partner_branches: [],
          features: "",
          tc: "",
        }}
        validationSchema={planValidationSchema}
        formCls="flex flex-col content-center justify-center space-y-8"
      >
        {({ values }) => (
          <Form>
            {/* Plan overview div*/}
            <div className="border">
              {/* Plan overview header */}
              <div className="border-b w-full px-4 py-2 bg-green-primary">
                <h2 className="text-white">Plan overview</h2>
              </div>
              {/* Plan overview header */}

              {/* Plan overview form */}
              <div className="p-4 space-y-2">
                {/* Plan overview form - line 1*/}
                <div className="flex space-x-8 items-center">
                  <div className="w-2/6">
                    <ReactInput
                      name="plan_name"
                      type="text"
                      label="Plan Name"
                    />
                  </div>

                  <div className="w-3/6 space-y-2">
                    <p className="text-gray-800 font-semibold text-lg">
                      Plan validity
                    </p>
                    <div className="flex space-x-8 items-center">
                      <div className="w-1/2 flex space-x-2 items-center">
                        {/* <p> From: </p> */}
                        <div className="w-1/2">
                          <ReactDatepicker
                            name="plan_start_date"
                            disabledDate={function disabledDate(current) {
                              return (
                                current &&
                                current.valueOf() < moment().add("days", -1)
                              );
                            }}
                          />
                        </div>
                        <div className="w-1/2">
                          <ReactTimepicker name="plan_start_time" />
                        </div>
                      </div>
                      <p style={{ marginBottom: "21px" }}>to</p>
                      <div className="w-1/2 flex space-x-2 items-center">
                        {/* <p> To: </p> */}
                        <div className="w-1/2">
                          <ReactDatepicker
                            name="plan_end_date"
                            disabledDate={function disabledDate(current) {
                              return current && current.valueOf() < moment();
                            }}
                          />
                        </div>

                        <div className="w-1/2">
                          <ReactTimepicker name="plan_end_time" />
                        </div>
                      </div>
                    </div>
                    {/* <div style={{ minHeight: 28 }}></div> */}
                  </div>

                  {/* <div className="w-1/6">
                <ReactCheckboxGroup
                  name="plan_x_loan_type"
                  label="Loan types"
                  options={LoanTypesoptions}
                />
              </div> */}

                  <div className="w-1/6">
                    <ReactRadioGroup
                      name="plan_x_loan_type"
                      label="Loan types"
                      options={LoanTypesoptions}
                    />
                  </div>
                </div>
                {/* Plan overview form - line 1*/}

                {/* Plan overview form - line 2*/}
                <div className="flex space-x-8 items-center">
                  <div className="w-1/6">
                    <ReactSelect
                      name="scheme_name"
                      type="text"
                      label="Scheme Name"
                      placeholder="Choose scheme name"
                      // disabledKey="plan_x_loan_type"
                      // disabledValue={4}
                      disabled={
                        values.plan_x_loan_type ===
                        parseInt(process.env.REACT_APP_SL_LOAN_TYPE_ID)
                      }
                    >
                      <Option value="ORO-REGULAR">ORO-REGULAR</Option>
                      <Option value="ORO-SPECIAL">ORO-SPECIAL</Option>
                    </ReactSelect>
                  </div>

                  <div className="w-1/6">
                    <ReactSelect
                      name="scheme_id"
                      type="text"
                      label="Scheme ID"
                      placeholder="Choose scheme ID"
                      // disabledKey="plan_x_loan_type"
                      // disabledValue={4}
                      disabled={
                        values.plan_x_loan_type ===
                        parseInt(process.env.REACT_APP_SL_LOAN_TYPE_ID)
                      }
                    >
                      <Option value="78858">78858</Option>
                      <Option value="78857">78857</Option>
                    </ReactSelect>
                  </div>

                  <div className="w-3/6">
                    <ReactSelect
                      mode="multiple"
                      name="partner_branches"
                      type="text"
                      label="Partner Branches"
                      placeholder="Choose partner branches"
                    >
                      {partnerBranchesList.map((branch) => (
                        <Option value={branch.id}>
                          {branch.partner_branch_name}
                        </Option>
                      ))}
                    </ReactSelect>
                  </div>
                </div>
                {/* Plan overview form - line 2*/}
              </div>
              {/* Plan overview form */}

              <div className="px-4">
                <div className="w-1/3">
                  <InterestType
                    partnerBranchesList={partnerBranchesList}
                    activePartnerBranches={activePartnerBranches}
                    setPartnerBranchesList={setPartnerBranchesList}
                  />
                </div>
              </div>
            </div>
            {/* Plan overview div*/}

            {/* Plan details div*/}
            <div className="border">
              {/* Plan details header */}
              <div className="border-b w-full px-4 py-2 bg-green-primary">
                <h2 className="text-white">Plan details</h2>
              </div>
              {/* Plan details header */}

              {/* Plan details form */}
              <div className="p-4 space-y-4">
                {/* Plan details form - line 1*/}
                <div className="flex space-x-8 items-center">
                  <div className="w-1/3">
                    <ReactInputNumber
                      name="min_amt"
                      label="Minimum amount"
                      min={0}
                      precision={0}
                    />
                  </div>

                  <div className="w-2/3 flex space-x-8">
                    <div className="w-1/2">
                      <ReactInputNumber
                        name="max_amt"
                        label="Maximum amount"
                        min={0}
                        precision={0}
                      />
                    </div>

                    <div className="w-1/2">
                      <ReactInputNumber
                        name="ltv"
                        label="LTV %"
                        min={0}
                        max={100}
                        precision={2}
                      />
                    </div>
                  </div>
                </div>
                {/* Plan details form - line 1*/}

                {/* Plan details form - line 2*/}
                <div className="flex space-x-8 items-center">
                  {/* {values.plan_type === ""} */}
                  <InterestFields />
                </div>
                {/* Plan details form - line 2*/}
              </div>
              {/* Plan details form */}
            </div>
            {/* Plan details div*/}

            {/* <div className="w-1/4 space-y-4">
          <p className="text-gray-800 font-semibold text-lg">
            Fulfilment partner logo
          </p>
          <button className="cta hover:shadow-lg">Upload</button>
        </div> */}

            <ReactTextarea name="features" label="Features" rows={4} />
            <ReactTextarea name="tc" label="Terms and conditions" rows={4} />
          </Form>
        )}
      </Formik>

      <div className="flex justify-center w-full space-x-8">
        <Popconfirm
          title={`Are you sure you want to cancel?`}
          onConfirm={() => {
            props.history.push("/plans");
          }}
          onCancel={() => {}}
          okText="Yes"
          placement="bottomRight"
          cancelText="No"
        >
          <button className="btn-danger hover:shadow-lg">Cancel</button>
        </Popconfirm>
        <button
          onClick={() => handleSubmit()}
          type="submit"
          className="btn-primary hover:shadow-lg"
        >
          Create Plan
        </button>
      </div>
    </div>
  );
};

export default CreatePlan;
