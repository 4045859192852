import React from "react";

import {
  ReactForm,
  ReactInput,
  ReactCheckboxGroup,
  ImageUpload,
} from "../../components/Form";
import { useMutation, useQuery } from "@apollo/client";
import { LeftOutlined } from "@ant-design/icons";
import { notification, Popconfirm, Button } from "antd";
import { partnerValidationSchema } from "./validationSchema";
import { INSERT_PARTNER, GET_PARTNER_TYPES } from "./graphql";
import Loading from "../../components/loading";
import ErrorPage from "../../components/ErrorPage";
// import jwt_decode from "jwt-decode";

const CreatePartner = (props) => {
  const formRef = React.useRef();

  const {
    data: partnerTypesData,
    loading: loadingPartnerTypes,
    error: partnerTypesQueryError,
  } = useQuery(GET_PARTNER_TYPES);

  const [insertPartner] = useMutation(INSERT_PARTNER, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description: "Something went wrong",
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Partner created",
      });

      props.history.push(`/partners/view/${data.insert_partners_one.id}`);
    },
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  if (loadingPartnerTypes) return <Loading />;

  if (partnerTypesQueryError) return <ErrorPage />;

  return (
    <div className="space-y-4">
      <Button
        icon={<LeftOutlined />}
        type="link"
        onClick={() => {
          props.history.push("/partners");
        }}
      >
        All partners
      </Button>
      <div className="flex justify-between">
        <h1> Create new SL partner</h1>
      </div>

      <ReactForm
        innerRef={formRef}
        onSubmit={(values) => {
          let partner_types = values.partner_type_id.map((type_id) => {
            return { partner_type_id: type_id };
          });

          insertPartner({
            variables: {
              obj: {
                partner_name: values.partner_name,
                partner_logo: values.partner_logo,
                partners_x_partnerTypes: {
                  data: partner_types,
                },
                partner_x_loan_types: {
                  data: {
                    loan_type_id: process.env.REACT_APP_SL_LOAN_TYPE_ID,
                  },
                },
              },
            },
          });
        }}
        initialValues={{
          partner_name: "",
          partner_logo: "",
          partner_type_id: [],
        }}
        validationSchema={partnerValidationSchema}
        formCls="flex flex-col content-center justify-center space-y-8"
      >
        {/* Partner overview div*/}
        <div className="border">
          {/* Partner overview header */}
          <div className="border-b w-full px-4 py-2 bg-green-primary">
            <h2 className="text-white">Partner overview</h2>
          </div>
          {/* Partner overview header */}

          {/* Partner overview form */}
          <div className="p-4 space-y-4">
            {/* Partner overview form - line 1*/}
            <div className="flex space-x-8 items-center">
              <div className="w-1/3">
                <ReactInput
                  name="partner_name"
                  type="text"
                  label="Partner Name"
                  placeholder=""
                ></ReactInput>
              </div>

              <div className="w-1/3">
                <ReactCheckboxGroup
                  name="partner_type_id"
                  label="Partner type"
                  // options={[
                  //   { label: "Personal Lender", value: 4 },
                  //   { label: "NBFC", value: 2 },
                  // ]}
                  options={partnerTypesData.partner_types.map((type) => {
                    return {
                      label: type.partner_type,
                      value: type.id,
                      disabled:
                        type.partner_type === "Bank" ||
                        type.partner_type === "Others",
                    };
                  })}
                />
              </div>

              <div className="w-1/3">
                <ReactCheckboxGroup
                  name="type"
                  label="Loan types"
                  relation_type="loan_type_id"
                  mode="Insert"
                  value="SL"
                  options={["SL"]}
                  disabled={true}
                />
              </div>
            </div>
            {/* Partner overview form - line 1*/}
          </div>

          {/* Partner overview form */}
        </div>
        {/* Partner overview div*/}

        <div className="space-y-2 relative">
          <h2 className="text-gray-800 font-semibold">Partner logo:</h2>
          {/* <button className="cta hover:shadow-lg">Upload</button> */}
          <ImageUpload
            buttonText="Upload"
            multiple={false}
            name="partner_logo"
            showError={true}
            maxLength={1}
          />
        </div>
      </ReactForm>

      <div className="flex justify-center w-full space-x-8">
        <Popconfirm
          title={`Are you sure you want to cancel?`}
          onConfirm={() => {
            props.history.push("/partners");
          }}
          onCancel={() => {}}
          okText="Yes"
          placement="bottomRight"
          cancelText="No"
        >
          <button className="btn-danger hover:shadow-lg">Cancel</button>
        </Popconfirm>
        <button
          onClick={() => handleSubmit()}
          type="submit"
          className="btn-primary hover:shadow-lg"
        >
          Create Partner
        </button>
      </div>
    </div>
  );
};

export default CreatePartner;
