import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Select, notification, Popconfirm, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { Form, Formik } from "formik";

// COMPONENTS
import {ReactInput, ReactInputNumber, ReactDatepicker, ReactGeocoding, ReactSelect, VisitTimePicker, ReactRadioGroup} from "../../components/Form";
import Loading from "../../components/loading";
import ErrorPage from "../../components/ErrorPage";

// API
import {
  GET_PARTNER_BRANCHES,
  GET_VISIT_BY_ID,
  CONFIRM_VISIT,
  GET_HOLIDAYS,
  GET_WORKING_HOURS,
  GET_ACTIVE_GOLD_ORNAMENT_TYPES,
} from "./graphql";

const { Option } = Select;

const ConfirmVisit = (props) => {
  const confirmFormRef = React.useRef();

  // const [searchText, SetSearchText] = useState("");
  
  // eslint-disable-next-line
  const [holidays, setHolidays] = useState([]);

  const {data: workingHoursData, loading: workingHoursLoading} = useQuery(GET_WORKING_HOURS, {
    fetchPolicy: "network-only",
  });

  const {loading: holidaysLoading, error: holidaysQueryError} = useQuery(GET_HOLIDAYS, {
    onCompleted(data) {
      // data.oro_meta[0].data;
      let holidaysArray = data.oro_meta
        .map((year) =>
          year.data.holidays.map((month) => {
            return month.timestamps?.filter(
              (timestamps) => timestamps.length > 0
            );
          })
        )
        .flat(2)
        .filter((timestamps) => timestamps !== undefined)
        .map((holiday) => moment(holiday).format("DD-MM-YYYY"));

      setHolidays(holidaysArray);
    },
  });

  const [confirmVisit] = useMutation(CONFIRM_VISIT, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description:
            "Something went wrong. Please check all details and try again",
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Visit confirmed",
      });
      // props.history.push("/visits");
      props.history.push(`/visits/view/${props.match.params.id}`);
    },
  });

  const {
    data: visitData,
    loading: visitLoading,
    error: visitError,
  } = useQuery(GET_VISIT_BY_ID, {
    variables: {
      where: {
        id: { _eq: parseInt(props.match.params.id) },
      },
    },
    fetchPolicy: "network-only",
    onCompleted(visitData) {
      if (visitData) {
        if (visitData.visits.length > 0) {
          setTimeout(function () {
            if (confirmFormRef.current !== undefined) {
              confirmFormRef.current.setFieldValue(
                "first_name",
                visitData.visits[0].first_name
              );
              // confirmFormRef.current.setFieldValue(
              //   "last_name",
              //   visitData.visits[0].last_name
              // );
              confirmFormRef.current.setFieldValue(
                "mobile",
                visitData.visits[0].mobile_number.split("+91")[1]
              );
              confirmFormRef.current.setFieldValue(
                "address",
                visitData.visits[0].address ? visitData.visits[0].address : null
              );
              confirmFormRef.current.setFieldValue(
                "lat",
                visitData.visits[0].address.lat
              );
              confirmFormRef.current.setFieldValue(
                "lng",
                visitData.visits[0].address.long
              );
              confirmFormRef.current.setFieldValue(
                "visit_type",
                visitData.visits[0].visit_loan_type
              );

              // if (
              //   visitData.visits[0].gold_type &&
              //   visitData.visits[0].visit_type === "GP"
              // )
              //   confirmFormRef.current.setFieldValue(
              //     "gold_type",
              //     visitData.visits[0].gold_type
              //   );

              if (visitData.visits[0].loan_subtype)
                confirmFormRef.current.setFieldValue(
                  "loan_subtype",
                  visitData.visits[0].loan_subtype
                );

              if (visitData.visits[0].partner_branch?.id)
                confirmFormRef.current.setFieldValue(
                  "partner_branch",
                  visitData.visits[0].partner_branch?.id
                );

              if (visitData.visits[0].channel)
                confirmFormRef.current.setFieldValue(
                  "channel",
                  visitData.visits[0].channel
                );
            } else {
              // window.location.reload();
              props.history.go();
            }
          }, 1000);
        }
      }
    },
  });

  const {
    loading: branchesDataLoding,
    error: branchesDataError,
    data: branchesData,
  } = useQuery(GET_PARTNER_BRANCHES);

  const {
    data: goldOrnamentTypes,
    loading: goldOrnamentTypesLoading,
    error: goldOrnamentTypesError,
  } = useQuery(GET_ACTIVE_GOLD_ORNAMENT_TYPES);

  if (
    branchesDataLoding ||
    visitLoading ||
    holidaysLoading ||
    workingHoursLoading ||
    goldOrnamentTypesLoading
  )
    return <Loading />;

  if (
    branchesDataError ||
    visitError ||
    holidaysQueryError ||
    goldOrnamentTypesError
  ) {
    return <ErrorPage />;
  }

  const handleSubmit = (values) => {
    if (confirmFormRef.current) {
      confirmFormRef.current.handleSubmit();
    }
  };

  let initialValues = {
    first_name: "",
    // last_name: "",
    mobile: "",
    gold_type: [],
    channel: "",
    address: {
      addr_line_1: "",
      addr_line_2: "",
      city: "",
      state: "",
      pincode: "",
      country: "India",
      label: "Home",
      preferred: "yes",
    },
    lat: null,
    lng: null,
    visit_type: "",
    loan_subtype: "",
    date: "",
    time: "",
    partner_branch: "",
    support_loan: false,
  };

  return (
    <div className="space-y-4">
      <Button
        type="link"
        icon={<LeftOutlined />}
        className="flex items-center mr-4"
        onClick={() => {
          props.history.push("/visits");
        }}
      >
        All Visits
      </Button>
      <div className="flex justify-between items-center">
        <div className="flex justify-between">
          <h1> Create new visit</h1>
        </div>
      </div>

      <Formik
        enableReinitialize={false}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, { resetForm }) => {
          confirmVisit({
            variables: {
              id: parseInt(props.match.params.id),
              visit_time: moment(values.date + values.time, "DD-MM-YYYY HH:mm"),
              confirmed_at: moment(),
              confirmed_by: jwt_decode(localStorage.getItem("token")).user_id,
              channel: values.channel,
              // gold_type: values.gold_type,
              visits_x_gold_ornament_types: values.gold_type.map(
                (gold_type) => {
                  return {
                    visit_id: parseInt(props.match.params.id),
                    gold_ornament_type_id: gold_type,
                  };
                }
              ),

              partner_branch_id: values.partner_branch,
              address: values.address,
              loan_subtype:
                values.loan_subtype === "" ? null : values.loan_subtype,
              lat: values.lat,
              long: values.lng,
              support_loan: values.support_loan,
            },
          }).then(() => resetForm({}));
        }}
        innerRef={confirmFormRef}
        initialValues={initialValues}
        formCls="flex flex-col content-center justify-center space-y-8"
      >
        {({ values }) => (
          <Form>
            {/* User details div*/}
            <div className="border">
              {/* User details header */}
              <div className="border-b w-full px-4 py-2 bg-green-primary">
                <h2 className="text-white">User details</h2>
              </div>
              {/* User details header */}

              {/* User details form */}
              <div className="p-4 space-y-4">
                {/* User details form - line 1*/}
                <div className="flex space-x-8">
                  <div className="w-1/5">
                    <ReactInput
                      name="first_name"
                      type="text"
                      label="Name"
                      disabled
                    />
                  </div>

                  {/* <div className="w-1/5">
                    <ReactInput
                      name="last_name"
                      type="text"
                      label="Last Name"
                      disabled
                    />
                  </div> */}

                  <div className="w-1/5">
                    <ReactInput
                      name="mobile"
                      type="text"
                      label="Mobile number"
                      placeholder=""
                      disabled
                    />
                  </div>

                  <div className="w-1/5">
                    {/* <ReactInput
                      name="gold_type"
                      type="text"
                      label="Gold type"
                      placeholder=""
                      // disabled={true}
                      disabled={
                        visitData.visits[0].gold_type &&
                        visitData.visits[0].visit_type === "GR"
                      }
                    /> */}
                    <ReactSelect
                      name="gold_type"
                      type="text"
                      label="Gold types"
                      // placeholder="Choose partner branch"
                      // disabled={true}
                      mode="multiple"
                      disabled={visitData.visits[0].visit_type === "GR"}
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {goldOrnamentTypes.gold_ornament_types.map((gold_ornament_type, i) => (
                        <Option value={gold_ornament_type.id} key={i}>
                          {gold_ornament_type.type}
                        </Option>
                      ))}
                    </ReactSelect>
                  </div>

                  <div className="w-1/5">
                    <ReactInput
                      name="channel"
                      type="text"
                      label="Channel"
                      placeholder=""
                      disabled={
                        visitData.visits[0].channel &&
                        visitData.visits[0].visit_type === "GR"
                      }
                    />
                  </div>
                </div>
                {/* User details form - line 1*/}

                {/* User details form - line 2*/}
                <div className="space-y-2">
                  <p className="text-gray-800 font-semibold text-lg">Address</p>
                  <div className="w-2/3">
                    <ReactInput
                      name="address.addr_line_1"
                      type="text"
                      placeholder="Address line 1"
                    />
                  </div>
                  <div className="w-2/3">
                    <ReactInput
                      name="address.addr_line_2"
                      type="text"
                      placeholder="Address line 2"
                    />
                  </div>
                  <div className="w-2/3 flex space-x-8">
                    <div className="w-1/3">
                      <ReactInput
                        name="address.city"
                        type="text"
                        placeholder="City"
                      />
                    </div>

                    <div className="w-1/3">
                      <ReactSelect
                        name="address.state"
                        type="text"
                        placeholder="State"
                      >
                        <Option value="Andhra Pradesh">Andhra Pradesh</Option>
                        <Option value="Andaman and Nicobar Islands">
                          Andaman and Nicobar Islands
                        </Option>
                        <Option value="Arunachal Pradesh">
                          Arunachal Pradesh
                        </Option>
                        <Option value="Assam">Assam</Option>
                        <Option value="Bihar">Bihar</Option>
                        <Option value="Chandigarh">Chandigarh</Option>
                        <Option value="Chhattisgarh">Chhattisgarh</Option>
                        <Option value="Dadra and Nagar Haveli and Daman and Diu">
                          Dadra and Nagar Haveli and Daman and Diu
                        </Option>
                        <Option value="Delhi">Delhi</Option>
                        <Option value="Goa">Goa</Option>
                        <Option value="Gujarat">Gujarat</Option>
                        <Option value="Haryana">Haryana</Option>
                        <Option value="Himachal Pradesh">
                          Himachal Pradesh
                        </Option>
                        <Option value="Jammu and Kashmir">
                          Jammu and Kashmir
                        </Option>
                        <Option value="Jharkhand">Jharkhand</Option>
                        <Option value="Karnataka">Karnataka</Option>
                        <Option value="Kerala">Kerala</Option>
                        <Option value="Ladakh">Ladakh</Option>
                        <Option value="Lakshadweep">Lakshadweep</Option>
                        <Option value="Madhya Pradesh">Madhya Pradesh</Option>
                        <Option value="Maharashtra	">Maharashtra </Option>
                        <Option value="Manipur">Manipur</Option>
                        <Option value="Meghalaya">Meghalaya</Option>
                        <Option value="Mizoram">Mizoram</Option>
                        <Option value="Nagaland">Nagaland</Option>
                        <Option value="Odisha">Odisha</Option>
                        <Option value="Puducherry">Puducherry</Option>
                        <Option value="Punjab">Punjab</Option>
                        <Option value="Rajasthan">Rajasthan</Option>
                        <Option value="Sikkim">Sikkim</Option>
                        <Option value="Tamil Nadu">Tamil Nadu</Option>
                        <Option value="Telangana">Telangana</Option>
                        <Option value="Tripura">Tripura</Option>
                        <Option value="Uttar Pradesh">Uttar Pradesh</Option>
                        <Option value="Uttarakhand">Uttarakhand</Option>
                        <Option value="West Bengal">West Bengal</Option>
                      </ReactSelect>
                    </div>

                    <div className="w-1/3">
                      <ReactInput
                        name="address.pincode"
                        type="text"
                        placeholder="Pincode"
                      />
                    </div>
                  </div>
                </div>
                {/* User details form - line 2*/}

                {/* User details form - line 3*/}
                <div className="space-y-2">
                  <p className="text-gray-800 font-semibold text-lg">
                    Location
                  </p>
                  <ReactGeocoding />
                </div>

                <div className="w-1/3 flex space-x-8">
                  <div className="w-1/2">
                    <ReactInputNumber name="lat" label="Lat" />
                  </div>

                  <div className="w-1/2">
                    <ReactInputNumber name="lng" label="Long" />
                  </div>
                </div>
              </div>
              {/* User details form */}
            </div>
            {/* User details div*/}

            {/* Visit details div*/}
            <div className="border">
              {/* Visit details header */}
              <div className="border-b w-full px-4 py-2 bg-green-primary">
                <h2 className="text-white">Visit details</h2>
              </div>
              {/* Visit details header */}

              {/* Visit details form */}
              <div className="p-4 space-y-4">
                {/* Visit details form - line 1*/}
                <div className="flex space-x-8">
                  <div className="w-1/3 space-y-3">
                    <div className="flex justify-between items-center">
                      <p className="text-gray-800 font-semibold text-lg">
                        Visit date and time
                      </p>
                      <p>
                        Preferred date & time:{" "}
                        {moment(visitData.visits[0].visit_time).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                      </p>
                    </div>

                    <div className="flex space-x-8">
                      <div className="w-1/2">
                        <ReactDatepicker
                          name="date"
                          disabledDate={function disabledDate(current) {
                            return (
                              (current &&
                                current.valueOf() <
                                  moment().subtract(1, "d")) ||
                              Object.keys(workingHoursData.oro_meta[0].data)
                                .filter((day) => {
                                  return (
                                    workingHoursData.oro_meta[0].data[day]
                                      .start === undefined
                                  );
                                })
                                .includes(moment(current).format("dddd"))
                            );
                          }}
                        />
                      </div>
                      <div className="w-1/2">
                        <VisitTimePicker
                          name="time"
                          workingHours={workingHoursData.oro_meta[0].data}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-1/5">
                    <ReactSelect
                      name="visit_type"
                      type="text"
                      label="Visit type"
                      placeholder="Only GL is available"
                      disabled={true}
                    >
                      <Option value="GL" disabled>
                        GL
                      </Option>
                    </ReactSelect>
                  </div>

                  {visitData.visits[0].visit_type === "GP" ? (
                    <div className="w-1/5">
                      <ReactSelect
                        name="loan_subtype"
                        type="text"
                        label="Loan subtype"
                        placeholder="Fresh Loan / Transfer Loan"
                      >
                        <Option value="FRESH_LOAN">Fresh Loan</Option>
                        <Option value="TRANSFER_LOAN">Transfer Loan</Option>
                      </ReactSelect>
                    </div>
                  ) : null}

                  <div className="w-1/5">
                    <ReactSelect
                      name="partner_branch"
                      type="text"
                      label="Partner branch"
                      // placeholder="Choose partner branch"
                      // disabled={true}
                      defaultValue={visitData.visits[0]?.partner_branch?.id}
                      disabled={visitData.visits[0].partner_branch?.id}
                    >
                      {branchesData.partner_branches.map((branch) => {
                        return (
                          <Option value={branch.id}>
                            {branch.partner_branch_name}
                          </Option>
                        );
                      })}
                      {/* <Option value="default">Choose lending partner</Option> */}
                    </ReactSelect>
                  </div>

                  {visitData.visits[0].visit_type === "GP" ? (
                    <div className="w-1/5">
                      <ReactRadioGroup
                        name="support_loan"
                        label="Applicable for support loan"
                        options={[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                        ]}
                        defaultValue={false}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              {/* Visit details form */}
            </div>
            {/* Visit details div*/}
          </Form>
        )}
      </Formik>

      <div className="flex justify-center w-full space-x-8">
        <Popconfirm
          title={`Are you sure you want to cancel?`}
          onConfirm={() => {
            props.history.push(`/visits/view/${props.match.params.id}`);
          }}
          onCancel={() => {}}
          okText="Yes"
          placement="bottomRight"
          cancelText="No"
        >
          <button className="btn-danger hover:shadow-lg">Cancel</button>
        </Popconfirm>
        <button
          className="btn-primary hover:shadow-lg"
          onClick={() => handleSubmit()}
          type="submit"
        >
          Confirm Visit
        </button>
      </div>
    </div>
  );
};

export default ConfirmVisit;
