// DEPS
import React, { useState } from "react";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/client";

// COMPONENTS
import HolidayPicker from "../../components/HolidayPicker";
import { ReactForm, ReactWorkingHourspicker } from "../../components/Form";
import Loading from "../../components/loading";
import ErrorPage from "../../components/ErrorPage";
import { Select, notification, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";

// API
import {GET_HOLIDAYS, GET_WORKING_HOURS, SET_LAST_META_AS_FALSE, INSERT_META} from "./graphql";

const SetMeta = (props) => {
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [holidaysForSelectedYear, setHolidaysForSelectedYear] = useState([]);
  const [changeType, setChangeType] = useState(null);
  const [workingHours, setWorkingHours] = useState({
    Monday: {},
    Tuesday: {},
    Wednesday: {},
    Thursday: {},
    Friday: {},
    Saturday: {},
    Sunday: {},
  });

  const [checkedDays, setCheckedDays] = React.useState({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
  });

  const formRef = React.useRef();

  const onSave = (payload, index) => {
    let formattedDate = payload.map((date) => moment(date).date());
    let copyState = [...holidaysForSelectedYear];

    let MonthIndex = copyState.findIndex((month) => month.month === index);

    copyState[MonthIndex] = {
      label: copyState[MonthIndex].label,
      month: copyState[MonthIndex].month,
      holidays: formattedDate,
      timestamps: payload,
    };

    setHolidaysForSelectedYear(copyState);
  };

  const years = (start, end) => {
    const result = [];
    for (let i = start; i < start + end; i++) {
      result.push(i);
    }
    return result;
  };

  const giveMonths = () => {
    let monthsObj = [];
    let months = moment.months();
    months.map((month, monthIndex) =>
      monthsObj.push({
        month: monthIndex,
        label: month,
        holidays: [],
      })
    );
    return monthsObj;
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const {
    data: holidays,
    loading: holidaysLoading,
    error: holidaysQueryError,
  } = useQuery(GET_HOLIDAYS, {
    variables: { year: { year: selectedYear } },
    fetchPolicy: "network-only",
    onCompleted(data) {
      if (data.oro_meta.length > 0)
        setHolidaysForSelectedYear(data.oro_meta[0].data.holidays);
      else setHolidaysForSelectedYear(giveMonths());
    },
  });

  const {
    data: workingHoursData,
    loading: workingHoursLoading,
    error: workingHoursQueryError,
  } = useQuery(GET_WORKING_HOURS, {
    fetchPolicy: "network-only",
  });

  const [setLastMetaAsFalse] = useMutation(SET_LAST_META_AS_FALSE, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        return notification.error({
          message: "Error!",
          description: "Something went wrong!",
        });
      });
    },
    onCompleted() {
      if (changeType === "HOLIDAYS")
        insertMeta({
          variables: {
            data: {
              type: "HOLIDAYS",
              data: {
                year: selectedYear,
                holidays: holidaysForSelectedYear,
              },
            },
          },
        });
      else if (changeType === "WORKING_HOURS") {
        insertMeta({
          variables: {
            data: {
              type: "WORKING_HOURS",
              data: workingHours,
            },
          },
        });
      }
    },
  });

  const [insertMeta] = useMutation(INSERT_META, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        return notification.error({
          message: "Error!",
          description: "Something went wrong!",
        });
      });
    },
    onCompleted() {
      notification.success({
        message: "Success!",
        description: "Data updated",
      });
    },
  });

  if (holidaysLoading || workingHoursLoading) return <Loading />;

  if (holidaysQueryError || workingHoursQueryError) return <ErrorPage />;

  return (
    <div className="space-y-8">
      <Button
        type="link"
        icon={<LeftOutlined />}
        className="flex items-center mr-4"
        onClick={() => {
          props.history.push("/visits");
        }}
      >
        All Visits
      </Button>
      <div className="space-y-4">
        <h1>HOLIDAYS</h1>

        <div className="flex space-x-4 items-center">
          <h2>Select year: </h2>
          <Select
            defaultValue={moment().year()}
            style={{ width: 120 }}
            onChange={(value) => {
              setHolidaysForSelectedYear([]);
              setSelectedYear(value);
            }}
            value={selectedYear}
          >
            {years(moment().year(), 10).map((year, yearIndex) => {
              return <Select.Option value={year}>{year}</Select.Option>;
            })}
          </Select>
        </div>
      </div>

      <div className="space-y-4">
        <div className="w-1/2 grid grid-cols-3 gap-4">
          <h3>Month</h3>
          <h3>Holidays</h3>
          <h3>Calender</h3>
        </div>

        <div className="grid gap-4">
          {holidaysForSelectedYear.map((mon, monIndex) => {
            return (
              <div className="grid grid-cols-6" key={monIndex}>
                <div className="col-auto">{mon.label}</div>
                <HolidayPicker
                  key={`${selectedYear}-${monIndex}`}
                  monIndex={monIndex}
                  selectedYear={selectedYear}
                  onSave={(payload) => onSave(payload, monIndex)}
                  datesOnMount={holidaysForSelectedYear.find(
                    (x) => x.month === monIndex
                  )}
                />
              </div>
            );
          })}
        </div>
      </div>

      <button
        onClick={() => {
          setChangeType("HOLIDAYS");
          setLastMetaAsFalse({
            variables: {
              id: holidays.oro_meta[0]?.id || 0,
            },
          });
        }}
        className="btn-primary hover:shadow-lg"
        type="submit"
      >
        Save
      </button>

      <div className="space-y-4">
        <h1>BUSINESS HOURS</h1>

        <div className="space-x-4">
          <ReactForm
            innerRef={formRef}
            onSubmit={(values) => {
              setChangeType("WORKING_HOURS");
              setWorkingHours(values.hours);
              setLastMetaAsFalse({
                variables: {
                  id: workingHoursData.oro_meta[0]?.id || 0,
                },
              });
            }}
            initialValues={{
              isChecked: {
                Monday: false,
                Tuesday: false,
                Wednesday: false,
                Thursday: false,
                Friday: false,
                Saturday: false,
                Sunday: false,
              },
              hours: {
                Monday:
                  Object.keys(workingHoursData.oro_meta[0].data.Monday).length >
                  0
                    ? {
                        start: moment(
                          workingHoursData.oro_meta[0].data.Monday.start
                        ),
                        end: moment(
                          workingHoursData.oro_meta[0].data.Monday.end
                        ),
                      }
                    : {},
                Tuesday:
                  Object.keys(workingHoursData.oro_meta[0].data.Tuesday)
                    .length > 0
                    ? {
                        start: moment(
                          workingHoursData.oro_meta[0].data.Tuesday.start
                        ),
                        end: moment(
                          workingHoursData.oro_meta[0].data.Tuesday.end
                        ),
                      }
                    : {},
                Wednesday:
                  Object.keys(workingHoursData.oro_meta[0].data.Wednesday)
                    .length > 0
                    ? {
                        start: moment(
                          workingHoursData.oro_meta[0].data.Wednesday.start
                        ),
                        end: moment(
                          workingHoursData.oro_meta[0].data.Wednesday.end
                        ),
                      }
                    : {},
                Thursday:
                  Object.keys(workingHoursData.oro_meta[0].data.Thursday)
                    .length > 0
                    ? {
                        start: moment(
                          workingHoursData.oro_meta[0].data.Thursday.start
                        ),
                        end: moment(
                          workingHoursData.oro_meta[0].data.Thursday.end
                        ),
                      }
                    : {},
                Friday:
                  Object.keys(workingHoursData.oro_meta[0].data.Friday).length >
                  0
                    ? {
                        start: moment(
                          workingHoursData.oro_meta[0].data.Friday.start
                        ),
                        end: moment(
                          workingHoursData.oro_meta[0].data.Friday.end
                        ),
                      }
                    : {},
                Saturday:
                  Object.keys(workingHoursData.oro_meta[0].data.Saturday)
                    .length > 0
                    ? {
                        start: moment(
                          workingHoursData.oro_meta[0].data.Saturday.start
                        ),
                        end: moment(
                          workingHoursData.oro_meta[0].data.Saturday.end
                        ),
                      }
                    : {},
                Sunday:
                  Object.keys(workingHoursData.oro_meta[0].data.Sunday).length >
                  0
                    ? {
                        start: moment(
                          workingHoursData.oro_meta[0].data.Sunday.start
                        ),
                        end: moment(
                          workingHoursData.oro_meta[0].data.Sunday.end
                        ),
                      }
                    : {},
              },
              isHoliday: {
                Monday: false,
                Tuesday: false,
                Wednesday: false,
                Thursday: false,
                Friday: false,
                Saturday: false,
                Sunday: false,
              },
            }}
            formCls="flex flex-col content-center justify-center space-y-8"
          >
            <ReactWorkingHourspicker
              // day={day}
              name="hours"
              checkedDays={checkedDays}
              setCheckedDays={setCheckedDays}
            />
          </ReactForm>
          <button
            // onClick={() => {
            //   if (!visitTime) {
            //     notification.warning({
            //       message: "Warning!",
            //       description: "No change made"
            //     });
            //   } else {
            //     setChangeType("VISIT_TIMINGS");
            //     setLastMetaAsFalse({
            //       variables: {
            //         id: visitTimings.oro_meta[0]?.id || 0
            //       }
            //     });
            //   }
            // }}
            onClick={() => handleSubmit()}
            className="btn-primary hover:shadow-lg"
            type="submit"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default SetMeta;
