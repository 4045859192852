import React, { useState, useEffect } from "react";
import { Select } from "antd";
import ImageUpload from "./imageUpload";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

const ReactSelectWithBtn = (props) => {
  const { errors, values, setFieldValue } = useFormikContext();

  const [proofType, setProofType] = useState(null);

  useEffect(() => {
    setProofType(props.editMode ? props.pre_value.type : null);
    // eslint-disable-next-line
  },  []);

  return (
    <div className="text-left space-y-2 w-full">
      {props.label && (
        <label className="text-gray-800 font-semibold text-lg">
          {props.label}
        </label>
      )}
      <div className="flex space-x-4 items-center relative">
        <Select
          {...props}
          onChange={(value) => {
            let temp = values[props.name];
            temp.type = value;
            setFieldValue(values[props.name], temp);
            setProofType(value);
          }}
          defaultValue={props.editMode ? props.pre_value.type : null}
          className="p-0 mb-0 text-gray-700 leading-tight ant-input-bg focus:outline-none focus:shadow-outline rounded"
        >
          {props.children}
        </Select>

        {proofType && (
          <ImageUpload
            buttonText={props.editMode ? "Edit ID proof" : "Upload ID proof"}
            multiple={true}
            maxLength={props.limit ? props.limit : 2}
            //   value={values.avatar}
            editMode={props.editMode}
            pre_files={props.editMode ? props.pre_images : null}
            maxLimitReached={() => {
              let tempValues = values[props.name];

              if (tempValues.length === 0) return false;

              let keys = tempValues.map((value) => {
                return value.type;
              });

              if (keys.includes(proofType)) {
                let index = tempValues.findIndex((value) => {
                  return value.type === proofType;
                });

                if (props.limit) {
                  return tempValues[index].image_url.length === props.limit;
                } else return tempValues[index].image_url.length === 2;
              } else {
                return false;
              }
            }}
            customizeSuccess={true}
            customOnSuccess={(data) => {
              if (values[props.name].image_url.length < props.limit + 1 || 3) {
                if (data !== undefined) {
                  let tempValues = values[props.name];

                  let keys = tempValues.type;

                  if (keys.includes(proofType)) {
                    tempValues.image_url = data;
                  } else {
                    // let obj = {
                    //   type: proofType,
                    //   image_url: data
                    // };
                    // tempValues.push(obj);
                    // console.log("Danger");
                  }

                  setFieldValue(props.name, tempValues);
                }
              }
            }}
            onError={(error) => {}}
            showError={false}
          />
        )}
      </div>
      <div style={{ minHeight: 21 }}>
        {errors[props.name] ? (
          <div className="error text-red-500">Required</div>
        ) : null}
      </div>
    </div>
  );
};

ReactSelectWithBtn.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
};

export default ReactSelectWithBtn;
