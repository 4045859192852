// DEPS
import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";

// COMPONENTS
import { Modal as AntModal, Select, notification } from "antd";
import {ReactForm, ReactInput, ReactInputNumber, ReactSelect} from "../Form";

// APIS
import {GET_LEDGER_LOAN_DETAILS} from "../../pages/loans/graphql";

const { Option } = Select;

const RECORD_OFFLINE_PAYMENT = gql`
  mutation recordOfflinePayment($oro_loan_number: String!, $payment_id: String) {
    ledger_loan_record_payment(oro_loan_number: $oro_loan_number, payment_id: $payment_id) {
      code
      message
    }
  }
`;

const INITIATE_OFFLINE_PAYMENT = gql`
  mutation initiateOfflinePayment($oro_loan_number: String!, $ledgerPayment: paymentType1!) {
    ledger_loan_initiate_payment(oro_loan_number: $oro_loan_number, payment: $ledgerPayment) {
      code
      body
    }
  }
`;

const FundTransferValidationSchema = Yup.object({
  utr: Yup.string().ensure().required("Required"),
  amount: Yup.number().typeError("Please enter a number").required("Required"),
  additional_text: Yup.string().nullable(),
  payment_type: Yup.string().ensure().required("Required"),
});

const RecordManualPayment = (props) => {
  
  const [recordOfflinePayment] = useMutation(RECORD_OFFLINE_PAYMENT, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description: error.message,
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Manual Payment recorded!",
      });
      
      props.setOpen(false);
    },
    refetchQueries: [
      {
        query: GET_LEDGER_LOAN_DETAILS,
        variables:{
          loanNumber: props.loan_number,
          sl_loanNumber: props.support_loan_number || "",
          isSupportLoanAvailable: !!props.support_loan_number,
        }
      },
    ],
  });
  
  const [initiateOfflinePayment] = useMutation(INITIATE_OFFLINE_PAYMENT, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description: error.message,
        });
      });
    },
    onCompleted(data) {
      recordOfflinePayment({
        variables: {
          oro_loan_number: props.loan_number,
          payment_id: `${data?.ledger_loan_initiate_payment?.body?.id}`
        }
      })
    },
    refetchQueries: [
      {
        query: GET_LEDGER_LOAN_DETAILS,
        variables:{
          loanNumber: props.loan_number,
          sl_loanNumber: props.support_loan_number || "",
          isSupportLoanAvailable: !!props.support_loan_number,
        }
      },
    ],
  });

  const formRef = React.useRef();

  const handleSubmit = (values) => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <AntModal
      width="640px"
      className="modal-title-center"
      visible={props.isOpen}
      footer={null}
    >
      <div className="space-y-8 mx-auto text-center">
        <h1>Enter transaction details</h1>

        <ReactForm
          formCls="flex flex-col content-center justify-center space-y-4"
          innerRef={formRef}
          initialValues={{
            utr: null,
            amount: props.min_amount || 0,
            additional_text: null,
            payment_type: "",
          }}
          validationSchema={FundTransferValidationSchema}
          onSubmit={async (values) => {
            initiateOfflinePayment({
              variables: {
                oro_loan_number: props.loan_number,
                ledgerPayment: {
                  amount: values.amount * 100,
                  method: "MANUAL",
                  transaction_id: values.utr
                }
              },
            });
          }}
        >
          <ReactInput
            name="utr"
            type="text"
            label="UTR"
            placeholder=""
          />

          <ReactInputNumber
            name="amount"
            label="Amount"
            min={props.min_amount}
            precision={2}
          />

          <ReactSelect
            name="payment_type"
            type="text"
            placeholder="RTGS / NEFT / IMPS / UPI"
            label="Transaction type"
          >
            <Option value="RTGS">RTGS</Option>
            <Option value="NEFT">NEFT</Option>
            <Option value="IMPS">IMPS</Option>
            <Option value="UPI">UPI</Option>
          </ReactSelect>

            <ReactInput
              name="additional_text"
              type="text"
              label="Additional text"
              placeholder=""
            />
        </ReactForm>

        <div className="flex space-x-4 justify-center">
          <button
            className="btn-primary"
            //   onClick={() => props.approveLoan()}
            onClick={() => handleSubmit()}
          >
            Confirm
          </button>
          <button
            className="btn-danger"
            onClick={() => {
              props.setOpen(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </AntModal>
  );
};

export default RecordManualPayment;
