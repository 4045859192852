import React from 'react'

function HorizontalTable({data=[], columns=[], title, className=''}) {
    return (
        <div className={`w-full flex flex-col items-center bg-white border border-gray-300 
                        ${className}`}
        >
            <div className="w-full py-2 upgraded-secondary-bg">
                <h4 className="w-full text-center font-bold text-black" style={{fontSize: '15px'}}>{title}</h4>
            </div>
            <div className="w-full grid grid-cols-2">
                {
                    data?.map((_, i) => (
                        <>
                        <div className="w-full py-3 px-3 border">
                            <p className={`w-full text-center text-black font-bold`} style={{fontSize: '14px'}}>{columns[i]}</p>
                        </div>
                        <div className="w-full py-3 px-3 border">
                            <p className={`w-full text-center text-black`} style={{fontSize: '14px'}}>{data[i]}</p>
                        </div>
                        </>
                    ))
                }
            </div>
        </div>
    )
}

export default HorizontalTable
