// DEPS
import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import { Button, Select, Popconfirm, notification } from "antd";
import { useMutation } from "@apollo/client";
import moment from "moment";

// COMPONENTS
import {
  ReactForm,
  ReactInput,
  ReactSelect,
  ReactSelectWithBtn,
  ReactDatepicker,
  ImageUpload,
} from "../../components/Form";
import { kycBankAccountDetailsValidationSchema } from "./validationSchema";

// API
import { INSERT_KYC_AND_BANK_ACCOUNT_DETAILS } from "./graphql";

const { Option } = Select;

const AddKYCAndBankAccountDetails = (props) => {
  const formRef = React.useRef();

  const handleSubmit = (values) => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const [insertKYCAndBankAccountDetails] = useMutation(
    INSERT_KYC_AND_BANK_ACCOUNT_DETAILS,
    {
      onError(error) {
        error.graphQLErrors.forEach((error) => {
          // console.log("Error", error);
          notification.error({
            message: "Error!",
            description:
              "Something went wrong. Please check all details and try again",
          });
        });
      },
      onCompleted(data) {
        notification.success({
          message: "Success!",
          description: "KYC and bank account details added",
        });
        props.history.push(`/users/view/${props.match.params.id}`);
      },
    }
  );

  let initialValues = {
    legal_name: "",
    father_name: "",
    mother_name: "",
    dob: "",
    marital_status: "",
    gender: "",
    id_proof: { type: "", image_url: [] },
    address_proof: { type: "", image_url: [] },
    pan_number: "",
    pan_proof: { type: "", image_url: [] },
    live_photo: "",
    bank_account_name: "",
    bank_account_number: "",
    bank_name: "",
    ifsc: "",
    bank_proof: { type: "", image_url: [] },
  };

  return (
    <div className="space-y-8">
      <Button
        type="link"
        icon={<LeftOutlined />}
        className="flex items-center mr-4"
        onClick={() => {
          props.history.push("/users");
        }}
      >
        All Users
      </Button>

      <div>
        <h1> Add KYC and bank account details for the customer</h1>
      </div>

      <ReactForm
        onSubmit={(values, { resetForm }) => {
          let kyc_data = {
            legal_name: values.legal_name,
            father_name: values.father_name,
            mother_name: values.mother_name,
            date_of_birth:
              moment(values.dob, "DD-MM-YYYY").format("YYYY-MM-DD") +
              "T00:00:00+00:00",
            gender: values.gender,
            marital_status: values.marital_status,
            id_proof: [
              {
                type: values.id_proof.type,
                image_url: values.id_proof.image_url[0],
              },
            ],
            address_proof: [
              {
                type: values.address_proof.type,
                image_url: values.address_proof.image_url[0],
              },
            ],
            pan_number: values.pan_number,
            pan_proof: [
              {
                type: values.pan_proof.type,
                image_url: values.pan_proof.image_url[0],
              },
            ],
            live_photo: values.live_photo,
            customer_auth_id: props.location.state.user_auth_id,
            address: "",
            is_approved: true,
          };

          let bank_account_data = {
            bank_account_name: values.bank_account_name,
            bank_account_number: values.bank_account_number,
            bank_name: values.bank_name,
            ifsc: values.ifsc,
            bank_proof: [
              {
                type: values.bank_proof.type,
                image_url: values.bank_proof.image_url[0],
              },
            ],
            bank_branch: "",
            user_auth_id: props.location.state.user_auth_id,
          };

          insertKYCAndBankAccountDetails({
            variables: {
              kycData: kyc_data,
              bankAccountData: bank_account_data,
            },
          });
        }}
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={kycBankAccountDetailsValidationSchema}
        formCls="flex flex-col content-center justify-center space-y-8"
      >
        {/* User KYC details div*/}
        <div className="border">
          {/* User KYC details header*/}
          <div className="border-b w-full px-4 py-2 bg-green-primary">
            <h2 className="text-white">User KYC details</h2>
          </div>
          {/* User KYC details header*/}

          {/* User KYC  details form*/}
          <div className="p-4 space-y-4">
            {/* Line 1 */}
            <div className="flex space-x-8">
              <div className="w-1/3">
                <ReactInput name="legal_name" type="text" label="Legal Name" />
              </div>

              <div className="w-1/3">
                <ReactInput
                  name="father_name"
                  type="text"
                  label="Father's Name"
                />
              </div>

              <div className="w-1/3">
                <ReactInput
                  name="mother_name"
                  type="text"
                  label="Mother's Name"
                />
              </div>
            </div>
            {/* Line 1 */}

            {/* Line 2 */}
            <div className="flex space-x-8">
              <div className="w-1/4">
                <ReactDatepicker
                  label="Date of birth"
                  name="dob"
                  disabledDate={function disabledDate(current) {
                    return current.valueOf() > moment();
                  }}
                />
              </div>

              <div className="w-1/4">
                <ReactSelect
                  name="marital_status"
                  type="text"
                  label="Marital Status"
                  placeholder="Single / Married / NA"
                >
                  <Option value="SINGLE">Single</Option>
                  <Option value="MARRIED">Married</Option>
                  <Option value="NOT_APPLICABLE">Not applicable</Option>
                </ReactSelect>
              </div>

              <div className="w-1/4">
                <ReactSelect
                  name="gender"
                  type="text"
                  label="Gender"
                  placeholder="Male / Female / Transgender / NA"
                >
                  <Option value="MALE">Male</Option>
                  <Option value="FEMALE">Female</Option>
                  <Option value="TRANSGENDER">Transgender</Option>
                  <Option value="NOT_APPLICABLE">Not applicable</Option>
                </ReactSelect>
              </div>

              <div className="w-1/5">
                <ReactInput name="pan_number" type="text" label="PAN number" />
              </div>
            </div>
            {/* Line 2 */}

            {/* Line 3 */}
            <div className="flex space-x-8">
              <div className="w-1/3 space-y-2">
                <label className="text-lg text-gray-800 font-semibold">
                  ID proof
                </label>

                <div className="w-full items-center flex space-x-8">
                  <ReactSelectWithBtn
                    name="id_proof"
                    type="text"
                    placeholder="Select proof"
                    limit={1}
                  >
                    <Option value="Aadhar card">Aadhar card</Option>
                    <Option value="Passport">Passport</Option>
                    <Option value="Voter ID">Voters ID</Option>
                    <Option value="License">Driving License</Option>
                  </ReactSelectWithBtn>
                </div>
              </div>

              <div className="w-1/3 space-y-2">
                <label className="text-lg text-gray-800 font-semibold">
                  Address proof
                </label>

                <div className="w-full items-center flex space-x-8">
                  <ReactSelectWithBtn
                    name="address_proof"
                    type="text"
                    placeholder="Select proof"
                    limit={1}
                  >
                    <Option value="Aadhar card">Aadhar card</Option>
                    <Option value="Ration card">Ration card</Option>
                    <Option value="Telephone bill">Telephone Bill</Option>
                    <Option value="Electricity bill">Electricity Bill</Option>
                    <Option value="Lease agreement">Lease agreement</Option>
                    <Option value="Passport">Passport</Option>
                  </ReactSelectWithBtn>
                </div>
              </div>

              <div className="w-1/3 space-y-2">
                <label className="text-lg text-gray-800 font-semibold">
                  PAN proof
                </label>

                <div className="w-full items-center flex space-x-8">
                  <ReactSelectWithBtn
                    name="pan_proof"
                    type="text"
                    placeholder="Select proof"
                    limit={1}
                  >
                    <Option value="FORM_60">Form 60</Option>
                    <Option value="CANCELLED_CHECK">Cancelled Check</Option>
                  </ReactSelectWithBtn>
                </div>
              </div>
            </div>
            {/* Line 3 */}

            <div className="space-y-2 relative">
              <h2 className="text-gray-800 font-semibold">User Photo:</h2>
              <ImageUpload
                buttonText="Upload"
                multiple={false}
                name="live_photo"
                showError={true}
                maxLength={1}
              />
            </div>
          </div>
          {/* User KYC details form*/}
        </div>
        {/* User KYC details div*/}

        {/* User Bank account details div*/}
        <div className="border">
          {/* User Bank account details header*/}
          <div className="border-b w-full px-4 py-2 bg-green-primary">
            <h2 className="text-white">User bank account details</h2>
          </div>
          {/* User Bank account details header*/}

          {/* User Bank account details form*/}
          <div className="p-4 space-y-4">
            {/* Line 1 */}
            <div className="flex space-x-8">
              <div className="w-1/3">
                <ReactInput
                  name="bank_account_name"
                  type="text"
                  label="Bank Account Name"
                />
              </div>

              <div className="w-1/3">
                <ReactInput
                  name="bank_account_number"
                  type="text"
                  label="Bank Account Number"
                />
              </div>

              <div className="w-1/3">
                <ReactInput name="bank_name" type="text" label="Bank Name" />
              </div>
            </div>
            {/* Line 1 */}

            {/* Line 2 */}
            <div className="w-2/3 flex space-x-8">
              <div className="w-1/2">
                <ReactInput name="ifsc" type="text" label="IFSC" />
              </div>

              <div className="w-1/2 space-y-2">
                <label className="text-lg text-gray-800 font-semibold">
                  Bank account proof
                </label>

                <div className="w-full items-center flex space-x-8">
                  <ReactSelectWithBtn
                    name="bank_proof"
                    type="text"
                    placeholder="Select proof"
                    limit={1}
                  >
                    <Option value="Passbook">Passbook</Option>
                    <Option value="Cancelled cheque">Cancelled cheque</Option>
                  </ReactSelectWithBtn>
                </div>
              </div>
            </div>
            {/* Line 2 */}
          </div>
          {/* User Bank account details form*/}
        </div>
        {/* User Bank account details div*/}
      </ReactForm>

      <div className="flex justify-center w-full space-x-8">
        <Popconfirm
          title={`Are you sure you want to cancel?`}
          onConfirm={() => {
            props.history.push("/users");
          }}
          onCancel={() => {}}
          okText="Yes"
          placement="bottomRight"
          cancelText="No"
        >
          <button className="btn-danger hover:shadow-lg">Cancel</button>
        </Popconfirm>
        <button
          className="btn-primary hover:shadow-lg"
          onClick={() => handleSubmit()}
          type="submit"
        >
          Insert user KYC and bank account details
        </button>
      </div>
    </div>
  );
};

export default AddKYCAndBankAccountDetails;
