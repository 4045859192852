// DEPS
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";

// COMPONENTS
import ErrorPage from "../../components/ErrorPage";
import ReactTable from "../../components/Table";

// APIS
import { GET_CUSTOMERS, EXPORT_CUSTOMERS } from "./graphql";

const UsersList = (props) => {
  const columns = [
    {
      title: "User ID",
      dataIndex: "userid",
      key: "userid",
      show: true,
      // width: 10,
      render: (text, data, index) =>
        data.customer ? data.customer.customer_display_id : "",
      sorter: (a, b) => {},
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Loan Account No:",
      dataIndex: "account_number",
      key: "account_number",
      show: true,
      render: (text, data, index) => {
        if (data.loans.length > 0) return `${data.loans[0]?.account_number}`;
        else return ``;
      }
      // width: 20,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "user_name",
      show: true,
      // width: 20,
      render: (text, data, index) => {
        if (data.kycs.length > 0) return `${data.kycs[0]?.legal_name}`;
        else return `${data.name}`;
      },
      sorter: (a, b) => {},
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      show: true,
      // width: 20,
    },

    {
      title: "Email",
      dataIndex: "email_id",
      key: "email_id",
      show: true,
      // width: 20,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      show: true,
      // width: 10,
    },
    {
      title: "Signed up on",
      dataIndex: "created_at",
      key: "created_at",
      show: true,
      // width: 15,
      render: (text, data, index) =>
        moment(data.created_at).format("DD-MM-YYYY"),
    },
    {
      title: "Loan ID",
      dataIndex: "loans",
      key: "loans",
      show: true,
      render: (text, data, index) => data?.loans?.length !== 0 ? (
        <Link
          to={{
            pathname: `/loans/view/${data?.loans[0]?.id}`,
            state: {
              loan_status: data?.loans[0]?.loan_status,
            },
          }}
        >
          <p className="text-green-primary hover:underline cursor-pointer">
            {data?.loans[0]?.loan_number || ""}
          </p>
        </Link>
      ) : null,
    },
    {
      title: "Visit ID",
      dataIndex: "loans",
      key: "loans",
      show: true,
      render: (text, data, index) => (data?.loans?.length !== 0 && data?.loans[0]?.visits?.length !== 0) ? (
        <Link
          to={{
            pathname: `/visits/view/${data?.loans[0]?.visits[0].id}`,
          }}
        >
          <p className="text-green-primary hover:underline cursor-pointer">
            {data?.loans[0]?.visits[0].visit_display_id || ""}
          </p>
        </Link>
      ) : null,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      show: true,
      fixed: "right",
      // width: 5,
      render: (text, data, index) => {
        return (
          <Link to={`/users/view/${data.customer.id}`}>
            <EyeOutlined />
          </Link>
        );
      },
    },
  ];

  //   const [filter, setFilter] = useState([
  //     {
  //       title: "Platform",
  //       dataIndex: "platform",
  //       key: "platform",
  //       type: "select",
  //       show: false,
  //       data: [
  //         { id: "android", name: "Android" },
  //         { id: "ios", name: "iOS" }
  //       ],
  //       isFixed: true,
  //       filter: { _in: "$selected" }
  //     }
  //   ]);

  // const [filterObj, setFilterObj] = useState({});
  const [customerIdOrder, SetCustomerIdOrder] = useState(null);
  const [customerNameOrder, SetCustomerNameOrder] = useState(null);
  // const [createdAtOrder, SetCreatedAtOrder] = useState(null);

  // useEffect(() => {
  //   let objectFilters = {};
  //   var re = new RegExp("$selected", "g");

  //   (filter || []).map(filter => {
  //     if ((filter.selected || []).length)
  //       if (filter.show || filter.isFixed) {
  //         if (filter.filterType) {
  //           objectFilters[filter.key] = filter.selected;
  //         } else {
  //           objectFilters[filter.key] = { _in: filter.selected };
  //         }
  //       }
  //   });
  //   // resetState();

  //   setFilterObj(objectFilters);
  // }, [filter]);

  const [tableData, setTableData] = useState({
    page: 1,
    data: [],
    hasMoreItems: true,
  });

  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    // position: ["none", "none"],
    pageSize: 10,
    current: 1,
    total: 30,
  });

  const [searchText, SetSearchText] = useState("");

  const resetState = () => {
    setTableData({
      page: 1,
      data: [],
      hasMoreItems: true,
    });
    setPagination({
      ...pagination,
      total: 0,
      current: 1,
    });
  };

  const [CSVData, setCSVData] = useState([]);

  const triggerCSVDownload = () => {
    document.getElementById("react-csv").click();
  };

  const customerDataQueryOnComplete = (customerData) => {
    if (!customerData.users) {
      return;
    }
    if (customerData.users_aggregate) {
      setPagination({
        ...pagination,
        total: customerData.users_aggregate.aggregate.count,
      });
    }
    let hasMoreItems = true;
    if ((customerData.users || []).length === 0) {
      hasMoreItems = false;
    }
    let customers = (customerData.users || []).map((customer) => {
      return { ...customer };
    });
    setTableData({
      ...tableData,
      data: customers,

      // data: [...tableData.data, ...agents],
      hasMoreItems: hasMoreItems,
    });
  };

  const formatCSVData = (customers) => {
    // let CSVData = [];

    let CSVData = (customers.users || []).map((data) => {
      return {
        name: `${data.name || ""}`,
        account_number: data.account_number,
        email_id: data.email_id,
        mobile_number: data.mobile_number,
        userID: (data.customer || {}).customer_display_id,
        created_at: moment(data.created_at).format("DD-MM-YYYY"),
        loan_number: data.loans.length === 0 ? "" : data?.loans[0]?.loan_number,
        visit_id: (data?.loans?.length !== 0 && data?.loans[0]?.visits?.length !== 0) ? data?.loans[0]?.visits[0]?.visit_display_id : "",
      };
    });

    setTimeout(function () {
      setCSVData(CSVData);
      triggerCSVDownload();
    }, 3000);
  };

  const { loading: customerDataLoading, error: customerDataQueryError } = useQuery(GET_CUSTOMERS, {
    variables: {
      limit: parseInt(process.env.REACT_APP_API_PAGE_LIMIT),
      offset: process.env.REACT_APP_API_PAGE_LIMIT * (tableData.page - 1),
      order_by: {
        id: customerIdOrder,
        created_at: customerIdOrder || customerNameOrder ? null : "desc",
        name: customerNameOrder,
      },
      where: {
        // ...filterObj,
        _and: [
          {
            role_name: {
              _eq: "CUSTOMER",
            },
          },
          {
            _or: [{ name: { _ilike: `%${searchText}%` } }, { mobile_number: { _ilike: `%${searchText}%` } }],
          },
        ],
      },
    },
    fetchPolicy: "network-only",
    onCompleted: customerDataQueryOnComplete,
  });

  const [exportPlanCSV, { loading: exportLoading }] = useLazyQuery(EXPORT_CUSTOMERS, {
    variables: {
      where: {
        // ...filterObj,
        _and: [
          {
            role_name: {
              _eq: "CUSTOMER",
            },
          },
          {
            _or: [{ name: { _ilike: `%${searchText}%` } }],
          },
        ],
      },
    },
    fetchPolicy: "network-only",
    onCompleted: formatCSVData,
  });

  const loadMoreItems = (value) => {
    if (!customerDataLoading && tableData.hasMoreItems) {
      setTableData((data) => {
        return { ...data, page: data.page + 1 };
      });
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    if (sorter.field === "name") {
      let order = sorter.order
        ? sorter.order === "descend"
          ? "desc"
          : "asc"
        : null;
      SetCustomerIdOrder(null);

      SetCustomerNameOrder(order);
    }
    if (sorter.field === "userid") {
      let order = sorter.order
        ? sorter.order === "descend"
          ? "desc"
          : "asc"
        : null;
      SetCustomerNameOrder(null);

      SetCustomerIdOrder(order);
    }
    setTableData({
      ...tableData,
      page: pagination.current,
    });
    setPagination(pager);
  };

  if (customerDataQueryError) {
    return <ErrorPage />;
  }

  return (
    <>
      <CSVLink
        data={CSVData}
        headers={[
          { label: "User ID", key: "userID" },
          { label: "Loan Account Number", key: "account_number" },
          { label: "Name", key: "name" },
          { label: "Email Address", key: "email_id" },
          { label: "Phone Number", key: "mobile_number" },
          { label: "Signed Up On", key: "created_at" },
          { label: "Loan Number", key: "loan_number" },
          { label: "Visit ID", key: "visit_id" },
        ]}
        filename={`Customers - ${moment().format("DD-MM-YYYY HH:mm")}.csv`}
        className="hidden"
        target="_blank"
        id="react-csv"
      >
        Download CSV
      </CSVLink>{" "}
      <div className="flex justify-between align-center mb-4">
        <h2 className="text-green-primary">All Users</h2>
        <div className="flex justify-between">
          <Button
            className=" rounded-lg flex align-center ml-3 mr-3"
            type="primary"
            ghost
            size={"large"}
            onClick={() => {
              exportPlanCSV();
            }}
            loading={exportLoading}
          >
            Export
          </Button>
        </div>
      </div>
      <div className="w-100 position-relatives">
        <ReactTable
          columns={columns}
          data={tableData.data}
          table={{
            rowKey: (record) => record.id,
            pagination: pagination,
          }}
          // filter={filter}
          page={tableData.page}
          hasMoreItems={tableData.hasMoreItems}
          loadItems={() => {
            loadMoreItems(0);
          }}
          handleTableChange={handleTableChange}
          isToggleFullScreen={false}
          isToggleColumn={true}
          isMoreFilter={false}
          isSearch={true}
          loading={customerDataLoading}
          onToolbarSearch={(value) => {
            resetState();
            SetSearchText(value);
          }}
          onToolbarMore={(index, value) => {
            //   const filterData = [...filter];
            //   filterData[index].show = value;
            //   resetState();
            //   setFilter(filterData);
          }}
          onFilterSelectChange={(index, key, value) => {
            //   const filterData = [...filter];
            //   filterData[index].selected = value;
            //   resetState();
            //   setFilter(filterData);
          }}
        />
      </div>
    </>
  );
};

export default UsersList;
