import moment from "moment";
import * as XLSX from "xlsx";
import axios from "axios";

export const getToken = () => {
  if (localStorage.token) {
    return localStorage.token;
  }
  return false;
};

export const genColumnKey = (key, dataIndex, index) => {
  if (key) {
    return key;
  }
  if (!key && dataIndex) {
    if (Array.isArray(dataIndex)) {
      return dataIndex.join("-");
    }
    return dataIndex;
  }
  return `${index}`;
};
export const avatarMap = (name, maxInitials) => {
  return name
    .split(/\s/)
    .map(function (part) {
      return part.substring(0, 1).toUpperCase();
    })
    .filter(function (v) {
      return !!v;
    })
    .slice(0, maxInitials)
    .join("");
};

export const exportToXLS = (fileName, headers, exportdata) => {
  let exportdataArr = exportdata.map((data) => {
    return Object.keys(data).map((datamap) => {
      return data[datamap];
    });
  });
  var worksheet = XLSX.utils.aoa_to_sheet([headers, ...exportdataArr]);

  var new_workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
  XLSX.writeFile(
    new_workbook,
    `${fileName}-${moment(new Date()).format("DD/MM/YYYY")}.xlsx`
  );

  return;
  // var worksheet = XLSX.utils.json_to_sheet(exportdata, {
  //   cellDates: true,
  //   header: headers,
  // });

  // var new_workbook = XLSX.utils.book_new();

  // XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");

  // XLSX.writeFile(
  //   new_workbook,
  //   `${fileName}-${moment(new Date()).format("DD/MM/YYYY")}.csv`
  // );
};

const Number = (props) => {
  return props.number.toLocaleString("en-IN", { maximumFractionDigits: 2 });
};

const Currency = (props) =>
  props.number.toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

const get_image_url = async (props) => {
  const GET_VALID_URL = {
    query: `
      mutation getValidURL ($url:[String!]) {
        getValidUrlsArray(image_urls: $url) {
          urls
          message
        }
      }
    `,
  };

  if (props.url[0].length === 1) {
    GET_VALID_URL.variables = {
      url: props.url.map(
        (url) => `${url[0].split(process.env.REACT_APP_S3_BASE_URL)[1]}`
      ),
    };
  } else {
    GET_VALID_URL.variables = {
      url: props.url.map(
        (url) => `${url.split(process.env.REACT_APP_S3_BASE_URL)[1]}`
      ),
    };
  }

  const valid_url = await axios({
    method: "post",
    url: process.env.REACT_APP_GQL_ENDPOINT,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: GET_VALID_URL,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // console.log("err: ", err);
    });

  if (valid_url === undefined) {
  } else {
    if (valid_url.data.errors)
      console.log("Error", valid_url.data.errors, props.url[0]);
    else if (valid_url.data.data.getValidUrlsArray.urls.length === 1)
      return valid_url.data.data.getValidUrlsArray.urls[0];
    else return valid_url.data.data.getValidUrlsArray.urls;
  }
};

export { Number, Currency, get_image_url };
