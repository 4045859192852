import React from "react";
import { InputNumber } from "antd";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import "./form.css";

const ReactInputNumber = (props) => {
  const { errors, values, setFieldValue } = useFormikContext();

  return (
    <div className="text-red-500 text-left space-y-2 flex flex-col">
      {props.label && (
        <label className="text-gray-800 font-semibold text-lg">
          {props.label}
        </label>
      )}

      <InputNumber
        onChange={(value) => {
          setFieldValue(props.name, value);
        }}
        value={values[props.name]}
        type="number"
        {...props}
        size="large"
        className="text-gray-700 leading-tight ant-input-bg focus:outline-none focus:shadow-outline rounded w-full"
        disabled={
          props.disabled || props.disabledKey !== undefined
            ? values[props.disabledKey] !== undefined
              ? values[props.disabledKey].includes(props.disabledValue)
              : props.disabled
            : props.disabled
        }
      />

      <div style={{ minHeight: 21 }}>
        {errors[props.name] ? (
          <div className="error">{errors[props.name]}</div>
        ) : null}
      </div>
    </div>
  );
};

ReactInputNumber.defaultProps = {
  autoComplete: "off",
};

ReactInputNumber.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  ispassword: PropTypes.bool,
  autoComplete: PropTypes.bool,
};
export default ReactInputNumber;
