import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const ErrorWrapper = ({ error, productionErrorMessage }) => {
  return (
    <div role="alert" className="p-8 bg-red-100 rounded text-red-500 space-y-2">
      <div className="flex space-x-1 items-center text-lg">
        <ExclamationCircleOutlined />
        <p>Error!</p>
      </div>

      {process.env.ENV !== "UAT" || process.env.ENV !== "PRODUCTION" ? (
        <>
          {error.graphQLErrors.map((error) => {
            return (
              <>
                <div className="flex space-x-1">
                  <p>Path: </p>
                  <pre>{error.extensions.path}</pre>
                </div>
                <div className="flex space-x-1">
                  <p>Code: </p>
                  <pre>{error.extensions.code}</pre>
                </div>
                <div className="flex space-x-1">
                  <p>Message: </p>
                  <pre>{error.message}</pre>
                </div>
              </>
            );
          })}
        </>
      ) : (
        <p>{productionErrorMessage}</p>
      )}
    </div>
  );
};

export default ErrorWrapper;
