// DEPS
import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import moment from "moment";
import _ from "lodash";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Link } from "react-router-dom";
import { Form, Formik } from "formik";

// ANT
import {
  EyeOutlined,
  LeftOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Popconfirm,
  notification,
  Tooltip,
  Modal as AntModal,
} from "antd";

// COMPONENTS
import Loading from "../../components/loading";
import DisplayTable from "../../components/displayTable";
import RecordFundTransfer from "../../components/Modals/fundTransfer";
import RecordPayment from "../../components/Modals/recordPayment";
import ApproveLoan from "../../components/Modals/approveLoan";
import RecordManualPayment from "../../components/Modals/recordManualPayment";
import ChangePartnerBranchForVisit from "../../components/Modals/changePartnerBranch";
import { ReactInput } from "../../components/Form";

// VIEWS
import UserBankAccountDetails from "../../views/userBankAccountDetails";

// APIS
import {
  GET_LOAN_BY_ID,
  APPROVE_LOAN_BY_OROCORP,
  REJECT_LOAN_BY_OROCORP,
  GET_CURRENT_INTEREST_FOR_LOAN_ID,
  UPDATE_PAYMENT_UTR,
  GET_LEDGER_LOAN_DETAILS,
} from "./graphql";

import { get_image_url } from "../../utils";
import ErrorPage from "../../components/ErrorPage";

const LoanDetails = (props) => {
  const [creditBreakupData, setCreditBreakupData] = useState([]);
  const [goldImages, setGoldImages] = useState(null);
  const [sealImages, setSealImages] = useState(null);

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [images, setImages] = useState([]);

  const [isPaymentsModalOpen, setPaymentsModalOpen] = useState(null);
  const [isFundTransferModalOpen, setFundTransferModalOpen] = useState(false);
  const [isManualPaymentModalOpen, setManualPaymentModalOpen] = useState(false);

  // eslint-disable-next-line
  const [payment, setPayment] = useState(null);

  const [loanReceiptsUrl, setLoanReceiptsUrl] = useState(null);

  const [paymentType, setPaymentType] = useState("");

  const [isLoanDisbursalComplete, setLoanDisbursalComplete] = useState(false);

  const [UTRModalVisible, setUTRModalVisible] = useState(false);
  const [paymentLoanID, setPaymentLoanID] = useState(null);

  const [isVisitCancellationModalOpen, toggleVisitCancellationModal] =
    useState(false);

  const [approveLoan] = useMutation(APPROVE_LOAN_BY_OROCORP, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description:
            "Something went wrong. Please check all details and try again",
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Loan approved",
      });
      // props.history.push("/loans");
    },
    refetchQueries: [
      {
        query: GET_LOAN_BY_ID,
        variables: {
          id: parseInt(props.match.params.id),
        },
      },
    ],
  });

  const [rejectLoan] = useMutation(REJECT_LOAN_BY_OROCORP, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description:
            "Something went wrong. Please check all details and try again",
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Loan rejected",
      });
      // props.history.push("/loans");
    },
  });

  const {
    data: loanData,
    loading: loanDataLoading,
    error: loanDataQueryError,
  } = useQuery(GET_LOAN_BY_ID, {
    variables: { id: parseInt(props.match.params.id) },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      getLedgerLoanPayments({
        variables: {
          loanNumber: data.loans_by_pk.loan_number,
          sl_loanNumber: data.loans_by_pk?.support_loan?.support_loan_id || "",
          isSupportLoanAvailable: !!data.loans_by_pk?.support_loan?.support_loan_id,
        },
      });
    },
  });

  const {
    data: interest,
    loading: interestLoading,
    error: interestQueryError,
  } = useQuery(GET_CURRENT_INTEREST_FOR_LOAN_ID, {
    variables: { loan_id: parseInt(props.match.params.id) },
    fetchPolicy: "network-only",
    skip:
      props.location?.state?.loan_status === "PENDING_OROCORP_APPROVAL" || true,
  });

  const [getLedgerLoanPayments, { data: payments, loading: paymentsLoading }] =
    useLazyQuery(GET_LEDGER_LOAN_DETAILS, {
      fetchPolicy: "network-only",
    });

  const [updateUTR] = useMutation(UPDATE_PAYMENT_UTR, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description:
            "Something went wrong. Please check all details and try again",
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "UTR updated",
      });
      setUTRModalVisible(false);
      // props.history.push("/loans");
    },
    refetchQueries: [
      {
        query: GET_LEDGER_LOAN_DETAILS,
        variables: {
          loanNumber: loanData?.loans_by_pk?.loan_number,
          sl_loanNumber: loanData?.loans_by_pk?.support_loan?.support_loan_id,
          isSupportLoanAvailable:
            !!loanData?.loans_by_pk?.support_loan?.support_loan_id,
        },
      },
    ],
  });

  useEffect(() => {
    async function setGolds() {
      if (loanData !== undefined) {
        let groupedGold = _.groupBy(loanData.loans_by_pk.golds, function (e) {
          return e.actual_quality;
        });

        let groupedGoldKeys = Object.keys(groupedGold);
        let goldTableData = [];

        groupedGoldKeys.forEach((key) => {
          let total_quantity = groupedGold[key].reduce((total, goldItem) => {
            return total + goldItem.quantity;
          }, 0);

          let total_gross_wt = groupedGold[key].reduce((total, goldItem) => {
            return total + goldItem.gross_weight;
          }, 0);

          let total_net_wt = groupedGold[key].reduce((total, goldItem) => {
            return total + goldItem.net_weight;
          }, 0);

          let total_actual_net_wt = groupedGold[key].reduce(
            (total, goldItem) => {
              return total + goldItem.actual_net_weight;
            },
            0
          );

          // let rate = data.loans_by_pk.plan.plans_x_rates.find(rate => {
          //   return (
          //     rate.rate.gold_quality.toString().trim() === key.toString().trim()
          //   );
          // });

          let rate = loanData.loans_by_pk.rate.ma_rate;

          // rate = rate ? rate.rate.ma_rate : null;
          const ltv = loanData.loans_by_pk.plan.ltv;

          const obj = {
            quality: key,
            quantity: total_quantity,
            net_weight: total_net_wt,
            actual_net_weight: total_actual_net_wt,
            gross_weight: total_gross_wt,
            rate: rate,
            ltv: ltv,
            credit: total_net_wt * rate,
          };

          goldTableData.push(obj);
        });

        let goldImageArray = [];

        let sealImageArray = [];

        await Promise.all(
          loanData.loans_by_pk.golds.map(async (goldItem) => {
            let gold_ornaments = await get_image_url({
              url: goldItem.gold_images.gold_ornaments,
            });

            let obj = {};

            if (
              goldItem.gold_images.used_touchstone !== undefined &&
              goldItem.gold_images.clean_touchstone !== undefined
            ) {
              let used_touchstone = await get_image_url({
                url: goldItem.gold_images.used_touchstone,
              });

              let clean_touchstone = await get_image_url({
                url: goldItem.gold_images.clean_touchstone,
              });

              if (Array.isArray(gold_ornaments))
                obj = {
                  id: goldItem.gold_display_id,
                  images_url: [
                    ...gold_ornaments.map((image) => {
                      return { source: image, caption: "Gold ornaments" };
                    }),
                    { source: used_touchstone, caption: "Used touchstone" },
                    { source: clean_touchstone, caption: "Clean touchstone" },
                  ],
                };
              else
                obj = {
                  id: goldItem.gold_display_id,
                  images_url: [
                    { source: gold_ornaments, caption: "Gold ornaments" },
                    { source: used_touchstone, caption: "Used touchstone" },
                    { source: clean_touchstone, caption: "Clean touchstone" },
                  ],
                };
            } else {
              if (Array.isArray(gold_ornaments))
                obj = {
                  id: goldItem.gold_display_id,
                  images_url: gold_ornaments.map((image) => {
                    return { source: image, caption: "Gold ornaments" };
                  }),
                };
              else
                obj = {
                  id: goldItem.gold_display_id,
                  images_url: [
                    { source: gold_ornaments, caption: "Gold ornaments" },
                  ],
                };
            }

            goldImageArray.push(obj);

            let sealed_obj = {};

            let sealed_gold = await get_image_url({
              url: goldItem.seal.pickup_sealed_gold_images.sealed_gold,
            });

            if (
              goldItem.seal.pickup_sealed_gold_images.sealed_gold_seal_ID !==
                undefined &&
              goldItem.seal.pickup_sealed_gold_images.sealed_gold_cover_wt !==
                undefined
            ) {
              let sealed_gold_seal_ID = await get_image_url({
                url: goldItem.seal.pickup_sealed_gold_images
                  .sealed_gold_seal_ID,
              });

              let sealed_gold_cover_wt = await get_image_url({
                url: goldItem.seal.pickup_sealed_gold_images
                  .sealed_gold_cover_wt,
              });

              if (Array.isArray(sealed_gold))
                sealed_obj = {
                  id: goldItem.gold_display_id,
                  images_url: [
                    ...sealed_gold.map((image) => {
                      return { source: image, caption: "Sealed gold" };
                    }),
                    { source: sealed_gold_seal_ID, caption: "Seal ID" },
                    {
                      source: sealed_gold_cover_wt,
                      caption: "Sealed cover weight",
                    },
                  ],
                };
              else
                sealed_obj = {
                  id: goldItem.gold_display_id,
                  images_url: [
                    { source: sealed_gold, caption: "Sealed gold" },
                    { source: sealed_gold_seal_ID, caption: "Seal ID" },
                    {
                      source: sealed_gold_cover_wt,
                      caption: "Sealed cover weight",
                    },
                  ],
                };
            } else {
              if (Array.isArray(sealed_gold))
                sealed_obj = {
                  id: goldItem.gold_display_id,
                  images_url: sealed_gold.map((image) => {
                    return { source: image, caption: "Sealed gold" };
                  }),
                };
              else
                sealed_obj = {
                  id: goldItem.gold_display_id,
                  images_url: [{ source: sealed_gold, caption: "Sealed gold" }],
                };
            }

            sealImageArray.push(sealed_obj);
          })
        );

        if (loanData.loans_by_pk.loan_receipts) {
          let urls = [];

          if (loanData.loans_by_pk.loan_receipts.pledge_receipt) {
            if (loanData.loans_by_pk.loan_receipts.pledge_receipt.length > 0) {
              let url = [loanData.loans_by_pk.loan_receipts.pledge_receipt[0]];
              if (
                url[0]?.includes(
                  "https://s3-image-resize.s3.ap-south-1.amazonaws.com/"
                )
              ) {
                let loan_receipts_url = url[0];
                urls.push({
                  caption: "Generated Pledge receipt - Primary loan",
                  source: loan_receipts_url,
                });
              } else {
                let loan_receipts_url = await get_image_url({
                  url: url,
                });
                urls.push({
                  caption: "Generated Pledge receipt - Primary loan",
                  source: loan_receipts_url,
                });
              }
            }
          }

          if (loanData.loans_by_pk.loan_receipts.signed_pledge_receipt) {
            if (
              loanData.loans_by_pk.loan_receipts.signed_pledge_receipt.length >
              0
            ) {
              let url = [
                loanData.loans_by_pk.loan_receipts.signed_pledge_receipt[0],
              ];
              if (
                url[0]?.includes(
                  "https://s3-image-resize.s3.ap-south-1.amazonaws.com/"
                )
              ) {
                let loan_receipts_url = url[0];
                urls.push({
                  caption: "Signed Pledge receipt - Primary loan",
                  source: loan_receipts_url,
                });
              } else {
                let loan_receipts_url = await get_image_url({
                  url: url,
                });
                urls.push({
                  caption: "Signed Pledge receipt - Primary loan",
                  source: loan_receipts_url,
                });
              }
            }
          }

          if (loanData.loans_by_pk.loan_receipts.release_letter) {
            if (loanData.loans_by_pk.loan_receipts.release_letter.length > 0) {
              let url = [loanData.loans_by_pk.loan_receipts.release_letter[0]];
              if (
                url[0]?.includes(
                  "https://s3-image-resize.s3.ap-south-1.amazonaws.com/"
                )
              ) {
                let loan_receipts_url = url;
                urls.push({
                  caption: "Release Letter - Primary loan",
                  source: loan_receipts_url,
                });
              } else {
                let loan_receipts_url = await get_image_url({
                  url: url,
                });
                urls.push({
                  caption: "Release Letter - Primary loan",
                  source: loan_receipts_url,
                });
              }
            }
          }

          if (loanData.loans_by_pk.loan_receipts.support_loan_receipts) {
            if (
              loanData.loans_by_pk.loan_receipts.support_loan_receipts
                .pledge_receipt
            ) {
              if (
                loanData.loans_by_pk.loan_receipts.support_loan_receipts
                  .pledge_receipt.length > 0
              ) {
                let url = [
                  loanData.loans_by_pk.loan_receipts.support_loan_receipts
                    .pledge_receipt[0],
                ];
                if (
                  url[0]?.includes(
                    "https://s3-image-resize.s3.ap-south-1.amazonaws.com/"
                  )
                ) {
                  let loan_receipts_url = url[0];
                  urls.push({
                    caption: "Generated Pledge receipt - Support loan",
                    source: loan_receipts_url,
                  });
                } else {
                  let loan_receipts_url = await get_image_url({
                    url: url,
                  });
                  urls.push({
                    caption: "Generated Pledge receipt - Support loan",
                    source: loan_receipts_url,
                  });
                }
              }
            }

            if (
              loanData.loans_by_pk.loan_receipts.support_loan_receipts
                .signed_pledge_receipt
            ) {
              if (
                loanData.loans_by_pk.loan_receipts.support_loan_receipts
                  .signed_pledge_receipt.length > 0
              ) {
                let url = [
                  loanData.loans_by_pk.loan_receipts.support_loan_receipts
                    .signed_pledge_receipt[0],
                ];
                if (
                  url[0]?.includes(
                    "https://s3-image-resize.s3.ap-south-1.amazonaws.com/"
                  )
                ) {
                  let loan_receipts_url = url[0];
                  urls.push({
                    caption: "Signed Pledge receipt - Support loan",
                    source: loan_receipts_url,
                  });
                } else {
                  let loan_receipts_url = await get_image_url({
                    url: url,
                  });
                  urls.push({
                    caption: "Signed Pledge receipt - Support loan",
                    source: loan_receipts_url,
                  });
                }
              }
            }
          }

          if (loanData.loans_by_pk.loan_receipts.blended_loan_receipts) {
            if (
              loanData.loans_by_pk.loan_receipts.blended_loan_receipts
                .pledge_receipt
            ) {
              if (
                loanData.loans_by_pk.loan_receipts.blended_loan_receipts
                  .pledge_receipt.length > 0
              ) {
                let url = [
                  loanData.loans_by_pk.loan_receipts.blended_loan_receipts
                    .pledge_receipt[0],
                ];
                if (
                  url[0]?.includes(
                    "https://s3-image-resize.s3.ap-south-1.amazonaws.com/"
                  )
                ) {
                  let loan_receipts_url = url[0];
                  urls.push({
                    caption: "Generated Pledge receipt - Blended loan",
                    source: loan_receipts_url,
                  });
                } else {
                  let loan_receipts_url = await get_image_url({
                    url: url,
                  });
                  urls.push({
                    caption: "Generated Pledge receipt - Blended loan",
                    source: loan_receipts_url,
                  });
                }
              }
            }

            if (
              loanData.loans_by_pk.loan_receipts.blended_loan_receipts
                .signed_pledge_receipt
            ) {
              if (
                loanData.loans_by_pk.loan_receipts.blended_loan_receipts
                  .signed_pledge_receipt.length > 0
              ) {
                let url = [
                  loanData.loans_by_pk.loan_receipts.blended_loan_receipts
                    .signed_pledge_receipt[0],
                ];
                if (
                  url[0]?.includes(
                    "https://s3-image-resize.s3.ap-south-1.amazonaws.com/"
                  )
                ) {
                  let loan_receipts_url = url[0];
                  urls.push({
                    caption: "Signed Pledge receipt - Blended loan",
                    source: loan_receipts_url,
                  });
                } else {
                  let loan_receipts_url = await get_image_url({
                    url: url,
                  });
                  urls.push({
                    caption: "Signed Pledge receipt - Blended loan",
                    source: loan_receipts_url,
                  });
                }
              }
            }
          }

          setLoanReceiptsUrl(urls);
        }

        setGoldImages(goldImageArray);

        setSealImages(sealImageArray);

        setCreditBreakupData(goldTableData);
      }
    }

    setGolds();
  }, [loanData]);

  if (loanDataLoading || interestLoading || paymentsLoading) return <Loading />;

  if (loanDataQueryError || interestQueryError) return <ErrorPage />;

  return (
    <>
      <div className="space-y-8">
        <div className="flex justify-between">
          <div className="flex items-center pl-3">
            <div className="relative">
              <LeftOutlined
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "#16A085",
                }}
              />
            </div>

            <Button
              type="link"
              onClick={() => {
                props.history.push("/loans");
              }}
            >
              All loans
            </Button>
          </div>
        </div>

        <div className="flex justify-between w-full items-center">
          <div>
            <h2>Loan details | {loanData.loans_by_pk.loan_number}</h2>
            <p>
              Requested on{" "}
              {moment(loanData.loans_by_pk.created_at).format("MMM DD, HH:mm")}{" "}
              | {loanData.loans_by_pk.loan_type.loan_type} |{" "}
              {loanData.loans_by_pk.loan_status === "PENDING_OROCORP_APPROVAL"
                ? "Pending Orocorp Approval"
                : null}
              {loanData.loans_by_pk.loan_status !==
                "PENDING_OROCORP_APPROVAL" &&
                (loanData.loans_by_pk.loan_status !== "OROCORP_REJECTED"
                  ? "Approved "
                  : "Rejected ")}
              {loanData.loans_by_pk.orocorp_approved_at
                ? `on ${moment(loanData.loans_by_pk.orocorp_approved_at).format(
                    "MMM DD, HH:mm"
                  )}`
                : null}
              {loanData.loans_by_pk.orocorp_approved_by_user
                ? ` by ${loanData.loans_by_pk.orocorp_approved_by_user.first_name} ${loanData.loans_by_pk.orocorp_approved_by_user.last_name}`
                : null}
            </p>
          </div>

          <div className="flex space-x-4">
            {loanData.loans_by_pk.loan_receipts && loanReceiptsUrl ? (
              loanReceiptsUrl.length > 0 ? (
                <button
                  className="cta py-2 px-4"
                  onClick={() => {
                    setIsImageModalOpen(!isImageModalOpen);
                    // setModalType("id_proof");
                    // setProofType(proof.type);
                    setImages(loanReceiptsUrl);
                  }}
                >
                  View loan receipts
                </button>
              ) : null
            ) : null}

            {loanData.loans_by_pk.loan_status === "PENDING_OROCORP_APPROVAL" ? (
              <ChangePartnerBranchForVisit
                isOpen={isVisitCancellationModalOpen}
                setOpen={toggleVisitCancellationModal}
                loan_id={loanData.loans_by_pk.id}
                visit_id={loanData.loans_by_pk.gold_pickup_visit[0]?.id}
                type="visit"
              />
            ) : null}

            {loanData.loans_by_pk.gold_pickup_visit.length !== 0 &&
            loanData.loans_by_pk.gold_pickup_visit[0]?.intermediate_status ===
              "OD_LOAN_RECEIPTS_ADDED" ? (
              <>
                {loanData.loans_by_pk.primary_loan_fund_transfer.aggregate
                  .count === 0 ? (
                  <button
                    className="cta py-2"
                    onClick={() => {
                      setPaymentType("Fund Transfer");
                      setFundTransferModalOpen(true);
                    }}
                  >
                    Enter primary loan fund transfer details
                  </button>
                ) : null}

                {loanData.loans_by_pk.support_loan &&
                loanData.loans_by_pk.support_loan_fund_transfer.aggregate
                  .count === 0 ? (
                  <button
                    className="cta py-2"
                    onClick={() => {
                      setPaymentType("Support Loan Fund Transfer");
                      setFundTransferModalOpen(true);
                    }}
                  >
                    Enter support loan fund transfer details
                  </button>
                ) : null}
              </>
            ) : null}

            {loanData.loans_by_pk.loan_status === "GOLD_STORED" ||
            loanData.loans_by_pk.loan_status === "GOLD_RELEASED" ||
            loanData.loans_by_pk.loan_status ===
              "LOAN_ACCOUNT_CLOSURE_INITIATED" ||
            loanData.loans_by_pk.loan_status === "LOAN_CLOSED" ? (
              loanData.loans_by_pk.loan_disbursal.aggregate.count === 0 ? (
                !isLoanDisbursalComplete ? (
                  <button
                    className="cta py-2"
                    onClick={() => {
                      setPaymentType("Loan disbursal");
                      setPaymentsModalOpen(true);
                    }}
                  >
                    Enter loan disbursal details
                  </button>
                ) : null
              ) : null
            ) : null}

            {loanData.loans_by_pk.loan_status === "PENDING_OROCORP_APPROVAL" ? (
              <>
                <Popconfirm
                  title={`Are you sure you want to reject this loan?`}
                  onConfirm={() => {
                    rejectLoan({
                      variables: {
                        loan_id: loanData.loans_by_pk.id,
                        visit_id: loanData.loans_by_pk.gold_pickup_visit[0]?.id,
                        approved_at: moment(),
                      },
                    });
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  placement="bottomRight"
                  cancelText="No"
                >
                  <button className="btn-danger hover:shadow-lg">
                    Reject loan
                  </button>
                </Popconfirm>
                <ApproveLoan
                  loan_id={loanData.loans_by_pk.id}
                  support_loan_applicable={
                    loanData.loans_by_pk.gold_pickup_visit[0]?.support_loan
                  }
                  approveLoan={approveLoan}
                  visit_id={loanData.loans_by_pk.gold_pickup_visit[0]?.id}
                  customer_auth_id={loanData.loans_by_pk.customer.user.auth_id}
                  loans_by_pk={loanData.loans_by_pk}
                />
              </>
            ) : null}
          </div>
        </div>

        {/* Loans Summary  */}
        <div className="w-1/2">
          <DisplayTable
            name="Loans summary table"
            data={[
              {
                type: "Data",
                header: "Primary loan amount",
                data: `₹ ${loanData.loans_by_pk.gl_amount.loan_amount}`,
                width: "1/3",
              },
              {
                type: "Data",
                header: "Support loan amount",
                data: loanData.loans_by_pk.support_loan
                  ? `₹ ${loanData.loans_by_pk.support_loan.sl_amount?.loan_amount}`
                  : "-",
                width: "1/3",
              },
              {
                type: "Data",
                header: "Total loan amount",
                data: `₹ ${
                  loanData.loans_by_pk.gl_amount.loan_amount +
                  (loanData.loans_by_pk.support_loan?.sl_amount?.loan_amount ||
                    0)
                }`,
                width: "1/3",
              },
            ]}
          />
        </div>
        {/* Loans Summary  */}

        {/* Loan details */}
        <div>
          <DisplayTable
            name="Primary loan details"
            data={[
              {
                type: "Data",
                header: "Loan ID",
                data: loanData.loans_by_pk.loan_number,
                width: "1/6",
              },
              {
                type: "Data",
                header: "Account No",
                data: loanData?.loans_by_pk?.account_number || "NA",
                width: "1/6",
              },
              {
                type: "Link",
                header: "Partner branch",
                link: `/partners/branch/view/${loanData.loans_by_pk.partner_branch.id}`,
                data: loanData.loans_by_pk.partner_branch.partner_branch_name,
                width: "1/6",
              },
              {
                type: "Data",
                header: "User",
                data:
                  loanData.loans_by_pk?.kyc?.legal_name ||
                  `${loanData.loans_by_pk.customer.user.name}`,
                width: "1/6",
              },
              {
                type: "Data",
                header: "Request time",
                data: moment(loanData.loans_by_pk.created_at).format(
                  "MMM DD, HH:mm"
                ),
                width: "1/6",
              },
              {
                type: "Data",
                header: "Type",
                data: loanData.loans_by_pk.loan_type.loan_type,
                width: "1/6",
              },
              {
                type: "Link",
                header: "Plan",
                link: `/plans/view/${loanData.loans_by_pk.plan.id}`,
                data: loanData.loans_by_pk.plan.plan_name,
                width: "1/6",
              },
              {
                type: "Data",
                header: "Scheme name",
                data: loanData.loans_by_pk.plan.scheme_name,
                width: "1/6",
              },
              {
                type: "Data",
                header: "Tenure",
                data: `${loanData.loans_by_pk.plan.tenure} months`,
                width: "1/6",
              },
              {
                type: "Data",
                header: "Interest",
                data: `${loanData.loans_by_pk.plan.interest} %`,
                width: "1/6",
              },
              {
                type: "Data",
                header:
                  loanData.loans_by_pk.loan_type.loan_type === "OD"
                    ? "Credit limit"
                    : "Loan amount",
                data: `₹ ${
                  loanData.loans_by_pk.od_amount
                    ? loanData.loans_by_pk.od_amount.credit_limit.toLocaleString(
                        "en-IN",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )
                    : loanData.loans_by_pk.gl_amount
                    ? loanData.loans_by_pk.gl_amount.loan_amount.toLocaleString(
                        "en-IN",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )
                    : null
                }`,
                width: "1/6",
              },
              {
                type: "Data",
                header: "Eligible loan amount",
                data: `₹ ${
                  loanData.loans_by_pk.od_amount
                    ? "-"
                    : loanData.loans_by_pk.gl_amount
                    ? loanData.loans_by_pk.gl_amount?.eligible_loan_amount?.toLocaleString(
                        "en-IN",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )
                    : null
                }`,
                width: "1/6",
              },
              {
                type: "Data",
                header: "Customer ID",
                data: loanData.loans_by_pk.customer_id_from_bank,
                width: "1/6",
              },
            ]}
          />
        </div>
        {/* Loan details */}

        {/* SUPPORT LOAN DETIALS  */}
        {loanData.loans_by_pk.support_loan ? (
          <div>
            <DisplayTable
              name="Support loan details"
              data={[
                {
                  type: "Data",
                  header: "Loan ID",
                  data: loanData.loans_by_pk.support_loan.support_loan_id,
                  width: "1/6",
                },
                {
                  type: "Link",
                  header: "Partner",
                  link: `/partners/view/${loanData.loans_by_pk.support_loan.partner_branch.partner.id}`,
                  data: loanData.loans_by_pk.support_loan.partner_branch.partner
                    .partner_name,
                  width: "1/6",
                },
                {
                  type: "Link",
                  header: "Partner Branch",
                  link: `/partners/view/${loanData.loans_by_pk.support_loan.partner_branch.id}`,
                  data: loanData.loans_by_pk.support_loan.partner_branch
                    .partner_branch_name,
                  width: "1/6",
                },

                {
                  type: "Data",
                  header: "Type",
                  data: "SL",
                  width: "1/6",
                },
                {
                  type: "Link",
                  header: "Plan",
                  link: `/plans/view/${loanData.loans_by_pk.support_loan.plan.id}`,
                  data: loanData.loans_by_pk.support_loan.plan.plan_name,
                  width: "1/6",
                },

                {
                  type: "Data",
                  header: "Tenure",
                  data: `${loanData.loans_by_pk.support_loan.plan.tenure} months`,
                  width: "1/6",
                },
                {
                  type: "Data",
                  header: "Interest",
                  data: `${loanData.loans_by_pk.support_loan.plan.interest} %`,
                  width: "1/6",
                },
                {
                  type: "Data",
                  header: "Loan amount",
                  data: `₹ ${loanData.loans_by_pk.support_loan.sl_amount.loan_amount}`,
                  width: "1/6",
                },
              ]}
            />
          </div>
        ) : null}

        {/* SUPPORT LOAN DETAILS  */}

        {/* Credit breakup */}
        <div>
          <h4 className="mb-2">CREDIT BREAKUP</h4>
          <div className="flex bg-green-primary">
            <div className=" font-semibold text-white p-3 w-1/5">
              Gold quality
            </div>
            <div className=" font-semibold text-white p-3 w-1/5">Quantity</div>
            <div className=" font-semibold text-white p-3 w-1/5">
              Actual Net weight
            </div>
            <div className=" font-semibold text-white p-3 w-1/5">
              Equivalent Net weight (22 carats)
            </div>
            <div className=" font-semibold text-white p-3 w-1/5">MA rate</div>
            <div className=" font-semibold text-white p-3 w-1/5">Plan LTV</div>
            {/* <div className=" font-semibold text-white p-3 w-1/6">Credit</div> */}
          </div>

          {creditBreakupData.map((data, i) => {
            return (
              <div className="flex bg-white" key={i}>
                <div className="font-semibold p-3 w-1/5 break-all">
                  {data.quality}
                </div>
                <div className="font-semibold p-3 w-1/5 break-all">
                  {data.quantity}
                </div>
                <div className="font-semibold p-3 w-1/5 break-all">
                  {data.actual_net_weight.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                  })}
                  g
                </div>
                <div className="font-semibold p-3 w-1/5 break-all">
                  {data.net_weight.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                  })}
                  g
                </div>
                <div className="font-semibold p-3 w-1/5 break-all">
                  ₹ {data.rate}
                </div>
                <div className="font-semibold p-3 w-1/5 break-all">
                  {data.ltv}%
                </div>
                {/* <div className=" font-semibold p-3 w-1/6 break-all">
                  ₹{" "}
                  {data.credit.toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </div> */}
              </div>
            );
          })}
        </div>
        {/* Credit breakup */}

        {/* GP Primary Agent info */}
        <div>
          <h4 className="mb-2">PRIMARY AGENT INFO FOR GOLD PICKUP VISIT</h4>
          <div className="flex bg-green-primary">
            <div className=" font-semibold text-white p-3 w-2/5">
              Agent name
            </div>
            <div className=" font-semibold text-white p-3 w-2/5">
              Mobile number
            </div>
            <div className=" font-semibold text-white p-3 w-2/5">Agent ID</div>
            <div className=" font-semibold text-white p-3 w-2/5">Visit ID</div>
            <div className=" font-semibold text-white p-3 w-1/6">Action</div>
          </div>
          <div className="flex bg-white">
            <div className="font-semibold p-3 w-2/5 break-all">
              {loanData.loans_by_pk.gold_pickup_visit[0]?.agent.first_name}{" "}
              {loanData.loans_by_pk.gold_pickup_visit[0]?.agent.last_name}
            </div>
            <div className="font-semibold p-3 w-2/5 break-all">
              {loanData.loans_by_pk.gold_pickup_visit[0]?.agent.mobile_number}
            </div>
            <div className="font-semibold p-3 w-2/5 break-all">
              {loanData.loans_by_pk.gold_pickup_visit[0]?.agent.agent
                ? loanData.loans_by_pk.gold_pickup_visit[0]?.agent.agent
                    .agent_display_id
                : null}
            </div>
            <div className="font-semibold p-3 w-2/5 break-all">
              {loanData.loans_by_pk.gold_pickup_visit[0]?.visit_display_id}
            </div>
            <div className="font-semibold p-3 w-1/6 break-all">
              <Link
                to={`/agents/view/${loanData.loans_by_pk.gold_pickup_visit[0]?.agent.id}`}
              >
                <EyeOutlined />
              </Link>
            </div>
          </div>
        </div>
        {/* GP Agent info */}

        {/* GP Secondary Agent info */}
        {loanData.loans_by_pk.gold_pickup_visit[0]?.secondary_field_agent ? (
          <div>
            <h4 className="mb-2">SECONDARY AGENT INFO FOR GOLD PICKUP VISIT</h4>
            <div className="flex bg-green-primary">
              <div className=" font-semibold text-white p-3 w-2/5">
                Agent name
              </div>
              <div className=" font-semibold text-white p-3 w-2/5">
                Mobile number
              </div>
              <div className=" font-semibold text-white p-3 w-2/5">
                Agent ID
              </div>
              <div className=" font-semibold text-white p-3 w-2/5">
                Visit ID
              </div>
              <div className=" font-semibold text-white p-3 w-1/6">Action</div>
            </div>
            <div className="flex bg-white">
              <div className="font-semibold p-3 w-2/5 break-all">
                {
                  loanData.loans_by_pk.gold_pickup_visit[0]
                    .secondary_field_agent.first_name
                }{" "}
                {
                  loanData.loans_by_pk.gold_pickup_visit[0]
                    .secondary_field_agent.last_name
                }
              </div>
              <div className="font-semibold p-3 w-2/5 break-all">
                {
                  loanData.loans_by_pk.gold_pickup_visit[0]
                    .secondary_field_agent.mobile_number
                }
              </div>
              <div className="font-semibold p-3 w-2/5 break-all">
                {loanData.loans_by_pk.gold_pickup_visit[0]
                  ?.secondary_field_agent.agent
                  ? loanData.loans_by_pk.gold_pickup_visit[0]
                      .secondary_field_agent.agent.agent_display_id
                  : null}
              </div>
              <div className="font-semibold p-3 w-2/5 break-all">
                {loanData.loans_by_pk.gold_pickup_visit[0]?.visit_display_id}
              </div>
              <div className="font-semibold p-3 w-1/6 break-all">
                <Link
                  to={`/agents/view/${loanData.loans_by_pk.gold_pickup_visit[0]?.secondary_field_agent.id}`}
                >
                  <EyeOutlined />
                </Link>
              </div>
            </div>
          </div>
        ) : null}
        {/* GP Secondary Agent info */}

        {/* GP Tertirary Agent info */}
        {loanData.loans_by_pk.gold_pickup_visit[0]?.tertiary_field_agent ? (
          <div>
            <h4 className="mb-2">TERTIARY AGENT INFO FOR GOLD PICKUP VISIT</h4>
            <div className="flex bg-green-primary">
              <div className=" font-semibold text-white p-3 w-2/5">
                Agent name
              </div>
              <div className=" font-semibold text-white p-3 w-2/5">
                Mobile number
              </div>
              <div className=" font-semibold text-white p-3 w-2/5">
                Agent ID
              </div>
              <div className=" font-semibold text-white p-3 w-2/5">
                Visit ID
              </div>
              <div className=" font-semibold text-white p-3 w-1/6">Action</div>
            </div>
            <div className="flex bg-white">
              <div className="font-semibold p-3 w-2/5 break-all">
                {
                  loanData.loans_by_pk.gold_pickup_visit[0]
                    ?.tertiary_field_agent.first_name
                }{" "}
                {
                  loanData.loans_by_pk.gold_pickup_visit[0]
                    ?.tertiary_field_agent.last_name
                }
              </div>
              <div className="font-semibold p-3 w-2/5 break-all">
                {
                  loanData.loans_by_pk.gold_pickup_visit[0]
                    ?.tertiary_field_agent.mobile_number
                }
              </div>
              <div className="font-semibold p-3 w-2/5 break-all">
                {loanData.loans_by_pk.gold_pickup_visit[0]?.tertiary_field_agent
                  .agent
                  ? loanData.loans_by_pk.gold_pickup_visit[0]
                      .tertiary_field_agent.agent.agent_display_id
                  : null}
              </div>
              <div className="font-semibold p-3 w-2/5 break-all">
                {loanData.loans_by_pk.gold_pickup_visit[0]?.visit_display_id}
              </div>
              <div className="font-semibold p-3 w-1/6 break-all">
                <Link
                  to={`/agents/view/${loanData.loans_by_pk.gold_pickup_visit[0]?.tertiary_field_agent.id}`}
                >
                  <EyeOutlined />
                </Link>
              </div>
            </div>
          </div>
        ) : null}

        {/* GP Tertirary Agent info */}

        {/* GR Agent info */}
        {loanData.loans_by_pk.gold_release_visit.length > 0 ? (
          loanData.loans_by_pk.gold_release_visit[0]?.agent ? (
            <div>
              <h4 className="mb-2">AGENT INFO FOR GOLD RELEASE VISIT</h4>
              <div className="flex bg-green-primary">
                <div className=" font-semibold text-white p-3 w-2/5">
                  Agent name
                </div>
                <div className=" font-semibold text-white p-3 w-2/5">
                  Mobile number
                </div>
                <div className=" font-semibold text-white p-3 w-2/5">
                  Agent ID
                </div>
                <div className=" font-semibold text-white p-3 w-2/5">
                  Visit ID
                </div>
                <div className=" font-semibold text-white p-3 w-1/6">
                  Action
                </div>
              </div>
              <div className="flex bg-white">
                <div className="font-semibold p-3 w-2/5 break-all">
                  {
                    loanData.loans_by_pk.gold_release_visit[0]?.agent
                      ?.first_name
                  }{" "}
                  {loanData.loans_by_pk.gold_release_visit[0]?.agent?.last_name}
                </div>
                <div className="font-semibold p-3 w-2/5 break-all">
                  {
                    loanData.loans_by_pk.gold_release_visit[0]?.agent
                      ?.mobile_number
                  }
                </div>
                <div className="font-semibold p-3 w-2/5 break-all">
                  {loanData.loans_by_pk.gold_release_visit[0]?.agent.agent
                    ? loanData.loans_by_pk.gold_release_visit[0]?.agent.agent
                        .agent_display_id
                    : null}
                </div>
                <div className="font-semibold p-3 w-2/5 break-all">
                  {loanData.loans_by_pk.gold_release_visit[0]?.visit_display_id}
                </div>
                <div className="font-semibold p-3 w-1/6 break-all">
                  <Link
                    to={`/agents/view/${loanData.loans_by_pk.gold_release_visit[0]?.agent?.id}`}
                  >
                    <EyeOutlined />
                  </Link>
                </div>
              </div>
            </div>
          ) : null
        ) : null}

        {/* GR Agent info */}

        {/* User Info & KYC */}
        <div className="flex space-x-32">
          <div className="block space-y-4">
            <h3 className="text-lg">ID PROOF</h3>
            <div className="border bg-white">
              {loanData.loans_by_pk.kyc.id_proof.map((proof, i) => {
                return (
                  <p
                    className="cursor-pointer hover:bg-gray-200 py-1 px-2"
                    key={i}
                    onClick={async () => {
                      let images = [];
                      let index = loanData.loans_by_pk.kyc.id_proof.findIndex(
                        (data) => {
                          return data.type === proof.type;
                        }
                      );
                      const valid_url = await get_image_url({
                        url: [
                          loanData.loans_by_pk.kyc.id_proof[index].image_url,
                        ],
                      });
                      images.push({
                        caption: `ID proof - ${proof.type}`,
                        source: valid_url,
                      });
                      setIsImageModalOpen(!isImageModalOpen);
                      setImages(images);
                    }}
                  >
                    {proof.type}
                  </p>
                );
              })}
            </div>
          </div>

          <div className="block space-y-4">
            <h3 className="text-lg">ADDRESS PROOF</h3>
            <div className="border bg-white">
              {loanData.loans_by_pk.kyc.address_proof.map((proof, i) => {
                return (
                  <p
                    className="cursor-pointer hover:bg-gray-200 py-1 px-2"
                    key={i}
                    onClick={async () => {
                      let images = [];
                      let index =
                        loanData.loans_by_pk.kyc.address_proof.findIndex(
                          (data) => {
                            return data.type === proof.type;
                          }
                        );
                      const valid_url = await get_image_url({
                        url: [
                          loanData.loans_by_pk.kyc.address_proof[index]
                            .image_url,
                        ],
                      });
                      images.push({
                        caption: `Address proof - ${proof.type}`,
                        source: valid_url,
                      });
                      setIsImageModalOpen(!isImageModalOpen);
                      setImages(images);
                    }}
                  >
                    {proof.type}
                  </p>
                );
              })}
            </div>
          </div>

          {loanData.loans_by_pk.kyc.live_photo.length > 0 ? (
            <div className="w-1/4 space-y-2">
              <h3>Recent Photo</h3>
              <button
                className="cta hover:shadow-lg"
                style={{ height: "38px", minWidth: "150px" }}
                onClick={async () => {
                  const valid_url = await get_image_url({
                    url: [loanData.loans_by_pk.kyc.live_photo],
                  });
                  setIsImageModalOpen(!isImageModalOpen);
                  setImages([
                    {
                      caption: "User photo",
                      source: valid_url,
                    },
                  ]);
                }}
              >
                View
              </button>
            </div>
          ) : null}
        </div>

        {/* BANK ACCOUNT DETAILS */}
        <UserBankAccountDetails
          user_auth_id={loanData.loans_by_pk.customer.user.auth_id}
        />
        {/* BANK ACCOUNT DETAILS */}

        <ModalGateway>
          {isImageModalOpen && images.length > 0 ? (
            <Modal
              onClose={() => {
                setIsImageModalOpen(!isImageModalOpen);
              }}
            >
              <Carousel views={images} />
            </Modal>
          ) : null}
        </ModalGateway>

        {/* Gold details */}
        <div>
          <h4 className="mb-2">GOLD DETAILS</h4>
          <div className="flex">
            <div className="flex flex-col w-1/4 bg-green-primary">
              <div className="font-semibold text-white p-3">
                Number of items
              </div>
              <div className="font-semibold text-white p-3">
                Total gross gold weight
              </div>
              <div className="font-semibold text-white p-3">
                Total actual net gold weight
              </div>
              <div className="font-semibold text-white p-3">
                Total equivalent net gold weight (22 carats)
              </div>
            </div>

            <div className="flex flex-col w-3/4 bg-white">
              <div className="font-semibold p-3">
                {creditBreakupData.reduce((acc, data) => {
                  return acc + data.quantity;
                }, 0)}
              </div>
              <div className="font-semibold p-3">
                {`${parseFloat(
                  creditBreakupData.reduce((acc, data) => {
                    return acc + data.gross_weight;
                  }, 0)
                ).toFixed(2)} g`}
              </div>
              <div className="font-semibold p-3">
                {`${parseFloat(
                  creditBreakupData.reduce((acc, data) => {
                    return acc + data.actual_net_weight;
                  }, 0)
                ).toFixed(2)} g`}
              </div>
              <div className="font-semibold p-3">
                {`${parseFloat(
                  creditBreakupData.reduce((acc, data) => {
                    return acc + data.net_weight;
                  }, 0)
                ).toFixed(2)} g`}
              </div>
            </div>
          </div>
        </div>
        {/* Gold details */}

        <div className="flex space-x-4">
          {/* Gold valuation images  */}
          {loanData.loans_by_pk.gold_valuation_document ? (
            loanData.loans_by_pk.gold_pickup_visit[0]?.secondary_field_agent ? (
              loanData.loans_by_pk.gold_valuation_document[
                loanData.loans_by_pk.gold_pickup_visit[0]?.secondary_field_agent
                  .auth_id
              ] ? (
                <div className="w-1/4 space-y-2">
                  <h3>
                    Gold valuation document from{" "}
                    {
                      loanData.loans_by_pk.gold_pickup_visit[0]
                        ?.secondary_field_agent?.first_name
                    }{" "}
                    {
                      loanData.loans_by_pk.gold_pickup_visit[0]
                        ?.secondary_field_agent?.last_name
                    }
                  </h3>
                  <button
                    className="cta hover:shadow-lg"
                    style={{ height: "38px", minWidth: "150px" }}
                    onClick={async () => {
                      const valid_url = await get_image_url({
                        url: [
                          loanData.loans_by_pk.gold_valuation_document[
                            loanData.loans_by_pk.gold_pickup_visit[0]
                              .secondary_field_agent.auth_id
                          ],
                        ],
                      });
                      setIsImageModalOpen(!isImageModalOpen);
                      setImages([
                        {
                          caption: "Gold valuation photo",
                          source: valid_url,
                        },
                      ]);
                    }}
                  >
                    View
                  </button>
                </div>
              ) : null
            ) : null
          ) : null}

          {loanData.loans_by_pk.gold_valuation_document ? (
            loanData.loans_by_pk.gold_pickup_visit[0]?.tertiary_field_agent ? (
              loanData.loans_by_pk.gold_valuation_document[
                loanData.loans_by_pk.gold_pickup_visit[0]?.tertiary_field_agent
                  .auth_id
              ] ? (
                <div className="w-1/4 space-y-2">
                  <h3>
                    Gold valuation document from{" "}
                    {
                      loanData.loans_by_pk.gold_pickup_visit[0]
                        ?.tertiary_field_agent?.first_name
                    }{" "}
                    {
                      loanData.loans_by_pk.gold_pickup_visit[0]
                        ?.tertiary_field_agent?.last_name
                    }
                  </h3>
                  <button
                    className="cta hover:shadow-lg"
                    style={{ height: "38px", minWidth: "150px" }}
                    onClick={async () => {
                      const valid_url = await get_image_url({
                        url: [
                          loanData.loans_by_pk.gold_valuation_document[
                            loanData.loans_by_pk.gold_pickup_visit[0]
                              .tertiary_field_agent.auth_id
                          ],
                        ],
                      });
                      setIsImageModalOpen(!isImageModalOpen);
                      setImages([
                        {
                          caption: "Gold valuation photo",
                          source: valid_url,
                        },
                      ]);
                    }}
                  >
                    View
                  </button>
                </div>
              ) : null
            ) : null
          ) : null}
          {/* Gold valuation images  */}
        </div>

        {/* Gold items */}
        <div>
          <div className="flex bg-green-primary">
            <div className=" font-semibold text-white p-3 w-2/6">Item ID</div>
            <div className=" font-semibold text-white p-3 w-2/6">Item type</div>
            <div className=" font-semibold text-white p-3 w-2/6">Quantity</div>
            <div className=" font-semibold text-white p-3 w-2/6">Quality</div>
            <div className=" font-semibold text-white p-3 w-2/6">
              Gross weight
            </div>
            <div className=" font-semibold text-white p-3 w-2/6">
              Actual Net weight
            </div>
            <div className=" font-semibold text-white p-3 w-2/6">
              Equivalent Net weight (22 carats)
            </div>
            <div className=" font-semibold text-white p-3 w-2/6">Seal ID</div>
            <div className=" font-semibold text-white p-3 w-1/6">
              Gold Photo
            </div>
            <div className=" font-semibold text-white p-3 w-1/6">
              Seal Photo
            </div>
          </div>
          <div className="bg-white">
            {loanData.loans_by_pk.golds.map((goldItem, i) => {
              return (
                <div className="flex" key={i}>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {goldItem.gold_display_id}
                  </div>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {goldItem.gold_type}
                  </div>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {goldItem.quantity}
                  </div>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {goldItem.actual_quality}
                  </div>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {goldItem.gross_weight.toLocaleString("en-IN", {
                      maximumFractionDigits: 2,
                    })}
                    g
                  </div>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {goldItem.actual_net_weight.toLocaleString("en-IN", {
                      maximumFractionDigits: 2,
                    })}
                    g
                  </div>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {goldItem.net_weight.toLocaleString("en-IN", {
                      maximumFractionDigits: 2,
                    })}
                    g
                  </div>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {goldItem?.seal?.pickup_seal_id}
                  </div>
                  <div className="font-semibold p-3 w-1/6 break-all">
                    <EyeOutlined
                      onClick={() => {
                        if (goldImages) {
                          let required_gold_item = goldImages.filter((img) => {
                            return img.id === goldItem.gold_display_id;
                          });

                          setImages(required_gold_item[0]?.images_url);
                          setIsImageModalOpen(true);
                        }
                      }}
                    />
                  </div>

                  <div className="font-semibold p-3 w-1/6 break-all">
                    <EyeOutlined
                      onClick={() => {
                        if (sealImages) {
                          let required_seal_item = sealImages.filter((img) => {
                            return img.id === goldItem.gold_display_id;
                          });

                          setImages(required_seal_item[0]?.images_url);
                          setIsImageModalOpen(true);
                        }
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* Gold items */}

        {/* Van Details */}
        <div className="space-y-4">
          <div className="space-y-1">
            <h4>VAN DETAILS</h4>
          </div>
          <div>
            <div className="flex bg-green-primary">
              <div className=" font-semibold text-white p-3 w-1/4">
                Bank Name
              </div>
              <div className=" font-semibold text-white p-3 w-1/4">
                Account Number
              </div>
              <div className=" font-semibold text-white p-3 w-1/4">
                Account Name
              </div>
              <div className=" font-semibold text-white p-3 w-1/4">IFSC</div>
            </div>
            <div className="bg-white">
              <div className="flex">
                <div className="font-semibold p-3 w-2/6 break-all">
                  {payments?.van_details?.body?.bank_name || ""}
                </div>
                <div className="font-semibold p-3 w-2/6 break-all">
                  {payments?.van_details?.body?.account_number || ""}
                </div>
                <div className="font-semibold p-3 w-2/6 break-all">
                  {payments?.van_details?.body?.account_name || ""}
                </div>
                <div className="font-semibold p-3 w-2/6 break-all">
                  {payments?.van_details?.body?.ifsc || ""}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Van Details */}

        {/* FUND TRANSFERS  */}
        {payments?.fund_transfers?.body.length > 0 ? (
          <div className="space-y-4">
            <div className="space-y-1">
              <h4>FUND TRANSFERS</h4>
            </div>
            <div>
              <div className="flex bg-green-primary">
                <div className=" font-semibold text-white p-3 w-2/6">UTR</div>
                <div className=" font-semibold text-white p-3 w-2/6">
                  Amount
                </div>
                <div className=" font-semibold text-white p-3 w-2/6">
                  Payment time
                </div>
                <div className=" font-semibold text-white p-3 w-2/6">
                  Payment mode
                </div>
                <div className=" font-semibold text-white p-3 w-2/6">
                  Transaction type
                </div>
              </div>

              <div className="bg-white">
                {payments?.fund_transfers?.body.map((payment, i) => {
                  return (
                    <div className="flex" key={i}>
                      <div className="font-semibold p-3 w-2/6 break-all">
                        {payment.transaction_id !== "-" &&
                        payment.transaction_id ? (
                          payment.transaction_id
                        ) : (
                          <Tooltip title="Click to add UTR">
                            <div
                              className="flex space-x-1 items-center text-red-500"
                              onClick={() => {
                                setPaymentLoanID(
                                  loanData?.loans_by_pk?.loan_number
                                );
                                setUTRModalVisible(!UTRModalVisible);
                              }}
                            >
                              <ExclamationCircleOutlined />{" "}
                              <p className="m-0 cursor-pointer hover:underline">
                                UTR pending
                              </p>
                            </div>
                          </Tooltip>
                        )}
                      </div>
                      <div className="font-semibold p-3 w-2/6 break-all">
                        ₹ {payment.amount}
                      </div>
                      <div className="font-semibold p-3 w-2/6 break-all">
                        {moment(payment.createdAt).format("DD-MM-YYYY HH:mm")}
                      </div>
                      <div className="font-semibold p-3 w-2/6 break-all">
                        {payment.method || "-"}
                      </div>
                      <div className="font-semibold p-3 w-2/6 break-all">
                        {payment.type || "-"}
                      </div>
                    </div>
                  );
                })}
                {/* {payments?.sl_fund_transfers && (
                  <React.Fragment>
                    {payments?.sl_fund_transfers?.body.map((payment, i) => {
                      return (
                        <div className="flex" key={i}>
                          <div className="font-semibold p-3 w-2/6 break-all">
                            {payment.transaction_id !== "-" && payment.transaction_id ? (payment.transaction_id) : (
                              <Tooltip title="Click to add UTR">
                                <div
                                  className="flex space-x-1 items-center text-red-500"
                                  onClick={() => {
                                    setPaymentLoanID(loanData?.loans_by_pk?.support_loan?.support_loan_id);
                                    setUTRModalVisible(!UTRModalVisible);
                                  }}
                                >
                                  <ExclamationCircleOutlined />{" "}
                                  <p className="m-0 cursor-pointer hover:underline">
                                    UTR pending
                                  </p>
                                </div>
                              </Tooltip>
                            )}
                          </div>
                          <div className="font-semibold p-3 w-2/6 break-all">
                            ₹ {payment.amount}
                          </div>
                          <div className="font-semibold p-3 w-2/6 break-all">
                            {moment(payment.createdAt).format("DD-MM-YYYY HH:mm")}
                          </div>
                          <div className="font-semibold p-3 w-2/6 break-all">
                            {payment.method || "-"}
                          </div>
                          <div className="font-semibold p-3 w-2/6 break-all">
                            {payment.type || "-"}
                          </div>
                        </div>
                      );
                    })}
                  </React.Fragment>
                )} */}
              </div>
            </div>
          </div>
        ) : null}
        {/* FUND TRANSFERS  */}

        {/* Transactions  */}
        <div className="space-y-4">
          <div className="space-y-1">
            <h4>TRANSACTIONS</h4>
            <div className="flex justify-between items-center">
              <div className="flex space-x-4">
                <h4>
                  Current principal amount: ₹
                  {(payments?.ledger_loan_details?.body?.loan
                    ?.oro_pending_principal || 0) +
                    (payments?.ledger_loan_details?.body?.supportLoan
                      ?.oro_pending_principal || 0)}
                </h4>
                <h4>
                  Current interest amount: ₹
                  {payments?.ledger_loan_details?.body?.total_pending_interest}
                </h4>
                <h4>
                  Cashback: ₹{payments?.ledger_loan_details?.body?.cashback}
                </h4>
                <h4>
                  Closing amount: ₹
                  {parseFloat(
                    (payments?.ledger_loan_details?.body
                      ?.total_closing_amount || 0) -
                      (payments?.ledger_loan_details?.body?.cashback || 0)
                  ).toFixed(2)}
                </h4>
              </div>

              <button
                className="cta py-2 px-4"
                onClick={() => setManualPaymentModalOpen(true)}
              >
                Record manual payment
              </button>
            </div>
          </div>
          <div>
            <div className="flex bg-green-primary">
              <div className=" font-semibold text-white p-3 w-2/6">UTR</div>
              <div className=" font-semibold text-white p-3 w-2/6">Amount</div>
              <div className=" font-semibold text-white p-3 w-2/6">
                GL towards branch
              </div>
              {loanData.loans_by_pk.support_loan ? (
                <>
                  <div className=" font-semibold text-white p-3 w-2/6">
                    Principal (SL)
                  </div>
                  <div className=" font-semibold text-white p-3 w-2/6">
                    Interest (SL)
                  </div>
                </>
              ) : null}
              <div className=" font-semibold text-white p-3 w-2/6">
                Cash back
              </div>
              <div className=" font-semibold text-white p-3 w-2/6">Date</div>
              <div className=" font-semibold text-white p-3 w-2/6">
                Payment mode
              </div>
              <div className=" font-semibold text-white p-3 w-2/6">
                Transaction type
              </div>
              <div className=" font-semibold text-white p-3 w-2/6">Status</div>
            </div>

            {payments?.payments?.body.length === 0 ? (
              <div className="w-full bg-white py-4 text-center">
                No payments received
              </div>
            ) : null}

            <div className="bg-white">
              {payments?.payments?.body.map((payment, i) => (
                <div className="flex" key={i}>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {payment.transaction_id}
                  </div>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    ₹ {payment.amount}
                  </div>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {payment.AccountActivities.filter(
                      (pay) => pay.loan_id === payment.loan_id
                    )[0]?.lender_principal_amount || "-"}
                  </div>
                  {loanData.loans_by_pk.support_loan ? (
                    <>
                      <div className="font-semibold p-3 w-2/6 break-all">
                        {payment.AccountActivities.filter(
                          (pay) => pay.loan_id !== payment.loan_id
                        )[0]?.oro_principal_amount || "-"}
                      </div>
                      <div className="font-semibold p-3 w-2/6 break-all">
                        {payment.AccountActivities.filter(
                          (pay) => pay.loan_id !== payment.loan_id
                        )[0]?.oro_interest_amount || "-"}
                      </div>
                    </>
                  ) : null}
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {payment.AccountActivities[0]?.cashback
                      ? `₹ ${payment.AccountActivities[0]?.cashback}`
                      : "-"}
                  </div>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {moment(payment.payment_time).format("DD-MM-YYYY HH:mm")}
                  </div>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {payment.method || "-"}
                  </div>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    {payment.type || "-"}
                  </div>
                  <div className="font-semibold p-3 w-2/6 break-all">
                    <span
                      className={
                        payment.status === "PENDING" ? "text-red-500" : ""
                      }
                    >
                      {payment.status}
                    </span>
                    {/*{payment.is_completed ? (*/}
                    {/*  "Completed"*/}
                    {/*) : (*/}
                    {/*  <button*/}
                    {/*    className="text-green-primary hover:underline"*/}
                    {/*    onClick={() => {*/}
                    {/*      setPayment({*/}
                    {/*        id: payment.id,*/}
                    {/*        customer_paid_amount: payment.paid_amount,*/}
                    {/*        cashback: payment.cash_back,*/}
                    {/*        bank_amount:*/}
                    {/*          payment.interest_amount_paid >*/}
                    {/*          payment.bank_interest*/}
                    {/*            ? payment.bank_interest +*/}
                    {/*              payment.principal_amount_paid*/}
                    {/*            : payment.interest_amount_paid +*/}
                    {/*              payment.cash_back +*/}
                    {/*              payment.principal_amount_paid,*/}
                    {/*      });*/}
                    {/*      setPaymentType("Oro Repayment");*/}
                    {/*      setPaymentsModalOpen(true);*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*    Transfer to Federal*/}
                    {/*  </button>*/}
                    {/*)}*/}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Transactions  */}
      </div>
      {isFundTransferModalOpen ? (
        <RecordFundTransfer
          isOpen={isFundTransferModalOpen}
          setOpen={setFundTransferModalOpen}
          type="OroToCustomer"
          loan_id={loanData.loans_by_pk.id}
          loan_number={loanData.loans_by_pk.loan_number}
          support_loan_number={
            loanData?.loans_by_pk?.support_loan?.support_loan_id
          }
          visit_id={loanData.loans_by_pk.gold_pickup_visit[0]?.id}
          paymentType={paymentType}
          amount={
            paymentType === "Fund Transfer"
              ? loanData.loans_by_pk.gl_amount.loan_amount
              : loanData.loans_by_pk.support_loan.sl_amount.loan_amount
          }
          fundTransferDone={
            loanData.loans_by_pk.support_loan
              ? loanData.loans_by_pk.primary_loan_fund_transfer.aggregate
                  .count !== 0 ||
                loanData.loans_by_pk.support_loan_fund_transfer.aggregate
                  .count !== 0
              : true
          }
        />
      ) : null}

      {isManualPaymentModalOpen ? (
        <RecordManualPayment
          isOpen={isManualPaymentModalOpen}
          setOpen={setManualPaymentModalOpen}
          loan_id={loanData.loans_by_pk.id}
          loan_number={loanData.loans_by_pk.loan_number}
          support_loan_number={
            loanData?.loans_by_pk?.support_loan?.support_loan_id
          }
          min_amount={interest?.interest?.total_interest_due || 0}
        />
      ) : null}

      {isPaymentsModalOpen ? (
        <RecordPayment
          isOpen={isPaymentsModalOpen}
          setOpen={setPaymentsModalOpen}
          type={paymentType}
          loan_id={loanData.loans_by_pk.id}
          payment={payment}
          setLoanDisbursalComplete={setLoanDisbursalComplete}
          amount={loanData.loans_by_pk.gl_amount.loan_amount}
        />
      ) : null}

      <AntModal
        title="Enter UTR"
        visible={UTRModalVisible}
        footer={null}
        onCancel={() => {
          setUTRModalVisible(false);
        }}
      >
        <Formik
          enableReinitialize={false}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values, { resetForm }) => {
            if (!values.utr || values.utr === "") {
              notification.error({
                message: "Error!",
                description: "Please enter the UTR",
              });
            } else {
              updateUTR({
                variables: {
                  oro_loan_number: paymentLoanID,
                  utr: values.utr,
                },
              });
            }
          }}
          initialValues={{
            utr: null,
          }}
          formCls="flex flex-col content-center justify-center space-y-8"
        >
          {({ values }) => (
            <Form>
              <ReactInput
                name="utr"
                type="text"
                // label="UTR"
                placeholder="Enter UTR"
              />

              <div className="flex justify-end">
                <Button
                  className=" rounded-md"
                  primary
                  onClick={() => {
                    setUTRModalVisible(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  htmlType="submit"
                  className=" rounded-md ml-2"
                  type="primary"
                  onClick={() => {}}
                >
                  Update
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </AntModal>
    </>
  );
};

export default LoanDetails;
