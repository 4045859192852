import React from "react";
import { Modal as AntModal, Select, notification, Popconfirm } from "antd";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";
import jwt_decode from "jwt-decode";

import { ReactForm, ReactSelect } from "../Form";

import Warning from "../../assets/warning.png";

const { Option } = Select;

const CANCEL_VISIT = gql`
  mutation cancelVisit(
    $visit_id: Int!
    $cancellation_reason: String!
    $cancelled_by_auth_id: String!
    $secondary_agents_visit_status: jsonb
  ) {
    update_visits_by_pk(
      pk_columns: { id: $visit_id }
      _set: {
        visit_status: "VISIT_CANCELLED"
        cancelled_by_auth_id: $cancelled_by_auth_id
        cancellation_reason: $cancellation_reason
        secondary_agents_visit_status: $secondary_agents_visit_status
      }
    ) {
      id
      visit_status
      cancellation_reason
      cancelled_by {
        id
        first_name
        last_name
      }
    }
  }
`;

const CancelVisit = (props) => {
  let initialValues = {
    cancellation_reason: "",
  };

  const formRef = React.useRef();

  const validationSchema = Yup.object({
    cancellation_reason: Yup.string().trim().required("Required"),
  });

  const handleSubmit = (values) => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const [cancelVisit, { loading: cancellingVisit }] = useMutation(
    CANCEL_VISIT,
    {
      onError(error) {
        error.graphQLErrors.forEach((error) => {
          notification.error({
            message: "Error!",
            description:
              "Something went wrong. Please check all details and try again",
          });
        });
      },
      onCompleted(data) {
        notification.success({
          message: "Success!",
          description: "Visit cancelled",
        });
        props.setOpen(false);
      },
    }
  );

  return (
    <AntModal
      width="640px"
      className="modal-title-center"
      visible={props.isOpen}
      footer={null}
    >
      <div className="space-y-8 mx-auto text-center">
        <div>
          <h1>Cancel Visit</h1>
        </div>
        <div>
          <img
            src={Warning}
            alt="warning"
            style={{ height: "8rem", width: "0 auto", margin: "0 auto" }}
          />
        </div>

        <p>Are you sure you want to cancel this visit?</p>

        <ReactForm
          onSubmit={(values, { resetForm }) => {
            let temp = {};

            if (props.secondary_fa_one) {
              temp[props.secondary_fa_one] = "VISIT_CANCELLED";
            }

            if (props.secondary_fa_two) {
              temp[props.secondary_fa_two] = "VISIT_CANCELLED";
            }

            cancelVisit({
              variables: {
                visit_id: Number(props.visit_id),
                cancelled_by_auth_id: jwt_decode(localStorage.getItem("token")).user_id,
                cancellation_reason: values.cancellation_reason,
                secondary_agents_visit_status: temp,
              },
            });
          }}
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          formCls="flex flex-col content-center justify-center space-y-2"
        >
          <ReactSelect
            name="cancellation_reason"
            type="text"
            label="Cancellation reason"
            placeholder="Choose a reason"
          >
            <Option value="Not serviceable">Not serviceable</Option>
            <Option value="Customer Cancelled">Customer Cancelled</Option>
            <Option value="Tech Issue">Tech Issue</Option>
            <Option value="Takeover issue - EL">Takeover issue - EL</Option>
            <Option value="Loan amount issue">Loan amount issue</Option>
            <Option value="Postponed">Postponed</Option>
          </ReactSelect>
        </ReactForm>

        <div className="flex justify-center w-full space-x-8">
          <Popconfirm
            title={`Are you sure you want to cancel this visit?`}
            onConfirm={() => handleSubmit()}
            onCancel={() => {}}
            okText="Yes"
            placement="bottomRight"
            cancelText="No"
          >
            <button className="btn-danger hover:shadow-lg" disabled={cancellingVisit}>
              {cancellingVisit && (
                <svg
                  className="animate-spin -ml-1 mr-1 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              )}
              Cancel visit
            </button>
          </Popconfirm>
          <button
            className="btn-primary hover:shadow-lg"
            onClick={() => {
              props.setOpen(false);
            }}
            type="submit"
          >
            Go back
          </button>
        </div>
      </div>
    </AntModal>
  );
};

export default CancelVisit;
