// DEPS
import React, { useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import moment from "moment";

// COMPONENTS
import ErrorPage from "../../components/ErrorPage";
import ReactTable from "../../components/Table";

const GET_LEDGER_ALL_PAYMENTS = gql`
  query getLedgerAllPayments(
    $limit: Int!
    $offset: Int!
    $oro_loan_number: String
    $order: String
    $isSearchAvailable: Boolean!
  ) {
    searchData: ledger_get_payment(
      limit: $limit
      offset: $offset
      oro_loan_number: $oro_loan_number
      order: $order
    ) @include(if: $isSearchAvailable) {
      code
      body
    }
    ledger_get_payment(limit: $limit, offset: $offset, order: $order) {
      code
      body
    }
  }
`;

const Transactions = (props) => {
  // const [filterObj, setFilterObj] = useState({});
  const [searchText, SetSearchText] = useState("");

  const [sort, setSort] = useState({
    payment_time: "DESC",
  });

  const [tableData, setTableData] = useState({
    page: 1,
    data: [],
    hasMoreItems: true,
  });

  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    // position: ["none", "none"],
    pageSize: 10,
    current: 1,
    total: 30,
  });

  const transactionQueryOnComplete = (visitData) => {
    if (!transactions.ledger_get_payment.body) {
      return;
    }
    if (transactions.ledger_get_payment.body.count) {
      setPagination({
        ...pagination,
        total: transactions.ledger_get_payment.body.count,
      });
    }
    let hasMoreItems = true;
    if ((transactions.ledger_get_payment.body.rows || []).length === 0) {
      hasMoreItems = false;
    }

    setTableData({
      ...tableData,
      data: transactions.ledger_get_payment.body.rows || [],
      hasMoreItems: hasMoreItems,
    });
  };
  const {
    loading: transactionsLoading,
    data: transactions,
    error: transactionsQueryError,
  } = useQuery(GET_LEDGER_ALL_PAYMENTS, {
    variables: {
      limit: parseInt(process.env.REACT_APP_API_PAGE_LIMIT),
      offset: process.env.REACT_APP_API_PAGE_LIMIT * (tableData.page - 1),
      oro_loan_number: searchText,
      order: sort.payment_time,
      isSearchAvailable: searchText !== "",
    },
    fetchPolicy: "network-only",
    onCompleted: transactionQueryOnComplete,
  });

  const loadMoreItems = (value) => {
    if (!transactionsLoading && tableData.hasMoreItems) {
      setTableData((data) => {
        return { ...data, page: data.page + 1 };
      });
    }
  };

  const resetState = () => {
    setTableData({
      page: 1,
      data: [],
      hasMoreItems: true,
    });
    setPagination({
      ...pagination,
      total: 0,
      current: 1,
    });
  };

  if (transactionsQueryError) return <ErrorPage />;

  const columns = [
    {
      title: "Loan ID",
      dataIndex: "Loan.loan_id",
      key: "Loan.loan_id",
      show: true,
      width: 150,
      render: (text, data) => {
        return (
          <Link
            to={{
              pathname: `/loans/view/${parseInt(
                data.Loan.oro_loan_number.replace("OMGL", "") || 0
              )}`,
            }}
          >
            <p className="text-green-primary hover:underline cursor-pointer">
              {data.Loan.oro_loan_number}
            </p>
          </Link>
        );
      },
      //   sorter: (a, b) => {},
      //   sortDirections: ["descend", "ascend"]
    },
    {
      title: "UTR",
      dataIndex: "transaction_id",
      key: "transaction_id",
      show: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      show: true,
      render: (text, data, index) => {
        if (text) return <div>₹ {text}</div>;
        else return <p>-</p>;
      },
    },
    {
      title: "GL towards branch",
      dataIndex: "AccountActivities[0].oro_principal_amount",
      key: "AccountActivities[0].oro_principal_amount",
      show: true,
      render: (text, data, index) => {
        if (
          data.AccountActivities.filter((pay) => pay.loan_id === data.loan_id)
            .length
        ) {
          return (
            <div>
              ₹{" "}
              {data.AccountActivities.filter(
                (pay) => pay.loan_id === data.loan_id
              )[0]?.lender_principal_amount || "-"}
            </div>
          );
        } else {
          return <p>-</p>;
        }
      },
    },
    {
      title: "SL Principle",
      dataIndex: "AccountActivities[0].lender_principal_amount",
      key: "AccountActivities[0].lender_principal_amount",
      show: true,
      render: (text, data, index) => {
        if (
          data.AccountActivities.filter((pay) => pay.loan_id !== data.loan_id)
            .length
        ) {
          return (
            <div>
              ₹{" "}
              {data.AccountActivities.filter(
                (pay) => pay.loan_id !== data.loan_id
              )[0]?.oro_principal_amount || "-"}
            </div>
          );
        } else {
          return <p>-</p>;
        }
      },
    },
    {
      title: "SL Interest",
      dataIndex: "AccountActivities[0].lender_interest_amount",
      key: "AccountActivities[0].lender_interest_amount",
      show: true,
      render: (text, data, index) => {
        if (
          data.AccountActivities.filter((pay) => pay.loan_id !== data.loan_id)
            .length
        ) {
          return (
            <div>
              ₹{" "}
              {data.AccountActivities.filter(
                (pay) => pay.loan_id !== data.loan_id
              )[0]?.oro_interest_amount || "-"}
            </div>
          );
        } else {
          return <p>-</p>;
        }
      },
    },
    {
      title: "Payment Time",
      dataIndex: "payment_time",
      key: "payment_time",
      show: true,
      render: (text, data, index) => {
        return <div>{moment(text).format("DD-MM-YYYY HH:mm")}</div>;
      },
      sorter: true,
      defaultSortOrder: "descend",
    },
    {
      title: "Transaction Type",
      dataIndex: "type",
      key: "type",
      show: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      show: true,
      render: (text) => (
        <p className={text === "PENDING" ? "text-red-500" : ""}>{text}</p>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;

    setSort({
      payment_time:
        sorter?.column?.dataIndex && sorter.column.dataIndex === "createdAt"
          ? sorter.order === "ascend"
            ? "ASC"
            : "DESC"
          : "DESC",
    });

    setTableData({
      ...tableData,
      page: pagination.current,
    });
    setPagination(pager);
  };

  return (
    <div className="space-y-8">
      <h2 className="text-green-primary">All Transactions</h2>

      <ReactTable
        columns={columns}
        data={
          transactions
            ? searchText
              ? transactions?.searchData?.body?.rows
              : transactions?.ledger_get_payment?.body?.rows
            : []
        }
        table={{
          rowKey: (record) => record.id,
          pagination: pagination,
        }}
        isToggleFullScreen={false}
        isToggleColumn={true}
        isMoreFilter={false}
        loading={transactionsLoading}
        loadItems={() => {
          loadMoreItems(0);
        }}
        page={tableData.page}
        hasMoreItems={tableData.hasMoreItems}
        handleTableChange={handleTableChange}
        onToolbarSearch={(value) => {
          resetState();
          SetSearchText(value);
        }}
        //   onToolbarMore={(index, value) => {
        //     const filterData = [...filter];
        //     filterData[index].show = value;
        //     resetState();

        //     setFilter(filterData);
        //   }}
        //   onFilterSelectChange={(index, key, value) => {
        //     const filterData = [...filter];
        //     filterData[index].selected = value;
        //     resetState();
        //     setFilter(filterData);
        //   }}
        //   handleTableChange={handleTableChange}

        // onChangeDate={(index, dataKey, value) => {
        //   const filter = [...filter];
        //   //filter[index].fromDate = moment(value[0]).toISOString();
        //   // filter[index].toDate = moment(value[1]).toISOString();
        //   resetState();

        //   setFilter(filterData);
        // }}
      />
    </div>
  );
};

export default Transactions;
