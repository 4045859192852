import { EyeOutlined, LeftOutlined, PlusOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Button, notification, Popconfirm, Tag } from "antd";
import moment from "moment";
import React, { useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Link } from "react-router-dom";
import ErrorPage from "../../../components/ErrorPage";
import ReactTable from "../../../components/Table";
import { GET_PARTNER_DETAILS, INACTIVATE_PARTNER } from "../graphql";
import { get_image_url } from "../../../utils";

const PartnerDetails = (props) => {
  const columns = [
    {
      title: "Branch ID",
      dataIndex: "partner_branches_display_id",
      key: "branch_id",
      show: true,
      width: 150,
    },
    {
      title: "Branch Name",
      dataIndex: "partner_branch_name",
      key: "branch_names",
      show: true,
    },
    {
      title: "Loan types",
      key: "loan_types",
      show: true,
      render: (text, data, index) =>
        data.partners_branches_x_loanTypes
          .map((loanType) => loanType.loan_type.loan_type)
          .join(", "),
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      show: true,
      width: 100,
      render: (text, data, index) => {
        return (
          <div>
            {text ? (
              <Tag color={"green"} key={text}>
                Active
              </Tag>
            ) : (
              <Tag color={"red"} key={text}>
                Inactive
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      show: true,
      fixed: "right",
      width: 100,
      render: (text, data, index) => {
        return (
          <Link to={`/partners/branch/view/${data.id}`}>
            <EyeOutlined />
          </Link>
        );
      },
    },
  ];

  const [partnerLogo, setPartnerLogo] = useState(null);

  const [isPartnerPhotoModalOpen, setIsPartnerPhotoModalOpen] = useState(false);

  const [inactivatePartner] = useMutation(INACTIVATE_PARTNER, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description: "Something went wrong!",
        });
      });
    },
    onCompleted() {
      notification.success({
        message: "Success!",
        description: "Partner inactivated!",
      });

      props.history.push("/partners");
    },
  });

  const {
    data: partnerData,
    loading: partnerLoading,
    error: partnerError,
  } = useQuery(GET_PARTNER_DETAILS, {
    variables: { id: parseInt(props.match.params.id) },
    fetchPolicy: "network-only",
  });

  if (!props.match.params.id || partnerError) {
    return <ErrorPage />;
  }
  if (partnerLoading) {
    return <div>Loading</div>;
  }

  const onInactivatePartner = () => {
    inactivatePartner({
      variables: {
        id: parseInt(props.match.params.id),
      },
    });
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center justify-start">
          <div className="flex items-center pl-3">
            <div className="relative">
              <LeftOutlined
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "#16A085",
                }}
              />
            </div>

            <Button
              type="link"
              onClick={() => {
                props.history.push("/partners");
              }}
            >
              All partners
            </Button>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <Link
            to={{
              pathname: "/partners/branch/create",
              state: { partner_id: props.match.params.id },
            }}
          >
            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="flex items-center rounded"
            >
              Create New Branch
            </Button>
          </Link>
          {partnerData.partners_by_pk.partners_x_partnerTypes
            .map((partner_type) => partner_type.partner_type.partner_type)
            .includes("Personal Lender") ||
          partnerData.partners_by_pk.partners_x_partnerTypes
            .map((partner_type) => partner_type.partner_type.partner_type)
            .includes("NBFC") ? (
            <button
              className="cta hover:shadow-lg"
              style={{ height: "38px ", minWidth: "150px" }}
              onClick={() => {
                props.history.push(`/partners/edit/${props.match.params.id}`);
              }}
            >
              Edit partner
            </button>
          ) : null}
          {partnerData.partners_by_pk.is_active && (
            <Popconfirm
              title={`Are you sure you want to inactivate this partner and all it's branches?`}
              onConfirm={() => {
                onInactivatePartner();
              }}
              onCancel={() => {}}
              okText="Yes"
              placement="bottomRight"
              cancelText="No"
            >
              <Button
                type={!partnerData.partners_by_pk.is_active ? "primary" : ""}
                danger={partnerData.partners_by_pk.is_active}
                className="rounded-md"
              >
                {`Make as ${
                  partnerData.partners_by_pk.is_active ? "in" : ""
                }active`}
              </Button>
            </Popconfirm>
          )}{" "}
        </div>
      </div>
      <div className="mt-3">
        <h2>
          Partner Details | {partnerData.partners_by_pk.partner_display_id}{" "}
        </h2>
        <div className="text-gray-600 flex space-x-1">
          {" "}
          <p className="text-gray-600">
            {`Created at ${moment(partnerData.partners_by_pk.created_at).format(
              "DD MMM YYYY HH:mm"
            )} by ${
              (partnerData.partners_by_pk.created_by || {}).first_name || ""
            } ${(partnerData.partners_by_pk.created_by || {}).last_name || ""}`}
          </p>
          <p>
            {partnerData.partners_by_pk.created_at !==
            partnerData.partners_by_pk.updated_at
              ? ` | Updated at ${moment(
                  partnerData.partners_by_pk.updated_at
                ).format("DD MMM YYYY HH:mm")} by ${
                  (partnerData.partners_by_pk.updated_by || {}).first_name || ""
                } ${
                  (partnerData.partners_by_pk.updated_by || {}).last_name || ""
                }`
              : ""}
          </p>
        </div>
      </div>
      <div className="mt-10">
        <h4 className="mb-2">OVERVIEW</h4>
        <div className="flex bg-green-primary">
          <div className=" font-semibold text-white p-3 w-1/5">Partner ID</div>
          <div className=" font-semibold text-white p-3 w-1/5">
            Partner Name
          </div>
          <div className=" font-semibold text-white p-3 w-1/5">
            Partner type
          </div>
          <div className=" font-semibold text-white p-3 w-1/5">Branches</div>
          <div className=" font-semibold text-white p-3 w-1/5">
            Partner Logo
          </div>
          <div className=" font-semibold text-white p-3 w-1/6">Status</div>
        </div>
        <div className="flex bg-white">
          <div className=" font-semibold p-3 w-1/5 break-all">
            {partnerData.partners_by_pk.partner_display_id}
          </div>
          <div className=" font-semibold p-3 w-1/5 break-all">
            {partnerData.partners_by_pk.partner_name}
          </div>
          <div className=" font-semibold p-3 w-1/5 break-all">
            {partnerData.partners_by_pk.partners_x_partnerTypes
              .map((partner_type) => partner_type.partner_type.partner_type)
              .toString()}
          </div>
          <div className=" font-semibold p-3 w-1/5 break-all">
            {
              partnerData.partners_by_pk.partner_branches_aggregate.aggregate
                .count
            }
          </div>
          <div
            className=" font-semibold p-3 w-1/5 text-blue-500 cursor-pointer break-all"
            onClick={async () => {
              const valid_url = await get_image_url({
                url: [partnerData.partners_by_pk.partner_logo],
              });

              setPartnerLogo([
                {
                  caption: "Partner Logo",
                  source: valid_url,
                },
              ]);

              setIsPartnerPhotoModalOpen(!isPartnerPhotoModalOpen);
            }}
          >
            View
          </div>
          <div className=" font-semibold p-3 w-1/6 break-all">
            {partnerData.partners_by_pk.is_active ? (
              <Tag color={"green"}>Active</Tag>
            ) : (
              <Tag color={"red"}>Inactive</Tag>
            )}
          </div>
        </div>
      </div>

      {/* CST DETAILS */}
      <div className="mt-8">
        {partnerData.partners_by_pk.cst ? (
          <>
            <h4 className="mb-2">CST DETAILS</h4>
            <div className="flex bg-green-primary">
              <div className=" font-semibold text-white p-3 w-1/3">Name</div>
              <div className=" font-semibold text-white p-3 w-1/3">
                Mobile number
              </div>
              <div className=" font-semibold text-white p-3 w-1/3">
                Email address
              </div>
            </div>
            <div className="flex bg-white">
              <div className=" font-semibold p-3 w-1/3 break-all">
                {partnerData.partners_by_pk.cst.first_name}{" "}
                {partnerData.partners_by_pk.cst.last_name}
              </div>
              <div className=" font-semibold p-3 w-1/3 break-all">
                {partnerData.partners_by_pk.cst.mobile_number}
              </div>
              <div className=" font-semibold p-3 w-1/3 break-all">
                {partnerData.partners_by_pk.cst.email_id}
              </div>
            </div>{" "}
          </>
        ) : null}
      </div>
      {/* CST DETAILS */}

      {/* Working Hours */}
      {/* <div className="mt-8">
        <h4 className="mb-2">WORKING HOURS</h4>
        {partnerData.partners_by_pk.working_hours ? (
          <div className="flex">
            <div className="flex flex-col w-1/4 bg-green-primary">
              <div className="font-semibold text-white p-3">Monday</div>
              <div className="font-semibold text-white p-3">Tuesday</div>
              <div className="font-semibold text-white p-3">Wednesday</div>
              <div className="font-semibold text-white p-3">Thursday</div>
              <div className="font-semibold text-white p-3">Friday</div>
              <div className="font-semibold text-white p-3">Saturday</div>
              <div className="font-semibold text-white p-3">Sunday</div>
            </div>
            <div className="flex flex-col w-3/4 bg-white">
              <div className="font-semibold p-3">
                {partnerData.partners_by_pk.working_hours.Monday.start !==
                undefined
                  ? moment(
                      partnerData.partners_by_pk.working_hours.Monday.start
                    ).format("HH:mm")
                  : "N/A "}
                -{" "}
                {partnerData.partners_by_pk.working_hours.Monday.end !==
                undefined
                  ? moment(
                      partnerData.partners_by_pk.working_hours.Monday.end
                    ).format("HH:mm")
                  : "N/A "}
              </div>
              <div className="font-semibold p-3">
                {partnerData.partners_by_pk.working_hours.Tuesday.start !==
                undefined
                  ? moment(
                      partnerData.partners_by_pk.working_hours.Tuesday.start
                    ).format("HH:mm")
                  : "N/A "}
                -{" "}
                {partnerData.partners_by_pk.working_hours.Tuesday.end !==
                undefined
                  ? moment(
                      partnerData.partners_by_pk.working_hours.Tuesday.end
                    ).format("HH:mm")
                  : "N/A "}
              </div>
              <div className="font-semibold p-3">
                {partnerData.partners_by_pk.working_hours.Wednesday.start !==
                undefined
                  ? moment(
                      partnerData.partners_by_pk.working_hours.Wednesday.start
                    ).format("HH:mm")
                  : "N/A "}
                -{" "}
                {partnerData.partners_by_pk.working_hours.Wednesday.end !==
                undefined
                  ? moment(
                      partnerData.partners_by_pk.working_hours.Wednesday.end
                    ).format("HH:mm")
                  : "N/A "}
              </div>
              <div className="font-semibold p-3">
                {partnerData.partners_by_pk.working_hours.Thursday.start !==
                undefined
                  ? moment(
                      partnerData.partners_by_pk.working_hours.Thursday.start
                    ).format("HH:mm")
                  : "N/A "}
                -{" "}
                {partnerData.partners_by_pk.working_hours.Thursday.end !==
                undefined
                  ? moment(
                      partnerData.partners_by_pk.working_hours.Thursday.end
                    ).format("HH:mm")
                  : "N/A "}
              </div>
              <div className="font-semibold p-3">
                {partnerData.partners_by_pk.working_hours.Friday.start !==
                undefined
                  ? moment(
                      partnerData.partners_by_pk.working_hours.Friday.start
                    ).format("HH:mm")
                  : "N/A "}
                -{" "}
                {partnerData.partners_by_pk.working_hours.Friday.end !==
                undefined
                  ? moment(
                      partnerData.partners_by_pk.working_hours.Friday.end
                    ).format("HH:mm")
                  : "N/A "}
              </div>
              <div className="font-semibold p-3">
                {partnerData.partners_by_pk.working_hours.Saturday.start !==
                undefined
                  ? moment(
                      partnerData.partners_by_pk.working_hours.Saturday.start
                    ).format("HH:mm")
                  : "N/A "}
                -{" "}
                {partnerData.partners_by_pk.working_hours.Saturday.end !==
                undefined
                  ? moment(
                      partnerData.partners_by_pk.working_hours.Saturday.end
                    ).format("HH:mm")
                  : "N/A "}
              </div>
              <div className="font-semibold p-3">
                {partnerData.partners_by_pk.working_hours.Sunday.start !==
                undefined
                  ? moment(
                      partnerData.partners_by_pk.working_hours.Sunday.start
                    ).format("HH:mm")
                  : "N/A "}
                -{" "}
                {partnerData.partners_by_pk.working_hours.Sunday.end !==
                undefined
                  ? moment(
                      partnerData.partners_by_pk.working_hours.Sunday.end
                    ).format("HH:mm")
                  : "N/A "}
              </div>
            </div>
          </div>
        ) : (
          <p>Working hours has not been defined for this partner</p>
        )}
      </div> */}
      {/* Working Hours */}

      <ModalGateway>
        {isPartnerPhotoModalOpen ? (
          <Modal
            onClose={() => {
              setIsPartnerPhotoModalOpen(!isPartnerPhotoModalOpen);
            }}
          >
            <Carousel views={partnerLogo} />
          </Modal>
        ) : null}
      </ModalGateway>

      <div className="mt-10">
        <h4 className="mb-2">BRANCHES</h4>
        <ReactTable
          columns={columns}
          loading={partnerLoading}
          data={partnerData.partners_by_pk.partner_branches}
          isSearch={false}
          isToggleColumn={false}
        />
      </div>
    </div>
  );
};

export default PartnerDetails;
