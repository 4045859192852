import React from "react";
import { Popover } from "antd";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import moment from "moment";

export default class HolidayPicker extends React.Component {
  state = {
    selectedDays: [],
    visible: false
  };

  handleDayClick = (day, { selected }) => {
    const { selectedDays } = this.state;
    if (selected) {
      const selectedIndex = selectedDays.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }

    this.setState({ selectedDays });
    this.props.onSave(selectedDays);
  };
  handleVisibleChange = visible => {
    this.setState({ visible });
  };
  onClose = () => {
    this.setState(
      {
        visible: false
      },
      () => this.props.onSave(this.state.selectedDays)
    );
  };
  initState = () => {
    let selectedDaysTemp = [];
    let itemsFromParent = this.props.datesOnMount;
    if (itemsFromParent.holidays)
      itemsFromParent.holidays.map(date =>
        selectedDaysTemp.push(
          new Date(this.props.selectedYear, this.props.monIndex, date)
        )
      );
    this.setState({ selectedDays: selectedDaysTemp });
  };
  componentDidMount() {
    this.initState();
  }

  render() {
    const renderDates = () => {
      let copy = [...this.state.selectedDays];
      let dates = [];
      copy.map(days => dates.push(moment(days).date()));
      return dates.join(",");
    };
    return (
      <>
        <div className="col-auto">{renderDates()}</div>
        <div className="col-auto">
          <Popover
            key={this.props.monIndex}
            content={
              <div className="flex flex-col">
                <DayPicker
                  canChangeMonth={false}
                  month={new Date(this.props.selectedYear, this.props.monIndex)}
                  onDayClick={this.handleDayClick}
                  selectedDays={this.state.selectedDays}
                />
              </div>
            }
            trigger="click"
            visible={this.state.visible}
            onVisibleChange={this.handleVisibleChange}
          >
            <button className="cta py-2 px-4 hover:shadow-md">
              Click to select dates
            </button>
          </Popover>
        </div>
      </>
    );
  }
}
