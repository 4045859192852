import gql from "graphql-tag";

export const GET_VISITS = gql`
query getVisits($limit: Int, $offset: Int, $where: visits_bool_exp = {}, $order_by: [visits_order_by!] = {}) {
  visits_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  visits(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    id
    visit_display_id
    visit_start_time
    visit_status
    visit_time
    visit_type
    visit_loan_type
    first_name
    last_name
    intermediate_status
    cancellation_reason
    agent {
      agent {
        id
        agent_display_id
        user {
          first_name
          last_name
        }
      }
    }
    loan {
      id
      loan_number
      loan_status
    }
    customer {
      id
      kycs {
        id
        is_approved
      }
    }
    visit_otps(where: {is_active: {_eq: true}}) {
      otp
      visit_id
      otp_from {
        first_name
        last_name
        name
      }
      otp_to {
        first_name
        last_name
        name
        mobile_number
      }
    }
  }
}
`;

export const EXPORT_VISITS = gql`
  query getVisitsForExport(
    $where: visits_bool_exp = {}
    $order_by: [visits_order_by!] = {}
  ) {
    visits(order_by: $order_by, where: $where) {
      id
      visit_display_id
      visit_start_time
      visit_status
      visit_time
      visit_type
      visit_loan_type
      address
      lat
      long
      channel
      first_name
      last_name
      mobile_number
      created_at
      updated_at
      agent_auth_id
      gold_type
      loan {
        id
        loan_number
      }
      assigned_by_auth_id
      confirmed_by_auth_id
      created_by {
        id
        first_name
        last_name
      }
      assigned_by {
        id
        first_name
        last_name
        auth_id
        mobile_number
      }
      agent {
        id
        agent {
          id
          agent_display_id
          user {
            first_name
            last_name
          }
        }
        auth_id
        first_name
        last_name
        mobile_number
      }
      customer {
        id
        first_name
        last_name
        name
        mobile_number
      }
      partner_branch {
        id
        partner_branch_name
      }
    }
  }
`;

const GET_VISIT_BY_ID = gql`
  query getVisitById($where: visits_bool_exp = {}) {
    visits(where: $where) {
      id
      visit_display_id
      visit_start_time
      visit_status
      intermediate_status
      visit_time
      visit_type
      visit_loan_type
      loan_subtype
      channel
      address
      lat
      long
      first_name
      last_name
      mobile_number
      created_at
      updated_at
      confirmed_at
      assigned_at
      agent_auth_id
      gold_type
      visits_x_gold_ornament_types {
        id
        gold_ornament_type {
          id
          type
        }
      }
      loan {
        id
        loan_display_id
        loan_number
        loan_status
        visits {
          id
          visit_type
          visits_x_gold_ornament_types {
            id
            gold_ornament_type {
              id
              type
            }
          }
        }
      }
      assigned_by_auth_id
      confirmed_by_auth_id
      created_by {
        id
        first_name
        last_name
      }
      assigned_by {
        id
        first_name
        last_name
        auth_id
        mobile_number
      }
      agent {
        id
        agent {
          id
          agent_display_id
        }
        auth_id
        first_name
        last_name
        mobile_number
      }
      secondary_field_agent_one_auth_id
      secondary_field_agent {
        id
        agent {
          id
          agent_display_id
        }
        auth_id
        first_name
        last_name
        mobile_number
      }
      secondary_field_agent_two_auth_id
      tertiary_field_agent {
        id
        agent {
          id
          agent_display_id
        }
        auth_id
        first_name
        last_name
        mobile_number
      }
      customer {
        id
        auth_id
        first_name
        last_name
        name
        mobile_number
        customer {
          id
        }
        user_bank_account_details(
          order_by: { created_at: desc }
          limit: 1
          where: { is_active: { _eq: true } }
        ) {
          id
          bank_account_name
          bank_account_number
          bank_name
          bank_branch
          bank_proof
          ifsc
          is_verified
        }
        kycs(
          order_by: { created_at: desc }
          limit: 1
          where: { is_active: { _eq: true } }
        ) {
          id
          legal_name
          address
          id_proof
          address_proof
          live_photo
          is_approved
        }
      }
      partner_branch {
        id
        partner_branch_name
      }
    }
  }
`;

const GET_FIELD_AGENTS = gql`
  query getFeildAgents($visit_id: Int!) {
    users(where: { role_name: { _eq: "FIELD_AGENT" } }) {
      id
      first_name
      last_name
      auth_id
      mobile_number
      is_active
      agent {
        id
        agent_display_id
      }
      visits(where: { id: { _neq: $visit_id } }) {
        id
        visit_status
      }
      secondary_field_agent_visits(where: { id: { _neq: $visit_id } }) {
        id
        secondary_agents_visit_status
      }
      tertiary_field_agent_visits(where: { id: { _neq: $visit_id } }) {
        id
        secondary_agents_visit_status
      }
    }
  }
`;

export const UPDATE_AGENT = gql`
  mutation updateAgent(
    $id: Int!
    $agent_auth_id: String!
    $visit_status: visit_status_enum = "VISIT_ASSIGNED"
    $assigned_by: String!
    $assigned_at: timestamptz!
    $secondary_field_agent_one_auth_id: String
    $secondary_field_agent_two_auth_id: String
    $secondary_agents_visit_status: jsonb
  ) {
    update_visits_by_pk(
      pk_columns: { id: $id }
      _set: {
        agent_auth_id: $agent_auth_id
        visit_status: $visit_status
        assigned_by_auth_id: $assigned_by
        assigned_at: $assigned_at
        secondary_field_agent_one_auth_id: $secondary_field_agent_one_auth_id
        secondary_field_agent_two_auth_id: $secondary_field_agent_two_auth_id
        secondary_agents_visit_status: $secondary_agents_visit_status
      }
    ) {
      id
      agent_auth_id
      visit_status
      secondary_field_agent_one_auth_id
      secondary_field_agent_two_auth_id
      secondary_agents_visit_status
      assigned_by_auth_id
    }
  }
`;

export const CANCEL_VISIT = gql`
  mutation cancelVisit(
    $id: Int!
    $visit_status: visit_status_enum = "VISIT_CANCELLED"
    $cancelled_by: String!
  ) {
    update_visits_by_pk(
      pk_columns: { id: $id }
      _set: { visit_status: $visit_status, cancelled_by_auth_id: $cancelled_by }
    ) {
      id
      visit_status
      cancelled_by_auth_id
    }
  }
`;

const GET_PARTNER_BRANCHES = gql`
  query getPartnerBranches {
    partner_branches(
      where: {
        is_active: { _eq: true }
        partners_branches_x_loanTypes: {
          loan_type: { loan_type: { _eq: "GL" } }
        }
      }
    ) {
      id
      partner_branch_name
    }
  }
`;

const INSERT_VISIT = gql`
  mutation insertVisit($obj: [visits_insert_input!]!) {
    insert_visits(objects: $obj) {
      returning {
        id
        visit_display_id
        first_name
        last_name
        address
        customer_auth_id
        gold_type
        lat
        long
        mobile_number
        visit_time
        visit_type
        visit_loan_type
        confirmed_at
      }
    }
  }
`;

const GET_CUSTOMER = gql`
  query getCustomer($mobile_number: String!) {
    users(
      where: {
        role_name: { _eq: "CUSTOMER" }
        _and: { mobile_number: { _eq: $mobile_number } }
      }
    ) {
      id
      first_name
      last_name
      name
      mobile_number
      auth_id
      customer {
        id
        lat
        long
        address
      }
    }
    users_aggregate(
      where: {
        _and: [
          { role_name: { _eq: "CUSTOMER" } }
          { mobile_number: { _eq: $mobile_number } }
          {
            customer_visits: {
              visit_status: {
                _in: ["VISIT_CONFIRMED", "VISIT_IN_PROGRESS", "VISIT_ASSIGNED"]
              }
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
      nodes {
        customer_visits(
          where: {
            visit_status: {
              _in: ["VISIT_CONFIRMED", "VISIT_IN_PROGRESS", "VISIT_ASSIGNED"]
            }
            intermediate_status: {
              _nin: [
                "OD_LOAN_AMOUNT_TRANSFERRED"
                "OD_STARTED_FOR_VAULT_LOCATION"
                "OD_VAULT_LOCATION_REACHED"
                "OD_VAULT_OTP_VALIDATED"
                "OD_LOAN_ACCOUNT_CREATED"
                "OD_GOLD_DEPOSITED"
              ]
            }
          }
        ) {
          id
          visit_status
          intermediate_status
        }
      }
    }
  }
`;

const CONFIRM_VISIT = gql`
  mutation confirmVisit(
    $id: Int!
    $channel: String!
    $visit_time: timestamptz!
    $confirmed_at: timestamptz!
    $confirmed_by: String!
    $partner_branch_id: Int!
    $address: jsonb!
    $lat: float8!
    $long: float8!
    $loan_subtype: loan_subtype_enum
    $visits_x_gold_ornament_types: [visits_x_gold_ornament_types_insert_input!]!
  ) {
    update_visits(
      where: { id: { _eq: $id } }
      _set: {
        channel: $channel
        partner_branch_id: $partner_branch_id
        visit_time: $visit_time
        visit_status: "VISIT_CONFIRMED"
        address: $address
        lat: $lat
        long: $long
        confirmed_at: $confirmed_at
        confirmed_by_auth_id: $confirmed_by
        loan_subtype: $loan_subtype
      }
    ) {
      returning {
        id
        gold_type
        visit_time
        visit_status
        confirmed_at
        confirmed_by_auth_id
        loan_subtype
      }
    }
    insert_visits_x_gold_ornament_types(
      objects: $visits_x_gold_ornament_types
    ) {
      affected_rows
    }
  }
`;

const APPROVE_KYC = gql`
  mutation approveKYC($kyc_id: Int!) {
    update_kyc_by_pk(pk_columns: { id: $kyc_id }, _set: { is_approved: true }) {
      id
      is_approved
    }
  }
`;

const GET_HOLIDAYS = gql`
  query getHolidays($year: jsonb) {
    oro_meta(
      where: {
        is_active: { _eq: true }
        type: { _eq: "HOLIDAYS" }
        data: { _contains: $year }
      }
    ) {
      id
      data
      type
    }
  }
`;

const GET_WORKING_HOURS = gql`
  query getWorkingHours {
    oro_meta(
      where: { is_active: { _eq: true }, type: { _eq: "WORKING_HOURS" } }
    ) {
      id
      data
      type
    }
  }
`;

const SET_LAST_META_AS_FALSE = gql`
  mutation setLastMetaAsFalse($id: Int!) {
    update_oro_meta_by_pk(pk_columns: { id: $id }, _set: { is_active: false }) {
      id
      is_active
    }
  }
`;

const INSERT_META = gql`
  mutation insertMeta($data: oro_meta_insert_input!) {
    insert_oro_meta_one(object: $data) {
      id
      data
      type
      is_active
      created_by_auth_id
    }
  }
`;

const CHECK_INSERT_KYC_BANK_ACCOUNT_DETAILS_FOR_CUSTOMER = gql`
  query checkCustomerKYCAndBankAccountDetails($id: Int!) {
    visits_by_pk(id: $id) {
      customer {
        id
        auth_id
        user_bank_account_details_aggregate(
          where: { is_active: { _eq: true } }
        ) {
          aggregate {
            count
          }
        }
        kycs_aggregate(where: { is_active: { _eq: true } }) {
          aggregate {
            count
          }
        }
        customer_visits_aggregate(
          where: { visit_status: { _eq: "VISIT_IN_PROGRESS" } }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

const GET_ACTIVE_GOLD_ORNAMENT_TYPES = gql`
  query getActiveGoldOrnamentTypes {
    gold_ornament_types(where: { is_active: { _eq: true } }) {
      id
      type
    }
  }
`;

export {
  GET_VISIT_BY_ID,
  GET_FIELD_AGENTS,
  GET_PARTNER_BRANCHES,
  INSERT_VISIT,
  GET_CUSTOMER,
  CONFIRM_VISIT,
  APPROVE_KYC,
  GET_HOLIDAYS,
  GET_WORKING_HOURS,
  SET_LAST_META_AS_FALSE,
  INSERT_META,
  CHECK_INSERT_KYC_BANK_ACCOUNT_DETAILS_FOR_CUSTOMER,
  GET_ACTIVE_GOLD_ORNAMENT_TYPES,
};
