import gql from "graphql-tag";

const GET_CUSTOMERS = gql`
  query customers($limit: Int, $offset: Int, $where: users_bool_exp = {}, $order_by: [users_order_by!] = {}) {
    users(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
      id
      first_name
      last_name
      name
      mobile_number
      email_id
      city
      created_at
      customer {
        id
        customer_display_id
      }
      loans(order_by: {created_at: desc}, limit: 1) {
        id
        loan_number
        loan_status
        account_number
        visits(order_by: {created_at: desc}, limit: 1) {
          id
          visit_display_id
          visit_status
        }
      }
      visits(order_by: {created_at: desc}, limit: 1) {
        id
        visit_display_id
        visit_status
      }
      kycs(where: {is_active: {_eq: true}}, order_by: {id: desc}, limit: 1) {
        id
        legal_name
      }
    }
    users_aggregate(order_by: $order_by, where: $where) {
      aggregate {
        count
      }
    }
  }

`;

const EXPORT_CUSTOMERS = gql`
  query exportCustomers($where: users_bool_exp = {}) {
    users(order_by: {created_at: desc}, where: $where) {
      id
      first_name
      last_name
      name
      mobile_number
      email_id
      city
      created_at
      customer {
        id
        customer_display_id
      }
      loans(order_by: {created_at: desc}, limit: 1) {
        id
        loan_number
        loan_status
        visits(order_by: {created_at: desc}, limit: 1) {
          id
          visit_display_id
          visit_status
        }
      }
    }
  }
`;

const GET_CUSTOMER_DETAILS_BY_ID = gql`
  query customerDetails($id: Int!) {
    users(where: { customer: { id: { _eq: $id } } }) {
      id
      first_name
      last_name
      name
      auth_id
      mobile_number
      email_id
      city
      created_at
      customer {
        id
        customer_display_id
        golds {
          id
          gold_display_id
          gold_type
          gross_weight
          net_weight
          actual_net_weight
          actual_quality
          quantity
          pickup_seal_id
          gold_images
          pickup_sealed_gold_images
        }
      }
      kycs(
        where: { is_active: { _eq: true } }
        order_by: { id: desc }
        limit: 1
      ) {
        id
        legal_name
        address
        id_proof
        address_proof
        live_photo
      }
      loans {
        id
        loan_number
        customer_id_from_bank
        orocorp_approved_at
        tenure
        gl_amount {
          id
          loan_amount
        }
        od_amount {
          id
          credit_limit
        }
        loan_type {
          id
          loan_type
        }
        support_loan {
          id
          sl_amount {
            id
            loan_amount
          }
        }
        partner_branch_approved_at
        partner_branch {
          id
          partner_branch_name
        }
      }
    }
  }
`;

const INSERT_KYC_AND_BANK_ACCOUNT_DETAILS = gql`
  mutation insertKYCAndBankAccountDetailsOne(
    $kycData: kyc_insert_input!
    $bankAccountData: user_bank_account_details_insert_input!
  ) {
    insert_user_bank_account_details_one(object: $bankAccountData) {
      id
      bank_account_name
      bank_account_number
      bank_name
      bank_branch
      ifsc
      bank_proof
    }
    insert_kyc_one(object: $kycData) {
      id
      legal_name
      father_name
      mother_name
      gender
      marital_status
      date_of_birth
      address_proof
      id_proof
      pan_number
      pan_proof
      live_photo
    }
  }
`;

export {
  GET_CUSTOMERS,
  GET_CUSTOMER_DETAILS_BY_ID,
  EXPORT_CUSTOMERS,
  INSERT_KYC_AND_BANK_ACCOUNT_DETAILS,
};
