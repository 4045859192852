import { useQuery, useMutation } from "@apollo/client";
import { Button, notification, Popconfirm, Tag } from "antd";
import moment from "moment";
import React, { useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import Loading from "../../components/loading";
import ErrorPage from "../../components/ErrorPage";
import { GET_AGENT_DETAILS_BY_ID } from "./graphql";
import { LeftOutlined } from "@ant-design/icons";
import gql from "graphql-tag";
import { get_image_url } from "../../utils";

// const DEACTIVATE_AGENT = gql`
//   mutation deactivateAgent($auth_id: String!) {
//     deactivate_field_agent(auth_id: $auth_id) {
//       id
//       is_active
//     }
//   }
// `;

const DEACTIVATE_AGENT = gql`
  mutation deactivateAgent($id: String!) {
    disable_field_agent(id: $id) {
      code
      message
    }
  }
`;

// const DELETE_AGENT = gql`
//   mutation deleteAgent($auth_id: String!) {
//     delete_field_agent(auth_id: $auth_id) {
//       message
//     }
//   }
// `;

const DELETE_AGENT = gql`
  mutation deleteAgent($id: String!) {
    delete_field_agent(id: $id) {
      code
      message
    }
  }
`;

const GET_AGENT_VISIT_COUNT = gql`
  query getAgentVisitCount($id: Int!) {
    users_by_pk(id: $id) {
      id
      visits_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

const AgentDetails = (props) => {
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [images, setImages] = useState([]);

  const {
    data: agentData,
    loading: agentDetailsLoading,
    error: agentDetailsError,
  } = useQuery(GET_AGENT_DETAILS_BY_ID, {
    variables: {
      id: parseInt(props.match.params.id),
    },
  });

  const {data: agentVisitCount, loading: agentVisitCountLoading} = useQuery(GET_AGENT_VISIT_COUNT, {
    variables: {
      id: parseInt(props.match.params.id),
    },
    fetchPolicy: "network-only",
  });

  const [deactivateAgent] = useMutation(DEACTIVATE_AGENT, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        if (error.message === "No agent exists with given number")
          notification.error({
            message: "Error!",
            description: "Agent does not exist",
          });
        else
          notification.error({
            message: "Error!",
            description: "Something went wrong!",
          });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Agent deactivated",
      });
    },
    refetchQueries: [
      {
        query: GET_AGENT_DETAILS_BY_ID,
        variables: {
          id: parseInt(props.match.params.id),
        },
      },
    ],
  });

  const [deleteAgent, { loading: deletingAgent }] = useMutation(DELETE_AGENT, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        if (error.message === "No agent exists with given number")
          notification.error({
            message: "Error!",
            description: "Agent does not exist",
          });
        else
          notification.error({
            message: "Error!",
            description: "Something went wrong!",
          });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Agent deleted",
      });

      props.history.push("/agents");
    },
  });

  if (agentDetailsLoading || agentVisitCountLoading) return <Loading />;

  if (agentDetailsError) return <ErrorPage />;

  return (
    <div className="space-y-8">
      <div className="flex justify-between">
        <div className="flex items-center pl-3">
          <div className="relative">
            <LeftOutlined
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "#16A085",
              }}
            />
          </div>

          <Button
            type="link"
            onClick={() => {
              props.history.push("/agents");
            }}
          >
            All agents
          </Button>
        </div>

        <div className="space-x-4">
          <button
            className="cta hover:shadow-lg"
            style={{ height: "38px ", minWidth: "150px" }}
            onClick={() => {
              props.history.push(`/agents/edit/${props.match.params.id}`);
            }}
          >
            Edit agent
          </button>
          {agentData.users_by_pk.is_active ? (
            <Popconfirm
              title={`Are you sure you want to deactivate this agent?`}
              onConfirm={() => {
                // deactivateAgent({
                //   variables: {
                //     auth_id: agentData.users_by_pk.auth_id,
                //   },
                // });
                deactivateAgent({
                  variables: {
                    id: props.match.params.id,
                  },
                });
              }}
              onCancel={() => {}}
              okText="Yes"
              placement="bottomRight"
              cancelText="No"
            >
              <Button
                type={!agentData.users_by_pk.is_active ? "primary" : ""}
                danger={agentData.users_by_pk.is_active}
                className="rounded-md"
              >
                {`Make as ${agentData.users_by_pk.is_active ? "in" : ""}active`}
              </Button>
            </Popconfirm>
          ) : agentVisitCount.users_by_pk.visits_aggregate.aggregate.count ===
            0 ? (
            <Popconfirm
              title={`Are you sure you want to delete this agent?`}
              onConfirm={() => {
                // deleteAgent({
                //   variables: {
                //     auth_id: agentData.users_by_pk.auth_id,
                //   },
                // });
                deleteAgent({
                  variables: {
                    id: props.match.params.id.toString(),
                  },
                });
              }}
              onCancel={() => {}}
              okText="Yes"
              placement="bottomRight"
              cancelText="No"
            >
              <Button
                danger={!agentData.users_by_pk.is_active}
                className="rounded-md"
                loading={deletingAgent}
              >
                Delete agent
              </Button>
            </Popconfirm>
          ) : null}
        </div>
      </div>

      <div>
        <h2>
          View Agent | {(agentData.users_by_pk || {}).first_name}{" "}
          {(agentData.users_by_pk || {}).last_name}
        </h2>
        <div className="flex text-gray-600 space-x-1">
          <p>{`Created at ${moment(
            (agentData.users_by_pk || {}).agent.created_at
          ).format("DD MMM YYYY HH:mm")} by ${
            ((agentData.users_by_pk || {}).agent.created_by || {}).first_name ||
            ""
          } ${
            ((agentData.users_by_pk || {}).agent.created_by || {}).last_name ||
            ""
          }`}</p>
          <p> </p>
          <p>{`${
            agentData.users_by_pk.agent.created_at !==
            agentData.users_by_pk.agent.updated_at
              ? ` | Updated at ${moment(
                  agentData.users_by_pk.agent.updated_at
                ).format("DD MMM YYYY HH:mm")} by ${
                  (agentData.users_by_pk.agent.updated_by || {}).first_name ||
                  ""
                } ${
                  (agentData.users_by_pk.agent.updated_by || {}).last_name || ""
                }`
              : ""
          }`}</p>
        </div>
      </div>

      {/* Agent Overview */}
      <div>
        <h4 className="mb-2">AGENT INFO</h4>
        <div className="flex bg-green-primary">
          <div className=" font-semibold text-white p-3 w-1/6">Name</div>
          <div className=" font-semibold text-white p-3 w-1/6">Type</div>
          <div className=" font-semibold text-white p-3 w-1/6">Status</div>
          <div className=" font-semibold text-white p-3 w-1/6">Email ID</div>
          <div className=" font-semibold text-white p-3 w-1/6">
            Mobile number
          </div>
          <div className=" font-semibold text-white p-3 w-1/6">Agent ID</div>
          <div className=" font-semibold text-white p-3 w-1/6">Address</div>
        </div>
        <div className="flex bg-white">
          <div className="font-semibold p-3 w-1/6 break-all">
            {agentData.users_by_pk.first_name} {agentData.users_by_pk.last_name}
          </div>
          <div className="font-semibold p-3 w-1/6 break-all capitalize">
            {agentData.users_by_pk.role_name.split("_").join(" ").toLowerCase()}
          </div>
          <div className=" font-semibold p-3 w-1/6 break-all">
            {agentData.users_by_pk.is_active ? (
              <Tag color={"green"}>Active</Tag>
            ) : (
              <Tag color={"red"}>Inactive</Tag>
            )}
          </div>
          <div className="font-semibold p-3 w-1/6 break-all">
            {agentData.users_by_pk.email_id}
          </div>
          <div className="font-semibold p-3 w-1/6 break-all">
            {agentData.users_by_pk.mobile_number}
          </div>
          <div className="font-semibold p-3 w-1/6 break-all">
            {agentData.users_by_pk.agent.agent_display_id}
          </div>
          <div className="font-semibold p-3 w-1/6 break-all">
            {agentData.users_by_pk.agent.address ? (
              <div>
                {agentData.users_by_pk.agent.address.addr_line_1},{" "}
                {agentData.users_by_pk.agent.address.addr_line_2},{" "}
                {agentData.users_by_pk.agent.address.city},{" "}
                {agentData.users_by_pk.agent.address.state},{" "}
                {agentData.users_by_pk.agent.address.pincode}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {/* Agent overview */}

      {/* Agent - Secondary contact details */}
      <div>
        <h4 className="mb-2">SECONDARY CONTACT DETAILS</h4>
        <div className="flex bg-green-primary">
          <div className=" font-semibold text-white p-3 w-1/4">Name</div>
          <div className=" font-semibold text-white p-3 w-1/4">
            Mobile number
          </div>
          <div className=" font-semibold text-white p-3 w-1/6">Relation</div>
          <div className=" font-semibold text-white p-3 w-1/4">Address</div>
        </div>
        <div className="flex bg-white">
          <div className="font-semibold p-3 w-1/4 break-all">
            {agentData.users_by_pk.agent.first_name}{" "}
            {agentData.users_by_pk.agent.last_name}
          </div>
          <div className="font-semibold p-3 w-1/4 break-all">
            +91{agentData.users_by_pk.agent.secondary_mobile_number}
          </div>
          <div className="font-semibold p-3 w-1/6 break-all">
            {agentData.users_by_pk.agent.relation}
          </div>

          <div className="font-semibold p-3 w-1/4 break-all">
            {agentData.users_by_pk.agent.secondary_address ? (
              <div>
                {agentData.users_by_pk.agent.secondary_address.addr_line_1},{" "}
                {agentData.users_by_pk.agent.secondary_address.addr_line_2},{" "}
                {agentData.users_by_pk.agent.secondary_address.city},{" "}
                {agentData.users_by_pk.agent.secondary_address.state},{" "}
                {agentData.users_by_pk.agent.secondary_address.pincode}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {/* Agent - Secondary contact details */}

      {/* Agent - Bank account details */}
      {agentData.users_by_pk.user_bank_account_details.length > 0 ? (
        <div>
          <h4 className="mb-2">BANK ACCOUNT DETAILS</h4>
          <div className="flex bg-green-primary">
            <div className=" font-semibold text-white p-3 w-1/4">
              Account name
            </div>
            <div className=" font-semibold text-white p-3 w-1/4">
              Account number
            </div>
            <div className=" font-semibold text-white p-3 w-1/6">IFSC</div>
            <div className=" font-semibold text-white p-3 w-1/4">Bank name</div>
            <div className=" font-semibold text-white p-3 w-1/4">
              Bank branch
            </div>
          </div>
          <div className="flex bg-white">
            <div className="font-semibold p-3 w-1/4 break-all">
              {
                agentData.users_by_pk.user_bank_account_details[0]
                  .bank_account_name
              }{" "}
            </div>
            <div className="font-semibold p-3 w-1/4 break-all">
              {
                agentData.users_by_pk.user_bank_account_details[0]
                  .bank_account_number
              }
            </div>
            <div className="font-semibold p-3 w-1/6 break-all">
              {agentData.users_by_pk.user_bank_account_details[0].ifsc}
            </div>

            <div className="font-semibold p-3 w-1/4 break-all">
              {agentData.users_by_pk.user_bank_account_details[0].bank_name}
            </div>

            <div className="font-semibold p-3 w-1/4 break-all">
              {agentData.users_by_pk.user_bank_account_details[0].bank_branch}
            </div>
          </div>
        </div>
      ) : null}

      {/* Agent - Bank accountdetails */}

      <div className="flex space-x-16">
        {agentData.users_by_pk.agent.id_proof ? (
          <div className="w-1/4 space-y-2">
            <h3>ID Proof</h3>

            <div className="border bg-white">
              {agentData.users_by_pk.agent.id_proof.map((proof) => {
                return (
                  <p
                    className="cursor-pointer hover:bg-gray-200 py-1 px-2"
                    onClick={async () => {
                      let images = [];

                      let index = agentData.users_by_pk.agent.id_proof.findIndex(
                        (data) => {
                          return data.type === proof.type;
                        }
                      );

                      await Promise.all(
                        agentData.users_by_pk.agent.id_proof[
                          index
                        ].image_url.map(async (url) => {
                          const valid_url = await get_image_url({
                            url: [url],
                          });

                          images.push({
                            caption: `ID proof - ${proof.type}`,
                            source: valid_url,
                          });
                        })
                      );

                      setIsImageModalOpen(!isImageModalOpen);
                      setImages(images);
                    }}
                  >
                    {proof.type}
                  </p>
                );
              })}
            </div>
          </div>
        ) : null}

        {agentData.users_by_pk.agent.address_proof ? (
          <div className="w-1/4 space-y-2">
            <h3>Address Proof</h3>

            <div className="border bg-white">
              {agentData.users_by_pk.agent.address_proof.map((proof) => {
                return (
                  <p
                    className="cursor-pointer hover:bg-gray-200 py-1 px-2"
                    onClick={async () => {
                      let images = [];

                      let index = agentData.users_by_pk.agent.address_proof.findIndex(
                        (data) => {
                          return data.type === proof.type;
                        }
                      );

                      await Promise.all(
                        agentData.users_by_pk.agent.address_proof[
                          index
                        ].image_url.map(async (url) => {
                          const valid_url = await get_image_url({
                            url: [url],
                          });
                          images.push({
                            caption: `Address proof - ${proof.type}`,
                            source: valid_url,
                          });
                        })
                      );

                      setIsImageModalOpen(!isImageModalOpen);
                      setImages(images);
                    }}
                  >
                    {proof.type}
                  </p>
                );
              })}
            </div>
          </div>
        ) : null}

        {agentData.users_by_pk.user_bank_account_details ? (
          agentData.users_by_pk.user_bank_account_details.length > 0 ? (
            agentData.users_by_pk.user_bank_account_details[0].bank_proof ? (
              <div className="w-1/4 space-y-2">
                <h3>Bank account Proof</h3>

                <div className="border bg-white">
                  {agentData.users_by_pk.user_bank_account_details[0].bank_proof.map(
                    (proof) => {
                      return (
                        <p
                          className="cursor-pointer hover:bg-gray-200 py-1 px-2"
                          onClick={async () => {
                            let images = [];

                            let index = agentData.users_by_pk.user_bank_account_details[0].bank_proof.findIndex(
                              (data) => {
                                return data.type === proof.type;
                              }
                            );

                            await Promise.all(
                              agentData.users_by_pk.user_bank_account_details[0].bank_proof[
                                index
                              ].image_url.map(async (url) => {
                                const valid_url = await get_image_url({
                                  url: [url],
                                });
                                images.push({
                                  caption: `Bank proof - ${proof.type}`,
                                  source: valid_url,
                                });
                              })
                            );

                            setIsImageModalOpen(!isImageModalOpen);
                            setImages(images);
                          }}
                        >
                          {proof.type}
                        </p>
                      );
                    }
                  )}
                </div>
              </div>
            ) : null
          ) : null
        ) : null}

        {agentData.users_by_pk.profile_picture ? (
          <div className="w-1/5 space-y-2">
            <h3>Agent Photo</h3>
            <button
              className="cta hover:shadow-lg"
              style={{ height: "38px", minWidth: "150px" }}
              onClick={async () => {
                const valid_url = await get_image_url({
                  url: [agentData.users_by_pk.profile_picture],
                });

                setIsImageModalOpen(!isImageModalOpen);
                setImages([{ source: valid_url }]);
              }}
            >
              View
            </button>
          </div>
        ) : null}

        {agentData.users_by_pk.agent.id_card_photos ? (
          <div className="w-1/5 space-y-2">
            <h3>Agent ID Card Photos</h3>
            <button
              className="cta hover:shadow-lg"
              style={{ height: "38px", minWidth: "150px" }}
              onClick={async () => {
                const valid_url = await get_image_url({
                  url: agentData.users_by_pk.agent.id_card_photos,
                });

                setIsImageModalOpen(!isImageModalOpen);
                setImages(
                  valid_url.map((url) => {
                    return {
                      source: url,
                      caption: "Agent ID card",
                    };
                  })
                );
              }}
            >
              View
            </button>
          </div>
        ) : null}

        <ModalGateway>
          {isImageModalOpen && images.length > 0 ? (
            <Modal
              onClose={() => {
                setIsImageModalOpen(!isImageModalOpen);
              }}
            >
              <Carousel views={images} />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </div>
  );
};

export default AgentDetails;
