import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Button, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ErrorPage from "../../components/ErrorPage";
import ReactTable from "../../components/Table";
import { EXPORT_PARTNERS, GET_PARTNERS, GET_PARTNERS_FILTER } from "./graphql";
import { CSVLink } from "react-csv";

const Partners = (props) => {
  const columns = [
    {
      title: "Partner ID",
      dataIndex: "partner_display_id",
      key: "planId",
      show: true,
      width: 150,
      sorter: (a, b) => {},
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Partner Name",
      dataIndex: "partner_name",
      key: "planId",
      show: true,
    },
    {
      title: "Partner Type",
      dataIndex: "partners_x_partnerTypes[0].partner_type.partner_type",
      key: "partners_x_partnerTypes[0].partner_type.partner_type",
      show: true,
      render: (text, data, index) => {
        return data.partners_x_partnerTypes.length > 0
          ? data.partners_x_partnerTypes
              .map((type) => {
                return type.partner_type.partner_type;
              })
              .join(", ")
          : null;
      },
    },
    {
      title: "Branches",
      dataIndex: "partner_branches_aggregate.aggregate.count",
      key: "partner_branches_aggregate.aggregate.count",
      show: true,
      render: (text, data, index) =>
        data.partner_branches_aggregate.aggregate.count,
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      show: true,
      width: 100,
      render: (text, data, index) => {
        return (
          <div>
            {text ? (
              <Tag color={"green"} key={text}>
                Active
              </Tag>
            ) : (
              <Tag color={"red"} key={text}>
                Inactive
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      show: true,
      fixed: "right",
      width: 100,
      render: (text, data, index) => {
        return (
          <Link to={`/partners/view/${data.id}`}>
            <EyeOutlined />
          </Link>
        );
      },
    },
  ];

  const [filter, setFilter] = useState([
    {
      title: "Partner Type",
      dataIndex: "partners_x_partnerTypes",
      key: "partners_x_partnerTypes",
      type: "select",
      show: false,
      data: [],
      isFixed: true,
      filterType: "partners_x_partnerTypes",
      // filter: { partner_type: { partner_type: { _eq: "$selected" } } },
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      type: "select",
      show: false,
      data: [
        { id: true, name: "Active" },
        { id: false, name: "Inactive" },
      ],
      isFixed: true,
      filter: { _in: "$selected" },
    },
  ]);
  const [filterObj, setFilterObj] = useState({});
  const [partnerIdOrder, SetPartnerIdOrder] = useState(null);

  useEffect(() => {
    let objectFilters = {};
    // var re = new RegExp("$selected", "g");

    (filter || []).forEach((filter) => {
      if ((filter.selected || []).length)
        if (filter.show || filter.isFixed) {
          if (filter.filterType) {
            objectFilters[filter.key] = {
              partner_type: { id: { _in: filter.selected } },
            };
          } else {
            objectFilters[filter.key] = { _in: filter.selected };
          }
        }

      //  {
      //   [filter.filterType]: JSON.parse(
      //     JSON.stringify(filter.filter)
      //       .split("$selected")
      //       .join(filter.selected)
      //   ),
      // };
    });
    // resetState();

    setFilterObj(objectFilters);
  }, [filter]);
  const [tableData, setTableData] = useState({
    page: 1,
    data: [],
    hasMoreItems: true,
  });
  const [searchText, SetSearchText] = useState("");
  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    // position: ["none", "none"],
    pageSize: 10,
    current: 1,
    total: 30,
  });
  const resetState = () => {
    setTableData({
      page: 1,
      data: [],
      hasMoreItems: true,
    });
    setPagination({
      ...pagination,
      total: 0,
      current: 1,
    });
  };

  const [CSVData, setCSVData] = useState([]);

  const formatCSVData = (partners) => {
    let CSVData = [];

    (partners.partners || []).forEach((data) => {
      ((data.partner_branches || []).length ? data.partner_branches : [{}]).forEach(
        (branch) => {
          CSVData.push({
            partner_id: data.partner_display_id,
            partner_name: data.partner_name,
            partner_type: (data.partners_x_partnerTypes || [])
              .map(
                (partnerType) => (partnerType.partner_type || {}).partner_type
              )
              .join(", "),
            partner_status: data.is_active ? "Active" : "Inactive",

            branch_id: branch.partner_branches_display_id,
            branch_name: branch.partner_branch_name,
            loan_types: (branch.partners_branches_x_loanTypes || [])
              .map((loanType) => loanType.loan_type.loan_type)
              .join(", "),
            branch_status: branch.partner_branches_display_id
              ? branch.is_active
                ? "Active"
                : "Inactive"
              : "",
            created_at: moment(data.created_at).format(
              process.env.REACT_APP_DATE_FORMAT
            ),
            updated_at: moment(data.updated_at).format(
              process.env.REACT_APP_DATE_FORMAT
            ),
          });
        }
      );
    });

    setTimeout(function () {
      setCSVData(CSVData);
      triggerCSVDownload();
    }, 3000);
  };

  const triggerCSVDownload = () => {
    document.getElementById("react-csv").click();
  };

  const [exportPlanCSV, { loading: exportLoading }] = useLazyQuery(EXPORT_PARTNERS, {
    variables: {
      order_by: {
        id: partnerIdOrder,
        created_at: partnerIdOrder ? null : "asc",
      },
      where: {
        partner_name: { _ilike: `%${searchText}%` },
        ...filterObj,
      },
    },
    fetchPolicy: "network-only",
    onCompleted: formatCSVData,
  });
  const planQueryOnComplete = (partnerData) => {
    if (!partnerData.partners) {
      return;
    }
    let hasMoreItems = true;
    if ((partnerData.partners || []).length === 0) {
      hasMoreItems = false;
    }
    if (partnerData.partners_aggregate) {
      setPagination({
        ...pagination,
        total: partnerData.partners_aggregate.aggregate.count,
      });
    }
    let partners = (partnerData.partners || []).map((partner) => {
      return { ...partner };
    });
    setTableData({
      ...tableData,
      data: partners,
      // data: [...tableData.data, ...partners],
      hasMoreItems: hasMoreItems,
    });
  };
  const {loading: partnerLoading, error: partnerError} = useQuery(GET_PARTNERS, {
    variables: {
      limit: parseInt(process.env.REACT_APP_API_PAGE_LIMIT),
      offset: process.env.REACT_APP_API_PAGE_LIMIT * (tableData.page - 1),
      order_by: {
        id: partnerIdOrder,
        created_at: partnerIdOrder ? null : "desc",
      },
      where: {
        partner_name: { _ilike: `%${searchText}%` },
        ...filterObj,
      },
    },
    fetchPolicy: "network-only",
    onCompleted: planQueryOnComplete,
  });

  const planDataQueryOnComplete = (partnerFilterData) => {
    let objectFilters = {},
      filters = [];
    (filter || []).forEach((filter) => {
      objectFilters[filter.key] = filter;
    });
    if (partnerFilterData.partner_types) {
      objectFilters[
        "partners_x_partnerTypes"
      ].data = partnerFilterData.partner_types.map((data) => {
        return { id: data.id, name: data.partner_type };
      });
    }
    Object.keys(objectFilters).forEach((key) => {
      filters.push(objectFilters[key]);
    });
    setFilter(filters);
  };

  const loadMoreItems = (value) => {
    if (!partnerLoading && tableData.hasMoreItems) {
      setTableData((data) => {
        return { ...data, page: data.page + 1 };
      });
    }
  };
  
  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    if (sorter.field === "partner_display_id") {
      let order = sorter.order
        ? sorter.order === "descend"
          ? "desc"
          : "asc"
        : null;
      SetPartnerIdOrder(order);
    }
    setTableData({
      ...tableData,
      page: pagination.current,
    });
    setPagination(pager);
  };
  
  const { error: filterError } = useQuery(GET_PARTNERS_FILTER, {
    onCompleted: planDataQueryOnComplete,
  });
  
  if (partnerError || filterError) {
    return <ErrorPage></ErrorPage>;
  }
  
  return (
    <div>
      <CSVLink
        data={CSVData}
        headers={[
          { label: "Partner ID", key: "partner_id" },
          { label: "Partner Name", key: "partner_name" },
          { label: "Partner Type", key: "partner_type" },
          { label: "Partner Status", key: "partner_status" },
          { label: "Branch ID", key: "branch_id" },
          { label: "Branch Name", key: "branch_name" },
          { label: "Loan Types", key: "loan_types" },
          { label: "Branch Status", key: "branch_status" },
          { label: "Created At", key: "created_at" },
          { label: "Updated At", key: "updated_at" },
        ]}
        filename={`Partners - ${moment().format("DD-MM-YYYY HH:mm")}.csv`}
        className="hidden"
        target="_blank"
        id="react-csv"
      >
        Download CSV
      </CSVLink>
      <div className="flex justify-between  align-center mb-4">
        <h2 className="text-green-primary">All Partners</h2>
        <div className="flex justify-between space-x-2">
          <Button
            htmlType="submit"
            className=" rounded-lg flex align-center "
            type="primary"
            size={"large"}
            icon={<PlusOutlined />}
            onClick={() => {
              props.history.push("/partners/create");
            }}
          >
            Create new SL partner
          </Button>
          <Link
            to={{
              pathname: "/partners/branch/create",
            }}
          >
            <Button
              className=" rounded-lg flex align-center "
              type="primary"
              size={"large"}
              icon={<PlusOutlined />}
            >
              Create New SL Branch
            </Button>
          </Link>
          <Button
            className=" rounded-lg flex align-center ml-3 mr-3"
            type="primary"
            ghost
            size={"large"}
            onClick={() => {
              exportPlanCSV();
            }}
            loading={exportLoading}
          >
            Export
          </Button>
        </div>
      </div>
      <div className="w-100 position-relatives">
        <ReactTable
          columns={columns}
          data={tableData.data}
          table={{
            rowKey: (record) => record.id,
            pagination: pagination,
          }}
          filter={filter}
          page={tableData.page}
          hasMoreItems={tableData.hasMoreItems}
          loadItems={() => {
            loadMoreItems(0);
          }}
          isToggleFullScreen={false}
          isToggleColumn={true}
          isMoreFilter={false}
          isSearch={true}
          loading={partnerLoading}
          onToolbarSearch={(value) => {
            resetState();
            SetSearchText(value);
          }}
          onToolbarMore={(index, value) => {
            const filterData = [...filter];
            filterData[index].show = value;
            resetState();

            setFilter(filterData);
          }}
          onFilterSelectChange={(index, key, value) => {
            const filterData = [...filter];
            filterData[index].selected = value;
            resetState();
            setFilter(filterData);
          }}
          handleTableChange={handleTableChange}

          // onChangeDate={(index, dataKey, value) => {
          //   const filter = [...filter];
          //   //filter[index].fromDate = moment(value[0]).toISOString();
          //   // filter[index].toDate = moment(value[1]).toISOString();
          //   resetState();

          //   setFilter(filterData);
          // }}
        />
      </div>
    </div>
  );
};

export default Partners;
