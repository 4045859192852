import React from "react";

function GlobalButton({
  label,
  size = null,
  type = "primary",
  handleClick = () => {},
  className = "",
  disabled = false,
  buttonProps = {},
}) {
  return (
    <button
      disabled={disabled}
      onClick={handleClick}
      className={`focus:outline-none text-md font-bold upgraded-${
        !disabled ? type : "disabled"
      }-btn rounded-md py-${
        size === "sm" ? "2" : "3"
      } px-3 flex items-center justify-center ${className}`}
      {...buttonProps}
    >
      {label}
    </button>
  );
}

export default GlobalButton;
