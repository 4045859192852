import React from "react";
import {
  TeamOutlined,
  EnvironmentOutlined,
  DownSquareOutlined,
  BankOutlined,
  BookOutlined,
  QuestionOutlined,
  SwapOutlined,
  UserOutlined,
  DollarOutlined,
  GoldOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";

const AdminSidebar = () => {
  return (
    <div
      style={{
        overflow: "scroll",
        background: "#fff",
        width: "10vw",
      }}
    >
      <div className="w-full">
        <NavLink
          to="/agents"
          activeClassName="navlinkClassName"
          className="w-full"
        >
          <div className="w-full py-4 px-6 flex items-center space-x-2">
            <TeamOutlined />
            <p>Agents</p>
          </div>
        </NavLink>
      </div>

      <div className="w-full">
        <NavLink
          to="/partners"
          activeClassName="navlinkClassName"
          className="w-full"
        >
          <div className="w-full py-4 px-6 flex items-center space-x-2">
            <BankOutlined />
            <p>Partners</p>
          </div>

          {/* </Menu.Item> */}
        </NavLink>
      </div>

      <div className="w-full">
        <NavLink
          to="/visits"
          activeClassName="navlinkClassName"
          className="w-full"
        >
          <div className="w-full py-4 px-6 flex items-center space-x-2">
            <EnvironmentOutlined />
            <p>Visits</p>
          </div>

          {/* </Menu.Item> */}
        </NavLink>
      </div>

      <div className="w-full">
        <NavLink
          to="/plans"
          activeClassName="navlinkClassName"
          className="w-full"
        >
          <div className="w-full py-4 px-6 flex items-center space-x-2">
            <DownSquareOutlined />
            <p>Plans</p>
          </div>

          {/* </Menu.Item> */}
        </NavLink>
      </div>

      <div className="w-full">
        <NavLink
          to="/rates"
          activeClassName="navlinkClassName"
          className="w-full"
        >
          <div className="w-full py-4 px-6 flex items-center space-x-2">
            <div style={{ width: "14px" }}>₹</div> <p>Rates</p>
          </div>

          {/* </Menu.Item> */}
        </NavLink>
      </div>

      <div className="w-full">
        <NavLink
          to="/loans"
          activeClassName="navlinkClassName"
          className="w-full"
        >
          <div className="w-full py-4 px-6 flex items-center space-x-2">
            <BookOutlined />
            <p>Loans</p>
          </div>
        </NavLink>
      </div>

      <div className="w-full">
        <NavLink
          to="/support-loans"
          activeClassName="navlinkClassName"
          className="w-full"
        >
          <div className="w-full py-4 px-6 flex items-center space-x-2">
            <BookOutlined />
            <p>Support loans</p>
          </div>
        </NavLink>
      </div>

      <div className="w-full">
        <NavLink
          to="/callback-requests"
          activeClassName="navlinkClassName"
          className="w-full"
        >
          <div className="w-full py-4 px-6 flex items-center space-x-2">
            <QuestionOutlined />
            <p>Callback requests</p>
          </div>
        </NavLink>
      </div>

      <div className="w-full">
        <NavLink
          to="/users"
          activeClassName="navlinkClassName"
          className="w-full"
        >
          <div className="w-full py-4 px-6 flex items-center space-x-2">
            <UserOutlined />
            <p>Users</p>
          </div>
        </NavLink>
      </div>

      <div className="w-full">
        <NavLink
          to="/transactions"
          activeClassName="navlinkClassName"
          className="w-full"
        >
          <div className="w-full py-4 px-6 flex items-center space-x-2">
            <SwapOutlined />
            <p>Transactions</p>
          </div>
        </NavLink>
      </div>

      <div className="w-full">
        <NavLink
          to="/oro-offer"
          activeClassName="navlinkClassName"
          className="w-full"
        >
          <div className="w-full py-4 px-6 flex items-center space-x-2">
            <DollarOutlined />
            <p>Oro offer</p>
          </div>
        </NavLink>
      </div>

      <div className="w-full">
        <NavLink
          to="/manage-gold-ornament-types"
          activeClassName="navlinkClassName"
          className="w-full"
        >
          <div className="w-full py-4 px-6 flex items-center space-x-2">
            <GoldOutlined />
            <p>Gold ornament types</p>
          </div>
        </NavLink>
      </div>
    </div>
  );
};

export default AdminSidebar;
