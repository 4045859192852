import React from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { Tag, Button, Popconfirm, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import ErrorPage from "../../components/ErrorPage";
import ReactTable from "../../components/Table";

const GET_GOLD_ORNAMENT_TYPES = gql`
  query getGoldOrnamentTypes {
    gold_ornament_types(order_by: { created_at: desc }) {
      id
      type
      is_active
    }
  }
`;

const TOGGLE_GOLD_ORNAMENT_TYPE_STATUS = gql`
  mutation toggleGoldOrnamentStatus($id: Int!, $status: Boolean!) {
    update_gold_ornament_types_by_pk(
      pk_columns: { id: $id }
      _set: { is_active: $status }
    ) {
      id
      is_active
    }
  }
`;

const INSERT_GOLD_ORNAMENT_TYPE = gql`
  mutation insertGoldOrnamentType($type: String!) {
    insert_gold_ornament_types_one(object: { type: $type }) {
      id
      type
    }
  }
`;

const GoldOrnamentTypes = (props) => {
  const {
    loading: goldOrnamentTypesLoading,
    data: goldOrnamentTypes,
    error: goldOrnamentTypesQueryError,
  } = useQuery(GET_GOLD_ORNAMENT_TYPES);

  const [toggleGoldOrnamentTypeStatus] = useMutation(
    TOGGLE_GOLD_ORNAMENT_TYPE_STATUS,
    {
      onError(error) {
        error.graphQLErrors.forEach((error) => {
          notification.error({
            message: "Error!",
            description: "Something went wrong. Please try again later",
          });
        });
      },
      onCompleted(data) {
        notification.success({
          message: "Success!",
          description: "Gold ornament type deleted",
        });
      },
      refetchQueries: [
        {
          query: GET_GOLD_ORNAMENT_TYPES,
        },
      ],
    }
  );

  const [insertGoldOrnamentType] = useMutation(INSERT_GOLD_ORNAMENT_TYPE, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description: "Something went wrong. Please try again later",
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Gold ornament type added",
      });
      document.getElementById("gold-ornament-type-input").value = "";
    },
    refetchQueries: [
      {
        query: GET_GOLD_ORNAMENT_TYPES,
      },
    ],
  });

  if (goldOrnamentTypesQueryError) return <ErrorPage />;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      show: true,
      width: 150,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      show: true,
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      show: true,
      render: (text, data, index) => {
        return (
          <div>
            {text ? (
              <Tag color={"green"} key={text}>
                Active
              </Tag>
            ) : (
              <Tag color={"red"} key={text}>
                Inactive
              </Tag>
            )}
          </div>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "delete",
      key: "delete",
      show: true,
      render: (text, data, index) => {
        return (
          <Popconfirm
            title={`Are you sure you want to ${
              data.is_active ? "deactivate" : "activate"
            } this gold ornament type?`}
            onConfirm={() => {
              toggleGoldOrnamentTypeStatus({
                variables: {
                  id: data.id,
                  status: !data.is_active,
                },
              });
            }}
            onCancel={() => {}}
            okText="Yes"
            placement="bottomRight"
            cancelText="No"
          >
            <button
              className={`flex space-x-2 items-center ${
                !data.is_active
                  ? "hover:text-green-primary"
                  : "hover:text-red-500"
              } cursor-pointer`}
            >
              <p className="pt-1">
                {!data.is_active ? "Activate" : "Deactivate"}
              </p>
            </button>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <div className="space-y-4">
      <h2 className="text-green-primary">Gold ornament types</h2>

      <div className="flex space-x-4 items-center justify-end">
        <input
          id="gold-ornament-type-input"
          className="py-2 pl-2 pr-16"
          placeholder="Enter type"
          autoComplete="off"
        />
        <Button
          htmlType="submit"
          className=" rounded-lg flex align-center "
          type="primary"
          size={"large"}
          icon={<PlusOutlined />}
          onClick={() => {
            let value = document.getElementById("gold-ornament-type-input").value;

            if (value === "") {
              notification.error({
                message: "Error!",
                description: "Please enter the gold ornament type and try again",
              });
            } else if(!/^((?![()<>/;[\]{}'"]).)*$/gm.test(value)) {
              notification.error({
                message: "Error!",
                description: "Special Characters are not allowed",
              });
            } else {
              insertGoldOrnamentType({
                variables: {
                  type: value,
                },
              });
            }
          }}
        >
          Add gold ornament type
        </Button>
      </div>

      <ReactTable
        columns={columns}
        data={goldOrnamentTypes ? goldOrnamentTypes.gold_ornament_types : []}
        table={{
          rowKey: (record) => record.id,
        }}
        isToggleFullScreen={false}
        isToggleColumn={true}
        isMoreFilter={false}
        isSearch={false}
        loading={goldOrnamentTypesLoading}
      />
    </div>
  );
};

export default GoldOrnamentTypes;
