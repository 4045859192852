import gql from "graphql-tag";
// import { BANK_ACCOUNT_DETAILS_FRAGMENT } from "../../graphqlFragments";

const EXPORT_LOANS = gql`
  query getExportLoans(
    $where: loans_bool_exp = {}
    $order_by: [loans_order_by!] = {}
  ) {
    loans(where: $where, order_by: $order_by) {
      id
      loan_display_id
      loan_number
      tenure
      loan_status
      created_at
      partner_branch {
        partner_branch_name
        partner {
          id
          partner_name
        }
      }
      golds {
        id
        actual_net_weight
        net_weight
        gross_weight
      }
      plan {
        id
        plan_name
      }
      orocorp_approved_by {
        id
        first_name
        last_name
      }
      loan_type {
        id
        loan_type
      }
      od_amount {
        id
        credit_limit
      }
      gl_amount {
        id
        loan_amount
      }
      customer {
        id
        user {
          id
          first_name
          last_name
          name
          mobile_number
          user_bank_account_details {
            id
            bank_account_name
            bank_account_number
            bank_name
            ifsc
          }
        }
      }
      visits {
        id
        address
      }
    }
  }
`;

const GET_LOANS = gql`
  query getLoans(
    $limit: Int
    $offset: Int
    $where: loans_bool_exp = {}
    $order_by: [loans_order_by!] = {}
  ) {
    loans_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    loans(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      id
      loan_display_id
      loan_number
      created_at
      loan_type {
        id
        loan_type
      }
      tenure
      loan_status
      created_at
      orocorp_approved_by {
        id
        first_name
        last_name
      }
      od_amount {
        id
        credit_limit
      }
      gl_amount {
        id
        loan_amount
      }
      support_loan {
        id
        sl_amount {
          id
          loan_amount
        }
      }
      partner_branch {
        partner_branch_name
        partner {
          id
          partner_name
        }
      }
      customer {
        id
        user {
          id
          first_name
          last_name
          name
          kycs(
            where: { is_active: { _eq: true } }
            order_by: { id: desc }
            limit: 1
          ) {
            id
            legal_name
          }
        }
      }
      gold_pickup_visit: visits(where: { visit_type: { _eq: "GP" } }) {
        id
        intermediate_status
      }
      visits(limit: 1, order_by: { updated_at: desc }) {
        id
        intermediate_status
        visit_display_id
        agent {
          id
          agent {
            agent_display_id
            id
            first_name
            last_name
            user {
              id
              first_name
              last_name
              name
            }
          }
        }
      }
    }
  }
`;

const GET_CURRENT_INTEREST_FOR_LOAN_ID = gql`
  query getCurrentInterestForLoanID($loan_id: Int!) {
    interest: get_interest_breakup_new(loan_id: $loan_id) {
      total_interest
      total_interest_due
      loan_closure
    }
  }
`;

const GET_LOAN_BY_ID = gql`
  query getLoanById($id: Int!) {
    loans_by_pk(id: $id) {
      id
      loan_display_id
      loan_number
      created_at
      gold_valuation_document
      account_number
      customer_id_from_bank
      support_loan {
        id
        support_loan_id
        sl_amount {
          id
          loan_amount
          paid_loan_amount
        }
        partner_branch {
          id
          partner_branch_name
          partner {
            id
            partner_name
          }
        }
        plan {
          id
          plan_name
          interest
          tenure
          
        }
      }
      fund_transfers: payments(
        where: {
          transaction_type: {
            _in: ["Fund Transfer", "Support Loan Fund Transfer"]
          }
          payment_status: { _eq: "Success" }
        }
      ) {
        id
        unique_transaction_reference
        paid_amount
        created_at
        payment_type
        transaction_type
      }
      payments(
        where: {
          transaction_type: {
            _in: ["Customer Repayment", "Closure Amount Payment"]
          }
          payment_status: { _eq: "Success" }
        }
      ) {
        id
        unique_transaction_reference
        paid_amount
        principal_amount_paid
        interest_amount_paid
        cash_back
        payment_date_time
        payment_type
        transaction_type
        is_completed
        bank_interest
        principal_paid_towards_support_loan
        interest_paid_towards_support_loan
      }
      primary_loan_fund_transfer: payments_aggregate(
        where: { transaction_type: { _in: ["Fund Transfer"] } }
      ) {
        aggregate {
          count
        }
      }
      support_loan_fund_transfer: payments_aggregate(
        where: { transaction_type: { _in: ["Support Loan Fund Transfer"] } }
      ) {
        aggregate {
          count
        }
      }
      loan_disbursal: payments_aggregate(
        where: { transaction_type: { _in: ["Loan disbursal"] } }
      ) {
        aggregate {
          count
        }
      }
      loan_type {
        id
        loan_type
      }
      loan_status
      orocorp_approved_at
      loan_receipts
      kyc {
        id
        id_proof
        address_proof
        live_photo
        legal_name
      }
      orocorp_approved_by {
        id
        first_name
        last_name
      }
      od_amount {
        id
        credit_limit
      }
      gl_amount {
        id
        loan_amount
        paid_loan_amount
        eligible_loan_amount
      }
      partner_branch {
        id
        partner_branch_name
        partner {
          id
          partner_name
        }
      }
      plan {
        id
        plan_name
        scheme_name
        ltv
        tenure
        interest
        bank_interest
      }
      customer {
        id
        user {
          id
          first_name
          last_name
          name
          mobile_number
          auth_id
          email_id
        }
      }
      golds {
        id
        gold_display_id
        quality
        actual_quality
        quantity
        net_weight
        actual_net_weight
        gross_weight
        gold_type
        pickup_seal_id
        gold_images
        pickup_sealed_gold_images
        seal {
          pickup_seal_id
          pickup_sealed_gold_images
          sealed_cover_gross_weight
          pickup_seal_images
        }
      }
      gold_pickup_visit: visits(where: { visit_type: { _eq: "GP" } }) {
        id
        intermediate_status
        support_loan
        agent {
          id
          first_name
          last_name
          mobile_number
          agent {
            id
            agent_display_id
          }
        }
        secondary_field_agent {
          id
          agent {
            id
            agent_display_id
          }
          auth_id
          first_name
          last_name
          mobile_number
        }
        tertiary_field_agent {
          id
          agent {
            id
            agent_display_id
          }
          auth_id
          first_name
          last_name
          mobile_number
        }
        visit_display_id
        address
      }
      gold_release_visit: visits(where: { visit_type: { _eq: "GR" } }) {
        id
        visit_status
        agent {
          id
          first_name
          last_name
          mobile_number
          agent {
            id
            agent_display_id
          }
        }
        visit_display_id
        address
      }
      rate {
        id
        ma_rate
        gold_quality
      }
    }
  }
`;

const GET_LEDGER_LOAN_DETAILS = gql`
  query getLedgerLoanPayments(
    $loanNumber: String!
    $sl_loanNumber: String!
    $isSupportLoanAvailable: Boolean!
  ) {
    fund_transfers: ledger_loan_get_payment(
      oro_loan_number: $loanNumber
      type: "OPENING"
    ) {
      body
      code
    }
    sl_fund_transfers: ledger_loan_get_payment(
      oro_loan_number: $sl_loanNumber
      type: "OPENING"
    ) @include(if: $isSupportLoanAvailable) {
      body
      code
    }
    payments: ledger_loan_get_payment(
      oro_loan_number: $loanNumber
      type: "INSTALLMENT"
    ) {
      body
      code
    }
    sl_payments: ledger_loan_get_payment(
      oro_loan_number: $sl_loanNumber
      type: "INSTALLMENT"
    ) @include(if: $isSupportLoanAvailable) {
      body
      code
    }
    ledger_loan_details: ledger_loan_get_loan(oro_loan_number: $loanNumber) {
      body
      code
    }
    van_details: ledger_van_getvan(oro_loan_number: $loanNumber) {
      body
      code
    }
  }
`;

const APPROVE_LOAN_BY_OROCORP = gql`
  mutation approveLoanByOrocorp(
    $loan_id: Int!
    $visit_id: Int!
    $approved_at: timestamptz!
    $user: usertype!
    $loan: loantype!
  ) {
    update_loans_by_pk(
      pk_columns: { id: $loan_id }
      _set: {
        loan_status: "OROCORP_APPROVED"
        orocorp_approved_at: $approved_at
      }
    ) {
      id
      loan_status
      orocorp_approved_by {
        id
        first_name
        last_name
      }
      orocorp_approved_at
    }
    update_visit_intermediate_status(
      new_status: "OD_LOAN_APPROVED_BY_OROCORP"
      visit_id: $visit_id
    ) {
      id
      intermediate_status
    }
    ledger_loans_create(user: $user, loan: $loan) {
      code
      message
    }
  }
`;

const REJECT_LOAN_BY_OROCORP = gql`
  mutation rejectLoanByOrocorp(
    $loan_id: Int!
    $visit_id: Int!
    $approved_at: timestamptz!
  ) {
    update_loans_by_pk(
      pk_columns: { id: $loan_id }
      _set: {
        loan_status: "OROCORP_REJECTED"
        orocorp_approved_at: $approved_at
      }
    ) {
      id
      loan_status
      orocorp_approved_at
      orocorp_approved_by {
        id
        first_name
        last_name
      }
    }
    update_visit_intermediate_status(
      new_status: "OD_LOAN_REJECTED_BY_OROCORP"
      visit_id: $visit_id
    ) {
      id
      intermediate_status
    }
  }
`;

const UPDATE_PAYMENT_UTR = gql`
  mutation updatePaymentUTR($utr: String!, $oro_loan_number: String!) {
    ledger_loans_fund_transfer_update_utr(
      oro_loan_number: $oro_loan_number
      utr: $utr
    ) {
      code
      body
    }
  }
`;

export {
  GET_LOANS,
  GET_LOAN_BY_ID,
  EXPORT_LOANS,
  APPROVE_LOAN_BY_OROCORP,
  REJECT_LOAN_BY_OROCORP,
  GET_CURRENT_INTEREST_FOR_LOAN_ID,
  UPDATE_PAYMENT_UTR,
  GET_LEDGER_LOAN_DETAILS,
};
