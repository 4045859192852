// DEPS
import React, { useState } from "react";
import { Formik } from "formik";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Modal, notification, Select } from "antd";
import jwtDecode from "jwt-decode";

// Components
import GlobalButton from "../../components/Button";
import GlobalTable from "../../components/Table/UpgradedTable";
import {
  offerValidationSchema,
  pledgeCardValidationSchema,
  supportLoanValidationSchema,
} from "./validationSchema";
import { LeftOutlined } from "@ant-design/icons";
import {
  ReactInput,
  ReactRadioGroup,
  ReactSelect,
} from "../../components/Form";
import ActionButton from "../../components/Button/ActionButton";

// APIs
import { GET_AGENTS } from "../Agent/graphql";
import { GET_RATES } from "../Rate/graphql";
import { CREATE_ORO_OFFER, GET_PLAN_DETAILS } from "./graphql";

const CreateOffer = (props) => {
  const formRef = React.useRef();
  const pledgeFormRef = React.useRef();
  const slFormRef = React.useRef();

  const [pledgeModal, setPledgeModal] = useState(false);
  const [supportLoanModal, setSupportLoanModal] = useState(false);
  const [pledgeCards, setPledgeCards] = useState([]);
  const [glPlans, setGlPlans] = useState([]);
  const [slPlans, setSlPlans] = useState([]);
  const [selectedGlPlan, setSelectedGlPlan] = useState(null);
  const [pledgeEditKey, setPledgeEditKey] = useState("");
  const [planEditKey, setPlanEditKey] = useState("");
  const [pledgeCardSummary, setPledgeCardSummary] = useState([
    {
      no: 0,
      total_gross_weight: 0,
      total_stones_weight: 0,
      total_net_weight: 0,
      total_releases_amount: 0,
    },
  ]);

  const { data: agentData, loading: agentLoading } = useQuery(GET_AGENTS, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        _and: [
          {
            role_name: {
              _in: ["FIELD_AGENT", "CALL_AGENT", "APPROVER"],
            },
          },
        ],
      },
    },
  });

  const { data: rateData } = useQuery(GET_RATES, {
    fetchPolicy: "network-only",
    variables: {
      where: { is_active: { _eq: true } },
    },
  });

  const [createOroOffer] = useMutation(CREATE_ORO_OFFER, {
    onError: (error) => {
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description: "Something went wrong!",
        });
      });
    },
    onCompleted: (data) => {
      notification.success({
        message: "Success!",
        description: "Offer created successfully!",
      });
      props.history.push(`/oro-offer/view/${data.insert_oro_offers_one.id}`);
    },
  });

  const [getPlans] = useLazyQuery(GET_PLAN_DETAILS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      let filteredPlans = [];
      data.all_gl_plans.forEach((plan, i) => {
        let eligible_amount = Math.round(
          pledgeCardSummary[0].total_net_weight *
          (plan.ltv / 100) *
          rateData?.rates[0]?.ma_rate
        )
        if ((plan.minimum_amount <= eligible_amount) && (plan.maximum_amount >= eligible_amount)) {
          filteredPlans.push(plan);
        }
      });
      let glPlans = filteredPlans.map((plan, i) => {
        let eligible_amount = Math.round(
          pledgeCardSummary[0].total_net_weight *
          (plan.ltv / 100) *
          rateData?.rates[0]?.ma_rate
        )
        let sl_copy = [...data.all_sl_plans];
        let sl = sl_copy.sort(
          (a, b) =>
            Math.abs(plan.interest - a.interest) -
            Math.abs(plan.interest - b.interest)
        )[0];
        return({
          gl_plan_name: plan.plan_name,
          gl_plan_type: plan.plan_type,
          gl_eligible_amount: eligible_amount,
          gl_interest: plan.interest,
          gl_tenure: plan.tenure,
          gl_ltv: plan.ltv,
          sl_plan_name: sl.plan_name,
          sl_plan_type: sl.plan_type,
          sl_eligible_amount: Math.round(eligible_amount * (i === 0 ? 0.16 : 0.18)),
          sl_ltv: sl.ltv,
          sl_interest: sl.interest,
          sl_tenure: sl.tenure,
          total_eligible_amount: Math.round(eligible_amount * (i === 0 ? 1.16 : 1.18)),
          net_ltv: parseFloat(
            ((eligible_amount * (i === 0 ? 1.16 : 1.18)) /
              (rateData?.rates[0]?.ma_rate *
                pledgeCardSummary[0].total_net_weight)) *
            100
          ).toFixed(2),
          total_interest: parseFloat(
            (Number(plan.maximum_amount) * Number(plan.interest) +
              sl.maximum_amount * Number(sl.interest)) /
            (plan.maximum_amount + sl.maximum_amount)
          ).toFixed(2),
        });
      });
      setGlPlans(glPlans);
      setSlPlans(data.all_sl_plans);
      formRef.current.setFieldValue("offer_plans", glPlans);
    },
  });

  const handleSubmit = (values) => {
    createOroOffer({
      variables: {
        object: {
          ...values,
          gold_rate: rateData?.rates[0]?.ma_rate,
          created_by: jwtDecode(localStorage.getItem("token")).user_id,
          offers_pledge_cards: {
            data: [...values.offers_pledge_cards],
          },
          offer_plans: {
            data: [
              ...values.offer_plans.map((plan) => ({
                ...plan,
                gl_interest: `${plan.gl_interest}`,
                gl_eligible_amount: `${plan.gl_eligible_amount}`,
              })),
            ],
          },
        },
      },
    });
  };

  const handlePledgeCardSubmit = (values) => {
    if (pledgeEditKey !== "") {
      let cards = [...pledgeCards];
      cards.splice(pledgeEditKey, 1, { ...values });
      setPledgeCards(cards);
      formRef.current.setFieldValue("offers_pledge_cards", cards);
      setPledgeModal(false);
      setPledgeEditKey("");
      pledgeFormRef.current.handleReset();
    } else {
      setPledgeCards([...pledgeCards, { ...values }]);
      formRef.current.setFieldValue("offers_pledge_cards", [
        ...pledgeCards,
        { ...values },
      ]);
      setPledgeModal(false);
      pledgeFormRef.current.handleReset();
    }
  };

  React.useEffect(() => {
    if (pledgeCards.length === 0) {
      setPledgeCardSummary([
        {
          no: 0,
          total_gross_weight: 0,
          total_stones_weight: 0,
          total_net_weight: 0,
          total_releases_amount: 0,
        },
      ]);
    } else {
      setPledgeCardSummary([
        {
          no: pledgeCards.length,
          total_gross_weight: pledgeCards.reduce(
            (sum, val) => sum + Number(val.gross_weight),
            0
          ),
          total_stones_weight: pledgeCards.reduce(
            (sum, val) => sum + Number(val.stone_deduction),
            0
          ),
          total_net_weight: pledgeCards.reduce(
            (sum, val) => sum + Number(val.net_weight),
            0
          ),
          total_releases_amount: pledgeCards.reduce(
            (sum, val) => sum + Number(val.release_amount),
            0
          ),
        },
      ]);

      getPlans({
        variables: {
          amount: pledgeCards.reduce(
            (sum, val) => sum + Number(val.release_amount),
            0
          ),
        },
      });
    }
    // eslint-disable-next-line
  }, [pledgeCards]);

  const handleSupportLoanSubmit = (values) => {
    let filteredValue = slPlans.filter(
      (plan) => plan.plan_name === values.sl_plan_name
    )[0];
    let plans = [...glPlans];
    let obj = {
      ...plans[planEditKey],
      sl_plan_name: filteredValue.plan_name,
      sl_plan_type: filteredValue.plan_type,
      sl_eligible_amount: Number(values.sl_eligible_amount),
      sl_ltv: filteredValue.ltv,
      sl_interest: values.sl_interest,
      sl_tenure: filteredValue.tenure,
      total_eligible_amount: Math.round(
        pledgeCardSummary[0].total_net_weight *
          (filteredValue.ltv / 100) *
          rateData?.rates[0]?.ma_rate +
          Number(values.sl_eligible_amount)
      ),
      net_ltv: parseFloat(
        ((pledgeCardSummary[0].total_net_weight *
          (filteredValue.ltv / 100) *
          rateData?.rates[0]?.ma_rate +
          Number(values.sl_eligible_amount)) /
          (rateData?.rates[0]?.ma_rate *
            pledgeCardSummary[0].total_net_weight)) *
          100
      ).toFixed(2),
      total_interest: parseFloat(
        (Number(filteredValue.maximum_amount) * Number(filteredValue.interest) +
          Number(values.sl_eligible_amount) * Number(values.sl_interest)) /
          (filteredValue.maximum_amount + Number(values.sl_eligible_amount))
      ).toFixed(2),
    };
    plans.splice(planEditKey, 1, { ...obj });
    setGlPlans(plans);
    formRef.current.setFieldValue("offer_plans", plans);
    setSupportLoanModal(false);
    setPlanEditKey("");
    slFormRef.current.handleReset();
  };

  const handleSupportLoanDelete = (index) => {
    let plans = [...glPlans];
    let obj = {
      ...plans[index],
      sl_plan_name: "",
      sl_plan_type: "",
      sl_eligible_amount: "",
      sl_ltv: "",
      sl_interest: "",
      sl_tenure: "",
      total_eligible_amount: Number(plans[index].gl_eligible_amount),
      net_ltv: Number(plans[index].gl_ltv),
      total_interest: Number(plans[index].gl_interest),
    };
    plans.splice(index, 1, { ...obj });
    setGlPlans(plans);
    formRef.current.setFieldValue("offer_plans", plans);
  };

  const pledgeCardColumns = [
    {
      id: 1,
      key: "pledge_card_identifier",
      title: "Pledge car no.",
      dataIndex: "pledge_card_identifier",
      width: "10%",
    },
    {
      id: 2,
      key: "gross_weight",
      title: "Gross weight",
      dataIndex: "gross_weight",
      width: "10%",
      render: (text) => `${text} gms`,
    },
    {
      id: 3,
      key: "stone_deduction",
      title: "Stones/Deductions",
      dataIndex: "stone_deduction",
      width: "10%",
      render: (text) => `${text} gms`,
    },
    {
      id: 4,
      key: "net_weight",
      title: "Net weight",
      dataIndex: "net_weight",
      width: "10%",
      render: (text) => `${text} gms`,
    },
    {
      id: 5,
      key: "release_amount",
      title: "Release amount",
      dataIndex: "release_amount",
      width: "10%",
      render: (text) => `₹ ${text}`,
    },
    {
      id: 6,
      key: "actions",
      title: "Actions",
      render: (text, record, index) => (
        <div className="flex items-center">
          <ActionButton
            label="Edit"
            type="secondary"
            handleClick={() => {
              setPledgeModal(true);
              setPledgeEditKey(index);
              pledgeFormRef.current.setValues({ ...record });
            }}
          />
          <ActionButton
            label="Delete"
            type="danger"
            handleClick={() => {
              let cards = [...pledgeCards];
              cards.splice(index, 1);
              setPledgeCards(cards);
              formRef.current.setFieldValue("offers_pledge_cards", cards);
            }}
          />
        </div>
      ),
      width: "5%",
    },
  ];

  const existingLoanColumns = [
    {
      id: 1,
      key: "no",
      title: "No. of pledge cards",
      dataIndex: "no",
      width: "10%",
    },
    {
      id: 2,
      key: "total_gross_weight",
      title: "Total gross weight",
      dataIndex: "total_gross_weight",
      width: "10%",
      render: (text) => `${text} gms`,
    },
    {
      id: 3,
      key: "total_stones_weight",
      title: "Stones/Deductions",
      dataIndex: "total_stones_weight",
      width: "10%",
      render: (text) => `${text} gms`,
    },
    {
      id: 4,
      key: "total_net_weight",
      title: "Total Net weight",
      dataIndex: "total_net_weight",
      width: "10%",
      render: (text) => `${text} gms`,
    },
    {
      id: 5,
      key: "total_releases_amount",
      title: "Total Release amount",
      dataIndex: "total_releases_amount",
      width: "10%",
      render: (text) => `₹ ${text}`,
    },
  ];

  const planColumns = [
    {
      id: 1,
      key: "gl_plan_name",
      title: "Plan Name / type",
      dataIndex: "gl_plan_name",
      width: "12%",
    },
    {
      id: 2,
      key: "gl_eligible_amount",
      title: "Eligible GL amount",
      dataIndex: "gl_eligible_amount",
      width: "10%",
      render: (text) => `₹ ${text}`,
    },
    {
      id: 3,
      key: "gl_interest",
      title: "Interest % p.a / p.m",
      dataIndex: "gl_interest",
      width: "10%",
      render: (text) =>
        `${text}% p.a / ${parseFloat(Number(text) / 12).toFixed(1)}% p.m`,
    },
    {
      id: 4,
      key: "gl_tenure",
      title: "Tenure",
      dataIndex: "gl_tenure",
      width: "7%",
      render: (text) => `${text} months`,
    },
    {
      id: 5,
      key: "gl_ltv",
      title: "LTV %",
      dataIndex: "gl_ltv",
      width: "5%",
      render: (text) => `${text}%`,
    },
  ];

  const slPlanColumns = [
    {
      id: 1,
      key: "sl_plan_name",
      title: "Plan Name / type",
      dataIndex: "sl_plan_name",
      width: "12%",
    },
    {
      id: 2,
      key: "sl_eligible_amount",
      title: "Eligible SL amount",
      dataIndex: "sl_eligible_amount",
      width: "10%",
      render: (text) => `₹ ${text}`,
    },
    {
      id: 3,
      key: "sl_interest",
      title: "Interest % p.a / p.m",
      dataIndex: "sl_interest",
      width: "10%",
      render: (text) =>
        `${text}% p.a / ${parseFloat(Number(text) / 12).toFixed(1)}% p.m`,
    },
    {
      id: 4,
      key: "sl_tenure",
      title: "Tenure",
      dataIndex: "sl_tenure",
      width: "7%",
      render: (text) => `${text} months`,
    },
    {
      id: 5,
      key: "sl_ltv",
      title: "LTV %",
      dataIndex: "sl_ltv",
      width: "5%",
      render: (text) => `${text}%`,
    },
  ];

  const planSummaryColumns = [
    {
      id: 1,
      key: "total_eligible_amount",
      title: "Max Eligible amount",
      dataIndex: "total_eligible_amount",
      width: "12%",
      render: (text) => `₹ ${text}`,
    },
    {
      id: 2,
      key: "net_ltv",
      title: "Net LTV %",
      dataIndex: "net_ltv",
      width: "10%",
      render: (text) => `${text}%`,
    },
    {
      id: 3,
      key: "total_interest",
      title: "Interest % p.a / p.m",
      dataIndex: "total_interest",
      width: "10%",
      render: (text) =>
        `${text}% p.a / ${parseFloat(Number(text) / 12).toFixed(1)}% p.m`,
    },
    {
      id: 4,
      key: "total_eligible_amount",
      title: "Total Release amount",
      dataIndex: "total_eligible_amount",
      width: "7%",
      render: (text) => `₹ ${pledgeCardSummary[0].total_releases_amount}`,
    },
    {
      id: 5,
      key: "total_eligible_amount",
      title: "Excess/Difference",
      dataIndex: "total_eligible_amount",
      width: "5%",
      render: (text, record) => {
        let value =
          Number(record.total_eligible_amount) -
          pledgeCardSummary[0].total_releases_amount;
        return `${value < 0 ? "- " : ""}₹ ${parseFloat(Math.abs(value)).toFixed(
          2
        )}`;
      },
    },
  ];

  return (
    <div>
      <Formik
        enableReinitialize={false}
        validateOnChange={false}
        validateOnBlur={false}
        innerRef={formRef}
        onSubmit={handleSubmit}
        initialValues={{
          lender_type: "",
          lender_name: "",
          release_amount_known: true,
          customer_name: "",
          customer_mobile_number: "",
          agent_auth_id: "",
          comments: "",
          offers_pledge_cards: [],
          offer_plans: [],
        }}
        validationSchema={offerValidationSchema}
      >
        {({ errors, touched, handleSubmit }) => (
          <div className="space-y-4">
            <Button
              className="text-black font-extrabold"
              type="link"
              icon={<LeftOutlined />}
              onClick={() => {
                props.history.push("/oro-offer");
              }}
            >
              All Oro offers
            </Button>
            <div className="px-4">
              <h3 className="w-full text-left text-lg font-bold text-black py-2">
                Generate a new Oro offer
              </h3>
              <div className="w-full flex flex-col items-center my-4">
                <div className="w-full upgraded-secondary-bg py-2">
                  <h4 className="w-full text-center uppercase text-md font-bold">
                    EXISTING LOAN DETAILS
                  </h4>
                </div>
                <div className="w-full bg-white py-3">
                  <div className="w-full px-2 lg:px-8 flex flex-col items-center">
                    <div className="w-full flex flex-col lg:flex-row items-center justify-between">
                      <div className="w-1/3 pr-6">
                        <ReactInput
                          name="lender_type"
                          type="text"
                          label="Lender type"
                          placeholder="Enter Lender type"
                        />
                      </div>
                      <div className="w-1/3 pr-6">
                        <ReactInput
                          name="lender_name"
                          type="text"
                          label="Create lender name"
                          placeholder="Eg: Muthoot Finance"
                        />
                      </div>
                      <div className="w-1/3">
                        <ReactRadioGroup
                          name="release_amount_known"
                          label="Release amount known?"
                          options={[
                            { label: "Yes", value: true },
                            { label: "No", value: false },
                          ]}
                          defaultValue={true}
                        />
                      </div>
                    </div>
                    <div className="w-full flex flex-col lg:flex-row items-center justify-between py-4">
                      <div className="w-1/3 pr-6">
                        <ReactInput
                          name="customer_name"
                          type="text"
                          label="Customer name"
                          placeholder="Enter full name"
                        />
                      </div>
                      <div className="w-1/3 pr-6">
                        <ReactInput
                          name="customer_mobile_number"
                          type="text"
                          label="Customer mobile number"
                          placeholder="Enter mobile number"
                        />
                      </div>
                      <div className="w-1/3">
                        <ReactSelect
                          name="agent_auth_id"
                          type="text"
                          label="Choose agent contact"
                          placeholder="Choose an agent contact"
                          loading={agentLoading}
                        >
                          {agentData?.users?.map((agent, i) => (
                            <Select.Option key={i} value={agent.auth_id}>
                              {`${agent.first_name || ""} ${
                                agent.last_name || ""
                              }`}
                            </Select.Option>
                          ))}
                        </ReactSelect>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col items-center mt-4">
                <div className="w-full upgraded-secondary-bg py-2">
                  <h4 className="w-full text-center uppercase text-md font-bold">
                    ADD AND MANAGE PLEDGE CARDS
                  </h4>
                </div>
                <div className="w-full bg-white py-3">
                  <div className="w-full py-4 px-4">
                    <GlobalTable
                      columns={pledgeCardColumns}
                      data={pledgeCards}
                      bordered={true}
                    />
                  </div>
                </div>
                <GlobalButton
                  handleClick={() => setPledgeModal(true)}
                  className="relative bottom-4"
                  label="Add a new pledge card"
                />
              </div>
              <div style={{ minHeight: 21 }} className="mb-4">
                {touched["offers_pledge_cards"] &&
                errors["offers_pledge_cards"] ? (
                  <div className="error text-red-500">
                    {errors["offers_pledge_cards"]}
                  </div>
                ) : null}
              </div>
              <p className="w-full text-left text-md font-bold py-1">
                EXISTING LOAN SUMMARY
              </p>
              <GlobalTable
                columns={existingLoanColumns}
                data={pledgeCardSummary}
                bordered={true}
                type="primary"
              />
              <div className="w-full flex flex-col items-center mt-4">
                <div className="w-full upgraded-secondary-bg py-2">
                  <h4 className="w-full text-center uppercase text-md font-bold">
                    PLAN CONFIGURATION: SELECT APPLICABLE GL PLANS AND ADD SL
                  </h4>
                </div>
                <div className="w-full py-3">
                  {glPlans.map((glPlan, i) => (
                    <React.Fragment key={i}>
                      <p className="w-full text-left text-md font-bold py-1 mt-4">
                        APPLICABLE GOLD LOAN PLAN {i + 1}
                      </p>
                      <GlobalTable
                        rowSelection={{
                          type: "checkbox",
                          columnWidth: "5%",
                          onSelect: (record, selected, selectedRows) => {
                            console.log(record, selected, selectedRows);
                          },
                        }}
                        columns={planColumns}
                        data={[{ ...glPlan }]}
                        bordered={true}
                        rowKey={(record) => i}
                      />
                      {Boolean(
                        glPlan.sl_plan_name !== "" ||
                          glPlan.sl_plan_type !== "" ||
                          glPlan.sl_eligible_amount !== "" ||
                          glPlan.sl_interest !== "" ||
                          glPlan.sl_tenure !== "" ||
                          glPlan.sl_ltv !== ""
                      ) ? (
                        <React.Fragment>
                          <div className="mt-4">
                            <p className="w-full text-left text-md font-bold py-1">
                              SUPPORT LOAN FOR GOLD LOAN PLAN {i + 1}
                            </p>
                            <GlobalTable
                              columns={[
                                ...slPlanColumns,
                                {
                                  id: 6,
                                  key: "actions",
                                  title: "Actions",
                                  render: (text, record, index) => (
                                    <div className="flex items-center">
                                      <ActionButton
                                        label="Edit"
                                        type="secondary"
                                        handleClick={() => {
                                          setSupportLoanModal(true);
                                          setSelectedGlPlan(glPlan);
                                          setPlanEditKey(i);
                                          slFormRef.current.setValues({
                                            sl_plan_name: record.sl_plan_name,
                                            sl_interest: record.sl_interest,
                                            sl_eligible_amount:
                                              record.sl_eligible_amount,
                                          });
                                        }}
                                      />
                                      <ActionButton
                                        label="Delete"
                                        type="danger"
                                        handleClick={() => {
                                          handleSupportLoanDelete(i);
                                        }}
                                      />
                                    </div>
                                  ),
                                  width: "5%",
                                },
                              ]}
                              data={[{ ...glPlan }]}
                              bordered={true}
                              rowKey={(record) => i}
                            />
                          </div>
                          <div className="mt-4">
                            <p className="w-full text-left text-md font-bold py-1">
                              OFFER {i + 1} SUMMARY - GL+SL
                            </p>
                            <GlobalTable
                              columns={planSummaryColumns}
                              data={[{ ...glPlan }]}
                              bordered={true}
                              rowKey={(record) => i}
                              type="primary"
                            />
                          </div>
                        </React.Fragment>
                      ) : (
                        <GlobalButton
                          handleClick={() => {
                            setSupportLoanModal(true);
                            setSelectedGlPlan(glPlan);
                            setPlanEditKey(i);
                          }}
                          className="mt-4"
                          label="Add support loan"
                        />
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <div className="my-3 flex flex-col items-center">
                <div className="w-full">
                  <ReactInput
                    name="comments"
                    type="text"
                    label="Add comments or remarks, if any"
                    placeholder="Eg: Purity detection as per NBFC policy"
                  />
                </div>
              </div>
              <div className="w-full flex flex-row items-center justify-center my-4">
                <GlobalButton
                  className="px-6"
                  label="Cancel"
                  type="danger"
                  handleClick={() => {
                    props.history.push("/oro-offer");
                  }}
                />
                <GlobalButton
                  handleClick={() => {
                    handleSubmit();
                  }}
                  className="px-6 mx-3"
                  label="Generate Oro Offer"
                  type="primary"
                  buttonProps={{
                    htmlType: "submit",
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>

      {/* Pledge Card Modal */}
      <Modal
        footer={null}
        width={800}
        centered={true}
        title="Add a new pledge card"
        visible={pledgeModal}
      >
        <Formik
          enableReinitialize={true}
          validateOnChange={false}
          validateOnBlur={false}
          innerRef={pledgeFormRef}
          onSubmit={handlePledgeCardSubmit}
          initialValues={{
            gross_weight: "",
            stone_deduction: "",
            net_weight: "",
            pledge_card_identifier: "",
            release_amount: "",
            pledge_card_url: "",
          }}
          validationSchema={pledgeCardValidationSchema}
        >
          {({ handleReset, handleSubmit }) => (
            <React.Fragment>
              <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4">
                <div className="w-full pr-1">
                  <ReactInput
                    name="gross_weight"
                    type="text"
                    label="Gross weight (grams)"
                    placeholder="Enter total gross weight"
                  />
                </div>
                <div className="w-full pr-1">
                  <ReactInput
                    name="stone_deduction"
                    type="text"
                    label="Stones / Deduction (grams)"
                    placeholder="Please leave empty if NA"
                  />
                </div>
                <div className="w-full">
                  <ReactInput
                    name="net_weight"
                    type="text"
                    label="Net weight (grams)"
                    placeholder="Enter Net weight"
                  />
                </div>
                <div className="w-full pr-1">
                  <ReactInput
                    name="pledge_card_identifier"
                    type="text"
                    label="Pledge card number"
                    placeholder="Enter Pledge card number"
                  />
                </div>
                <div className="w-full pr-1">
                  <ReactInput
                    name="release_amount"
                    type="text"
                    label="Release amount"
                    placeholder="Enter release amount"
                    prefix={<span>₹</span>}
                  />
                </div>
                <div className="w-full pr-1">
                  <ReactInput
                    name="pledge_card_url"
                    type="text"
                    label="Pledge card photo link"
                    placeholder="Paste google drive link"
                  />
                </div>
              </div>
              <div className="flex flex-row items-center my-4">
                <GlobalButton
                  handleClick={() => {
                    handleSubmit();
                  }}
                  label={
                    pledgeEditKey === ""
                      ? "Add pledge card"
                      : "Save pledge card"
                  }
                  type="success"
                  buttonProps={{ type: "submit" }}
                />
                <GlobalButton
                  handleClick={() => {
                    handleReset();
                    setPledgeModal(false);
                  }}
                  className="mx-3 px-5"
                  label="Cancel"
                  type="danger"
                />
              </div>
            </React.Fragment>
          )}
        </Formik>
      </Modal>

      {/* Support Loan Modal */}
      <Modal
        footer={null}
        width={1000}
        centered={true}
        title="Add support loan"
        visible={supportLoanModal}
      >
        <Formik
          enableReinitialize={false}
          validateOnChange={false}
          validateOnBlur={false}
          innerRef={slFormRef}
          onSubmit={handleSupportLoanSubmit}
          initialValues={{
            sl_plan_name: "",
            sl_interest: "",
            sl_eligible_amount: "",
          }}
          validationSchema={supportLoanValidationSchema}
        >
          {({ handleReset, handleSubmit }) => (
            <React.Fragment>
              <div className="w-full flex flex-col items-center">
                <h4 className="w-full text-md text-center py-2 font-bold">
                  APPLICABLE GOLD LOAN PLAN
                </h4>
                <GlobalTable
                  columns={planColumns}
                  data={selectedGlPlan ? [{ ...selectedGlPlan }] : []}
                  bordered={true}
                />
                <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4 mt-6">
                  <div className="w-full pr-1">
                    <ReactSelect
                      name="sl_plan_name"
                      type="text"
                      label="Select SL Plan"
                      placeholder="Choose SL Plan"
                    >
                      {slPlans.map((slPlan, i) => (
                        <Select.Option key={i} value={slPlan.plan_name}>
                          {slPlan.plan_name}
                        </Select.Option>
                      ))}
                    </ReactSelect>
                  </div>
                  <div className="w-full pr-1">
                    <ReactInput
                      name="sl_interest"
                      type="text"
                      label="Enter SL topup %"
                      placeholder="Enter topup amount"
                      prefix={<span>%</span>}
                    />
                  </div>
                  <div className="w-full">
                    <ReactInput
                      name="sl_eligible_amount"
                      type="text"
                      label="Enter SL amount"
                      placeholder="Enter SL amount"
                      prefix={<span>₹</span>}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center justify-center my-4 ml-4">
                <GlobalButton
                  handleClick={() => handleSubmit()}
                  label="Add support loan"
                  type="success"
                />
                <GlobalButton
                  handleClick={() => {
                    handleReset();
                    setSupportLoanModal(false);
                  }}
                  className="mx-3 px-5"
                  label="Cancel"
                  type="danger"
                />
              </div>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default CreateOffer;
