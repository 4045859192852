import { TimePicker, Checkbox } from "antd";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import "./form.css";

const { RangePicker } = TimePicker;

const ReactWorkingHourspicker = (props) => {
  const { values, setFieldValue, errors } = useFormikContext();

  return (
    <div className="text-left w-full space-y-4">
      <div className="w-1/2 grid grid-cols-3 gap-4">
        <h2>Day of the week</h2>
        <h2>Open / Close time</h2>
        <h2>Holiday?</h2>
      </div>
      {Object.keys(values.hours).map((day) => {
        return (
          <div className="w-1/2 grid grid-cols-3 gap-4">
            <Checkbox
              checked={values.isChecked[day]}
              defaultChecked={values.isChecked[day]}
              onChange={() => {
                let temp = values.isChecked;
                temp[day] = !temp[day];
                setFieldValue("isChecked", temp);
              }}
            >
              {day}
            </Checkbox>

            <div className="w-3/4">
              <RangePicker
                format="HH:mm"
                value={[values.hours[day]["start"], values.hours[day]["end"]]}
                disabled={!values.isChecked[day]}
                onChange={(dates, dateStrings) => {
                  let temp;

                  Object.keys(values.hours).forEach((mapped_day) => {
                    temp = values.hours;

                    if (values.isChecked[`${mapped_day}`]) {
                      temp[`${mapped_day}`]["start"] = dates[0];
                      temp[`${mapped_day}`]["end"] = dates[1];
                    }
                  });

                  setFieldValue("hours", temp);
                }}
              />
            </div>

            <Checkbox
              className="flex items-center"
              checked={values.isHoliday[day]}
              defaultChecked={values.isHoliday[day]}
              disabled={!values.isChecked[day]}
              onChange={() => {
                let tempHols = values.isHoliday;
                let tempHours;

                Object.keys(values.hours).forEach((mapped_day) => {
                  tempHours = values.hours;

                  if (values.isChecked[`${mapped_day}`]) {
                    tempHours[`${mapped_day}`] = {};
                    tempHols[mapped_day] = !tempHols[mapped_day];
                  }
                });

                setFieldValue("isHoliday", tempHols);
                setFieldValue("hours", tempHours);
              }}
            >
              Holiday
            </Checkbox>
          </div>
        );
      })}

      <div style={{ minHeight: 21 }}>
        {errors[props.name] ? (
          <div className="error text-red-500">{errors[props.name]}</div>
        ) : null}
      </div>
    </div>
  );
};

ReactWorkingHourspicker.propTypes = {
  label: PropTypes.string,
};
export default ReactWorkingHourspicker;
