// DEPS
import * as React from "react";
import { Form, Formik } from "formik";
import { Select, Popconfirm, notification, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import jwt_decode from "jwt-decode";

// COMPONENTS
import {ReactInput, ReactSelect, ReactSelectWithBtn, ImageUpload} from "../../components/Form";
import { agentValidationSchema } from "./validationSchema.js";

// API
import { CREATE_AGENT } from "./graphql";

const { Option } = Select;

export default function CreateAgent(props) {
  const formRef = React.useRef();

  const handleSubmit = (values) => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const [createAgent] = useMutation(CREATE_AGENT, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        // if (
        //   error.message === "Email or mobile number already in use!" ||
        //   error.message === "Phone number already in use!"
        // ) {
        //   notification.error({
        //     message: "Error!",
        //     description: "Phone number already in use!",
        //   });
        // } else {
        //   notification.error({
        //     message: "Error!",
        //     description: "Something went wrong!",
        //   });
        // }
        if (error.message === "Agent exists with this phone number")
          notification.success({
            message: "Error!",
            description: error.message,
          });
        else
          notification.error({
            message: "Error!",
            description: "Something went wrong!",
          });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Agent created",
      });

      props.history.push(`/agents/`);
    },
  });

  // const { data: latestUser, error: latestUserQueryError } = useQuery(
  //   GET_LATEST_USER_ID
  // );

  // if (latestUserQueryError) {
  //   return <ErrorPage />;
  // }

  return (
    <div className="space-y-4">
      <Button
        type="link"
        icon={<LeftOutlined />}
        onClick={() => {
          props.history.push("/agents");
        }}
      >
        All agents
      </Button>
      <div className="flex justify-between">
        <h1> Create new agent</h1>
      </div>

      <Formik
        enableReinitialize={false}
        validateOnChange={false}
        validateOnBlur={false}
        innerRef={formRef}
        onSubmit={async (values, { resetForm }) => {
          if (values.id_proof_1.type === values.id_proof_2.type) {
            notification.error({
              message: "Error!",
              description: "Please choose two different ID proofs",
            });
          } else if (
            values.address_proof_1.type === values.address_proof_2.type
          ) {
            notification.error({
              message: "Error!",
              description: "Please choose two different address proofs",
            });
          } else {
            notification.info({
              message: "Please wait!",
              description: "Agent is being created...",
            });

            createAgent({
              variables: {
                address: values.address,
                address_proof: [values.address_proof_1, values.address_proof_2],
                id_proof: [values.id_proof_1, values.id_proof_2],
                first_name: values.secondary_contact_first_name,
                last_name: values.secondary_contact_last_name,
                relation: values.secondary_contact_relation,
                secondary_address: values.secondary_contact_address,
                secondary_mobile_number: `+91${values.secondary_contact_mobile}`,
                created_by_auth_id: jwt_decode(localStorage.getItem("token")).user_id,
                id_card_photos: values.id_card_photos,
                user: {
                  first_name: values.first_name,
                  last_name: values.last_name,
                  email_id: values.email,
                  role_name: values.agent_type === "field_agent" ? "FIELD_AGENT" : values.agent_type === "call_agent" ? "CALL_AGENT" : values.agent_type === "approver" ? "APPROVER" : null,
                  mobile_number: `+91${values.mobile}`,
                  profile_picture: values.agent_photo,
                  user_bank_account_details: {
                    bank_account_name: values.bank_account_name,
                    bank_account_number: values.bank_account_number,
                    ifsc: values.bank_ifsc_code,
                    bank_name: values.bank_name,
                    bank_branch: values.bank_branch,
                    bank_proof: [values.bank_account_proof_1],
                  },
                },
              },
            });
          }
        }}
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          mobile: "",
          agent_type: "",
          address: {
            addr_line_1: "",
            addr_line_2: "",
            city: "",
            state: "",
            pincode: "",
            country: "India",
            label: "Home",
            preferred: "yes",
          },
          id_proof_1: { type: "", image_url: [] },
          id_proof_2: { type: "", image_url: [] },
          address_proof_1: { type: "", image_url: [] },
          address_proof_2: { type: "", image_url: [] },
          id_card_photos: [],
          secondary_contact_first_name: "",
          secondary_contact_last_name: "",
          secondary_contact_mobile: "",
          secondary_contact_relation: "",
          secondary_contact_address: {
            addr_line_1: "",
            addr_line_2: "",
            city: "",
            state: "",
            pincode: "",
            country: "India",
            label: "Home",
            preferred: "yes",
          },
          bank_account_name: "",
          bank_account_number: "",
          bank_ifsc_code: "",
          bank_name: "",
          bank_branch: "",
          bank_account_proof_1: { type: "", image_url: [] },
          agent_photo: "",
        }}
        validationSchema={agentValidationSchema}
        formCls="flex flex-col content-center justify-center space-y-8"
      >
        {({ values, setFieldValue }) => (
          <Form>
            {/* Agent ID div*/}
            <div className="border rounded bg-white">
              {/* Agent ID header */}
              <div className="border-b w-full px-4 py-2 bg-green-primary">
                <h2 className="text-white">Agent ID</h2>
              </div>
              {/* Agent ID header */}

              {/* Agent ID form */}
              <div className="p-4 space-y-4" style={{ marginBottom: "30px" }}>
                {/* Agent ID form - line 1*/}
                <div className="flex space-x-8">
                  <div className="w-1/3">
                    <ReactInput
                      name="first_name"
                      type="text"
                      label="First Name"
                      placeholder=""
                    />
                  </div>

                  <div className="w-2/3 flex space-x-8">
                    <div className="w-1/2">
                      <ReactInput
                        name="last_name"
                        type="text"
                        label="Last Name"
                        placeholder=""
                      />
                    </div>

                    <div className="w-1/2">
                      <ReactInput
                        name="email"
                        type="text"
                        label="Email"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                {/* Agent ID form - line 1*/}

                {/* Agent ID form - line 2*/}
                <div className="flex space-x-8">
                  <div className="w-1/3">
                    <ReactInput
                      name="mobile"
                      type="text"
                      label="Mobile number"
                      placeholder=""
                    />
                  </div>

                  <div className="w-2/3 space-y-2">
                    <label className="text-gray-800 font-semibold text-lg">
                      ID Proof
                    </label>
                    <div className="flex w-full space-x-8 items-center">
                      <div className="w-1/2">
                        <ReactSelectWithBtn
                          type="text"
                          placeholder="Select ID proof 1"
                          name="id_proof_1"
                        >
                          <Option value="Aadhar card">Aadhar card</Option>
                          <Option value="Passport">Passport</Option>
                          <Option value="Voter ID">Voters ID</Option>
                          <Option value="License">Driving License</Option>
                        </ReactSelectWithBtn>
                      </div>

                      <div className="w-1/2">
                        <ReactSelectWithBtn
                          type="text"
                          placeholder="Select ID proof 2"
                          name="id_proof_2"
                        >
                          <Option value="Aadhar card">Aadhar card</Option>
                          <Option value="Passport">Passport</Option>
                          <Option value="Voter ID">Voters ID</Option>
                          <Option value="License">Driving License</Option>
                        </ReactSelectWithBtn>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Agent ID form - line 2*/}
              </div>
              {/* Agent ID form */}
            </div>
            {/* Agent ID div*/}

            {/* Agent address div*/}
            <div className="border rounded bg-white">
              {/* Agent address header */}
              <div className="border-b w-full px-4 py-2 bg-green-primary">
                <h2 className="text-white">Agent address</h2>
              </div>
              {/* Agent address header */}

              {/* Agent address form */}
              <div className="p-4 space-y-4" style={{ marginBottom: "30px" }}>
                {/* Agent address form - line 1 */}
                <div className="space-y-2">
                  <p className="text-gray-800 font-semibold text-lg">Address</p>
                  <div className="w-2/3">
                    <ReactInput
                      name="address.addr_line_1"
                      type="text"
                      placeholder="Address line 1"
                    />
                  </div>
                  <div className="w-2/3">
                    <ReactInput
                      name="address.addr_line_2"
                      type="text"
                      placeholder="Address line 2"
                    />
                  </div>
                  <div className="w-2/3 flex space-x-8">
                    <div className="w-1/3">
                      <ReactInput
                        name="address.city"
                        type="text"
                        placeholder="City"
                      />
                    </div>

                    <div className="w-1/3">
                      <ReactSelect
                        name="address.state"
                        type="text"
                        placeholder="State"
                      >
                        <Option value="Andhra Pradesh">Andhra Pradesh</Option>
                        <Option value="Andaman and Nicobar Islands">
                          Andaman and Nicobar Islands
                        </Option>
                        <Option value="Arunachal Pradesh">
                          Arunachal Pradesh
                        </Option>
                        <Option value="Assam">Assam</Option>
                        <Option value="Bihar">Bihar</Option>
                        <Option value="Chandigarh">Chandigarh</Option>
                        <Option value="Chhattisgarh">Chhattisgarh</Option>
                        <Option value="Dadra and Nagar Haveli and Daman and Diu">
                          Dadra and Nagar Haveli and Daman and Diu
                        </Option>
                        <Option value="Delhi">Delhi</Option>
                        <Option value="Goa">Goa</Option>
                        <Option value="Gujarat">Gujarat</Option>
                        <Option value="Haryana">Haryana</Option>
                        <Option value="Himachal Pradesh">
                          Himachal Pradesh
                        </Option>
                        <Option value="Jammu and Kashmir">
                          Jammu and Kashmir
                        </Option>
                        <Option value="Jharkhand">Jharkhand</Option>
                        <Option value="Karnataka">Karnataka</Option>
                        <Option value="Kerala">Kerala</Option>
                        <Option value="Ladakh">Ladakh</Option>
                        <Option value="Lakshadweep">Lakshadweep</Option>
                        <Option value="Madhya Pradesh">Madhya Pradesh</Option>
                        <Option value="Maharashtra	">Maharashtra </Option>
                        <Option value="Manipur">Manipur</Option>
                        <Option value="Meghalaya">Meghalaya</Option>
                        <Option value="Mizoram">Mizoram</Option>
                        <Option value="Nagaland">Nagaland</Option>
                        <Option value="Odisha">Odisha</Option>
                        <Option value="Puducherry">Puducherry</Option>
                        <Option value="Punjab">Punjab</Option>
                        <Option value="Rajasthan">Rajasthan</Option>
                        <Option value="Sikkim">Sikkim</Option>
                        <Option value="Tamil Nadu">Tamil Nadu</Option>
                        <Option value="Telangana">Telangana</Option>
                        <Option value="Tripura">Tripura</Option>
                        <Option value="Uttar Pradesh">Uttar Pradesh</Option>
                        <Option value="Uttarakhand">Uttarakhand</Option>
                        <Option value="West Bengal">West Bengal</Option>
                      </ReactSelect>
                    </div>

                    <div className="w-1/3">
                      <ReactInput
                        name="address.pincode"
                        type="text"
                        placeholder="Pincode"
                      />
                    </div>
                  </div>
                </div>
                {/* Agent address form - line 1 */}

                {/* Agent address form - line 2 */}
                <div className="flex space-x-8">
                  <div className="w-3/4 space-y-2">
                    <p className="text-gray-800 font-semibold text-lg">
                      Address Proof
                    </p>
                    <div className="flex space-x-8 items-center">
                      <div className="w-1/2">
                        <ReactSelectWithBtn
                          type="text"
                          placeholder="Select proof 1"
                          name="address_proof_1"
                        >
                          <Option value="Aadhar card">Aadhar card</Option>
                          <Option value="Ration card">Ration card</Option>
                          <Option value="Telephone bill">Telephone Bill</Option>
                          <Option value="Electricity bill">
                            Electricity Bill
                          </Option>
                          <Option value="Lease agreement">
                            Lease agreement
                          </Option>
                          <Option value="Passport">Passport</Option>
                        </ReactSelectWithBtn>
                      </div>

                      <div className="w-1/2">
                        <ReactSelectWithBtn
                          type="text"
                          placeholder="Select proof 2"
                          name="address_proof_2"
                        >
                          <Option value="Aadhar card">Aadhar card</Option>
                          <Option value="Ration card">Ration card</Option>
                          <Option value="Telephone bill">Telephone Bill</Option>
                          <Option value="Electricity bill">
                            Electricity Bill
                          </Option>
                          <Option value="Lease agreement">
                            Lease agreement
                          </Option>
                          <Option value="Passport">Passport</Option>
                        </ReactSelectWithBtn>
                      </div>
                    </div>
                  </div>

                  <div className="w-1/4">
                    <ReactSelect
                      name="agent_type"
                      type="text"
                      placeholder="Field / Call / Approver"
                      label="Agent Type"
                    >
                      <Option value="field_agent">Field</Option>
                      <Option value="call_agent" disabled>
                        Call
                      </Option>
                      <Option value="approver" disabled>
                        Approver
                      </Option>
                    </ReactSelect>
                  </div>
                </div>
                {/* Agent address form - line 1 */}
              </div>
              {/* Agent address form */}
            </div>
            {/* Agent address div*/}

            {/* Agent secondary contact div*/}
            <div className="border space-y-4 rounded bg-white">
              {/* Agent secondary contact header */}
              <div className="border-b w-full px-4 py-2 bg-green-primary">
                <h2 className="text-white">Secondary contact</h2>
              </div>
              {/* Agent secondary contact header */}

              {/* Agent secondary contact form */}
              <div className="p-4 space-y-4">
                {/* Agent secondary contact form - line 1 */}
                <div className="flex space-x-8">
                  <div className="w-1/3">
                    <ReactInput
                      name="secondary_contact_first_name"
                      type="text"
                      label="First Name"
                      placeholder=""
                    />
                  </div>

                  <div className="w-2/3 flex space-x-8">
                    <div className="w-1/2">
                      <ReactInput
                        name="secondary_contact_last_name"
                        type="text"
                        label="Last Name"
                        placeholder=""
                      />
                    </div>

                    <div className="w-1/2">
                      <ReactSelect
                        name="secondary_contact_relation"
                        type="text"
                        placeholder="Select relation"
                        label="Relation"
                      >
                        <Option value="Father">Father</Option>
                        <Option value="Mother">Mother</Option>
                        <Option value="Brother">Brother</Option>
                        <Option value="Sister">Sister</Option>
                        <Option value="Husband">Husband</Option>
                        <Option value="Wife">Wife</Option>
                      </ReactSelect>
                    </div>
                  </div>
                </div>
                {/* Agent secondary contact form - line 1 */}

                {/* Agent secondary contact form - line 2 */}
                <div className="flex space-x-8">
                  <div className="w-1/3">
                    <ReactInput
                      name="secondary_contact_mobile"
                      type="text"
                      label="Mobile Number"
                      placeholder=""
                    />
                  </div>

                  {/* <div className="w-2/3">
                <ReactInput
                  name="secondary_contact_address"
                  type="text"
                  label="Address"
                  placeholder=""
                />
              </div> */}
                </div>
                {/* Agent secondary contact form - line 2 */}

                {/* Agent secondary contact  - line 3*/}
                <div className="space-y-2">
                  <p className="text-gray-800 font-semibold text-lg">Address</p>
                  <div className="w-2/3">
                    <ReactInput
                      name="secondary_contact_address.addr_line_1"
                      type="text"
                      placeholder="Address line 1"
                    />
                  </div>
                  <div className="w-2/3">
                    <ReactInput
                      name="secondary_contact_address.addr_line_2"
                      type="text"
                      placeholder="Address line 2"
                    />
                  </div>
                  <div className="w-2/3 flex space-x-8">
                    <div className="w-1/3">
                      <ReactInput
                        name="secondary_contact_address.city"
                        type="text"
                        placeholder="City"
                      />
                    </div>

                    <div className="w-1/3">
                      <ReactSelect
                        name="secondary_contact_address.state"
                        type="text"
                        placeholder="State"
                      >
                        <Option value="Andhra Pradesh">Andhra Pradesh</Option>
                        <Option value="Andaman and Nicobar Islands">
                          Andaman and Nicobar Islands
                        </Option>
                        <Option value="Arunachal Pradesh">
                          Arunachal Pradesh
                        </Option>
                        <Option value="Assam">Assam</Option>
                        <Option value="Bihar">Bihar</Option>
                        <Option value="Chandigarh">Chandigarh</Option>
                        <Option value="Chhattisgarh">Chhattisgarh</Option>
                        <Option value="Dadra and Nagar Haveli and Daman and Diu">
                          Dadra and Nagar Haveli and Daman and Diu
                        </Option>
                        <Option value="Delhi">Delhi</Option>
                        <Option value="Goa">Goa</Option>
                        <Option value="Gujarat">Gujarat</Option>
                        <Option value="Haryana">Haryana</Option>
                        <Option value="Himachal Pradesh">
                          Himachal Pradesh
                        </Option>
                        <Option value="Jammu and Kashmir">
                          Jammu and Kashmir
                        </Option>
                        <Option value="Jharkhand">Jharkhand</Option>
                        <Option value="Karnataka">Karnataka</Option>
                        <Option value="Kerala">Kerala</Option>
                        <Option value="Ladakh">Ladakh</Option>
                        <Option value="Lakshadweep">Lakshadweep</Option>
                        <Option value="Madhya Pradesh">Madhya Pradesh</Option>
                        <Option value="Maharashtra	">Maharashtra </Option>
                        <Option value="Manipur">Manipur</Option>
                        <Option value="Meghalaya">Meghalaya</Option>
                        <Option value="Mizoram">Mizoram</Option>
                        <Option value="Nagaland">Nagaland</Option>
                        <Option value="Odisha">Odisha</Option>
                        <Option value="Puducherry">Puducherry</Option>
                        <Option value="Punjab">Punjab</Option>
                        <Option value="Rajasthan">Rajasthan</Option>
                        <Option value="Sikkim">Sikkim</Option>
                        <Option value="Tamil Nadu">Tamil Nadu</Option>
                        <Option value="Telangana">Telangana</Option>
                        <Option value="Tripura">Tripura</Option>
                        <Option value="Uttar Pradesh">Uttar Pradesh</Option>
                        <Option value="Uttarakhand">Uttarakhand</Option>
                        <Option value="West Bengal">West Bengal</Option>
                      </ReactSelect>
                    </div>

                    <div className="w-1/3">
                      <ReactInput
                        name="secondary_contact_address.pincode"
                        type="text"
                        placeholder="Pincode"
                      />
                    </div>
                  </div>
                </div>
                {/* Agent secondary contact  - line 3*/}
              </div>
              {/* Agent secondary contact form */}
            </div>
            {/* Agent secondary contact div*/}

            {/* Agent bank account div*/}
            <div className="border rounded bg-white">
              {/* Agent bank account header */}
              <div className="border-b w-full px-4 py-2 bg-green-primary">
                <h2 className="text-white">Bank account details</h2>
              </div>
              {/* Agent bank account header */}

              {/* Agent bank account form */}
              <div className="p-4 space-y-4">
                {/* Agent bank account form - line 1 */}
                <div className="flex space-x-8">
                  <div className="w-1/3">
                    <ReactInput
                      name="bank_account_name"
                      type="text"
                      label="Account Name"
                      placeholder=""
                    />
                  </div>

                  <div className="w-2/3 flex space-x-8">
                    <div className="w-1/2">
                      <ReactInput
                        name="bank_account_number"
                        type="text"
                        label="Account Number"
                      />
                    </div>

                    <div className="w-1/2">
                      <ReactInput
                        name="bank_ifsc_code"
                        type="text"
                        label="IFSC Code"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                {/* Agent bank account form - line 1 */}

                {/* Agent bank account form - line 2 */}
                <div
                  className="flex space-x-8"
                  style={{ marginBottom: "30px" }}
                >
                  <div className="w-1/3">
                    <ReactInput
                      name="bank_name"
                      type="text"
                      label="Bank Name"
                      placeholder=""
                    />
                  </div>

                  <div className="w-2/3 flex space-x-8">
                    <div className="w-1/3">
                      <ReactInput
                        name="bank_branch"
                        type="text"
                        label="Bank Branch"
                        placeholder=""
                      />
                    </div>

                    <div className="w-2/3 space-y-2">
                      <label className="text-lg text-gray-800 font-semibold">
                        Bank account proof
                      </label>

                      <div className="w-full items-center flex space-x-8">
                        <ReactSelectWithBtn
                          name="bank_account_proof_1"
                          type="text"
                          placeholder="Select proof"
                        >
                          <Option value="Passbook">Passbook</Option>
                          <Option value="Cancelled cheque">
                            Cancelled cheque
                          </Option>
                        </ReactSelectWithBtn>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Agent bank account form - line 2 */}
              </div>
            </div>

            <div className="flex mt-6">
              <div className="w-1/5 space-y-2 relative">
                <h2 className="text-gray-800 font-semibold">Agent Photo:</h2>
                <ImageUpload
                  buttonText="Upload"
                  multiple={false}
                  name="agent_photo"
                  showError={true}
                  editMode={true}
                  maxLength={1}
                />
              </div>

              <div className="w-1/2 space-y-2 relative">
                <h2 className="text-gray-800 font-semibold">
                  Agent ID Card Photos (please upload the front side first):
                </h2>
                <ImageUpload
                  buttonText="Upload"
                  multiple={false}
                  name="id_card_photos"
                  showError={true}
                  editMode={true}
                  maxLength={2}
                  customizeSuccess={true}
                  customOnSuccess={(data) => {
                    if (values["id_card_photos"].length < 3) {
                      if (data !== undefined) {
                        setFieldValue("id_card_photos", data);
                      }
                    }
                  }}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <div className="flex justify-center w-full space-x-8">
        <Popconfirm
          title={`Are you sure you want to cancel?`}
          onConfirm={() => {
            props.history.push("/agents");
          }}
          onCancel={() => {}}
          okText="Yes"
          placement="bottomRight"
          cancelText="No"
        >
          <button className="btn-danger hover:shadow-lg">Cancel</button>
        </Popconfirm>
        <button
          type="submit"
          className="btn-primary hover:shadow-lg"
          onClick={() => handleSubmit()}
        >
          Create Agent
        </button>
      </div>
    </div>
  );
}

// mutation MyMutation {
//   delete_field_agent(id: "320") {
//     code
//     message
//   }
// }

// mutation MyMutation {
//   disable_field_agent(id: "320") {
//     code
//     message
//   }
// }

// mutation confirmVisit($address:jsonb,
// $address_proof: jsonb,
// $created_by_auth_id: String,
// $first_name: String,
// $id_proof: jsonb,
// $last_name: String,
// $relation: String,
// $secondary_address: jsonb,
// $secondary_mobile_number: String,
// $user: CreateFieldAgentUsersObjRelInsertInput) {
//   create_agent(
// address: $address
// address_proof: $address_proof
// created_by_auth_id: $created_by_auth_id
// first_name: $first_name
// id_proof: $id_proof
// last_name: $last_name
// relation: $relation
// secondary_address: $secondary_address
// secondary_mobile_number: $secondary_mobile_number
// user: $user) {
//     code
//     message
//   }
// }

// {

//         "address": {
//             "addr_line_1": "asdf",
//             "addr_line_2": "dskf",
//             "city": "kk",
//             "state": "Andhra Pradesh",
//             "pincode": "505001",
//             "country": "India",
//             "label": "Home",
//             "preferred": "yes"
//         },
//         "address_proof": [
//             {
//                 "type": "Ration card",
//                 "image_url": [
//                     "https://orocorp-uat.s3.ap-south-1.amazonaws.com/747404Screenshot%2020210224%20at%201.04.59%20PM.png"
//                 ]
//             },
//             {
//                 "type": "Aadhar card",
//                 "image_url": [
//                     "https://orocorp-uat.s3.ap-south-1.amazonaws.com/845065Screenshot%2020210224%20at%201.04.59%20PM.png"
//                 ]
//             }
//         ],
//         "id_proof": [
//             {
//                 "type": "Aadhar card",
//                 "image_url": [
//                     "https://orocorp-uat.s3.ap-south-1.amazonaws.com/707724Screenshot%2020210224%20at%201.04.59%20PM.png"
//                 ]
//             },
//             {
//                 "type": "Voter ID",
//                 "image_url": [
//                     "https://orocorp-uat.s3.ap-south-1.amazonaws.com/635148Screenshot%2020210224%20at%201.04.59%20PM.png"
//                 ]
//             }
//         ],
//         "first_name": "sdf",
//         "last_name": "kdsjf",
//         "relation": "Father",
//         "secondary_address": {
//             "addr_line_1": "kasdjf",
//             "addr_line_2": "kdsjf",
//             "city": "Chennai",
//             "state": "Andhra Pradesh",
//             "pincode": "505002",
//             "country": "India",
//             "label": "Home",
//             "preferred": "yes"
//         },
//         "secondary_mobile_number": "+919999999999",
//         "created_by_auth_id": "NUVFmMYE6kPlUsshO8ZToqPVl552",
//         "user": {

//             "first_name": "kethan",
//             "last_name": "test",
//             "email_id": "kds@ldks.com",
//             "role_name": "FIELD_AGENT",
//             "mobile_number": "+911506134432",
//             "profile_picture": "https://orocorp-uat.s3.ap-south-1.amazonaws.com/145839Screenshot%2020210224%20at%201.04.59%20PM.png",
//             "user_bank_account_details": {
//                 "bank_account_name": "adf",
//                 "bank_account_number": "1234",
//                 "ifsc": "sfk",
//                 "bank_name": "aksdf",
//                 "bank_branch": "fk",
//                 "bank_proof": [
//                     {
//                         "type": "Passbook",
//                         "image_url": [
//                             "https://orocorp-uat.s3.ap-south-1.amazonaws.com/995094Screenshot%2020210224%20at%201.04.59%20PM.png"
//                         ]
//                     }
//                 ]
//             }

//         }

// }
