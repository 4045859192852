// DEPS
import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Carousel, { Modal as ImageModal, ModalGateway } from "react-images";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import * as Sentry from "@sentry/react";
import {Button, Modal, notification, Select, Tooltip, Tag} from "antd";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, Formik } from "formik";

// COMPONENTS
import DisplayTable from "../../components/displayTable";
import Loading from "../../components/loading";
import ChangePartnerBranchForVisit from "../../components/Modals/changePartnerBranch";
import { ReactSelect } from "../../components/Form";
import ErrorPage from "../../components/ErrorPage";
import ErrorWrapper from "../../components/ErrorWrapper";
import CancelVisit from "../../components/Modals/cancelVisit";

// VIEWS
import UserBankAccountDetails from "../../views/userBankAccountDetails";

// APIS
import {GET_FIELD_AGENTS, GET_VISIT_BY_ID, UPDATE_AGENT, APPROVE_KYC, CHECK_INSERT_KYC_BANK_ACCOUNT_DETAILS_FOR_CUSTOMER} from "./graphql";
import { get_image_url } from "../../utils/index";

// ICONS
import { LeftOutlined, EyeOutlined } from "@ant-design/icons";

const { Option } = Select;

const visitValidationSchema = Yup.object({
  agent_auth_id: Yup.string().trim().required("Required"),
});

const InsertKYCBankAccountDetailsBtn = (props) => {
  if (props.customerKYCAndBankAccountDetails) {
    if (
      props.customerKYCAndBankAccountDetails.customer
        ?.user_bank_account_details_aggregate.aggregate.count === 0 &&
      props.customerKYCAndBankAccountDetails.customer?.kycs_aggregate.aggregate
        .count === 0
    )
      if (
        props.customerKYCAndBankAccountDetails.customer
          .customer_visits_aggregate.aggregate.count !== 0
      )
        return (
          <Tooltip title="Customer has a visit in progress.">
            <button
              className="py-2 px-4 border bg-gray-300 rounded text-white cursor-not-allowed"
              disabled
            >
              Add user KYC and bank account details
            </button>
          </Tooltip>
        );
      else
        return (
          <Link
            to={{
              pathname: `/users/${props.customer_id}/add-user-kyc-bank-account-details`,
              state: {
                user_auth_id:
                  props.customerKYCAndBankAccountDetails.customer.auth_id,
              },
            }}
          >
            <Button
              htmlType="submit"
              className=" rounded-lg flex align-center "
              type="primary"
              size={"large"}
              icon={<PlusOutlined />}
            >
              Add user KYC and bank account details
            </Button>
          </Link>
        );
  } else throw Error("Error in fetching user KYC and bank account details");

  return null;
};

const ViewVisits = (props) => {
  // FOR KYC
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [images, setImages] = useState([]);
  // FOR KYC

  // const [visitStatus, setVisitStatus] = useState({});
  const [ModalVisible, setModalVisible] = useState(false);
  const [GRModalVisible, setGRModalVisible] = useState(false);
  const [filteredFieldAgentData, setFilteredFieldAgentData] = useState([]);

  const [isVisitCancellationModalOpen, toggleVisitCancellationModal] =
    useState(false);

  const [updateVisit] = useMutation(UPDATE_AGENT, {
    refetchQueries: [
      {
        query: GET_VISIT_BY_ID,
      },
    ],
  });
  // const [cancelVisit] = useMutation(CANCEL_VISIT, {
  //   refetchQueries: [
  //     {
  //       query: GET_VISIT_BY_ID,
  //     },
  //   ],
  // });

  const [approveKYC] = useMutation(APPROVE_KYC, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description:
            "Something went wrong. Please try again or contact support for help.",
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "KYC approved",
      });
    },
    refetchQueries: [
      {
        query: GET_VISIT_BY_ID,
        variables: {
          where: {
            id: { _eq: parseInt(props.match.params.id) },
          },
        },
      },
    ],
  });

  const visitsOnCompleted = (visitData) => {
    if (!visitData.visits) {
      return;
    }
    let visitStatus = {};

    if (visitData.visits[0].visit_status === "VISIT_REQUESTED") {
      visitStatus.message = "Visit requested by user, to be confirmed.";
    } else if (visitData.visits[0].visit_status === "VISIT_CONFIRMED") {
      visitStatus.message = "Visit confirmed by ";
      if (visitData.visits[0].created_by) {
        visitStatus.user = {
          name: `${visitData.visits[0].created_by.first_name} ${visitData.visits[0].created_by.last_name}`,
          id: visitData.visits[0].created_by.id,
        };
      }
    } else if (visitData.visits[0].visit_status === "VISIT_ASSIGNED") {
      visitStatus.message = "Assigned by";
      if (visitData.visits[0].assigned_by) {
        visitStatus.user = {
          name: `${visitData.visits[0].assigned_by.first_name} ${visitData.visits[0].assigned_by.last_name}`,
          id: visitData.visits[0].assigned_by.id,
        };
      }
    } else if (visitData.visits[0].visit_status === "VISIT_COMPLETED") {
      visitStatus.message = "Visit completed";
    } else if (visitData.visits[0].visit_status === "VISIT_CANCELLED") {
      visitStatus.message = "Visit cancelled";
    }
    // setVisitStatus(visitStatus);
  };

  const {
    data: visitData,
    loading: visitLoading,
    error: visitError,
  } = useQuery(GET_VISIT_BY_ID, {
    variables: {
      where: {
        id: { _eq: parseInt(props.match.params.id) },
      },
    },
    onCompleted: visitsOnCompleted,
    fetchPolicy: "network-only",
  });

  const { loading: FieldAgentLoading, error: FieldAgentError } = useQuery(
    GET_FIELD_AGENTS,
    {
      variables: {
        visit_id: parseInt(props.match.params.id),
      },
      fetchPolicy: "network-only",
      onCompleted(FieldAgentData) {
        let temp = [];

        FieldAgentData.users.forEach((user) => {
          if (user.is_active) {
            temp.push(user);
          }

          // console.log("user", user);

          // if (
          //   user.visits.length === 0 &&
          //   user.secondary_field_agent_visits.length === 0 &&
          //   user.tertiary_field_agent_visits.length === 0
          // ) {
          //   temp.push(user);
          // } else {
          //   if (user.is_active) {
          //     let primary_visits_status = user.visits.map(
          //       (visit) => visit.visit_status
          //     );

          //     let secondary_visits_status =
          //       user.secondary_field_agent_visits !== undefined
          //         ? user.secondary_field_agent_visits.map(
          //             (visit) =>
          //               visit.secondary_agents_visit_status[user.auth_id]
          //           )
          //         : [];

          //     let tertiary_visits_status =
          //       user.tertiary_field_agent_visits !== undefined
          //         ? user.tertiary_field_agent_visits.map(
          //             (visit) =>
          //               visit.secondary_agents_visit_status[user.auth_id]
          //           )
          //         : [];

          //     if (
          //       !primary_visits_status.includes("VISIT_ASSIGNED") &&
          //       !primary_visits_status.includes("VISIT_IN_PROGRESS") &&
          //       !secondary_visits_status.includes("VISIT_ASSIGNED") &&
          //       !secondary_visits_status.includes("VISIT_IN_PROGRESS") &&
          //       !tertiary_visits_status.includes("VISIT_ASSIGNED") &&
          //       !tertiary_visits_status.includes("VISIT_IN_PROGRESS")
          //     )
          //       temp.push(user);
          //   }
          // }
        });

        setFilteredFieldAgentData(temp);
      },
    }
  );

  const {
    data: customerKYCAndBankAccountDetails,
    // loading: customerKYCAndBankDetailsCheckLoading,
    error: customerKYCAndBankAccountDetailsQueryError,
  } = useQuery(CHECK_INSERT_KYC_BANK_ACCOUNT_DETAILS_FOR_CUSTOMER, {
    variables: {
      id: parseInt(props.match.params.id),
    },
    fetchPolicy: "network-only",
  });

  if (!props.match.params.id || visitError || FieldAgentError) {
    return <ErrorPage></ErrorPage>;
  }
  if (visitLoading || FieldAgentLoading) {
    return <Loading />;
  }
  let visitDetails = visitData.visits[0];
  if (!visitLoading && !visitDetails) {
    return <ErrorPage></ErrorPage>;
  }

  return (
    <>
      <div>
        <div className="flex justify-between">
          <Button
            type="link"
            onClick={() => {
              props.history.push("/visits");
            }}
            className="flex align-items-center"
            icon={<LeftOutlined />}
          >
            All visits
          </Button>
          <div></div>
        </div>

        <div className="flex justify-between">
          <div className="mt-3">
            <h2>Visit Details | {visitDetails.visit_display_id} </h2>

            <div className="flex text-gray-600 space-x-1">
              {visitDetails.created_at !== visitDetails.confirmed_at ? (
                <p>
                  Requested at:{" "}
                  {moment(visitDetails.created_at).format("DD MMM YYYY HH:mm")}
                </p>
              ) : null}

              {visitDetails.created_by ? (
                <p>{`| Confirmed at ${moment(visitDetails.confirmed_at).format(
                  "DD MMM YYYY HH:mm"
                )} by ${(visitDetails.created_by || {}).first_name || ""} ${
                  (visitDetails.created_by || {}).last_name || ""
                } `}</p>
              ) : null}

              {visitDetails.assigned_by ? (
                <p>
                  {" "}
                  | Assigned at{" "}
                  {moment(visitDetails.assigned_at).format(
                    "DD MMM YYYY HH:mm"
                  )}{" "}
                  by {(visitDetails.assigned_by || {}).first_name || ""}{" "}
                  {(visitDetails.assigned_by || {}).last_name || ""}
                </p>
              ) : null}
            </div>
          </div>

          <div className="flex justify-end mt-4">
            {/* ADD KYC AND BANK ACCOUNT DETAILS FOR CUSTOMER */}
            {visitDetails.loan_subtype === "TRANSFER_LOAN" ? (
              customerKYCAndBankAccountDetailsQueryError ? (
                <ErrorWrapper
                  error={customerKYCAndBankAccountDetailsQueryError}
                  productionErrorMessage="Error in fetching user kyc, bank account details and current visits"
                />
              ) : customerKYCAndBankAccountDetails ? (
                <Sentry.ErrorBoundary
                  fallbackRender={({ error }) => (
                    <div
                      role="alert"
                      className="p-8 bg-red-100 rounded text-red-500 space-y-2 mt-10"
                    >
                      <div className="flex space-x-1 items-center text-lg">
                        <ExclamationCircleOutlined />
                        <p>Error!</p>
                      </div>

                      {process.env.ENV !== "UAT" ||
                      process.env.ENV !== "PRODUCTION" ? (
                        <pre>{error.message}</pre>
                      ) : (
                        <p>
                          Something went wrong. Please contact support if you
                          want to add KYC and bank account data for the user
                        </p>
                      )}
                    </div>
                  )}
                >
                  <InsertKYCBankAccountDetailsBtn
                    customer_id={visitDetails.customer?.customer?.id}
                    customerKYCAndBankAccountDetails={
                      customerKYCAndBankAccountDetails.visits_by_pk
                    }
                  />
                </Sentry.ErrorBoundary>
              ) : null
            ) : null}
            {/* ADD KYC AND BANK ACCOUNT DETAILS FOR CUSTOMER */}

            {/* REASSIGN PARTNER BRANCH FOR VISIT PRE-LOAN APPROVAL  */}
            {visitDetails.visit_status === "VISIT_ASSIGNED" ||
            visitDetails.visit_status === "VISIT_IN_PROGRESS" ? (
              <ChangePartnerBranchForVisit
                isOpen={isVisitCancellationModalOpen}
                setOpen={toggleVisitCancellationModal}
                visit_id={props.match.params.id}
                type="visit"
              />
            ) : null}

            {visitDetails.visit_status !== "VISIT_COMPLETED" &&
              visitDetails.visit_status !== "VISIT_IN_PROGRESS" &&
              visitDetails.visit_status !== "VISIT_CANCELLED" && (
                <>
                  {(visitDetails.visit_status === "VISIT_ASSIGNED" ||
                    visitDetails.visit_status === "VISIT_CONFIRMED") && (
                    <div>
                      <Button
                        htmlType="submit"
                        className="ml-2 rounded-md flex align-center "
                        type="primary"
                        onClick={() => {
                          if (visitDetails.visit_type === "GP")
                            setModalVisible(true);
                          else setGRModalVisible(true);
                        }}
                      >
                        {`${
                          visitDetails.visit_status === "VISIT_ASSIGNED"
                            ? "Reassign"
                            : "Assign"
                        } Visit`}
                      </Button>
                    </div>
                  )}

                  {visitDetails.visit_status === "VISIT_CONFIRMED" && (
                    <Link to={`/visits/confirm/${props.match.params.id}`}>
                      <Button
                        htmlType="submit"
                        className="ml-2 rounded-md flex align-center "
                        type="primary"
                      >
                        Edit visit
                      </Button>
                    </Link>
                  )}

                  {visitDetails.visit_status === "VISIT_REQUESTED" && (
                    <Link to={`/visits/confirm/${props.match.params.id}`}>
                      <Button
                        htmlType="submit"
                        className="ml-2 rounded-md flex align-center "
                        type="primary"
                      >
                        Confirm
                      </Button>
                    </Link>
                  )}

                  <div>
                    <Button
                      htmlType="submit"
                      className=" rounded-md flex align-center ml-2"
                      danger
                      onClick={() => toggleVisitCancellationModal(true)}
                    >
                      Cancel Visit
                    </Button>
                  </div>
                </>
              )}
          </div>
        </div>

        <div className="mt-10">
          <DisplayTable
            name="Visit overview"
            data={[
              {
                type: "Data",
                header: "Visit ID",
                data: visitDetails.visit_display_id,
                width: "1/6",
              },
              {
                type: "Data",
                header: "Visit type",
                data: visitDetails.visit_type.toUpperCase(),
                width: "1/6",
              },
              {
                type: "Data",
                header: "Loan type",
                data: visitDetails.visit_loan_type.toUpperCase(),
                width: "1/6",
              },
              {
                type: "Data",
                header: "Loan subtype",
                data: visitDetails.loan_subtype?.split("_").join(" "),
                width: "1/6",
              },
              {
                type: "Data",
                header: "Status",
                data:
                  visitDetails.visit_type === "GR" &&
                  (visitDetails.visit_status === "VISIT_REQUESTED" ||
                    visitDetails.visit_status === "RELEASE_REQUESTED")
                    ? "RELEASE REQUESTED"
                    : visitDetails.visit_status.split("_").join(" "),
                width: "1/4",
              },
              {
                type: "Link",
                header: "Assigned to",
                data: visitDetails.agent
                  ? `${visitDetails.agent.first_name} ${visitDetails.agent.last_name}`
                  : "N/A",
                link: visitDetails.agent
                  ? `/agents/view/${visitDetails.agent.id}`
                  : null,
                width: "1/4",
              },
              {
                type: "Data",
                header: "Visit time",
                data: moment(visitDetails.visit_time).format("DD.MM.YYYY HH:mm"),
                width: "1/4",
              },
              {
                type: "Link",
                header: "Loan ID",
                data: visitDetails.loan ? visitDetails.loan.loan_number : "-",
                link: visitDetails.loan
                  ? `/loans/view/${visitDetails.loan.id}`
                  : null,
                state: {
                  loan_status: visitDetails?.loan?.loan_status,
                },
                width: "1/4",
              },
              {
                type: "Data",
                header: "Partner branch",
                data: visitDetails.partner_branch
                  ? visitDetails.partner_branch.partner_branch_name
                  : "-",
                width: "1/4",
              },
              {
                type: "External Link",
                header: "Location",
                data: "Link",
                link: `https://www.google.com/maps/place/${visitDetails.lat},${visitDetails.long}`,
                width: "1/6",
              },
            ]}
          />
        </div>

        {/* USER INFO */}
        {visitDetails.first_name && (
          <div className="mt-10">
            <h4 className="mb-2">USER INFO</h4>
            <div className="flex bg-green-primary">
              <div className=" font-semibold text-white p-3 w-1/4">Name</div>
              <div className=" font-semibold text-white p-3 w-1/4">
                Mobile number
              </div>
              <div className=" font-semibold text-white p-3 w-1/2">Address</div>
              <div className=" font-semibold text-white p-3 w-1/6">Channel</div>
              <div className=" font-semibold text-white p-3 w-1/6">Lat</div>
              <div className=" font-semibold text-white p-3 w-1/6">Long</div>
              <div className=" font-semibold text-white p-3 w-1/6">
                Gold Type
              </div>
              {/* <div className=" font-semibold text-white p-3 w-1/6">Action</div> */}
            </div>
            <div className="flex bg-white">
              <div className=" font-semibold p-3 w-1/4 break-all">{`${
                visitDetails.first_name || ""
              } `}</div>
              <div className=" font-semibold p-3 w-1/4 break-all">
                {`${visitDetails.mobile_number || ""}`}
              </div>
              <div className=" font-semibold p-3 w-1/2 break-all">
                {visitDetails.address
                  ? `${
                      visitDetails.address["address line 1"] ||
                      visitDetails.address["addr_line_1"] ||
                      ""
                    }, ${
                      visitDetails.address["address line 2"] ||
                      visitDetails.address["addr_line_2"] ||
                      ""
                    }, ${visitDetails.address["city"] || ""}-${
                      visitDetails.address["pincode"] || ""
                    }`
                  : ""}
              </div>
              <div className=" font-semibold p-3 w-1/6 break-all">
                {visitDetails.channel || "-"}
              </div>
              <div className=" font-semibold p-3 w-1/6 break-all">
                {visitDetails.lat}
              </div>
              <div className=" font-semibold p-3 w-1/6 break-all">
                {visitDetails.long}
              </div>
              <div className=" font-semibold p-3 w-1/6 break-all">
                {visitDetails.visit_type === "GP"
                  ? visitDetails.gold_type ||
                    visitDetails.visits_x_gold_ornament_types.map(
                      (visits_x_gold_ornament_type, index) => {
                        if (
                          index !==
                          visitDetails.visits_x_gold_ornament_types.length - 1
                        )
                          return (
                            visits_x_gold_ornament_type.gold_ornament_type
                              .type + ", "
                          );
                        else
                          return visits_x_gold_ornament_type.gold_ornament_type
                            .type;
                      }
                    )
                  : visitDetails.gold_type ||
                    visitDetails?.loan?.visits
                      .filter((visit) => visit.visit_type === "GP")[0]
                      ?.visits_x_gold_ornament_types.map(
                        (visits_x_gold_ornament_type, index) =>
                          visits_x_gold_ornament_type.gold_ornament_type.type +
                          " "
                      )}
                {/* {visitDetails.gold_type ||
                  visitDetails.visits_x_gold_ornament_types.map(
                    (visits_x_gold_ornament_type, index) => {
                      if (
                        index !==
                        visitDetails.visits_x_gold_ornament_types.length - 1
                      )
                        return (
                          visits_x_gold_ornament_type.gold_ornament_type.type +
                          ", "
                        );
                      else
                        return visits_x_gold_ornament_type.gold_ornament_type
                          .type;
                    }
                  )} */}
              </div>
              {/* <div className=" font-semibold p-3 w-1/6">
              <Link
                to={`/user/view/${
                  visitDetails.user ? visitDetails.user.id : "" || ""
                }`}
              >
                <EyeOutlined />
              </Link>
            </div> */}
            </div>
          </div>
        )}
        {/* USER INFO */}

        {/* BANK ACCOUNT DETAILS */}
        {visitDetails.customer ? (
          <UserBankAccountDetails
            user_auth_id={visitDetails.customer?.auth_id}
            visit_intermediate_status={visitDetails.intermediate_status}
          />
        ) : null}
        {/* BANK ACCOUNT DETAILS */}

        {/* KYC  */}
        {visitDetails.customer?.kycs[0] ? (
          <>
            <div className="mt-10 space-y-4">
              <div className="w-full flex justify-between items-center">
                <div>
                  <h4 className="mb-2">USER INFO & KYC</h4>
                </div>

                <div className="space-x-4">
                  {visitDetails.intermediate_status ===
                    "OD_KYC_DETAILS_ADDED" &&
                  !visitDetails.customer.kycs[0].is_approved ? (
                    <>
                      <button
                        className="btn-primary hover:shadow-lg"
                        onClick={() =>
                          approveKYC({
                            variables: {
                              kyc_id: visitDetails.customer.kycs[0].id,
                            },
                          })
                        }
                      >
                        Approve KYC{" "}
                      </button>
                      {/* <button className="btn-danger hover:shadow-lg">
                      Reject KYC{" "}
                    </button> */}
                    </>
                  ) : null}
                </div>
              </div>
              <div>
                <div className="flex bg-green-primary">
                  <div className=" font-semibold text-white p-3 w-1/4">
                    Name
                  </div>
                  <div className=" font-semibold text-white p-3 w-1/4">
                    Mobile number
                  </div>
                  <div className=" font-semibold text-white p-3 w-2/4">
                    Loan address
                  </div>
                </div>
                <div className="flex bg-white">
                  <div className="font-semibold p-3 w-1/4 break-all">
                    {visitDetails.customer.kycs[0].legal_name}
                  </div>
                  <div className="font-semibold p-3 w-1/4 break-all">
                    {visitDetails.customer.mobile_number}
                  </div>
                  <div className="font-semibold p-3 w-2/4 break-all">
                    {visitDetails.address.addr_line_1},{" "}
                    {visitDetails.address.addr_line_2},{" "}
                    {visitDetails.address.city} ,{visitDetails.address.state},
                    {"-"}
                    {visitDetails.address.pincode}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex space-x-32 mt-10">
              <div className="block space-y-4">
                <h3 className="text-lg">ID PROOF</h3>
                <div className="border bg-white">
                  {visitDetails.customer.kycs[0].id_proof.map((proof) => {
                    return (
                      <p
                        className="cursor-pointer hover:bg-gray-200 py-1 px-2"
                        onClick={async () => {
                          let images = [];

                          let index =
                            visitDetails.customer.kycs[0].id_proof.findIndex(
                              (data) => {
                                return data.type === proof.type;
                              }
                            );

                          const valid_url = await get_image_url({
                            url: [
                              visitDetails.customer.kycs[0].id_proof[index]
                                .image_url,
                            ],
                          });

                          images.push({
                            caption: `ID proof - ${proof.type}`,
                            source: valid_url,
                          });

                          setIsImageModalOpen(!isImageModalOpen);
                          setImages(images);
                        }}
                      >
                        {proof.type}
                      </p>
                    );
                  })}
                </div>
              </div>

              <div className="block space-y-4">
                <h3 className="text-lg">ADDRESS PROOF</h3>
                <div className="border bg-white">
                  {visitDetails.customer.kycs[0].address_proof.map((proof) => {
                    return (
                      <p
                        className="cursor-pointer hover:bg-gray-200 py-1 px-2"
                        onClick={async () => {
                          let images = [];

                          let index =
                            visitDetails.customer.kycs[0].address_proof.findIndex(
                              (data) => {
                                return data.type === proof.type;
                              }
                            );

                          const valid_url = await get_image_url({
                            url: [
                              visitDetails.customer.kycs[0].address_proof[index]
                                .image_url,
                            ],
                          });

                          images.push({
                            caption: `Address proof - ${proof.type}`,
                            source: valid_url,
                          });

                          setIsImageModalOpen(!isImageModalOpen);
                          setImages(images);
                        }}
                      >
                        {proof.type}
                      </p>
                    );
                  })}
                </div>
              </div>

              {visitDetails.customer.kycs[0].live_photo.length > 0 ? (
                <div className="w-1/4 space-y-2">
                  <h3>Recent Photo</h3>
                  <button
                    className="cta hover:shadow-lg"
                    style={{
                      height: "38px",
                      minWidth: "150px",
                    }}
                    onClick={async () => {
                      const valid_url = await get_image_url({
                        url: [visitDetails.customer.kycs[0].live_photo],
                      });

                      setIsImageModalOpen(!isImageModalOpen);
                      setImages([
                        {
                          caption: "User photo",
                          source: valid_url,
                        },
                      ]);
                    }}
                  >
                    View
                  </button>
                </div>
              ) : null}
            </div>
            <ModalGateway>
              {isImageModalOpen && images.length > 0 ? (
                <ImageModal
                  onClose={() => {
                    setIsImageModalOpen(!isImageModalOpen);
                  }}
                >
                  <Carousel views={images} />
                </ImageModal>
              ) : null}
            </ModalGateway>{" "}
          </>
        ) : null}

        {/* KYC  */}

        {visitDetails.agent && (
          <div className="mt-10">
            <h4 className="mb-2">PRIMARY AGENT INFO</h4>
            <div className="flex bg-green-primary">
              <div className=" font-semibold text-white p-3 w-1/2">
                Agent Name
              </div>
              <div className=" font-semibold text-white p-3 w-1/2">
                Mobile number
              </div>
              <div className=" font-semibold text-white p-3 w-1/4">
                Agent ID
              </div>
              <div className=" font-semibold text-white p-3 w-1/6">Action</div>
            </div>
            <div className="flex bg-white">
              <div className=" font-semibold p-3 w-1/2 break-all">{`${visitDetails.agent.first_name} ${visitDetails.agent.last_name}`}</div>
              <div className=" font-semibold p-3 w-1/2 break-all">
                {`${visitDetails.agent.mobile_number || ""}`}
              </div>
              <div className=" font-semibold p-3 w-1/4 break-all">
                {`${visitDetails.agent.agent.agent_display_id || ""}`}
              </div>
              <div className=" font-semibold p-3 w-1/6 break-all">
                <Link to={`/agents/view/${visitDetails.agent.id || ""}`}>
                  <EyeOutlined />
                </Link>
              </div>
            </div>
          </div>
        )}

        {visitDetails.secondary_field_agent && (
          <div className="mt-10">
            <h4 className="mb-2">SECONDARY AGENT INFO</h4>
            <div className="flex bg-green-primary">
              <div className=" font-semibold text-white p-3 w-1/2">
                Agent Name
              </div>
              <div className=" font-semibold text-white p-3 w-1/2">
                Mobile number
              </div>
              <div className=" font-semibold text-white p-3 w-1/4">
                Agent ID
              </div>
              <div className=" font-semibold text-white p-3 w-1/6">Action</div>
            </div>
            <div className="flex bg-white">
              <div className=" font-semibold p-3 w-1/2 break-all">{`${visitDetails.secondary_field_agent.first_name} ${visitDetails.secondary_field_agent.last_name}`}</div>
              <div className=" font-semibold p-3 w-1/2 break-all">
                {`${visitDetails.secondary_field_agent.mobile_number || ""}`}
              </div>
              <div className=" font-semibold p-3 w-1/4 break-all">
                {`${
                  visitDetails.secondary_field_agent.agent.agent_display_id ||
                  ""
                }`}
              </div>
              <div className=" font-semibold p-3 w-1/6 break-all">
                <Link
                  to={`/agents/view/${
                    visitDetails.secondary_field_agent.id || ""
                  }`}
                >
                  <EyeOutlined />
                </Link>
              </div>
            </div>
          </div>
        )}

        {visitDetails.tertiary_field_agent && (
          <div className="mt-10">
            <h4 className="mb-2">TERTIARY AGENT INFO</h4>
            <div className="flex bg-green-primary">
              <div className=" font-semibold text-white p-3 w-1/2">
                Agent Name
              </div>
              <div className=" font-semibold text-white p-3 w-1/2">
                Mobile number
              </div>
              <div className=" font-semibold text-white p-3 w-1/4">
                Agent ID
              </div>
              <div className=" font-semibold text-white p-3 w-1/6">Action</div>
            </div>
            <div className="flex bg-white">
              <div className=" font-semibold p-3 w-1/2 break-all">{`${visitDetails.tertiary_field_agent.first_name} ${visitDetails.tertiary_field_agent.last_name}`}</div>
              <div className=" font-semibold p-3 w-1/2 break-all">
                {`${visitDetails.tertiary_field_agent.mobile_number || ""}`}
              </div>
              <div className=" font-semibold p-3 w-1/4 break-all">
                {`${
                  visitDetails.tertiary_field_agent.agent.agent_display_id || ""
                }`}
              </div>
              <div className=" font-semibold p-3 w-1/6 break-all">
                <Link
                  to={`/agents/view/${
                    visitDetails.tertiary_field_agent.id || ""
                  }`}
                >
                  <EyeOutlined />
                </Link>
              </div>
            </div>
          </div>
        )}

        {/* GP  */}
        <Modal
          title="Assign field agent"
          visible={ModalVisible}
          footer={null}
          onCancel={() => {
            setModalVisible(false);
          }}
        >
          <Formik
            enableReinitialize={false}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values, { resetForm }) => {
              let secondary_agents_visit_status = {};
              if (values.secondary_agent_auth_id)
                secondary_agents_visit_status[values.secondary_agent_auth_id] =
                  "VISIT_ASSIGNED";
              if (values.tertiary_agent_auth_id)
                secondary_agents_visit_status[values.tertiary_agent_auth_id] =
                  "VISIT_ASSIGNED";

              updateVisit({
                variables: {
                  id: parseInt(props.match.params.id),
                  agent_auth_id: values.agent_auth_id,
                  secondary_field_agent_one_auth_id:
                    values.secondary_agent_auth_id,
                  secondary_field_agent_two_auth_id:
                    values.tertiary_agent_auth_id,
                  secondary_agents_visit_status: secondary_agents_visit_status,
                  assigned_by: jwt_decode(localStorage.getItem("token")).user_id,
                  assigned_at: moment(),
                },
              });

              notification.success({
                message: "Success!",
                description: "Visit updated",
              });
              setModalVisible(false);

              resetForm({});
            }}
            initialValues={{
              agent_auth_id: visitDetails.agent_auth_id || "",
              secondary_agent_auth_id:
                visitDetails.secondary_field_agent_one_auth_id,
              tertiary_agent_auth_id:
                visitDetails.secondary_field_agent_two_auth_id,
            }}
            validationSchema={visitValidationSchema}
            formCls="flex flex-col content-center justify-center space-y-8"
          >
            {({ values }) => (
              <Form>
                <ReactSelect
                  name="agent_auth_id"
                  type="text"
                  label="Primary field agent"
                  placeholder="Choose field agent"
                  showSearch
                  optionFilterProp="children"
                  allowClear
                  filterOption={(input, option) => {
                    return (
                      (((option.children[0] || {}).props || {}).children || [])
                        .join(" ")
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  // disabled={true}
                >
                  {filteredFieldAgentData.map((user) => {
                    if (
                      user.auth_id === values.secondary_agent_auth_id ||
                      user.auth_id === values.tertiary_agent_auth_id
                    )
                      return null;

                    let primary_visits_status = user.visits.map(
                      (visit) => visit.visit_status
                    );

                    let secondary_visits_status =
                      user.secondary_field_agent_visits !== undefined
                        ? user.secondary_field_agent_visits.map(
                            (visit) =>
                              visit.secondary_agents_visit_status[user.auth_id]
                          )
                        : [];

                    let tertiary_visits_status =
                      user.tertiary_field_agent_visits !== undefined
                        ? user.tertiary_field_agent_visits.map(
                            (visit) =>
                              visit.secondary_agents_visit_status[user.auth_id]
                          )
                        : [];

                    if (
                      primary_visits_status.includes("VISIT_ASSIGNED") ||
                      primary_visits_status.includes("VISIT_IN_PROGRESS") ||
                      secondary_visits_status.includes("VISIT_ASSIGNED") ||
                      secondary_visits_status.includes("VISIT_IN_PROGRESS") ||
                      tertiary_visits_status.includes("VISIT_ASSIGNED") ||
                      tertiary_visits_status.includes("VISIT_IN_PROGRESS")
                    )
                      return (
                        <Option value={user.auth_id}>
                          <div className="flex justify-between items-center">
                            <div>
                              <p>
                                {user.first_name} {user.last_name} /{" "}
                                {(user.agent || {}).agent_display_id}
                              </p>
                              <p>{user.mobile_number} </p>
                            </div>

                            {values.agent_auth_id === user.auth_id ? null : (
                              <Tag color={"red"} key="IN_VISIT">
                                Busy
                              </Tag>
                            )}
                          </div>
                        </Option>
                      );

                    return (
                      <Option value={user.auth_id}>
                        <div>
                          {user.first_name} {user.last_name} /{" "}
                          {(user.agent || {}).agent_display_id}
                        </div>
                        <div>{user.mobile_number}</div>
                      </Option>
                    );
                  })}
                </ReactSelect>

                <ReactSelect
                  name="secondary_agent_auth_id"
                  type="text"
                  label="Secondary field agent (optional)"
                  placeholder="Choose secondary field agent"
                  showSearch
                  optionFilterProp="children"
                  allowClear
                  filterOption={(input, option) => {
                    return (
                      (((option.children[0] || {}).props || {}).children || [])
                        .join(" ")
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  // disabled={true}
                >
                  {filteredFieldAgentData.map((user) => {
                    if (
                      user.auth_id === values.agent_auth_id ||
                      user.auth_id === values.tertiary_agent_auth_id
                    )
                      return null;

                    let primary_visits_status = user.visits.map(
                      (visit) => visit.visit_status
                    );

                    let secondary_visits_status =
                      user.secondary_field_agent_visits !== undefined
                        ? user.secondary_field_agent_visits.map(
                            (visit) =>
                              visit.secondary_agents_visit_status[user.auth_id]
                          )
                        : [];

                    let tertiary_visits_status =
                      user.tertiary_field_agent_visits !== undefined
                        ? user.tertiary_field_agent_visits.map(
                            (visit) =>
                              visit.secondary_agents_visit_status[user.auth_id]
                          )
                        : [];

                    if (
                      primary_visits_status.includes("VISIT_ASSIGNED") ||
                      primary_visits_status.includes("VISIT_IN_PROGRESS") ||
                      secondary_visits_status.includes("VISIT_ASSIGNED") ||
                      secondary_visits_status.includes("VISIT_IN_PROGRESS") ||
                      tertiary_visits_status.includes("VISIT_ASSIGNED") ||
                      tertiary_visits_status.includes("VISIT_IN_PROGRESS")
                    )
                      return (
                        <Option value={user.auth_id}>
                          <div className="flex justify-between items-center">
                            <div>
                              <p>
                                {user.first_name} {user.last_name} /{" "}
                                {(user.agent || {}).agent_display_id}
                              </p>
                              <p>{user.mobile_number} </p>
                            </div>

                            {values.agent_auth_id === user.auth_id ? null : (
                              <Tag color={"red"} key="IN_VISIT">
                                Busy
                              </Tag>
                            )}
                          </div>
                        </Option>
                      );

                    return (
                      <Option value={user.auth_id}>
                        <div>
                          {user.first_name} {user.last_name} /{" "}
                          {(user.agent || {}).agent_display_id}
                        </div>
                        <div>{user.mobile_number}</div>
                      </Option>
                    );
                  })}
                </ReactSelect>

                <ReactSelect
                  name="tertiary_agent_auth_id"
                  type="text"
                  label="Tertiary field agent (optional)"
                  placeholder="Choose tertiary field agent"
                  showSearch
                  optionFilterProp="children"
                  allowClear
                  filterOption={(input, option) => {
                    return (
                      (((option.children[0] || {}).props || {}).children || [])
                        .join(" ")
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  // disabled={true}
                >
                  {filteredFieldAgentData.map((user) => {
                    if (
                      user.auth_id === values.agent_auth_id ||
                      user.auth_id === values.secondary_agent_auth_id
                    )
                      return null;

                    let primary_visits_status = user.visits.map(
                      (visit) => visit.visit_status
                    );

                    let secondary_visits_status =
                      user.secondary_field_agent_visits !== undefined
                        ? user.secondary_field_agent_visits.map(
                            (visit) =>
                              visit.secondary_agents_visit_status[user.auth_id]
                          )
                        : [];

                    let tertiary_visits_status =
                      user.tertiary_field_agent_visits !== undefined
                        ? user.tertiary_field_agent_visits.map(
                            (visit) =>
                              visit.secondary_agents_visit_status[user.auth_id]
                          )
                        : [];

                    if (
                      primary_visits_status.includes("VISIT_ASSIGNED") ||
                      primary_visits_status.includes("VISIT_IN_PROGRESS") ||
                      secondary_visits_status.includes("VISIT_ASSIGNED") ||
                      secondary_visits_status.includes("VISIT_IN_PROGRESS") ||
                      tertiary_visits_status.includes("VISIT_ASSIGNED") ||
                      tertiary_visits_status.includes("VISIT_IN_PROGRESS")
                    )
                      return (
                        <Option value={user.auth_id}>
                          <div className="flex justify-between items-center">
                            <div>
                              <p>
                                {user.first_name} {user.last_name} /{" "}
                                {(user.agent || {}).agent_display_id}
                              </p>
                              <p>{user.mobile_number} </p>
                            </div>

                            {values.agent_auth_id === user.auth_id ? null : (
                              <Tag color={"red"} key="IN_VISIT">
                                Busy
                              </Tag>
                            )}
                          </div>
                        </Option>
                      );

                    return (
                      <Option value={user.auth_id}>
                        <div>
                          {user.first_name} {user.last_name} /{" "}
                          {(user.agent || {}).agent_display_id}
                        </div>
                        <div>{user.mobile_number}</div>
                      </Option>
                    );
                  })}
                </ReactSelect>
                <div className="flex justify-end">
                  <Button
                    className=" rounded-md"
                    primary
                    onClick={() => {
                      setModalVisible(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    className=" rounded-md ml-2"
                    type="primary"
                    onClick={() => {}}
                  >
                    Update
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
        {/* GP  */}

        {/* GR  */}
        <Modal
          title="Assign field agent"
          visible={GRModalVisible}
          footer={null}
          onCancel={() => {
            setGRModalVisible(false);
          }}
        >
          <Formik
            enableReinitialize={false}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values, { resetForm }) => {
              updateVisit({
                variables: {
                  id: parseInt(props.match.params.id),
                  agent_auth_id: values.agent_auth_id,
                  assigned_by: jwt_decode(localStorage.getItem("token")).user_id,
                  assigned_at: moment(),
                },
              });

              notification.success({
                message: "Success!",
                description: "Visit updated",
              });
              setGRModalVisible(false);

              resetForm({});
            }}
            initialValues={{
              agent_auth_id: visitDetails.agent_auth_id || "",
            }}
            validationSchema={visitValidationSchema}
            formCls="flex flex-col content-center justify-center space-y-8"
          >
            {({ values }) => (
              <Form>
                <ReactSelect
                  name="agent_auth_id"
                  type="text"
                  label="Field agent"
                  placeholder="Choose field agent"
                  showSearch
                  optionFilterProp="children"
                  allowClear
                  filterOption={(input, option) => {
                    return (
                      (((option.children[0] || {}).props || {}).children || [])
                        .join(" ")
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  // disabled={true}
                >
                  {filteredFieldAgentData.map((user) => {
                    if (
                      user.auth_id === values.secondary_agent_auth_id ||
                      user.auth_id === values.tertiary_agent_auth_id
                    )
                      return null;

                    let primary_visits_status = user.visits.map(
                      (visit) => visit.visit_status
                    );

                    let secondary_visits_status =
                      user.secondary_field_agent_visits !== undefined
                        ? user.secondary_field_agent_visits.map(
                            (visit) =>
                              visit.secondary_agents_visit_status[user.auth_id]
                          )
                        : [];

                    let tertiary_visits_status =
                      user.tertiary_field_agent_visits !== undefined
                        ? user.tertiary_field_agent_visits.map(
                            (visit) =>
                              visit.secondary_agents_visit_status[user.auth_id]
                          )
                        : [];

                    if (
                      primary_visits_status.includes("VISIT_ASSIGNED") ||
                      primary_visits_status.includes("VISIT_IN_PROGRESS") ||
                      secondary_visits_status.includes("VISIT_ASSIGNED") ||
                      secondary_visits_status.includes("VISIT_IN_PROGRESS") ||
                      tertiary_visits_status.includes("VISIT_ASSIGNED") ||
                      tertiary_visits_status.includes("VISIT_IN_PROGRESS")
                    )
                      return (
                        <Option value={user.auth_id}>
                          <div className="flex justify-between items-center">
                            <div>
                              <p>
                                {user.first_name} {user.last_name} /{" "}
                                {(user.agent || {}).agent_display_id}
                              </p>
                              <p>{user.mobile_number} </p>
                            </div>

                            {values.agent_auth_id === user.auth_id ? null : (
                              <Tag color={"red"} key="IN_VISIT">
                                Busy
                              </Tag>
                            )}
                          </div>
                        </Option>
                      );

                    return (
                      <Option value={user.auth_id}>
                        <div>
                          {user.first_name} {user.last_name} /{" "}
                          {(user.agent || {}).agent_display_id}
                        </div>
                        <div>{user.mobile_number}</div>
                      </Option>
                    );
                  })}
                </ReactSelect>

                <div className="flex justify-end">
                  <Button
                    className=" rounded-md"
                    primary
                    onClick={() => {
                      setGRModalVisible(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    className=" rounded-md ml-2"
                    type="primary"
                    onClick={() => {}}
                  >
                    Update
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
        {/* GR  */}
      </div>
      <CancelVisit
        isOpen={isVisitCancellationModalOpen}
        setOpen={toggleVisitCancellationModal}
        visit_id={props.match.params.id}
        secondary_fa_one={visitDetails.secondary_field_agent_one_auth_id}
        secondary_fa_two={visitDetails.secondary_field_agent_two_auth_id}
      />
    </>
  );
};

export default ViewVisits;
