const verifyToken = async (token) => {
	let response = await fetch(`${process.env.REACT_APP_AUTH_ENDPOINT}/api/v1/auth/verify-google`, {
		method: 'POST',
		headers: {
			"Authorization": `JWT ${token}`,
			"client-id": "customerapp",
		},
	});
	return await response.json();
};

const refetchToken = async () => {
	let response = await fetch(`${process.env.REACT_APP_AUTH_ENDPOINT}/api/v1/auth/refresh-token`, {
		method: 'POST',
		headers: {
			"refresh-token": `${localStorage.getItem('refreshToken')}`,
		},
	});
	return await response.json();
};

const logout = async () => {
	let response = await fetch(`${process.env.REACT_APP_AUTH_ENDPOINT}/api/v1/auth/logout`, {
		method: 'POST',
		headers: {
			"Authorization": `JWT ${localStorage.getItem('token')}`,
			"refresh-token": `${localStorage.getItem('refreshToken')}`,
		},
	});
	return await response.json();
};

export { verifyToken, refetchToken, logout };
