import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Button, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ErrorPage from "../../components/ErrorPage";
import ReactTable from "../../components/Table";
import { EXPORT_PLANS, GET_PLANS, GET_PLAN_DATA } from "./graphql";
import { CSVLink } from "react-csv";

const columns = [
  {
    title: "Plan ID",
    dataIndex: "planId",
    key: "planId",
    show: true,
    width: 150,
    sorter: (a, b) => {},
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Scheme ID",
    dataIndex: "schemeID",
    key: "schemeID",
    show: true,
    width: 150,
  },
  {
    title: "Plan Name",
    dataIndex: "plan_name",
    key: "plan_name",
    show: true,
  },
  {
    title: "Loan Type",
    dataIndex: "type",
    key: "type",
    show: true,
    width: 150,
  },
  {
    title: "Validity",
    dataIndex: "validity",
    key: "validity",
    show: true,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    show: true,
    width: 100,
    render: (text, data, index) => {
      return (
        <div>
          {text ? (
            <Tag color={"green"} key={text}>
              Active
            </Tag>
          ) : (
            <Tag color={"red"} key={text}>
              Inactive
            </Tag>
          )}
        </div>
      );
    },
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    show: true,
    fixed: "right",
    width: 100,
    render: (text, data, index) => {
      return (
        <Link to={`/plans/view/${data.key}`}>
          <EyeOutlined />
        </Link>
      );
    },
  },
];

const Plans = (props) => {
  const [filter, setFilter] = useState([
    // {
    //   title: "Rates",
    //   dataIndex: "plans_x_rates",
    //   key: "plans_x_rates",
    //   type: "select",
    //   show: false,
    //   data: [],
    //   isFixed: true,
    //   filterType: "rate"
    //   //filter: { id: { _in: "$selected" } },
    // },
    {
      title: "Loan Types",
      dataIndex: "plans_x_loanTypes",
      key: "plans_x_loanTypes",
      type: "select",
      show: false,
      data: [],
      isFixed: true,
      filterType: "loan_type",
      //filter: { loan_type: { id: { _in: "$selected" } } },
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      type: "select",
      show: false,
      data: [
        { id: true, name: "Active" },
        { id: false, name: "Inactive" },
      ],
      isFixed: true,
      filter: { _in: "$selected" },
    },
  ]);
  
  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    // position: ["none", "none"],
    pageSize: 10,
    current: 1,
    total: 30,
  });
  
  const [tableData, setTableData] = useState({
    page: 1,
    data: [],
    hasMoreItems: true,
  });
  
  const [filterObj, setFilterObj] = useState({});
  
  useEffect(() => {
    let objectFilters = {};
    // var re = new RegExp("$selected", "g");

    (filter || []).forEach((filter) => {
      if ((filter.selected || []).length)
        if (filter.show || filter.isFixed) {
          if (filter.filterType) {
            objectFilters[filter.key] = {
              [filter.filterType]: { id: { _in: Number(filter.selected) } },
            };
          } else {
            objectFilters[filter.key] = { _in: filter.selected };
          }
        }

      //  {
      //   [filter.filterType]: JSON.parse(
      //     JSON.stringify(filter.filter)
      //       .split("$selected")
      //       .join(filter.selected)
      //   ),
      // };
    });
    // resetState();

    setFilterObj(objectFilters);
  }, [filter]);

  const [planIdOrder, SetPlanIdOrder] = useState(null);
  const [searchText, SetSearchText] = useState("");
  
  const planQueryOnComplete = (planData) => {
    if (!planData.plans) {
      return;
    }
    if (planData.plans_aggregate) {
      setPagination({
        ...pagination,
        total: planData.plans_aggregate.aggregate.count,
      });
    }
    let hasMoreItems = true;
    if ((planData.plans || []).length === 0) {
      hasMoreItems = false;
    }
    let plans = (planData.plans || []).map((plan) => {
      return {
        key: plan.id,
        schemeID: plan.scheme_id,
        planId: plan.plan_display_id,
        status: plan.is_active,
        plan_name: plan.plan_name,
        type: (plan.plans_x_loanTypes || [])
          .map((loanType) => {
            return (loanType.loan_type || {}).loan_type || "";
          })
          .toString(),
        validity:
          plan.plan_start_date && plan.plan_end_date
            ? `${moment(plan.plan_start_date).format(
                "DD MMM YYYY HH:mm"
              )} - ${moment(plan.plan_end_date).format("DD MMM YYYY HH:mm")}`
            : "",
      };
    });
    setTableData({
      ...tableData,
      // data: [...tableData.data, ...plans],
      data: plans,
      hasMoreItems: hasMoreItems,
    });
  };
  
  const planDataQueryOnComplete = (planData) => {
    let objectFilters = {},
      filters = [];
    (filter || []).forEach((filter) => {
      objectFilters[filter.key] = filter;
    });
    if (planData.loan_types) {
      objectFilters["plans_x_loanTypes"].data = planData.loan_types.map(
        (data) => {
          return { id: data.id, name: data.loan_type };
        }
      );
    }
    Object.keys(objectFilters).forEach((key) => {
      filters.push(objectFilters[key]);
    });

    setFilter(filters);
  };

  const [CSVData, setCSVData] = useState([]);

  const formatCSVData = (plans) => {
    let CSVData = [];

    plans.plans.forEach((data) => {
      let temp = {
        planId: data.plan_display_id,
        schemeId: data.scheme_id,
        plan_name: data.plan_name,
        plan_start_date: data.plan_start_date
          ? moment(data.plan_start_date).format(
              process.env.REACT_APP_DATE_FORMAT
            )
          : "",
        plan_end_date: data.plan_end_date
          ? moment(data.plan_end_date).format(process.env.REACT_APP_DATE_FORMAT)
          : "",
        status: data.is_active ? "Active" : "Inactive",
        loan_type: (data.plans_x_loanTypes || [])
          .map((loanType) => {
            return (loanType.loan_type || {}).loan_type || "";
          })
          .toString(),
        interest: data.interest + "%",
        interest_slab_2: data.interest_slab_2
          ? data.interest_slab_2 + "%"
          : "-",
        interest_slab_3: data.interest_slab_3
          ? data.interest_slab_3 + "%"
          : "-",
        bank_interest: data.bank_interest + "%",
        ltv: data.ltv + "%",
        tenure: data.tenure + "months",
        minAmount: "₹ " + data.minimum_amount,
        maxAmount: "₹ " + data.maximum_amount,
        feature: data.features,
        tncs: data.tncs,
        created_at: moment(data.created_at).format(
          process.env.REACT_APP_DATE_FORMAT
        ),
        updated_at: moment(data.updated_at).format(
          process.env.REACT_APP_DATE_FORMAT
        ),
      };

      CSVData.push(temp);
    });

    setTimeout(function () {
      setCSVData(CSVData);
      triggerCSVDownload();
    }, 3000);
  };

  const triggerCSVDownload = () => {
    document.getElementById("react-csv").click();
  };

  const { loading: planLoading, error: planError } = useQuery(GET_PLANS, {
    variables: {
      limit: parseInt(process.env.REACT_APP_API_PAGE_LIMIT),
      offset: process.env.REACT_APP_API_PAGE_LIMIT * (tableData.page - 1),
      where: {
        plan_name: { _ilike: `%${searchText}%` },
        ...filterObj,
      },
      order_by: {
        id: planIdOrder,
        created_at: planIdOrder ? null : "desc",
      },
    },
    fetchPolicy: "network-only",
    onCompleted: planQueryOnComplete,
  });
  
  const [exportPlanCSV, { loading: exportLoading, error: exportError },] = useLazyQuery(EXPORT_PLANS, {
    variables: {
      where: {
        plan_name: { _ilike: `%${searchText}%` },
        ...filterObj,
      },
      order_by: {
        id: planIdOrder,
        created_at: planIdOrder ? null : "asc",
      },
    },
    fetchPolicy: "network-only",
    onCompleted: formatCSVData,
  });
  
  const {data: filterData, error: filterError,} = useQuery(GET_PLAN_DATA, {
    onCompleted: planDataQueryOnComplete,
  });
  
  const loadMoreItems = (value) => {
    if (!planLoading && tableData.hasMoreItems) {
      setTableData((data) => {
        return { ...data, page: data.page + 1 };
      });
    }
  };
  
  const resetState = () => {
    setTableData({
      page: 1,
      data: [],
      hasMoreItems: true,
    });
    setPagination({
      ...pagination,
      total: 0,
      current: 1,
    });
  };
  
  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    if (sorter.field === "planId") {
      let order = sorter.order
        ? sorter.order === "descend"
          ? "desc"
          : "asc"
        : null;
      SetPlanIdOrder(order);
    }
    setTableData({
      ...tableData,
      page: pagination.current,
    });
    setPagination(pager);
  };

  if (planError || filterError || exportError) {
    return <ErrorPage />;
  }

  return (
    <div>
      <CSVLink
        data={CSVData}
        headers={[
          { label: "Plan ID", key: "planId" },
          { label: "Scheme ID", key: "schemeId" },
          { label: "Plan Name", key: "plan_name" },
          { label: "Plan Start Date", key: "plan_start_date" },
          { label: "Plan End Date", key: "plan_end_date" },
          { label: "Status", key: "status" },
          { label: "Loan Type", key: "loan_type" },
          { label: "Rate Name", key: "rate_type" },
          { label: "Interest", key: "interest" },
          { label: "Interest Slab 2", key: "interest_slab_2" },
          { label: "Interest Slab 3", key: "interest_slab_3" },
          { label: "Bank Interest", key: "bank_interest" },
          { label: "LTV", key: "ltv" },
          { label: "Tenure", key: "tenure" },
          { label: "Minimum Amount", key: "minAmount" },
          { label: "Maximum Amount", key: "maxAmount" },
          { label: "Features", key: "feature" },
          { label: "Terms and Conditions", key: "tncs" },
          { label: "Created At", key: "created_at" },
          { label: "Updated At", key: "updated_at" },
        ]}
        filename={`Plans - ${moment().format("DD-MM-YYYY HH:mm")}.csv`}
        className="hidden"
        target="_blank"
        id="react-csv"
      >
        Download CSV
      </CSVLink>
      <div className="flex justify-between  align-center mb-4">
        <h2 className="text-green-primary">All Plans</h2>
        <div className="flex">
          <Button
            htmlType="submit"
            className=" rounded-lg flex align-center "
            type="primary"
            size={"large"}
            icon={<PlusOutlined />}
            onClick={() => {
              props.history.push("/plans/create");
            }}
          >
            Create new plan
          </Button>
          <Button
            className=" rounded-lg flex align-center ml-3 mr-3"
            type="primary"
            ghost
            size={"large"}
            onClick={() => {
              exportPlanCSV();
            }}
            loading={exportLoading}
          >
            Export
          </Button>
        </div>
      </div>
      <div className="w-100 position-relatives">
        <ReactTable
          columns={columns}
          data={tableData.data}
          table={{
            pagination: pagination,
          }}
          handleTableChange={handleTableChange}
          filter={filter}
          page={tableData.page}
          hasMoreItems={tableData.hasMoreItems}
          loadItems={() => {
            loadMoreItems(0);
          }}
          isToggleFullScreen={false}
          isToggleColumn={true}
          isMoreFilter={false}
          isSearch={true}
          loading={planLoading}
          onToolbarSearch={(value) => {
            resetState();
            SetSearchText(value);
          }}
          onToolbarMore={(index, value) => {
            const filterData = [...filter];
            filterData[index].show = value;
            resetState();

            setFilter(filterData);
          }}
          onFilterSelectChange={(index, key, value) => {
            const filterData = [...filter];
            filterData[index].selected = value;
            resetState();
            setFilter(filterData);
          }}
          onChangeDate={(index, dataKey, value) => {
            // const filter = [...filter];
            //filter[index].fromDate = moment(value[0]).toISOString();
            // filter[index].toDate = moment(value[1]).toISOString();
            resetState();

            setFilter(filterData);
          }}
        />
      </div>
    </div>
  );
};

export default Plans;
