import React from 'react'
import { Table } from 'antd'

function GlobalTable(props) {
    
    const {
        handleTableChange,
        rowKey,
        loading = false,
        columns = [],
        data = [],
        bordered,
        pagination = false,
        type='simple',
        rowSelection = null,
        className = ''
    } = props;
    
    // const [max, setMax] = useState(perPage);
    // const [min, setMin] = useState(0);
    //
    // const totalPages = Math.floor(data?.length/perPage);
    // const [currentPage, setCurrenPage] = useState(1);
    // const [minPage, setMinPage] = useState(1);

    // const handleNext = (num) => {
    //     if (num < totalPages) {
    //         //data
    //         let minimum = min + perPage;
    //         if (minimum <= data?.length) {
    //             setMin(minimum);
    //         }
    //         let maximum = max + perPage;
    //         if (maximum <= data?.length) {
    //             setMax(maximum);
    //         }
    //         //page
    //         setCurrenPage(num+1);
    //         let minimumPage = num + 1;
    //         setMinPage(minimumPage);
    //
    //     }
    //
    // }
    //
    // const handlePrev = (num) => {
    //     if (num > 1) {
    //         //data
    //         let minimum = min - perPage;
    //         if (minimum > -1) {
    //             setMin(minimum);
    //         }
    //         let maximum = max - perPage;
    //         if (maximum <= data?.length) {
    //             setMax(maximum);
    //         }
    //         //page
    //         setCurrenPage(num-1);
    //         let minimumPage = num - 1;
    //         if (minimumPage > 0) {
    //             setMinPage(minimumPage);
    //         } else {
    //             setMinPage(0);
    //         }
    //     }
    // }

    return (
        <div className={`lg:w-full flex flex-col items-start ${className}`}>
            <Table
                vertical={true}
                rowSelection={rowSelection}
                className={`${type==='primary'?'primary-table':'simple-table'} oro-offer lg:w-full`}
                columns={columns}
                dataSource={data}
                pagination={pagination}
                bordered={bordered}
                loading={loading}
                rowKey={rowKey}
                onChange={handleTableChange}
            />
            {/*{*/}
            {/*    data?.length > perPage  && (*/}
            {/*        <div className="w-full flex flex-col lg:flex-row items-center justify-between py-6">*/}
            {/*        <p className="lg:py-0 py-3 text-left text-md text-black">*/}
            {/*            Showing {min+1} to {max} of {data?.length} Offers*/}
            {/*        </p>*/}
            {/*        <div className="flex flex-row items-center">*/}
            {/*            <p onClick={() => handlePrev(currentPage)} className="text-left text-md text-black font-bold px-2 cursor-pointer">Prev</p>*/}
            {/*            <div className="flex flex-row items-center px-2">*/}
            {/*                {*/}
            {/*                    minPage < totalPages && (*/}
            {/*                        <p className={`text-left text-md text-gray-500 font-bold px-2 cursor-pointer`}>*/}
            {/*                            {minPage}*/}
            {/*                        </p>*/}
            {/*                    )*/}
            {/*                }*/}
            {/*                {*/}
            {/*                    (totalPages - minPage) > 0 && (*/}
            {/*                        <p className={`text-left text-md text-gray-500 font-bold px-2 cursor-pointer`}>*/}
            {/*                            ..*/}
            {/*                        </p>*/}
            {/*                    )*/}
            {/*                }*/}
            {/*                <p className={`text-left text-md text-gray-500 font-bold px-2 cursor-pointer`}>*/}
            {/*                    {totalPages}*/}
            {/*                </p>*/}
            {/*            </div>*/}
            {/*            <p onClick={() => handleNext(currentPage)} className="text-left text-md upgraded-primary-color font-bold px-2 cursor-pointer upgraded-selector">Next</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    )*/}
            {/*}*/}
        </div>
    )
}

export default GlobalTable
