import gql from "graphql-tag";

export const BANK_ACCOUNT_DETAILS_FRAGMENT = gql`
  fragment userBankAccountDetails on user_bank_account_details {
    id
    bank_name
    bank_branch
    ifsc
    bank_account_name
    bank_account_number
    bank_proof
  }
`;
