import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Button, Popconfirm, notification, Tag } from "antd";
import moment from "moment";
import ErrorPage from "../../components/ErrorPage";
import Loading from "../../components/loading";
import { INACTIVATE_BRANCH, GET_BRANCH_DETAILS_BY_ID } from "./graphql";
import { LeftOutlined } from "@ant-design/icons";

const BranchDetails = (props) => {
  const {
    data: branchData,
    loading: branchDetailsLoading,
    error: branchDetailsError,
  } = useQuery(GET_BRANCH_DETAILS_BY_ID, {
    variables: {
      id: parseInt(props.match.params.id),
      isBranch: true,
    },
    fetchPolicy: "network-only",
  });

  const [inactivateBranch] = useMutation(INACTIVATE_BRANCH, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description: "Something went wrong!",
        });
      });
    },
    onCompleted() {
      notification.success({
        message: "Success!",
        description: "Branch inactivated!",
      });

      props.history.push("/partners");
    },
  });

  const onInactivateBranch = () => {
    inactivateBranch({ variables: { id: parseInt(props.match.params.id) } });
  };

  if (branchDetailsLoading) {
    return <Loading />;
  }

  if (!props.match.params.id || branchDetailsError) {
    return <ErrorPage />;
  }

  return (
    <div className="space-y-8">
      <div className="flex justify-between">
        <div className="flex items-center pl-3">
          <div className="relative">
            <LeftOutlined
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "#16A085",
              }}
            />
          </div>

          <Button
            type="link"
            onClick={() => {
              props.history.push("/partners");
            }}
          >
            All partners
          </Button>
        </div>

        <div className="space-x-4">
          {branchData.partner_branches_by_pk.partners_branches_x_loanTypes[0]
            .loan_type.loan_type === "SL" ? (
            <button
              className="cta hover:shadow-lg"
              style={{ height: "38px ", minWidth: "150px" }}
              onClick={() => {
                props.history.push(
                  `/partners/branch/edit/${props.match.params.id}`
                );
              }}
            >
              Edit branch
            </button>
          ) : null}
          {branchData.partner_branches_by_pk.is_active && (
            <Popconfirm
              title={`Are you sure you want to deactivate this branch?`}
              onConfirm={() => {
                onInactivateBranch();
                //   ActivatePlan({
                //     variables: {
                //       id: parseInt(props.match.params.id),
                //       status: !planData.plans[0].is_active
                //     }
                //   });
              }}
              onCancel={() => {}}
              okText="Yes"
              placement="bottomRight"
              cancelText="No"
            >
              <Button
                type={
                  !branchData.partner_branches_by_pk.is_active ? "primary" : ""
                }
                danger={branchData.partner_branches_by_pk.is_active}
                className="rounded-md"
              >
                {`Make as ${
                  branchData.partner_branches_by_pk.is_active ? "in" : ""
                }active`}
              </Button>
            </Popconfirm>
          )}
        </div>
      </div>

      <div>
        <h2>
          Branch Details |{" "}
          {branchData.partner_branches_by_pk.partner_branches_display_id}{" "}
        </h2>
        <div className="text-gray-600 flex space-x-1">
          {" "}
          <p className="text-gray-600">
            {" "}
            {`Created at ${moment(
              branchData.partner_branches_by_pk.created_at
            ).format("DD MMM YYYY HH:mm")} by ${
              branchData.partner_branches_by_pk.created_by.first_name
            } ${branchData.partner_branches_by_pk.created_by.last_name}`}
          </p>
          {/* <p>
            {branchData.partner_branches_by_pk.created_at !==
            branchData.partner_branches_by_pk.updated_at
              ? ` | Updated at ${moment(
                  branchData.partner_branches_by_pk.updated_at
                ).format("DD MMM YYYY HH:mm")} by ${
                  branchData.partner_branches_by_pk.updated_by.first_name
                } ${
                  branchData.partner_branches_by_pk.updated_by.last_name
                }`
              : ""}
          </p> */}
        </div>
      </div>

      <div>
        <h4 className="mb-2">BRANCH OVERVIEW</h4>
        <div className="flex bg-green-primary">
          <div className=" font-semibold text-white p-3 w-1/4">Branch ID</div>
          <div className=" font-semibold text-white p-3 w-1/4">Branch Name</div>
          <div className=" font-semibold text-white p-3 w-1/4">Partner</div>
          <div className=" font-semibold text-white p-3 w-1/4">
            Applicable loan types{" "}
          </div>
          <div className=" font-semibold text-white p-3 w-1/6">
            Branch Status{" "}
          </div>
        </div>
        <div className="flex bg-white">
          <div className="font-semibold p-3 w-1/4">
            {branchData.partner_branches_by_pk.partner_branches_display_id}
          </div>
          <div className="font-semibold p-3 w-1/4">
            {branchData.partner_branches_by_pk.partner_branch_name}
          </div>
          <div className="font-semibold p-3 w-1/4">
            {branchData.partner_branches_by_pk.partner.partner_name}
          </div>
          <div className="font-semibold p-3 w-1/4">
            {(
              branchData.partner_branches_by_pk.partners_branches_x_loanTypes ||
              []
            )
              .map((loanType) => {
                return loanType.loan_type.loan_type || "";
              })
              .toString()
              .split(",")
              .join(", ")}
          </div>

          <div className="font-semibold p-3 w-1/6">
            {branchData.partner_branches_by_pk.is_active ? (
              <Tag color={"green"}>Active</Tag>
            ) : (
              <Tag color={"red"}>Inactive</Tag>
            )}
          </div>
        </div>
      </div>

      {/* Partner details */}
      {branchData.partner_branches_by_pk.address ? (
        <div>
          <h4 className="mb-2">BRANCH DETAILS</h4>
          <div className="flex">
            <div className="flex flex-col w-1/4 bg-green-primary">
              {/* <div className="font-semibold text-white p-3">POC Name</div>
            <div className="font-semibold text-white p-3">POC email</div>
            <div className="font-semibold text-white p-3">
              POC contact number
            </div> */}
              <div className="font-semibold text-white p-3">Address</div>
              <div className=" font-semibold text-white p-3">Lat Long</div>
            </div>

            <div className="flex flex-col w-3/4 bg-white">
              {/* <div className="font-semibold p-3">
              {branchData.partner_branches_by_pk.poc_name}
            </div>
            <div className="font-semibold p-3">
              {branchData.partner_branches_by_pk.poc_email}
            </div>
            <div className="font-semibold p-3">
              {branchData.partner_branches_by_pk.poc_phone_number}
            </div> */}
              <div className="font-semibold p-3">
                {branchData.partner_branches_by_pk.address ? (
                  <div>
                    {branchData.partner_branches_by_pk.address.addr_line_1},{" "}
                    {branchData.partner_branches_by_pk.address.addr_line_2},{" "}
                    {branchData.partner_branches_by_pk.address.city},{" "}
                    {branchData.partner_branches_by_pk.address.state},{" "}
                    {branchData.partner_branches_by_pk.address.pincode}
                  </div>
                ) : null}
              </div>

              <div className="font-semibold p-3 w-1/6">
                {branchData.partner_branches_by_pk.lat},{" "}
                {branchData.partner_branches_by_pk.long}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* Partner details */}

      {/* BLC DETAILS */}
      <div className="mt-8">
        {branchData.partner_branches_by_pk.blc ? (
          <>
            <h4 className="mb-2">BRANCH LOAN CHECKER DETAILS</h4>
            <div className="flex bg-green-primary">
              <div className=" font-semibold text-white p-3 w-1/3">Name</div>
              <div className=" font-semibold text-white p-3 w-1/3">
                Mobile number
              </div>
              <div className=" font-semibold text-white p-3 w-1/3">
                Email address
              </div>
            </div>
            <div className="flex bg-white">
              <div className=" font-semibold p-3 w-1/3 break-all">
                {branchData.partner_branches_by_pk.blc.first_name}{" "}
                {branchData.partner_branches_by_pk.blc.last_name}
              </div>
              <div className=" font-semibold p-3 w-1/3 break-all">
                {branchData.partner_branches_by_pk.blc.mobile_number}
              </div>
              <div className=" font-semibold p-3 w-1/3 break-all">
                {branchData.partner_branches_by_pk.blc.email_id}
              </div>
            </div>{" "}
          </>
        ) : null}
      </div>
      {/* BLC DETAILS */}

      {/* BLV DETAILS */}
      <div className="mt-8">
        {branchData.partner_branches_by_pk.blv ? (
          <>
            <h4 className="mb-2">BRANCH LOAN VERIFIER DETAILS</h4>
            <div className="flex bg-green-primary">
              <div className=" font-semibold text-white p-3 w-1/3">Name</div>
              <div className=" font-semibold text-white p-3 w-1/3">
                Mobile number
              </div>
              <div className=" font-semibold text-white p-3 w-1/3">
                Email address
              </div>
            </div>
            <div className="flex bg-white">
              <div className=" font-semibold p-3 w-1/3 break-all">
                {branchData.partner_branches_by_pk.blv.first_name}{" "}
                {branchData.partner_branches_by_pk.blv.last_name}
              </div>
              <div className=" font-semibold p-3 w-1/3 break-all">
                {branchData.partner_branches_by_pk.blv.mobile_number}
              </div>
              <div className=" font-semibold p-3 w-1/3 break-all">
                {branchData.partner_branches_by_pk.blv.email_id}
              </div>
            </div>{" "}
          </>
        ) : null}
      </div>
      {/* BLV DETAILS */}

      {/* PLANS */}
      <div className="mt-8">
        {branchData.partner_branches_by_pk.plans_x_partner_branches.length >
        0 ? (
          <>
            <h4 className="mb-2">PLANS</h4>
            <div className="flex bg-green-primary">
              <div className=" font-semibold text-white p-3 w-1/3">Plan ID</div>
              <div className=" font-semibold text-white p-3 w-1/3">
                Plan name
              </div>
            </div>
            {branchData.partner_branches_by_pk.plans_x_partner_branches.map(
              (plans) => {
                return (
                  <div className="flex bg-white">
                    <div className=" font-semibold p-3 w-1/3 break-all">
                      {plans.plan.plan_display_id}
                    </div>
                    <div className=" font-semibold p-3 w-1/3 break-all">
                      {plans.plan.plan_name}
                    </div>
                  </div>
                );
              }
            )}
          </>
        ) : null}
      </div>
      {/* BLV DETAILS */}
    </div>
  );
};

export default BranchDetails;
