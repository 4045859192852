// DEPS
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSubscription } from "@apollo/client";
import gql from "graphql-tag";
import { Link } from "react-router-dom";
import moment from "moment";
import LogoutTimer from "./LogoutTimer";
import jwtDecode from "jwt-decode";
import { logout } from "../utils/userSessions";

// COMPONENTS
import {Popconfirm, Menu, Dropdown, Badge, notification} from "antd";

// ASSETS
import Logo from "../assets/logo@2x.png";
import NotificationIcon from "../assets/logo192.png";
import { NotificationOutlined } from "@ant-design/icons";

const GET_REQUESTED_VISITS = gql`
  subscription getAssignedVisits {
    visits_aggregate(where: { visit_status: { _eq: "VISIT_REQUESTED" } }) {
      aggregate {
        count
      }
      nodes {
        id
        visit_display_id
      }
    }
  }
`;

const GET_LOANS_PENDING_OROCORP_APPROVAL = gql`
  subscription getLoansPendingOrocorpApproval {
    loans_aggregate(
      where: { loan_status: { _eq: "PENDING_OROCORP_APPROVAL" } }
    ) {
      aggregate {
        count
      }
      nodes {
        id
        loan_number
      }
    }
  }
`;

const GET_STORED_LOANS = gql`
  subscription getStoredLoans($date: timestamptz!) {
    loans_aggregate(
      where: {
        loan_status: { _eq: "GOLD_STORED" }
        partner_branch_stored_at: { _gte: $date }
      }
    ) {
      aggregate {
        count
      }
      nodes {
        id
        loan_number
        partner_branch_stored_at
      }
    }
  }
`;

const GET_UNRESOLVED_CALLBACK_REQUESTS = gql`
  subscription getUnresolvedCallbackRequests {
    callback_requests_aggregate(where: { is_resolved: { _eq: false } }) {
      aggregate {
        count
      }
      nodes {
        id
      }
    }
  }
`;

const CustomMenuItem = (props) => {
  const { id, text, type, loan_status, ...other } = props;

  if (type === "callbacks")
    return (
      <Link to={`/callback-requests`}>
        <Menu.Item {...other} key={id}>
          <p className="p-2">
            {text}: {id}
          </p>
        </Menu.Item>
      </Link>
    );
  return (
    <Link
      to={{
        pathname: `/${type}/view/${id}`,
        state: loan_status
          ? {
              loan_status: loan_status,
            }
          : null,
      }}
    >
      <Menu.Item {...other} key={id}>
        <p className="p-2">{text}</p>
      </Menu.Item>
    </Link>
  );
};

const Nav = (props) => {
  const NotificationDropdown = () => {
    return (
      <Menu id="notifications-menu">
        {storedLoans?.loans_aggregate?.nodes?.map((node) => (
          <CustomMenuItem
            id={node.id}
            text={`Loan ID: ${node.loan_number} Stored`}
            type="loans"
          />
        ))}
        {pendingApprovalLoans?.loans_aggregate?.nodes?.map((node) => (
          <CustomMenuItem
            id={node.id}
            text={`Loan ID: ${node?.loan_number} Pending Approval`}
            type="loans"
            loan_status="PENDING_OROCORP_APPROVAL"
          />
        ))}
        {requestedVisits?.visits_aggregate?.nodes?.map((node) => (
          <CustomMenuItem
            id={node.id}
            text={`Visit ID: ${node?.visit_display_id} Requested`}
            type="visits"
          />
        ))}
        {unresolvedCallbacks?.callback_requests_aggregate?.nodes?.map(
          (node) => (
            <CustomMenuItem
              id={node?.id}
              text="New callbacks requested"
              type="callbacks"
            />
          )
        )}
      </Menu>
    );
  };
  
  const { data: requestedVisits } = useSubscription(GET_REQUESTED_VISITS);

  const { data: pendingApprovalLoans } = useSubscription(GET_LOANS_PENDING_OROCORP_APPROVAL);

  const { data: storedLoans } = useSubscription(GET_STORED_LOANS, {
    variables: { date: moment().startOf("day").subtract(1, "days") }
  });

  const { data: unresolvedCallbacks } = useSubscription(GET_UNRESOLVED_CALLBACK_REQUESTS);

  const [prevRequestedVisitsCount, setPrevRequestedVisitsCount] = useState(0);
  const [prevPendingApprovalLoansCount, setPrevPendingApprovalLoansCount] = useState(0);
  const [prevStoredLoansCount, setPrevStoredLoansCount] = useState(0);
  const [prevUnresolvedCallbacksCount, setPrevUnresolvedCallbacksCount] = useState(0);

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      window.Notification.requestPermission();
    }
  });

  useEffect(() => {
    // if (payments) {
    //   if (
    //     prevPaymentCount !== payments.payments_aggregate.aggregate.count &&
    //     payments.payments_aggregate.aggregate.count !== 0
    //   ) {
    //     var options = {
    //       // body: "New loan request received!",
    //       dir: "ltr",
    //     };
    //     //  var notification = new window.Notification("Notification Demo", options);
    //     new window.Notification("New payments received!", options);
    //     setPrevPaymentCount(payments.payments_aggregate.aggregate.count);
    //   }
    //   if (payments.payments_aggregate.aggregate.count === 0) {
    //     setPrevPaymentCount(0);
    //   }
    // }

    if (unresolvedCallbacks) {
      if (prevUnresolvedCallbacksCount !== unresolvedCallbacks?.callback_requests_aggregate?.aggregate.count && unresolvedCallbacks?.callback_requests_aggregate?.aggregate.count !== 0) {
        new window.Notification("New callback requests!", {dir: "ltr", icon: NotificationIcon});
        setPrevUnresolvedCallbacksCount(unresolvedCallbacks?.callback_requests_aggregate?.aggregate.count || 0);
      }
      if (unresolvedCallbacks?.callback_requests_aggregate?.aggregate.count === 0) {
        setPrevUnresolvedCallbacksCount(0);
      }
    }

    if (storedLoans) {
      if (prevStoredLoansCount !== storedLoans?.loans_aggregate?.aggregate?.count && storedLoans?.loans_aggregate?.aggregate?.count !== 0) {
        new window.Notification("New loans stored!", {dir: "ltr", icon: NotificationIcon});
        setPrevStoredLoansCount(storedLoans?.loans_aggregate?.aggregate?.count || 0);
      }
      if (storedLoans.loans_aggregate.aggregate.count === 0) {
        setPrevStoredLoansCount(0);
      }
    }

    if (pendingApprovalLoans) {
      if (prevPendingApprovalLoansCount !== pendingApprovalLoans?.loans_aggregate?.aggregate?.count && pendingApprovalLoans?.loans_aggregate?.aggregate?.count !== 0) {
        new window.Notification("New loans pending approval!", {dir: "ltr", icon: NotificationIcon});
        setPrevPendingApprovalLoansCount(pendingApprovalLoans?.loans_aggregate?.aggregate?.count || 0);
      }
      if (pendingApprovalLoans.loans_aggregate.aggregate.count === 0) {
        setPrevPendingApprovalLoansCount(0);
      }
    }

    if (requestedVisits) {
      if (prevRequestedVisitsCount !== requestedVisits?.visits_aggregate?.aggregate?.count && requestedVisits?.visits_aggregate?.aggregate?.count !== 0) {
        new window.Notification("New visits requested!", {dir: "ltr", icon: NotificationIcon});
        setPrevRequestedVisitsCount(requestedVisits?.visits_aggregate?.aggregate?.count || 0);
      }
      if (requestedVisits?.visits_aggregate?.aggregate?.count === 0) {
        setPrevRequestedVisitsCount(0);
      }
    }
  }, [requestedVisits, pendingApprovalLoans, storedLoans, unresolvedCallbacks, prevPendingApprovalLoansCount, prevRequestedVisitsCount, prevStoredLoansCount, prevUnresolvedCallbacksCount]);

  return (
    <div
      className="shadow-md bg-green-primary flex w-full justify-between pr-3"
      style={{ height: "64px" }}
    >
      <div className="p-3">
        <img
          src={Logo}
          alt="orocorp-logo"
          style={{
            height: "40px",
            width: "auto",
          }}
        />
      </div>
      <div className="flex justify-center items-center space-x-4">
        <Dropdown
          overlay={NotificationDropdown}
          trigger={["click"]}
          className="relative"
        >
          <Badge
            size="small"
            count={prevRequestedVisitsCount + prevPendingApprovalLoansCount + prevStoredLoansCount + prevUnresolvedCallbacksCount}
          >
            <span
              className="ant-dropdown-link text-white pr-0 cursor-pointer"
              onClick={(e) => e.preventDefault()}
            >
              Notifications <NotificationOutlined className="cursor-pointer " />
            </span>
          </Badge>
        </Dropdown>
        <LogoutTimer/>
        <p className="text-white">Logged in as {jwtDecode(localStorage.getItem('token')).email}</p>
        <Popconfirm
          title={`Are you sure you want to log out?`}
          onConfirm={async () => {
            logout().then(res => {
              if (res.code === "200") {
                localStorage.clear();
                window.location.assign("/");
              } else {
                notification.error({
                  message: "Something went wrong",
                  description: res.message,
                });
              }
            });
          }}
          onCancel={() => {}}
          okText="Yes"
          placement="bottomRight"
          cancelText="No"
        >
          <button
            className="btn-danger border-none"
            style={{ height: "32px", lineHeight: "16px", outline: "none" }}
          >
            Log out
          </button>
        </Popconfirm>
      </div>
    </div>
  );
};

Nav.protoTypes = {
  title: PropTypes.string,
};
export default Nav;
