import * as React from "react";

import {
  ReactForm,
  ReactInput,
  ReactCheckboxGroup,
  ReactSelect,
  //   ReactGeocoding,
} from "../../components/Form";
import Loading from "../../components/loading";
import ErrorPage from "../../components/ErrorPage";
import { LeftOutlined } from "@ant-design/icons";
import { GET_LOAN_TYPES } from "../Plan/graphql";
import {
  GET_SL_PARTNERS_LIST,
  GET_LATEST_BRANCH_ID,
  INSERT_PARTNER_BRANCH,
  INSERT_BLC_BLV,
} from "./graphql";
// import jwt_decode from "jwt-decode";
import { useQuery, useMutation } from "@apollo/client";
import { branchValidationSchema } from "./validationSchema";
// import axios from "axios";
import { Select, notification, Popconfirm, Button } from "antd";
const { Option } = Select;

const CreateBranch = (props) => {
  const formRef = React.useRef();

  const {loading: loadingLoanTypes, error: loanTypesQueryError} = useQuery(GET_LOAN_TYPES);

  const { error: latestBranchQueryError } = useQuery(GET_LATEST_BRANCH_ID);

  const {data: partnersData, loading: loadingPartners, error: partnersQueryError} = useQuery(GET_SL_PARTNERS_LIST);
  
  // eslint-disable-next-line
  const [insertBlcBlv] = useMutation(INSERT_BLC_BLV, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description: "Something went wrong!",
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Lending partner branch created",
      });
      props.history.push(
        `/partners/branch/view/${data.insert_partner_branch.id}`
      );
    },
  });

  // const {
  //   data: partnersData,
  //   loading: loadingPartners,
  //   error: partnersQueryError
  // } = useQuery(GET_PARTNERS_LIST);

  const [insertPartnerBranch] = useMutation(INSERT_PARTNER_BRANCH, {
    onError(error) {
      console.log("error", error);
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description: "Something went wrong!",
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Lending partner branch created",
      });
      props.history.push(
        `/partners/branch/view/${data.insert_partner_branches_one.id}`
      );
    },
  });

  if (loadingLoanTypes || loadingPartners) return <Loading />;

  if (loanTypesQueryError || partnersQueryError || latestBranchQueryError)
    return <ErrorPage />;

  //   let LoanTypesoptions = loanTypesData.loan_types.map((loan_type) => {
  //     let obj = {
  //       label: loan_type.loan_type,
  //       value: loan_type.id,
  //       disabled: loan_type.loan_type === "AL" || loan_type.loan_type === "TL",
  //     };

  //     return obj;
  //   });

  let initialValues = {
    branch_name: "",
    partner: props.location.state
      ? parseInt(props.location.state.partner_id)
      : "",
    // partner: "Federal Bank",
    // loan_types: [],
    // blcfirstname: "",
    // blclastname: "",
    // blcemail: "",
    // blcnumber: "",
    // blvfirstname: "",
    // blvlastname: "",
    // blvemail: "",
    // blvnumber: "",
    // address: {
    //   addr_line_1: "",
    //   addr_line_2: "",
    //   city: "",
    //   state: "",
    //   pincode: "",
    //   country: "India",
    //   label: "Home",
    //   preferred: "yes",
    // },
    // lat: "",
    // lng: "",
    // isChecked: {
    //   Monday: false,
    //   Tuesday: false,
    //   Wednesday: false,
    //   Thursday: false,
    //   Friday: false,
    //   Saturday: false,
    //   Sunday: false
    // },
    // hours: {
    //   Monday: {},
    //   Tuesday: {},
    //   Wednesday: {},
    //   Thursday: {},
    //   Friday: {},
    //   Saturday: {},
    //   Sunday: {}
    // }
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-start">
        <Button
          type="link"
          icon={<LeftOutlined />}
          className="flex items-center mr-4"
          onClick={() => {
            props.history.push("/partners");
          }}
        >
          All Partners
        </Button>
      </div>

      <div className="flex justify-between">
        <h1> Create new branch</h1>
      </div>

      <ReactForm
        innerRef={formRef}
        onSubmit={(values) => {
          let obj = {
            partner_branch_name: values.branch_name,
            partners_id: values.partner,
            partners_branches_x_loanTypes: {
              data: {
                loan_type_id: process.env.REACT_APP_SL_LOAN_TYPE_ID,
              },
            },
          };

          insertPartnerBranch({ variables: { obj: obj } });

          //   let query = {
          //     query: `
          //       query MyQuery($name:String!) {
          //         partner_branches(where: {partner_branch_name: {_eq: $name}}) {
          //            id
          //         }
          //       }
          //     `,
          //   };

          //   query.variables = {
          //     name: values.branch_name,
          //   };

          //   axios
          //     .post(`${process.env.REACT_APP_GQL_ENDPOINT}`, query, {
          //       headers: {
          //         "content-type": "application/json",
          //         "x-hasura-admin-secret": "Remember001",
          //       },
          //     })
          //     .then((result) => {
          //       if (result.data.data.partner_branches.length > 0) {
          //         notification.error({
          //           message: "Error!",
          //           description: "Branch already exists",
          //         });
          //       }
          //       // return;
          //       else {
          //         let loanTypes = values.loan_types.map((loan_type) => {
          //           return { loan_type_id: loan_type };
          //         });

          //         // insertBlcBlv

          //         let requestData = {
          //           address: values.address,
          //           lat: values.lat,
          //           long: values.lng,
          //           name: values.branch_name,
          //           working_hours: values.hours,
          //           partners_id: values.partner,
          //           partners_branches_x_loanTypes: {
          //             data: loanTypes,
          //           },
          //           obj: [
          //             {
          //               role_name: "BLC",
          //               first_name: values.blcfirstname,
          //               last_name: values.blclastname,
          //               email_id: values.blcemail,
          //               mobile_number: values.blcnumber,
          //             },
          //             {
          //               role_name: "BLV",
          //               first_name: values.blvfirstname,
          //               last_name: values.blvlastname,
          //               email_id: values.blvemail,
          //               mobile_number: values.blvnumber,
          //             },
          //           ],
          //         };

          // let requestData = {
          //   partner_branch_name: values.branch_name,
          //   poc_name: values.name,
          //   poc_email: values.email,
          //   poc_phone_number: values.number,
          //   address: values.address,
          //   lat: values.lat,
          //   long: values.lng,
          //   working_hours: values.hours,
          //   partners_id: values.partner,
          //   created_by: jwt_decode(localStorage.getItem("token")).user_id,
          //   partners_branches_x_loanTypes: {
          //     data: loanTypes
          //   },
          //   user: {
          //     data: {
          //       role_name: "PARTNER_BRANCH"
          //     }
          //   }
          // };

          //              $address: jsonb!
          // $lat: float8!
          // $long: float8!
          // $name: String!
          // $partner_id: Int!
          // $working_hours: jsonb!
          // $loan_types: [partner_branches_x_loanType_insert_input!]!

          // address: values.address,
          //               lat: values.lat,
          //               long: values.lng,
          //               name: values.branch_name,
          //               working_hours: values.hours,
          //               partners_id: 28,
          //               partners_branches_x_loanTypes: {
          //                 data: loanTypes
          //               },

          // insertBlcBlv({
          //   variables: {
          //     obj: requestData.obj,
          //     partner_branch: {
          //       address: requestData.address,
          //       lat: requestData.lat,
          //       long: requestData.long,
          //       partners_id: requestData.partners_id,
          //       partner_branch_name: requestData.name,
          //       partners_branches_x_loanTypes: loanTypes,
          //       // working_hours: requestData.working_hours
          //     },
          //   },
          // });
          //   }
          // })
          // .catch((e) => {
          //   console.log("Error", e);
          // });
          return;
        }}
        initialValues={initialValues}
        validationSchema={branchValidationSchema}
        formCls="flex flex-col content-center justify-center space-y-8"
      >
        {/* Branch overview section */}
        <div className="border">
          {/* Branch overview header */}
          <div className="border-b w-full px-4 py-2 bg-green-primary">
            <h2 className="text-white">Branch overview</h2>
          </div>
          {/* Branch overview header */}

          {/* Branch overview form */}
          <div className="p-4 space-y-4">
            {/* Branch overview form - line 1*/}
            <div className="flex space-x-8 items-center">
              <div className="w-1/3">
                <ReactInput
                  name="branch_name"
                  type="text"
                  label="Branch Name"
                  placeholder=""
                />
              </div>

              <div className="w-1/3">
                <ReactSelect
                  name="partner"
                  type="text"
                  label="Partner"
                  placeholder="Choose partner"
                  // disabled={true}
                  // defaultValue={props.location.state.partner_id}
                >
                  {partnersData.partners.map((partner) => {
                    return (
                      <Option value={partner.id}>{partner.partner_name}</Option>
                    );
                  })}
                </ReactSelect>
              </div>

              <div className="w-1/3">
                <ReactCheckboxGroup
                  name="loan_types"
                  label="Available loan types"
                  options={["GL"]}
                  value="GL"
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Partner details section */}
        {/* <div className="border"> */}
        {/* Partner details header */}
        {/* <div className="border-b w-full px-4 py-2 bg-green-primary">
            <h2 className="text-white">Branch details</h2>
          </div> */}
        {/* Partner details header */}

        {/* Partner details form */}
        {/* <div className="p-4 space-y-4"> */}
        {/* Partner details form - line 1*/}
        {/* <div className="flex space-x-8 items-center">
              <div className="w-2/3 flex space-x-8">
                <div className="w-1/2 flex  space-x-4">
                  <div className="w-1/2">
                    <ReactInput
                      name="blcfirstname"
                      type="text"
                      label="BLC First Name"
                      placeholder=""
                    ></ReactInput>
                  </div>

                  <div className="w-1/2">
                    <ReactInput
                      name="blclastname"
                      type="text"
                      label="BLC Last Name"
                      placeholder=""
                    ></ReactInput>
                  </div>
                </div>

                <div className="w-1/2">
                  <ReactInput
                    name="blcemail"
                    type="text"
                    label="BLC email"
                    placeholder=""
                  ></ReactInput>
                </div>
              </div>

              <div className="w-1/3">
                <ReactInput
                  name="blcnumber"
                  type="text"
                  label="BLC contact number"
                  placeholder=""
                ></ReactInput>
              </div>
            </div> */}
        {/* Partner details form - line 1*/}

        {/* Partner details form - line 1*/}
        {/* <div className="flex space-x-8 items-center">
              <div className="w-2/3 flex space-x-8">
                <div className="w-1/2 flex  space-x-4">
                  <div className="w-1/2">
                    <ReactInput
                      name="blvfirstname"
                      type="text"
                      label="BLV First Name"
                      placeholder=""
                    ></ReactInput>
                  </div>

                  <div className="w-1/2">
                    <ReactInput
                      name="blvlastname"
                      type="text"
                      label="BLV Last Name"
                      placeholder=""
                    ></ReactInput>
                  </div>
                </div>

                <div className="w-1/2">
                  <ReactInput
                    name="blvemail"
                    type="text"
                    label="BLV email"
                    placeholder=""
                  ></ReactInput>
                </div>
              </div>

              <div className="w-1/3">
                <ReactInput
                  name="blvnumber"
                  type="text"
                  label="BLV contact number"
                  placeholder=""
                ></ReactInput>
              </div>
            </div> */}
        {/* Partner details form - line 1*/}

        {/* Partner details form - line 2*/}
        {/* <div className="space-y-2">
              <p className="text-gray-800 font-semibold text-lg">Address</p>
              <div className="w-2/3">
                <ReactInput
                  name="address.addr_line_1"
                  type="text"
                  placeholder="Address line 1"
                />
              </div>
              <div className="w-2/3">
                <ReactInput
                  name="address.addr_line_2"
                  type="text"
                  placeholder="Address line 2"
                />
              </div>
              <div className="w-2/3 flex space-x-8">
                <div className="w-1/3">
                  <ReactInput
                    name="address.city"
                    type="text"
                    placeholder="City"
                  />
                </div>

                <div className="w-1/3">
                  <ReactSelect
                    name="address.state"
                    type="text"
                    placeholder="State"
                  >
                    <Option value="Andhra Pradesh">Andhra Pradesh</Option>
                    <Option value="Andaman and Nicobar Islands">
                      Andaman and Nicobar Islands
                    </Option>
                    <Option value="Arunachal Pradesh">Arunachal Pradesh</Option>
                    <Option value="Assam">Assam</Option>
                    <Option value="Bihar">Bihar</Option>
                    <Option value="Chandigarh">Chandigarh</Option>
                    <Option value="Chhattisgarh">Chhattisgarh</Option>
                    <Option value="Dadra and Nagar Haveli and Daman and Diu">
                      Dadra and Nagar Haveli and Daman and Diu
                    </Option>
                    <Option value="Delhi">Delhi</Option>
                    <Option value="Goa">Goa</Option>
                    <Option value="Gujarat">Gujarat</Option>
                    <Option value="Haryana">Haryana</Option>
                    <Option value="Himachal Pradesh">Himachal Pradesh</Option>
                    <Option value="Jammu and Kashmir">Jammu and Kashmir</Option>
                    <Option value="Jharkhand">Jharkhand</Option>
                    <Option value="Karnataka">Karnataka</Option>
                    <Option value="Kerala">Kerala</Option>
                    <Option value="Ladakh">Ladakh</Option>
                    <Option value="Lakshadweep">Lakshadweep</Option>
                    <Option value="Madhya Pradesh">Madhya Pradesh</Option>
                    <Option value="Maharashtra	">Maharashtra </Option>
                    <Option value="Manipur">Manipur</Option>
                    <Option value="Meghalaya">Meghalaya</Option>
                    <Option value="Mizoram">Mizoram</Option>
                    <Option value="Nagaland">Nagaland</Option>
                    <Option value="Odisha">Odisha</Option>
                    <Option value="Puducherry">Puducherry</Option>
                    <Option value="Punjab">Punjab</Option>
                    <Option value="Rajasthan">Rajasthan</Option>
                    <Option value="Sikkim">Sikkim</Option>
                    <Option value="Tamil Nadu">Tamil Nadu</Option>
                    <Option value="Telangana">Telangana</Option>
                    <Option value="Tripura">Tripura</Option>
                    <Option value="Uttar Pradesh">Uttar Pradesh</Option>
                    <Option value="Uttarakhand">Uttarakhand</Option>
                    <Option value="West Bengal">West Bengal</Option>
                  </ReactSelect>
                </div>

                <div className="w-1/3">
                  <ReactInput
                    name="address.pincode"
                    type="text"
                    placeholder="Pincode"
                  />
                </div>
              </div>
            </div> */}
        {/* Partner details form - line 2*/}

        {/* <ReactGeocoding /> */}

        {/* Partner details form - line 3*/}
        {/* <div className="flex space-x-8 items-center">
              <div className="w-1/3 space-y-2">
                <p className="text-gray-800 font-semibold text-lg">
                  Address lat, long
                </p>

                <div className="w-full flex space-x-8">
                  <div className="w-1/2">
                    <ReactInput name="lat" type="text"></ReactInput>
                  </div>

                  <div className="w-1/2">
                    <ReactInput name="lng" type="text"></ReactInput>
                  </div>
                </div>
              </div>
            </div> */}
        {/* Partner details form - line 3*/}

        {/* Partner details form - line 4*/}
        {/* <div className="flex space-x-8 w-2/3">
              <div className="space-y-4 w-full">
                <p className="text-gray-800 font-semibold text-lg">
                  Working hours
                </p>

                <div className="flex space-x-8 items-center w-full">
                  <ReactWorkingHourspicker
                    // day={day}
                    name="hours"
                    checkedDays={checkedDays}
                    setCheckedDays={setCheckedDays}
                  />
                </div>
              </div>
            </div> */}
        {/* Partner details form - line 4*/}
        {/* </div> */}
        {/* Partner details form */}
        {/* </div> */}
        {/* Partner details section */}
      </ReactForm>

      <div className="flex justify-center w-full space-x-8">
        <Popconfirm
          title={`Are you sure you want to cancel?`}
          onConfirm={() => {
            props.history.push("/partners");
          }}
          onCancel={() => {}}
          okText="Yes"
          placement="bottomRight"
          cancelText="No"
        >
          <button className="btn-danger hover:shadow-lg">Cancel</button>
        </Popconfirm>
        {/* <button className="btn-primary hover:shadow-lg">Create Partner</button> */}
        <button
          onClick={() => handleSubmit()}
          type="submit"
          className="btn-primary hover:shadow-lg"
        >
          Create Branch
        </button>
      </div>
    </div>
  );
};

export default CreateBranch;
