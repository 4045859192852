import gql from "graphql-tag";

const GET_SUPPORT_LOANS = gql`
  query getSupportLoans(
    $limit: Int
    $offset: Int
    $where: support_loans_bool_exp = {}
    $order_by: [support_loans_order_by!] = {}
  ) {
    support_loans_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    support_loans(
      limit: $limit
      offset: $offset
      where: $where
      order_by: $order_by
    ) {
      id
      support_loan_id
      primary_loan_id
      created_at
      partner_branch {
        id
        partner_branch_name
        partner {
          id
          partner_name
        }
      }
      plan {
        id
        plan_name
        tenure
      }
      primary_loan {
        id
        loan_number
        loan_status
        kyc {
          id
          legal_name
        }
        orocorp_approved_by {
          id
          first_name
          last_name
        }
      }
      sl_amount {
        id
        loan_amount
      }
    }
  }
`;

const EXPORT_SUPPORT_LOANS = gql`
  query getSupportLoans(
    $where: support_loans_bool_exp = {}
    $order_by: [support_loans_order_by!] = {}
  ) {
    support_loans_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    support_loans(where: $where, order_by: $order_by) {
      id
      support_loan_id
      primary_loan_id
      partner_branch {
        id
        partner_branch_name
        partner {
          id
          partner_name
        }
      }
      plan {
        id
        plan_name
        tenure
      }
      primary_loan {
        id
        loan_number
        kyc {
          id
          legal_name
        }
        orocorp_approved_by {
          id
          first_name
          last_name
        }
      }
      sl_amount {
        id
        loan_amount
      }
    }
  }
`;

export { GET_SUPPORT_LOANS, EXPORT_SUPPORT_LOANS };
