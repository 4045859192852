import * as Yup from "yup";

export const supportLoanValidationSchema = Yup.object({
	sl_plan_name: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
	sl_interest: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
	sl_eligible_amount: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
});

export const pledgeCardValidationSchema = Yup.object({
	gross_weight: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
	stone_deduction: Yup.string().trim().matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
	net_weight: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
	pledge_card_identifier: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
	release_amount: Yup.string().trim().matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
	pledge_card_url: Yup.string().trim().required("Required"),
});

export const offerValidationSchema = Yup.object({
	lender_type: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
	lender_name: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
	release_amount_known: Yup.bool().required("Required"),
	customer_name: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
	customer_mobile_number: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
	agent_auth_id: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
	comments: Yup.string().trim().matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
	offers_pledge_cards: Yup.array().ensure().compact().min(1, "Required at least one pledge card").required("Required at least one pledge card").of(pledgeCardValidationSchema),
	// offer_plans: [],
});
