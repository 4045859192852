import React, { useState, useEffect } from "react";
import { Upload, notification, Modal } from "antd";
import { useFormikContext } from "formik";
// import S3FileUpload from "react-s3";
import axios from "axios";
import { get_image_url } from "../../utils";
// import "./style.scss";

// const s3Config = {
//   bucketName: process.env.REACT_APP_S3_NAME,
//   region: process.env.REACT_APP_S3_REGION,
//   accessKeyId: process.env.REACT_APP_S3_ACCESS_KEYID,
//   secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEYID
// };

const ImageUpload = (props) => {
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(null);

  const { errors, setFieldValue } = useFormikContext();

  function uploadFiles(urlList, fileList) {
    return new Promise((resolve, reject) => {
      const formUploadPromiseArray = fileList.map(
        (file, index) =>
          new Promise((resolveUpload, rejectUpload) => {
            const xhr = new XMLHttpRequest();
            xhr.file = file;
            xhr.onreadystatechange = function (e) {
              if (this.readyState === 4 && this.status === 200) {
                resolveUpload(urlList[index]);
              } else if (this.readyState === 4 && this.status !== 200) {
                rejectUpload(e);
              }
            };
            xhr.open("PUT", urlList[index], true);
            if (
              file.type === "image/png" ||
              file.type === "image/jpeg" ||
              file.type === "application/pdf"
            ) {
              xhr.setRequestHeader("Content-Type", file.type);
              xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
              xhr.setRequestHeader(
                "Access-Control-Allow-Headers",
                "Content-Type"
              );
              xhr.setRequestHeader("Access-Control-Allow-Methods", "*");
            } else if (
              file.type === "audio/wav" ||
              file.type === "audio/webm"
            ) {
              xhr.setRequestHeader("Content-Type", file.type);
              xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
              xhr.setRequestHeader(
                "Access-Control-Allow-Headers",
                "Content-Type"
              );
              xhr.setRequestHeader("Access-Control-Allow-Methods", "*");
            } else {
              xhr.setRequestHeader("Content-Type", "binary/octet-stream");
              xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
              xhr.setRequestHeader(
                "Access-Control-Allow-Headers",
                "Content-Type"
              );
              xhr.setRequestHeader("Access-Control-Allow-Methods", "*");
            }
            //          "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Headers": "Content-Type",
            // "Access-Control-Allow-Methods": "*"

            xhr.send(file);
          })
      );

      Promise.all(formUploadPromiseArray)
        .then((urls) => {
          resolve(urls.map((url) => url.split("?")[0]));
        })
        .catch((err) => {
          console.log("Error", err);
          reject(err);
        });
    });
  }

  const uploadToS3 = async ({
    action,
    data,
    file,
    filename,
    headers,
    onError,
    onProgress,
    onSuccess,
    withCredentials,
  }) => {
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      const GET_SIGNED_URL = {
        query: `
        mutation MyMutation ($filename: String!) {
          getSignedUrl(name: $filename) {
            signedUrl
          }
        }
      `,
      };

      GET_SIGNED_URL.variables = {
        filename: file.name,
      };

      const get_signed_url = await axios({
        method: "post",
        url: process.env.REACT_APP_GQL_ENDPOINT,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: GET_SIGNED_URL,
      })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.log("err: ", err);
        });

      const signed_url = get_signed_url.data.data.getSignedUrl.signedUrl;

      const filesArray = [file];
      const signedS3UrlArray = [signed_url];
      // const signedS3UrlArray = [res.data.data.getS3SignedUrl.signedUrl];
      const [uploadedResponse] = await uploadFiles(
        signedS3UrlArray,
        filesArray
      );

      onSuccess(uploadedResponse, file);
    }
  };

  // FOR EDIT PURPOSES

  useEffect(() => {
    updateFileList();
    // eslint-disable-next-line
  }, []);

  const updateFileList = () => {
    if (props.editMode) {
      let fileList;
      if (props.multiple) {
        fileList = props.pre_files.map((v, i) => ({
          uid: i,
          name: `Image - ${i + 1}`,
          response: v,
          url: v,
        }));
      } else {
        fileList = props.pre_files
          ? [
              {
                uid: 0,
                name: "Image",
                response: props.pre_files,
                url: props.pre_files,
              },
            ]
          : [];
      }
      setFileList(fileList);
    }
  };

  const handleChange = ({ file, fileList }) => {
    if (
      file.type !== "image/png" &&
      file.type !== "image/jpg" &&
      file.type !== "image/jpeg"
    ) {
      notification.error({
        message: "Error!",
        description:
          "File type not allowed. Please upload in .jpg, .jpeg or .png formats only",
      });
    } else {
      if (file.status === "done") {
        if (fileList.length > props.maxLength) {
          notification.warning({
            message: "Warning!",
            description: `Only ${
              props.maxLength === 1
                ? `${props.maxLength} image`
                : `${props.maxLength} images`
            } will be uploaded`,
          });
        }
        fileList = fileList.slice(-1 * props.maxLength);

        if (fileList.length) {
          let data = fileList.map((img) => img.response);
          setFieldValue(props.name, data[0]);
        } else {
          setFieldValue(props.name, []);
        }
      }
      setFileList(fileList);
    }
  };

  const customOnSuccess = ({ file, fileList }) => {
    if (
      file.type !== "image/png" &&
      file.type !== "image/jpg" &&
      file.type !== "image/jpeg"
    ) {
      notification.error({
        message: "Error!",
        description:
          "File type not allowed. Please upload in .jpg, .jpeg or .png formats only",
      });
    } else {
      if (file.status === "done") {
        if (fileList.length > props.maxLength) {
          notification.warning({
            message: "Warning!",
            description: `Only ${props.maxLength} image(s) will be uploaded`,
          });
        }
        fileList = fileList.slice(-1 * props.maxLength);
        if (fileList.length) {
          let data = fileList.map((img) => img.response);
          props.customOnSuccess(data);
        } else {
          props.customOnSuccess([]);
        }
      }

      if (file.status === "removed") {
        if (fileList.length) {
          let data = fileList.map((img) => img.response);
          props.customOnSuccess(data);
        } else {
          props.customOnSuccess([]);
        }
      }
      setFileList(fileList);
    }
  };

  return (
    <div className="space-y-2">
      <Upload
        accept={props.accept || ".jpg, .jpeg, .png"}
        className={props.className}
        customRequest={uploadToS3}
        // listType="picture-card"
        onPreview={async (file) => {
          //
          const valid_url = await get_image_url({
            url: [file.response],
          });

          setPreviewImage(valid_url);
          setPreviewVisible(true);
        }}
        fileList={fileList}
        onChange={props.customizeSuccess ? customOnSuccess : handleChange}
        multiple={props.multiple}
      >
        <div>
          <button type="button" className="cta hover:shadow-lg">
            {props.buttonText}
          </button>
        </div>
      </Upload>
      {props.showError && (
        <div style={{ minHeight: 21 }}>
          {errors[props.name] ? (
            <div className="error text-red-500">{errors[props.name]}</div>
          ) : null}
        </div>
      )}
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => {
          setPreviewImage("");
          setPreviewVisible(false);
        }}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default ImageUpload;
