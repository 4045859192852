import { Layout } from "antd";
import React, { useEffect } from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import Nav from "../components/Nav";
import AdminSidebar from "../components/Sidebar/adminSidebar";
// import Can from "../components/Can";
// import Sidebar from "../components/Sidebar/Sidebar";
// import ReactGA from 'react-ga';
import { version } from "../../package.json";
import { getToken } from "../utils";
import jwt_decode from "jwt-decode";

const { Content } = Layout;

const Auth = ({ component: Component, allowed_roles, history, ...rest }) => {

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.assign("/");
    } else if (jwt_decode(localStorage.getItem("token"))["X-Hasura-Default-Role"] === "partner_branch") {
      history.push(`/redirect`);
    } else {
      if (!allowed_roles.includes(jwt_decode(localStorage.getItem("token"))["X-Hasura-Default-Role"])) {
        history.push(`/not-found`);
      }
    }
  }, [allowed_roles, history]);

  return (
    <Route
      {...rest}
      render={(props) =>
        getToken() ? (
          <div>
            <Layout className="flex flex-row">
              <Layout
                style={{
                  width: "100vw",
                  minHeight: "100vh",
                  background: "#fff",
                }}
              >
                <div
                  className="pl-0"
                  style={{
                    zIndex: 99,
                    width: "100vw",
                    minHeight: "64px",
                  }}
                >
                  <Nav {...props} hideAdmin title={""} />
                </div>

                <div
                  className="flex flex-row"
                  style={{ minHeight: "calc(100vh - 64px)" }}
                >
                  <AdminSidebar props={props} />
                  <Content className="px-4 py-8 bg-gray-100">
                    <Component {...props} />
                    <div
                      style={{
                        position: "absolute",
                        width: "100%",
                        textAlign: "right",
                        paddingBottom: 10,
                        paddingRight: 10,
                        bottom: 0,
                        left: 0,
                      }}
                    >
                      {version}
                    </div>
                  </Content>
                </div>
              </Layout>
            </Layout>

            {/* <GAComponent Component={Component} {...props} /> */}
          </div>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default withRouter(Auth);
