import { Button, notification } from "antd";
import React, { useState } from "react";
import Layout from "./layout";
import "./style.css";
import GoogleLogin from "react-google-login";
import {verifyToken} from "../../utils/userSessions";

const Login = props => {
  const [loading, setLoading] = useState(false);
  
  const handleSuccess = (e) => {
    setLoading(true);
    verifyToken(e.tokenId).then(res => {
      if (res.code === "200") {
        localStorage.setItem("token", res.user.token);
        localStorage.setItem("refreshToken", res.user.refreshToken);
        props.setAuthenticated(true);
        setLoading(false);
      } else {
        notification.error({
          message: "Something went wrong",
          description: res.message,
        });
        setLoading(false);
      }
    });
  };
  
  const handleFailure = (e) => {
    console.log(e);
  }

  return (
    <div>
      <Layout loading={loading}>
        <div className="w-1/2 mx-auto bg-white rounded-lg text-left loginContainer">
          <div className="text-center flex flex-col items-center justify-center space-y-4">
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}
              render={(props) => (
                <Button
                  className="w-1/2 rounded-lg "
                  type="primary"
                  size={"large"}
                  onClick={props.onClick}
                >
                  Signin with Google
                </Button>
              )}
              onSuccess={handleSuccess}
              onFailure={handleFailure}
              cookiePolicy={'single_host_origin'}
            />
          </div>
        </div>
      </Layout>
    </div>
  );
};
export default Login;
