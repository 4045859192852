import React, { useState } from "react";
import { useFormikContext } from "formik";
import axios from "axios";
import SimpleMap from "../Map/map";

import { AutoComplete, Input } from "antd";

const ReactGeocoding = props => {
  const [lat, setLat] = useState(13.0827);
  const [lng, setLng] = useState(80.2707);
  const [markerVisibility, setMarkerVisibility] = useState(false);
  const [address, setAddress] = useState("");

  const [predictions, setPredictions] = useState([]);

  const { setFieldValue } = useFormikContext();

  const onMarker = (lat, lng) => {
    if (predictions.length === 0) {
      //   getAddressFromCoordinates(lat, lng);
      setMarkerVisibility(true);
    }

    setLat(lat);
    setLng(lng);

    setFieldValue("lat", lat);
    setFieldValue("lng", lng);
  };

  const onSelect = (value, option) => {
    setMarkerVisibility(true);
    setAddress(option.props.children);

    // getCoordinates(value);
  };
  const onSearch = search => {
    setAddress(search);

    getPredictions(search);
  };

  const getPredictions = searchText => {
    const url = `${process.env.REACT_APP_GET_PREDICTIONS_API}?search=${searchText}`;
    axios.post(url).then(res => setPredictions(res.data.data));
  };

  const options = predictions.map(pred => {
    return (
      <AutoComplete.Option key={pred.place_id}>
        {pred.description}
      </AutoComplete.Option>
    );
  });

  return (
    <div className="w-full space-y-4">
      <SimpleMap
        lat={lat}
        lng={lng}
        onMarker={onMarker}
        markerVisibility={markerVisibility}
      />
      <AutoComplete
        className="hideSuffix"
        value={address}
        style={{ width: "100%", marginBottom: "2rem" }}
        onSelect={onSelect}
        onSearch={e => onSearch(e)}
        placeholder="Search for place here"
        dataSource={options}
        allowClear
      >
        <Input value={address} />
      </AutoComplete>
    </div>
  );
};

export default ReactGeocoding;
