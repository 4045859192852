import * as Yup from "yup";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const addressSchema = {
  addr_line_1: Yup.string()
    .ensure()
    .required("Required")
    .matches(/^((?![()<>/;[\]{}]).)*$/gm, "Special Characters are not allowed"),
  addr_line_2: Yup.string()
    .ensure()
    .matches(/^((?![()<>/;[\]{}]).)*$/gm, "Special Characters are not allowed"),
  city: Yup.string()
    .ensure()
    .required("Required")
    .matches(/^((?![()<>/;[\]{}]).)*$/gm, "Special Characters are not allowed"),
  state: Yup.string().ensure().required("Required").matches(/^((?![()<>/;[\]{}]).)*$/gm, "Special Characters are not allowed"),
  pincode: Yup.string()
    .length(6, "Pincode should have 6 numbers")
    .required("Required")
    .matches(/^((?![()<>/;[\]{}]).)*$/gm, "Special Characters are not allowed"),
};

const agentValidationSchema = Yup.object({
  first_name: Yup.string()
    .trim()
    .required("Required")
    .matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  last_name: Yup.string()
    .trim()
    .required("Required")
    .matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  email: Yup.string()
    .trim()
    .email("Invalid email address")
    .required("Required")
    .matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  mobile: Yup.string()
    .matches(phoneRegExp, "Invalid mobile number")
    .length(10, "Invalid mobile number")
    .required("Required")
    .matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  agent_type: Yup.string().trim().required("Required"),
  agent_photo: Yup.string().trim().required("Required"),
  address: Yup.object().shape(addressSchema),
  // id_proof: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       type: Yup.string().required("Required"),
  //       id_proof: Yup.string().required("Required")
  //     })
  //   )
  //   .min(2, "Required"),
  // id_proof: Yup.array().min(2, "Required"),
  id_proof_1: Yup.object().shape({
    type: Yup.string().required("Required"),
    image_url: Yup.array().min(1, "Required"),
  }),
  id_proof_2: Yup.object().shape({
    type: Yup.string().required("Required"),
    image_url: Yup.array().min(1, "Required"),
  }),
  address_proof_1: Yup.object().shape({
    type: Yup.string().required("Required"),
    image_url: Yup.array().min(1, "Required"),
  }),
  address_proof_2: Yup.object().shape({
    type: Yup.string().required("Required"),
    image_url: Yup.array().min(1, "Required"),
  }),
  id_card_photos: Yup.array().min(2, "Required"),
  // address_proof: Yup.array().min(2, "Required"),
  secondary_contact_first_name: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  secondary_contact_last_name: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  secondary_contact_relation: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  secondary_contact_mobile: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  secondary_contact_address: Yup.object().shape(addressSchema),
  bank_account_name: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  bank_account_number: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  bank_name: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  bank_branch: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  bank_ifsc_code: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  // bank_account_proof: Yup.array().min(1, "Required")
  bank_account_proof_1: Yup.object().shape({
    type: Yup.string().required("Required"),
    image_url: Yup.array().min(1, "Required"),
  }),
});

const editAgentValidationSchema = Yup.object({
  first_name: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  last_name: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  agent_photo: Yup.string().typeError("Required").trim().required("Required"),
  id_card_photos: Yup.array().min(2, "Required"),
  bank_account_name: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  bank_account_number: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  bank_name: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  bank_branch: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  bank_ifsc_code: Yup.string().trim().required("Required").matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  bank_account_proof: Yup.object().shape({
    type: Yup.string().required("Required"),
    img_proof: Yup.array().min(1, "Required"),
  }),
});

export {
  phoneRegExp,
  addressSchema,
  agentValidationSchema,
  editAgentValidationSchema,
};
