import React from "react";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import Agent from "../pages/Agent";
import CreateAgent from "../pages/Agent/createAgent";
import AgentDetails from "../pages/Agent/agentDetails";
import NotFound from "../pages/NotFound";
import Plans from "../pages/Plan";
import CreatePlan from "../pages/Plan/createPlan";
import ViewPlans from "../pages/Plan/view";
import Rates from "../pages/Rate";
import ViewRates from "../pages/Rate/view";
import UserList from "../pages/Users/list";
import UserDetails from "../pages/Users/details";
import AddUserKYCAndBankAccountDetails from "../pages/Users/addKYCAndBankAccountDetails";
import Visits from "../pages/Visits";
import SetMeta from "../pages/Visits/setMeta";
import CreateVisit from "../pages/Visits/createVisit";
import ViewVisits from "../pages/Visits/view";
import ConfirmVisit from "../pages/Visits/confirmVisit";
import EditPartner from "../pages/Partner/editPartner";
import CreatePartner from "../pages/Partner/createPartner";
import BranchDetails from "../pages/Partner/branchDetails";
import CreateBranch from "../pages/Partner/createBranch";
import EditBranch from "../pages/Partner/editBranch";
import LoanListing from "../pages/loans/list";
import LoanDetails from "../pages/loans/details";
import Auth from "./Auth";
import Partners from "../pages/Partner";
import PartnerDetails from "../pages/Partner/ParnerDetails";
import EditAgent from "../pages/Agent/editAgent";
import GoToPartnerApp from "../pages/goToPartnerApp";
import CallbackRequests from "../pages/Partner/callbackRequests";
import Transactions from "../pages/Transactions/list";
import OroOffer from "../pages/oro-offer";
import SupportLoanListing from "../pages/supportLoans/list";
import SupportLoanDetails from "../pages/supportLoans/details";
import CreateOffer from '../pages/oro-offer/create';
import ViewOffer from '../pages/oro-offer/view';
import EditOffer from "../pages/oro-offer/edit";
import ManageGoldOrnamentTypes from "../pages/goldOrnamentTypes";

// for ApolloProvider
import {ApolloClient, ApolloProvider, HttpLink, Observable, split} from "@apollo/client";
import {InMemoryCache} from "@apollo/client/cache";
import {WebSocketLink} from "@apollo/client/link/ws";
import {getMainDefinition} from "@apollo/client/utilities";
import {onError} from "@apollo/client/link/error";

// Utils
import history from "../utils/history";
import {refetchToken} from "../utils/userSessions";

function Routes() {
	const wsLink = new WebSocketLink({
		uri: process.env.REACT_APP_GQL_SUBSCRIPTION_ENDPOINT,
		options: {
			reconnect: true,
			connectionParams: {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			},
		},
	});
	
	const httpLink = new HttpLink({
		uri: process.env.REACT_APP_GQL_ENDPOINT,
		// headers: header
		headers: {
			Authorization: `Bearer ${localStorage.getItem("token")}`,
		},
	});
	
	const errorLink = onError(
		({graphQLErrors, networkError, operation, forward}) => {
			if (graphQLErrors) {
				console.error("graphQLErrors", graphQLErrors);
				for (let err of graphQLErrors) {
					switch (err.extensions.code) {
						case "invalid-jwt":
							break;
						case "access-denied":
							return new Observable((observer) => {
								refetchToken().then((res) => {
									if (res.code === "200") {
										localStorage.setItem("token", res.user.token);
										let oldHeaders = operation.getContext().headers;
										operation.setContext({
											headers: {
												...oldHeaders,
												Authorization: `Bearer ${res.user.token}`,
											},
										});
										
										const subscriber = {
											next: observer.next.bind(observer),
											error: observer.error.bind(observer),
											complete: observer.complete.bind(observer),
										};
										
										// Retry last failed request
										return forward(operation).subscribe(subscriber);
									} else {
										observer.error({});
									}
								}).catch(err => {
									// No refresh or client token available, we force user to login
									observer.error(err);
								});
							});
						// break;
						case "unexpected":
							return new Observable((observer) => {
								refetchToken().then((res) => {
									if (res.code === "200") {
										localStorage.setItem("token", res.user.token);
										let oldHeaders = operation.getContext().headers;
										operation.setContext({
											headers: {
												...oldHeaders,
												Authorization: `Bearer ${res.user.token}`,
											},
										});
										
										const subscriber = {
											next: observer.next.bind(observer),
											error: observer.error.bind(observer),
											complete: observer.complete.bind(observer),
										};
										
										// Retry last failed request
										return forward(operation).subscribe(subscriber);
									} else {
										observer.error({});
									}
								}).catch(err => {
									// No refresh or client token available, we force user to login
									observer.error(err);
								});
							});
						// break;
						default:
						// console.log("default error handler");
					}
				}
			}
			if (networkError) {
				// logoutUser();
				console.error("networkError", networkError);
				// for (let err of networkError) {
				if (networkError.extensions?.code === "start-failed") {
					// case "start-failed":
					if (networkError.message === "cannot start as connection_init failed with : Invalid response from authorization hook") {
						return new Observable((observer) => {
							refetchToken().then((res) => {
								if (res.code === "200") {
									localStorage.setItem("token", res.user.token);
									let oldHeaders = operation.getContext().headers;
									operation.setContext({
										headers: {
											...oldHeaders,
											Authorization: `Bearer ${res.user.token}`,
										},
									});
									
									const subscriber = {
										next: observer.next.bind(observer),
										error: observer.error.bind(observer),
										complete: observer.complete.bind(observer),
									};
									
									// Retry last failed request
									return forward(operation).subscribe(subscriber);
								} else {
									observer.error({});
								}
							}).catch(err => {
								// No refresh or client token available, we force user to login
								observer.error(err);
							});
						});
					}
				} else {
					console.log("default network error handler");
				}
			}
		}
	);
	
	const link = split(
		// split based on operation type
		({query}) => {
			const definition = getMainDefinition(query);
			return (
				definition.kind === "OperationDefinition" &&
				definition.operation === "subscription"
			);
		},
		wsLink,
		httpLink
	);
	
	const client = new ApolloClient({
		link: errorLink.concat(link),
		// uri: "https://orocrop-dev.nfndev.com/v1/graphql",
		cache: new InMemoryCache({}),
	});
	
	return (
		<ApolloProvider client={client}>
			<div style={{margin: "0 auto"}}>
				<Router history={history}>
					<Switch>
						<Route
							path="/"
							exact
							component={() => <Redirect to="/visits"/>}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/redirect"
							exact
							component={GoToPartnerApp}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/agents"
							exact
							component={Agent}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/agents/create"
							exact
							component={CreateAgent}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/agents/view/:id"
							exact
							component={AgentDetails}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/agents/edit/:id"
							exact
							component={EditAgent}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/plans/create"
							exact
							component={CreatePlan}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/visits/create"
							exact
							component={CreateVisit}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/callback-requests"
							exact
							component={CallbackRequests}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/transactions"
							exact
							component={Transactions}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/partners"
							exact
							component={Partners}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/partners/create"
							exact
							component={CreatePartner}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/partners/branch/create"
							exact
							component={CreateBranch}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/partners/branch/edit/:id"
							exact
							component={EditBranch}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/partners/edit/:id"
							exact
							component={EditPartner}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/partners/view/:id"
							exact
							component={PartnerDetails}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/partners/branch/view/:id"
							exact
							component={BranchDetails}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/visits"
							exact
							component={Visits}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/visits/set-meta"
							exact
							component={SetMeta}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/plans"
							exact
							component={Plans}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/users"
							exact
							component={UserList}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/users/view/:id"
							exact
							component={UserDetails}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/users/:id/add-user-kyc-bank-account-details"
							exact
							component={AddUserKYCAndBankAccountDetails}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/rates"
							exact
							component={Rates}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/plans/view/:id"
							exact
							component={ViewPlans}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/rates/view/:id"
							exact
							component={ViewRates}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/visits/view/:id"
							exact
							component={ViewVisits}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/visits/confirm/:id"
							exact
							component={ConfirmVisit}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/loans"
							exact
							component={LoanListing}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/loans/view/:id"
							exact
							component={LoanDetails}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/support-loans"
							exact
							component={SupportLoanListing}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/support-loans/view/:id"
							exact
							component={SupportLoanDetails}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/oro-offer"
							exact
							component={OroOffer}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/oro-offer/create"
							exact
							component={CreateOffer}
							allowed_roles={["super_admin"]}
						/>
						<Auth
							path="/oro-offer/view/:id"
							exact
							component={ViewOffer}
							allowed_roles={["super_admin"]}
						/>
            <Auth
              path="/oro-offer/edit/:id"
              exact
              component={EditOffer}
              allowed_roles={["super_admin"]}
            />
						<Auth
							path="/manage-gold-ornament-types"
							exact
							component={ManageGoldOrnamentTypes}
							allowed_roles={["super_admin"]}
						/>
						<Route path="*" component={NotFound}></Route>
					</Switch>
				</Router>
			</div>
		</ApolloProvider>
	);
}

export default Routes;
