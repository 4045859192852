// DEPENDENCIES
import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { ErrorBoundary } from "react-error-boundary";
import PropTypes from "prop-types";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Skeleton, Tooltip, Button, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

// COMPONENTS
import DisplayTable from "../components/displayTable";
import ErrorWrapper from "../components/ErrorWrapper";
import { get_image_url } from "../utils";

// APIS
import { BANK_ACCOUNT_DETAILS_FRAGMENT } from "../graphqlFragments";

const GET_USER_BANK_ACCOUNT_DETAILS = gql`
  ${BANK_ACCOUNT_DETAILS_FRAGMENT}
  query getUserBankAccountDetails($auth_id: String!) {
    user_bank_account_details(
      where: { user_auth_id: { _eq: $auth_id }, is_active: { _eq: true } }
    ) {
      ...userBankAccountDetails
      is_verified
      user {
        id
        mobile_number
        kycs {
          id
          legal_name
        }
      }
    }
  }
`;

const INITIATE_BANK_ACCOUNT_VERIFICATION = gql`
  mutation initiateBankAccountVerification(
    $bank_account_table_id: Int!
    $bank_account_number: String!
    $ifsc: String!
    $mobile_number: String!
    $name: String!
  ) {
    initiate_bank_account_verification(
      account_number: $bank_account_number
      id: $bank_account_table_id
      ifsc: $ifsc
      mobile_number: $mobile_number
      name: $name
    ) {
      status
    }
  }
`;

const CHECK_BANK_ACCOUNT_VERIFICATION_STATUS = gql`
  query checkBankAccountVerificationStatus($id: Int!) {
    check_bank_account_verification_status(log_table_id: $id) {
      status
    }
  }
`;

const CHECK_LOGS_FOR_BANK_ACCOUNT_VERIFICATION = gql`
  query checkLogsForBankAccountVerification($id: Int!) {
    transaction_id: logs(
      where: {
        table_id: { _eq: $id }
        type: { _eq: "BANK_ACCOUNT_VERIFICATION_TXN_ID" }
      }
    ) {
      id
      data
    }
    transaction_status: logs(
      where: {
        table_id: { _eq: $id }
        type: { _eq: "BANK_ACCOUNT_VERIFICATION_RESPONSE" }
      }
    ) {
      id
      data
    }
  }
`;

const UserBankAccountDetails = ({
  user_auth_id,
  visit_intermediate_status,
}) => {
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [images, setImages] = useState([]);

  const [
    checkBankAccountVerificationStatus,
    {
      data: bankAccountVerificationStatus,
      loading: loadingBankAccountVerifictionStatus,
      error: errorCheckingBankAccountVerificationStatus,
    },
  ] = useLazyQuery(CHECK_BANK_ACCOUNT_VERIFICATION_STATUS, {
    fetchPolicy: "network-only",
  });

  const [initiateBankAccountVerifiction] = useMutation(
    INITIATE_BANK_ACCOUNT_VERIFICATION,
    {
      onError(error) {
        notification.error({
          message: "Error!",
          description: "Something went wrong. Please try again or contact support for help.",
        });
      },
      onCompleted(data) {
        notification.success({
          message: "Success!",
          description: "Bank account verification initiated",
        });
      },
    }
  );

  const [
    checkLogs,
    { data: logs, loading: loadingLogs, error: logError },
  ] = useLazyQuery(CHECK_LOGS_FOR_BANK_ACCOUNT_VERIFICATION, {
    fetchPolicy: "network-only",
    onCompleted(log) {
      if (
        log.transaction_status.length === 0 &&
        log.transaction_id.length === 0
      ) {
        // INITIATE VERIFICATION
        initiateBankAccountVerifiction({
          variables: {
            name:
              bankAccountDetails?.user_bank_account_details[0].user.kycs[0]
                .legal_name,
            bank_account_table_id:
              bankAccountDetails?.user_bank_account_details[0].id,
            ifsc: bankAccountDetails?.user_bank_account_details[0].ifsc,
            bank_account_number: bankAccountDetails?.user_bank_account_details[0].bank_account_number.toString(),
            mobile_number: bankAccountDetails?.user_bank_account_details[0].user.mobile_number
              .toString()
              .split("+91")[1],
          },
        });
      } else if (
        log.transaction_status.length === 0 &&
        log.transaction_id.length === 1
      ) {
        // CHECK VERIFICATION STATUS
        checkBankAccountVerificationStatus({
          variables: {
            id: bankAccountDetails?.user_bank_account_details[0].id,
          },
        });
      } else if (
        log.transaction_status.length === 1 &&
        log.transaction_id.length === 1
      ) {
        // RETURN VERIFICATION REPONSE
        if (logs.transaction_status[0].data.payload.status === "COMPLETED") {
          notification.success({
            message: "Success!",
            description: `Bank Account Verified. Name: ${logs.transaction_status[0].data.payload.name}. Score: ${logs.transaction_status[0].data.payload.name_match_score}`,
            duration: 0,
          });
        } else {
          notification.error({
            message: "Error!",
            description: `Bank Account Verification Failed. Reason: ${logs.transaction_status[0].data.payload.reason}`,
            duration: 0,
          });
        }
      }
    },
  });

  const {
    data: bankAccountDetails,
    loading: bankAccountDetailsLoading,
    error: bankAccountDetailsQueryError,
  } = useQuery(GET_USER_BANK_ACCOUNT_DETAILS, {
    variables: { auth_id: user_auth_id },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (bankAccountVerificationStatus !== undefined) {
      if (
        bankAccountVerificationStatus.check_bank_account_verification_status
          .status === "COMPLETED"
      ) {
        notification.success({
          message: "Success!",
          description: "Bank Account Verified",
        });
      } else if (
        bankAccountVerificationStatus.check_bank_account_verification_status
          .status === "PENDING"
      ) {
        notification.warning({
          message: "Warning!",
          description: "Bank Account Verification In Progress",
        });
      } else {
        notification.error({
          message: "Error!",
          description: `Bank Account Verification Failed. Reason: ${bankAccountVerificationStatus.check_bank_account_verification_status.reason}`,
          duration: 0,
        });
      }
    }
  }, [bankAccountVerificationStatus]);

  useEffect(() => {
    if (errorCheckingBankAccountVerificationStatus)
      notification.error({
        message: "Error!",
        description: "Failed to fetch bank account verification status",
      });
  }, [errorCheckingBankAccountVerificationStatus]);

  useEffect(() => {
    if (logError)
      notification.error({
        message: "Error!",
        description: "Something went wrong. Please contact support for help",
      });
  }, [logError]);

  if (bankAccountDetailsQueryError)
    return (
      <ErrorWrapper
        error={bankAccountDetailsQueryError}
        productionErrorMessage="Error in fetching user bank account details"
      />
    );

  return (
    <>
      <div className="mt-10">
        <Skeleton active loading={bankAccountDetailsLoading}>
          {bankAccountDetails?.user_bank_account_details.length > 0 ? (
            <>
              <div className="w-full text-right">
                {visit_intermediate_status === "OD_KYC_DETAILS_ADDED" ? (
                  process.env.REACT_APP_ENV !== "UAT" &&
                  process.env.REACT_APP_ENV !== "PRODUCTION" ? (
                    <Tooltip title="Feature not activated. All bank accounts are activated by default.">
                      <button
                        className="py-2 px-4 border bg-gray-300 rounded text-white cursor-not-allowed"
                        disabled
                      >
                        Check bank account validity
                      </button>
                    </Tooltip>
                  ) : (
                    <Button
                      htmlType="submit"
                      className="h-auto btn-primary"
                      type="primary"
                      loading={
                        loadingLogs || loadingBankAccountVerifictionStatus
                      }
                      onClick={() => {
                        checkLogs({
                          variables: {
                            id:
                              bankAccountDetails?.user_bank_account_details[0]
                                .id,
                          },
                        });
                      }}
                    >
                      Check bank account validity
                    </Button>
                  )
                ) : null}
              </div>
              {
                <DisplayTable
                  name="Bank account details"
                  data={[
                    {
                      type: "Data",
                      header: "Bank account name",
                      data:
                        bankAccountDetails.user_bank_account_details[0]
                          .bank_account_name,
                      width: "1/6",
                    },
                    {
                      type: "Data",
                      header: "Bank account number",
                      data:
                        bankAccountDetails.user_bank_account_details[0]
                          .bank_account_number,
                      width: "1/6",
                    },
                    {
                      type: "Data",
                      header: "Bank name",
                      data:
                        bankAccountDetails.user_bank_account_details[0]
                          .bank_name,
                      width: "1/6",
                    },
                    {
                      type: "Data",
                      header: "IFSC",
                      data:
                        bankAccountDetails.user_bank_account_details[0].ifsc,
                      width: "1/6",
                    },
                    {
                      type: "Data",
                      header: "Bank branch",
                      data:
                        bankAccountDetails.user_bank_account_details[0]
                          .bank_branch,
                      width: "1/6",
                    },
                  ]}
                />
              }
              {
                <div className="flex space-x-32 mt-10">
                  <div className="block space-y-4">
                    <h3 className="text-lg">BANK PROOF</h3>
                    <div className="border bg-white">
                      {bankAccountDetails.user_bank_account_details[0]?.bank_proof.map(
                        (proof, i) => {
                          return (
                            <p
                              key={i}
                              className="cursor-pointer hover:bg-gray-200 py-1 px-2"
                              onClick={async () => {
                                let images = [];

                                let index = bankAccountDetails.user_bank_account_details[0].bank_proof.findIndex(
                                  (data) => {
                                    return data.type === proof.type;
                                  }
                                );

                                const valid_url = await get_image_url({
                                  url: [
                                    bankAccountDetails
                                      .user_bank_account_details[0].bank_proof[
                                      index
                                    ].image_url,
                                  ],
                                });

                                images.push({
                                  caption: `Bank proof - ${proof.type}`,
                                  source: valid_url,
                                });

                                setIsImageModalOpen(!isImageModalOpen);
                                setImages(images);
                              }}
                            >
                              {proof.type}
                            </p>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              }
            </>
          ) : null}
        </Skeleton>
      </div>

      <ModalGateway>
        {isImageModalOpen && images.length > 0 ? (
          <Modal
            onClose={() => {
              setIsImageModalOpen(!isImageModalOpen);
            }}
          >
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};

const UI = ({ user_auth_id, visit_intermediate_status }) => {
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div
          role="alert"
          className="p-8 bg-red-100 rounded text-red-500 space-y-2 mt-10"
        >
          <div className="flex space-x-1 items-center text-lg">
            <ExclamationCircleOutlined />
            <p>Error!</p>
          </div>

          {process.env.ENV !== "UAT" || process.env.ENV !== "PRODUCTION" ? (
            <pre>{error.message}</pre>
          ) : (
            <p>Failed to fetch the user's bank account details</p>
          )}
        </div>
      )}
    >
      <UserBankAccountDetails
        user_auth_id={user_auth_id}
        visit_intermediate_status={visit_intermediate_status}
      />
    </ErrorBoundary>
  );
};

UI.props = {
  user_auth_id: PropTypes.string.isRequired,
  visit_intermediate_status: PropTypes.string,
};

export default UI;
