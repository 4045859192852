import React, { useState } from "react";
import {Modal as AntModal, Select, notification, Button} from "antd";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import * as Yup from "yup";
import { ErrorBoundary } from "react-error-boundary";
import PrimaryButton from "../../components/primaryBtn";
import { ReactForm, ReactSelect } from "../Form";
import ErrorWrapper from "../../components/ErrorWrapper";

import Warning from "../../assets/warning.png";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

const CHANGE_PARTNER_BRANCH_FOR_LOAN = gql`
  mutation updatePartnerBranchForLoan(
    $loan_id: Int!
    $visit_id: Int!
    $partner_branch_id: Int!
  ) {
    update_visits_by_pk(
      pk_columns: { id: $visit_id }
      _set: { partner_branch_id: $partner_branch_id }
    ) {
      id
      partner_branch {
        id
        partner_branch_name
      }
    }
    update_loans_by_pk(
      pk_columns: { id: $loan_id }
      _set: { partner_branch_id: $partner_branch_id }
    ) {
      id
      partner_branch {
        id
        partner_branch_name
      }
    }
  }
`;

const CHANGE_PARTNER_BRANCH_FOR_VISIT = gql`
  mutation updatePartnerBranchForVisit(
    $visit_id: Int!
    $partner_branch_id: Int!
  ) {
    update_visits_by_pk(
      pk_columns: { id: $visit_id }
      _set: { partner_branch_id: $partner_branch_id }
    ) {
      id
      partner_branch {
        id
        partner_branch_name
      }
    }
  }
`;

const GET_GL_PARTNER_BRANCHES = gql`
  query getGLParnterBranches {
    partner_branches(
      where: {
        partners_branches_x_loanTypes: {
          loan_type: { loan_type: { _eq: "GL" } }
        }
        is_active: { _eq: true }
      }
    ) {
      id
      partner_branch_name
    }
  }
`;

const ChangePartnerBranchForVisit = (props) => {
  console.log("props", props);
  let initialValues = {
    partner_branch: "",
  };

  const formRef = React.useRef();

  const validationSchema = Yup.object({
    partner_branch_id: Yup.string().ensure().required("Required"),
  });

  const handleSubmit = (values) => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const [
    changePartnerBranchForLoan,
    { loading: changingPartnerBranchForLoan },
  ] = useMutation(CHANGE_PARTNER_BRANCH_FOR_LOAN, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description:
            "Something went wrong. Please check all details and try again",
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Partner branch changed",
      });
      props.setOpen(false);
    },
  });

  const [
    changePartnerBranchForVisit,
    { loading: changingPartnerBranchForVisit },
  ] = useMutation(CHANGE_PARTNER_BRANCH_FOR_VISIT, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description:
            "Something went wrong. Please check all details and try again",
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Partner branch changed",
      });
      props.setOpen(false);
    },
  });

  return (
    <AntModal
      width="640px"
      className="modal-title-center"
      visible={props.isOpen}
      footer={null}
    >
      <div className="space-y-8 mx-auto text-center">
        <div>
          <h1>Change Partner Branch</h1>
        </div>
        <div>
          <img
            src={Warning}
            alt="warning"
            style={{ height: "8rem", width: "0 auto", margin: "0 auto" }}
          />
        </div>

        <p>
          Are you sure you want to change the partner branch for this visit?
        </p>

        <ReactForm
          onSubmit={(values) => {
            console.log("values", values);

            if (props.type === "visit") {
              changePartnerBranchForVisit({
                variables: {
                  visit_id: parseInt(props.visit_id),
                  partner_branch_id: values.partner_branch_id,
                },
              });
            }

            if (props.type === "loan") {
              changePartnerBranchForLoan({
                variables: {
                  loan_id: parseInt(props.loan_id),
                  visit_id: parseInt(props.visit_id),
                  partner_branch_id: values.partner_branch_id,
                },
              });
            }
          }}
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          formCls="flex flex-col content-center justify-center space-y-2"
        >
          <ReactSelect
            name="partner_branch_id"
            type="text"
            label="Partner branch"
            placeholder="Choose a branch"
          >
            {props.glPartners.partner_branches.map((branch) => (
              <Option value={branch.id}>{branch.partner_branch_name}</Option>
            ))}
          </ReactSelect>
        </ReactForm>

        <div className="flex justify-center w-full space-x-8">
          {/* <Button
            //   className="btn-danger hover:shadow-lg"
            type="primary"
            htmlType="submit"
            loading={
              changingPartnerBranchForLoan || changingPartnerBranchForVisit
            }
          >
            Change branch
          </Button> */}
          <PrimaryButton
            handleSubmit={handleSubmit}
            loading={
              changingPartnerBranchForVisit || changingPartnerBranchForLoan
            }
            buttonText="Change branch"
          />
          <Button
            // className="btn-primary hover:shadow-lg"
            danger
            size="large"
            onClick={() => {
              props.setOpen(false);
            }}
            type="submit"
          >
            Go back
          </Button>
        </div>
      </div>
    </AntModal>
  );
};

const UI = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const {
    data: glPartners,
    loading: glPartnersLoading,
    error: glPartnerAPIError,
  } = useQuery(GET_GL_PARTNER_BRANCHES, {
    skip: !isModalOpen,
  });

  if (glPartnersLoading)
    return (
      <button className="btn-disabled hover:shadow-lg flex" disabled>
        <svg
          class="animate-spin -ml-1 mr-1 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        Change Partner Branch
      </button>
    );

  if (glPartnerAPIError) {
    return (
      <ErrorWrapper
        error={glPartnerAPIError}
        productionErrorMessage="Error in GL partner list"
      />
    );
  }

  return (
    <ErrorBoundary
      fallbackRender={({ error }) => (
        <div
          role="alert"
          className="p-8 bg-red-100 rounded text-red-500 space-y-2 mt-10"
        >
          <div className="flex space-x-1 items-center text-lg">
            <ExclamationCircleOutlined />
            <p>Error!</p>
          </div>

          {process.env.ENV !== "UAT" || process.env.ENV !== "PRODUCTION" ? (
            <pre>{error.message}</pre>
          ) : (
            <p>Failed to fetch GL partners</p>
          )}
        </div>
      )}
    >
      <button className="cta" onClick={() => setModalOpen(true)}>
        Change Partner Branch
      </button>
      {isModalOpen ? (
        <ChangePartnerBranchForVisit
          {...props}
          isOpen={isModalOpen}
          setOpen={setModalOpen}
          glPartners={glPartners}
        />
      ) : null}
    </ErrorBoundary>
  );
};

export default UI;
