import { EyeOutlined } from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Button, Tag } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ErrorPage from "../../components/ErrorPage";
import ReactTable from "../../components/Table";
// import { exportToXLS } from "../../utils";
import { EXPORT_RATES, GET_RATES } from "./graphql";
import { CSVLink } from "react-csv";

const columns = [
  {
    title: "Rate ID",
    dataIndex: "rateId",
    key: "rateId",
    show: true,
    sorter: (a, b) => {},
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Gold quality(carats)",
    dataIndex: "quality",
    key: "quality",
    show: true,
  },
  {
    title: "MA rate",
    dataIndex: "maRate",
    key: "maRate",
    show: true,
    render: (text) => {
      return <p> ₹ {text} </p>;
    },
  },
  {
    title: "Active from",
    dataIndex: "active_from",
    key: "active_from",
    show: true,
    render: (text) => {
      return <p> {moment(text).format("DD-MM-YYYY")} </p>;
    },
  },
  {
    title: "Active till",
    dataIndex: "active_till",
    key: "active_till",
    show: true,
    render: (text, data) => {
      return (
        <p>{!data.active_till ? "-" : moment(text).format("DD-MM-YYYY")}</p>
      );
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    show: true,
    render: (text, data, index) => {
      return (
        <div>
          {text ? (
            <Tag color={"green"} key={text}>
              Active
            </Tag>
          ) : (
            <Tag color={"red"} key={text}>
              Inactive
            </Tag>
          )}
        </div>
      );
    },
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    show: true,
    fixed: "right",
    width: 100,
    render: (text, data, index) => {
      return (
        <Link to={`/rates/view/${data.key}`}>
          <EyeOutlined />
        </Link>
      );
    },
  },
];

const Rates = (props) => {
  // const [filter, setFilter] = useState([
  //   {
  //     title: "Status",
  //     dataIndex: "is_active",
  //     key: "is_active",
  //     type: "select",
  //     show: false,
  //     data: [
  //       { id: true, name: "Active" },
  //       { id: false, name: "Inactive" }
  //     ],
  //     isFixed: true,
  //     filter: { _in: "$selected" }
  //   }
  // ]);
  // const [filterObj, setFilterObj] = useState({});

  // useEffect(() => {
  //   let objectFilters = {};
  //   var re = new RegExp("$selected", "g");

  //   (filter || []).map(filter => {
  //     if (filter.show || filter.isFixed) {
  //       if ((filter.selected || []).length) {
  //         if (filter.filterType) {
  //           objectFilters[filter.key] = {
  //             [filter.filterType]: { id: { _in: filter.selected } }
  //           };
  //         } else {
  //           objectFilters[filter.key] = { _in: filter.selected };
  //         }
  //       }
  //     }

  //     //  {
  //     //   [filter.filterType]: JSON.parse(
  //     //     JSON.stringify(filter.filter)
  //     //       .split("$selected")
  //     //       .join(filter.selected)
  //     //   ),
  //     // };
  //   });
  //   // resetState();
  //   setFilterObj(objectFilters);
  // }, [filter]);
  const [tableData, setTableData] = useState({
    page: 1,
    data: [],
    hasMoreItems: true,
  });
  // const [searchText, SetSearchText] = useState("");
  const [rateIdOrder, SetRateIdOrder] = useState("desc");

  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    // position: ["none", "none"],
    pageSize: 10,
    current: 1,
    total: 30,
  });

  const rateQueryOnComplete = (rateData) => {
    if (!rateData.rates) {
      return;
    }
    if (rateData.rates_aggregate) {
      setPagination({
        ...pagination,
        total: rateData.rates_aggregate.aggregate.count,
      });
    }
    let hasMoreItems = true;
    if ((rateData.rates || []).length === 0) {
      hasMoreItems = false;
    }
    let rates = (rateData.rates || []).map((rate) => {
      return {
        key: rate.id,
        rateId: rate.rate_display_id,
        status: rate.is_active,
        quality: rate.gold_quality,
        maRate: rate.ma_rate,
        active_from: rate.active_from,
        active_till: rate.active_till,
      };
    });
    setTableData({
      ...tableData,
      data: rates,
      // data: [...tableData.data, ...rates],
      hasMoreItems: hasMoreItems,
    });
  };

  // const rateQueryOnComplete = rateData => {

  // };
  // const rateDataQueryOnComplete = rateData => {
  //   let objectFilters = {},
  //     filters = [];
  //   (filter || []).map(filter => {
  //     objectFilters[filter.key] = filter;
  //   });

  //   Object.keys(objectFilters).map(key => {
  //     filters.push(objectFilters[key]);
  //   });
  //   setFilter(filters);
  // };

  const { loading: rateLoading, error: rateError } = useQuery(
    GET_RATES,
    {
      variables: {
        limit: parseInt(process.env.REACT_APP_API_PAGE_LIMIT),
        offset: process.env.REACT_APP_API_PAGE_LIMIT * (tableData.page - 1),
        order_by: {
          id: rateIdOrder,
          // created_at: rateIdOrder ? null : "asc"
        },
      },
      fetchPolicy: "network-only",
      onCompleted: rateQueryOnComplete,
    }
  );
  // const {
  //   data: filterData,
  //   loading: filterLoading,
  //   error: filterError
  // } = useQuery(GET_RATE_DATA, {
  //   onCompleted: rateDataQueryOnComplete
  // });

  const [CSVData, setCSVData] = useState([]);

  const formatCSVData = (rates) => {
    let CSVData = [];

    (rates.rates || []).forEach((data) => {
      let temp = {
        rateId: data.rate_display_id,
        status: data.is_active ? "Active" : "Inactive",

        gold_quality: data.gold_quality,
        ma_rate: "₹ " + data.ma_rate,
        plan_name: (data.plans_x_rates || [])
          .map((loanType) => {
            return ((loanType || {}).plan || {}).plan_name || "";
          })
          .toString(),
        // created_at: moment(data.created_at).format(
        //   process.env.REACT_APP_DATE_FORMAT
        // ),
        // updated_at: moment(data.updated_at).format(
        //   process.env.REACT_APP_DATE_FORMAT
        // )
      };

      CSVData.push(temp);
    });

    setTimeout(function () {
      setCSVData(CSVData);
      triggerCSVDownload();
    }, 3000);
  };

  const triggerCSVDownload = () => {
    document.getElementById("react-csv").click();
  };

  const [exportPlanCSV, { loading: exportLoading },] = useLazyQuery(EXPORT_RATES, {
    variables: {
      order_by: {
        id: rateIdOrder,
        // created_at: rateIdOrder ? null : "asc"
      },
    },
    fetchPolicy: "network-only",
    onCompleted: formatCSVData,
  });

  const loadMoreItems = (value) => {
    if (!rateLoading && tableData.hasMoreItems) {
      setTableData((data) => {
        return { ...data, page: data.page + 1 };
      });
    }
  };
  // const resetState = () => {
  //   setTableData({
  //     page: 1,
  //     data: [],
  //     hasMoreItems: true
  //   });
  //   setPagination({
  //     ...pagination,
  //     total: 0,
  //     current: 1
  //   });
  // };
  if (rateError) {
    return <ErrorPage></ErrorPage>;
  }
  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    if (sorter.field === "rateId") {
      let order = sorter.order
        ? sorter.order === "descend"
          ? "desc"
          : "asc"
        : null;
      SetRateIdOrder(order);
    }
    setTableData({
      ...tableData,
      page: pagination.current,
    });
    setPagination(pager);
  };

  return (
    <div>
      <CSVLink
        data={CSVData}
        headers={[
          { label: "Rate ID", key: "rateId" },
          { label: "Status", key: "status" },
          { label: "Applicable for", key: "applicableFor" },
          { label: "Gold quality (carats)", key: "gold_quality" },
          { label: "Moving avg. (M.A) Rate (INR)", key: "ma_rate" },
          { label: "Plan name", key: "plan_name" },
          // { label: "Created At", key: "created_at" },
          // { label: "Updated At", key: "updated_at" }
        ]}
        filename={`Rates - ${moment().format("DD-MM-YYYY HH:mm")}.csv`}
        className="hidden"
        target="_blank"
        id="react-csv"
      >
        Download CSV
      </CSVLink>
      <div className="flex justify-between  align-center mb-4">
        <h2 className="text-green-primary">All Rates</h2>
        <div className="flex justify-between align-center">
          {/* <Button
            htmlType="submit"
            className=" rounded-lg flex align-center "
            type="primary"
            size={"large"}
            icon={<PlusOutlined />}
            onClick={() => {
              props.history.push("/rates/create");
            }}
          >
            Create new rate
          </Button> */}
          <Button
            className=" rounded-lg flex align-center ml-3 mr-3"
            type="primary"
            ghost
            size={"large"}
            onClick={() => {
              exportPlanCSV();
            }}
            loading={exportLoading}
          >
            Export
          </Button>
        </div>
      </div>
      <div className="w-100 position-relatives">
        <ReactTable
          columns={columns}
          data={tableData.data}
          table={{
            // pagination: {
            //   hideOnSinglePage: true,
            //   // position: ["none", "none"],
            //   pageSize: 10,
            //   current: tableData.page,
            //   total: 30,
            //   //total: tableData.total,
            // },
            pagination: pagination,
          }}
          // filter={filter}
          page={tableData.page}
          hasMoreItems={tableData.hasMoreItems}
          loadItems={() => {
            loadMoreItems(0);
          }}
          isToggleFullScreen={false}
          isToggleColumn={true}
          isMoreFilter={false}
          isSearch={false}
          loading={rateLoading}
          handleTableChange={handleTableChange}
          // onToolbarSearch={value => {

          //   resetState();
          //   SetSearchText(value);
          // }}
          // onToolbarMore={(index, value) => {
          //   const filterData = [...filter];
          //   filterData[index].show = value;
          //   resetState();

          //   setFilter(filterData);
          // }}
          // onFilterSelectChange={(index, key, value) => {
          //   const filterData = [...filter];
          //   filterData[index].selected = value;
          //   resetState();

          //   setFilter(filterData);
          // }}
          // onChangeDate={(index, dataKey, value) => {
          //   const filter = [...filter];
          //   //filter[index].fromDate = moment(value[0]).toISOString();
          //   // filter[index].toDate = moment(value[1]).toISOString();
          //   resetState();

          //   // setFilter(filterData);
          // }}
          // table={{
          //   scroll: {
          //     y: "calc(100vh - 300px)",
          //   },
          // }}
        ></ReactTable>
      </div>
    </div>
  );
};

export default Rates;
