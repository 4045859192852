import * as Yup from "yup";

const planValidationSchema = Yup.object({
  plan_name: Yup.string()
    .trim()
    .required("Required")
    .matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  scheme_id: Yup.mixed().when("plan_x_loan_type", {
    is: (val) => val !== parseInt(process.env.REACT_APP_SL_LOAN_TYPE_ID),
    then: Yup.string().trim().required("Required"),
  }),
  scheme_name: Yup.mixed().when("plan_x_loan_type", {
    is: (val) => val !== parseInt(process.env.REACT_APP_SL_LOAN_TYPE_ID),
    then: Yup.string().trim().required("Required"),
  }),
  plan_x_loan_type: Yup.number().typeError("Required").required("Required"),
  plan_type: Yup.string().trim().required("Required"),
  plan_start_date: Yup.string().trim().required("Required"),
  plan_start_time: Yup.string().trim().required("Required"),
  plan_end_date: Yup.string().trim().required("Required"),
  plan_end_time: Yup.string().trim().required("Required"),
  min_amt: Yup.number().typeError("Required").required("Required"),
  max_amt: Yup.number().typeError("Required").required("Required"),
  bank_interest: Yup.mixed().when("plan_x_loan_type", {
    is: (val) => val !== parseInt(process.env.REACT_APP_SL_LOAN_TYPE_ID),
    then: Yup.number().typeError("Required").required("Required"),
  }),
  interest: Yup.number().typeError("Required").required("Required"),
  interest_slab_2: Yup.mixed().when("plan_type", {
    is: (val) => val === "Monthly Repayment Plan",
    then: Yup.number().typeError("Required").required("Must enter value"),
  }),
  interest_slab_3: Yup.mixed().when("plan_type", {
    is: (val) => val === "Monthly Repayment Plan",
    then: Yup.number().typeError("Required").required("Must enter value"),
  }),
  ltv: Yup.number().typeError("Required").required("Required"),
  tenure: Yup.number().typeError("Required").required("Required"),
  // rates: Yup.array().min(1, "Required"),
  features: Yup.string()
    .trim()
    .required("Required")
    .matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  tc: Yup.string()
    .trim()
    .required("Required")
    .matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
});

export { planValidationSchema };
