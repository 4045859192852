// DEPS
import { useQuery } from "@apollo/client";
import { Button, Tag } from "antd";
import moment from "moment";
import React from "react";
import { LeftOutlined } from "@ant-design/icons";

// COMPONENTS
import ErrorPage from "../../components/ErrorPage";

// APIS
import { GET_RATE_BY_ID } from "./graphql";

const ViewRates = (props) => {
  const { data: rateData, loading: planLoading, error: planError } = useQuery(
    GET_RATE_BY_ID,
    {
      variables: {
        where: {
          id: { _eq: parseInt(props.match.params.id) },
        },
      },
      fetchPolicy: "network-only",
      // onCompleted: planOnCompleted
    }
  );
  if (!props.match.params.id || planError) {
    return <ErrorPage></ErrorPage>;
  }
  if (planLoading) {
    return <div>Loading</div>;
  }
  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center pl-3">
          <div className="relative">
            <LeftOutlined
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "#16A085",
              }}
            />
          </div>

          <Button
            type="link"
            onClick={() => {
              props.history.push("/rates");
            }}
          >
            All rates
          </Button>
        </div>
      </div>
      <div className="mt-3">
        <h2>Rate Details | {rateData.rates[0].rate_display_id} </h2>

        {/* <div className="flex text-gray-600 space-x-1">
          <p>{`Created at ${moment(rateData.rates[0].created_at).format(
            "DD MMM YYYY HH:mm"
          )} by ${(rateData.rates[0].created_by || {}).first_name || ""} ${(
            rateData.rates[0].created_by || {}
          ).last_name || ""} `}</p>
          <p> </p>
          <p>{`${
            rateData.rates[0].created_at !== rateData.rates[0].updated_at
              ? ` | Updated at ${moment(rateData.rates[0].updated_at).format(
                  "DD MMM YYYY HH:mm"
                )} ${(rateData.rates[0].updated_by || {}).first_name || ""} ${(
                  rateData.rates[0].updated_by || {}
                ).last_name || ""}`
              : ""
          }`}</p>
        </div> */}
      </div>
      <div className="mt-10">
        <h4 className="mb-2">RATE OVERVIEW</h4>
        <div className="flex bg-green-primary">
          <div className=" font-semibold text-white p-3 w-1/6">Rate ID</div>
          <div className=" font-semibold text-white p-3 w-1/6">
            Gold Quality
          </div>
          <div className=" font-semibold text-white p-3 w-1/6">MA rate</div>
          <div className=" font-semibold text-white p-3 w-1/6">Active from</div>
          <div className=" font-semibold text-white p-3 w-1/6">Active till</div>
          <div className=" font-semibold text-white p-3 w-1/6">Status</div>
        </div>
        <div className="flex bg-white">
          <div className="font-semibold p-3 w-1/6 break-all">
            {rateData.rates[0].rate_display_id}
          </div>

          <div className="font-semibold p-3 w-1/6 break-all">
            {rateData.rates[0].gold_quality}
          </div>

          <div className="font-semibold p-3 w-1/6 break-all">
            ₹ {rateData.rates[0].ma_rate}
          </div>

          <div className="font-semibold p-3 w-1/6 break-all">
            {moment(rateData.rates[0].active_from).format("DD-MM-YYYY")}
          </div>

          <div className="font-semibold p-3 w-1/6 break-all">
            {!rateData.rates[0].is_active
              ? moment(rateData.rates[0].active_till).format("DD-MM-YYYY")
              : "-"}
          </div>

          <div className="font-semibold p-3 w-1/6 break-all">
            {rateData.rates[0].is_active ? (
              <Tag color={"green"}>Active</Tag>
            ) : (
              <Tag color={"red"}>Inactive</Tag>
            )}
          </div>
        </div>
      </div>

      <div className="mt-10">
        <h4 className="mb-2">PLAN DETAILS</h4>
        <div className="flex bg-green-primary">
          <div className=" font-semibold text-white p-3 w-1/2">Plan ID</div>
          <div className=" font-semibold text-white p-3 w-1/2">Plan Name</div>
          <div className=" font-semibold text-white p-3 w-1/2">Plan Status</div>
        </div>
        {(rateData.rates[0].plans_x_rates || []).length === 0 && (
          <div className="p-3 bg-white w-full text-center">No data</div>
        )}

        {(rateData.rates[0].plans_x_rates || []).map((loanType, index) => {
          return (
            <div className="flex bg-white" key={`LoanType-${index}`}>
              <div className="font-semibold p-3 w-1/2 break-all">
                {(loanType.plan || {}).plan_display_id}
              </div>
              <div className="font-semibold p-3 w-1/2 break-all">
                {(loanType.plan || {}).plan_name}
              </div>
              <div className="font-semibold p-3 w-1/2 break-all">
                {(loanType.plan || {}).is_active ? (
                  <Tag color={"green"} key={`LoanType-tag-${index}`}>
                    Active
                  </Tag>
                ) : (
                  <Tag color={"red"} key={`LoanType-tag-${index}`}>
                    Inactive
                  </Tag>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ViewRates;
