import * as Yup from "yup";

const kycBankAccountDetailsValidationSchema = Yup.object({
  legal_name: Yup.string().trim().required("Required"),
  father_name: Yup.string().trim().required("Required"),
  mother_name: Yup.string().trim().required("Required"),
  gender: Yup.string().trim().required("Required"),
  marital_status: Yup.string().trim().required("Required"),
  dob: Yup.string().trim().required("Required"),
  address_proof: Yup.object().shape({
    type: Yup.string().required("Required"),
    image_url: Yup.array().min(1, "Required"),
  }),
  id_proof: Yup.object().shape({
    type: Yup.string().required("Required"),
    image_url: Yup.array().min(1, "Required"),
  }),
  pan_number: Yup.string().trim().required("Required"),
  pan_proof: Yup.object().shape({
    type: Yup.string().required("Required"),
    image_url: Yup.array().min(1, "Required"),
  }),
  live_photo: Yup.string().trim().required("Required"),
  bank_account_name: Yup.string().trim().required("Required"),
  bank_account_number: Yup.string().trim().required("Required"),
  bank_name: Yup.string().trim().required("Required"),
  ifsc: Yup.string().trim().required("Required"),
  bank_proof: Yup.object().shape({
    type: Yup.string().required("Required"),
    image_url: Yup.array().min(1, "Required"),
  }),
});

export { kycBankAccountDetailsValidationSchema };
