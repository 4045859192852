import * as Yup from "yup";
// import { phoneRegExp, addressSchema } from "../Agent/validationSchema";

const partnerValidationSchema = Yup.object({
  partner_name: Yup.string()
    .trim()
    .required("Required")
    .matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  partner_logo: Yup.string().trim().required("Required"),
  partner_type_id: Yup.array().required("Required"),
});

const partnerEditValidationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .required("Required")
    .matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  partner_type_id: Yup.array()
    .of(
      Yup.object().shape({
        partner_type_id: Yup.number(),
        is_active: Yup.boolean(),
      })
    )
    .compact((v) => !v.is_active)
    .required("Required"),
  partner_logo: Yup.string().trim().required("Required"),
});

const branchValidationSchema = Yup.object({
  branch_name: Yup.string()
    .trim()
    .required("Required")
    .matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  partner: Yup.string().trim().required("Required"),
  //   loan_types: Yup.array().min(1, "Required"),
  //   blcfirstname: Yup.string().trim().required("Required"),
  //   blclastname: Yup.string().trim().required("Required"),
  //   blcemail: Yup.string().trim().email("Invalid email").required("Required"),
  //   blcnumber: Yup.string()
  //     .matches(phoneRegExp, "Invalid mobile number")
  //     .length(10, "Invalid mobile number")
  //     .required("Required"),
  //   blvfirstname: Yup.string().trim().required("Required"),
  //   blvlastname: Yup.string().trim().required("Required"),
  //   blvemail: Yup.string().trim().email("Invalid email").required("Required"),
  //   blvnumber: Yup.string()
  //     .matches(phoneRegExp, "Invalid mobile number")
  //     .length(10, "Invalid mobile number")
  //     .required("Required"),
  //   address: Yup.object().shape(addressSchema),
  //   lat: Yup.string().trim().required("Required"),
  //   lng: Yup.string().trim().required("Required"),
});

const editBranchValidationSchema = Yup.object({
  branch_name: Yup.string()
    .trim()
    .required("Required")
    .matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  partner: Yup.string().trim().required("Required"),
  // loan_types: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       id: Yup.number(),
  //       loan_type: Yup.number(),
  //       is_active: Yup.boolean(),
  //     })
  //   )
  //   .compact((v) => !v.is_active)
  //   .required("Required"),
  // name: Yup.string()
  //   .trim()
  //   .required("Required"),
  // number: Yup.string()
  //   .matches(phoneRegExp, "Invalid mobile number")
  //   .length(10, "Invalid mobile number")
  //   .required("Required"),
  // address: Yup.object().shape(addressSchema),
  // lat: Yup.string().trim().required("Required"),
  // lng: Yup.string().trim().required("Required"),
});

export {
  partnerValidationSchema,
  branchValidationSchema,
  editBranchValidationSchema,
  partnerEditValidationSchema,
};
