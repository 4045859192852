import {
  PushpinOutlined,
  SettingOutlined,
  VerticalAlignMiddleOutlined
} from "@ant-design/icons";
import { Checkbox, Popover, Tooltip } from "antd";
import { ConfigConsumer } from "antd/lib/config-provider/context";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { genColumnKey } from "../../utils";
import DnDItem from "./DndItem";

const ToolTipIcon = ({
  title,
  show,
  children,
  columnKey,
  fixed,
  columnsMap,
  setColumnsMap
}) => {
  if (show) {
    return (
      <Tooltip title={title}>
        <span
          onClick={() => {
            const config = columnsMap[columnKey || ""] || {};
            const columnKeyMap = {
              ...columnsMap,
              [columnKey]: { ...config, fixed }
            };
            setColumnsMap(columnKeyMap);
          }}
        >
          {children}
        </span>
      </Tooltip>
    );
  }
  return null;
};

const CheckboxListItem = ({
  columnKey,
  className,
  columnsMap,
  title,
  setColumnsMap,
  fixed
}) => {
  const config = columnsMap[columnKey || "null"] || { show: true };
  return (
    <span className={`${className}-list-item`} key={columnKey}>
      <Checkbox
        onChange={e => {
          if (columnKey) {
            const tempConfig = columnsMap[columnKey || ""] || {};
            const newSetting = { ...tempConfig };
            if (e.target.checked) {
              delete newSetting.show;
              newSetting.show = true;
            } else {
              newSetting.show = false;
            }
            const columnKeyMap = {
              ...columnsMap,
              [columnKey]: newSetting
            };
            setColumnsMap(columnKeyMap);
          }
        }}
        checked={config.show !== false}
      >
        {title}
      </Checkbox>
      <span className={`${className}-list-item-option`}>
        <ToolTipIcon
          columnKey={columnKey}
          fixed="left"
          title={"Left Pin"}
          show={fixed !== "left"}
          columnsMap={columnsMap}
          setColumnsMap={setColumnsMap}
        >
          <PushpinOutlined
            style={{
              transform: "rotate(-90deg)"
            }}
          />
        </ToolTipIcon>
        <ToolTipIcon
          columnKey={columnKey}
          fixed={undefined}
          title={"No pin"}
          show={!!fixed}
          columnsMap={columnsMap}
          setColumnsMap={setColumnsMap}
        >
          <VerticalAlignMiddleOutlined />
        </ToolTipIcon>
        <ToolTipIcon
          columnKey={columnKey}
          fixed="right"
          title={"Right pin"}
          show={fixed !== "right"}
          columnsMap={columnsMap}
          setColumnsMap={setColumnsMap}
        >
          <PushpinOutlined />
        </ToolTipIcon>
      </span>
    </span>
  );
};

const CheckboxList = ({
  list,
  className,
  showTitle = true,
  title: listTitle,
  columnsMap,
  setColumnsMap,
  sortKeyColumns,
  setSortKeyColumns
}) => {
  const show = list && list.length > 0;
  if (!show) {
    return null;
  }
  const move = (id, targetIndex) => {
    const newColumns = [...sortKeyColumns];
    const findIndex = newColumns.findIndex(columnKey => columnKey === id);

    const key = newColumns[findIndex];
    newColumns.splice(findIndex, 1);
    if (targetIndex === 0) {
      newColumns.unshift(key);
    } else {
      newColumns.splice(targetIndex, 0, key);
    }
    setSortKeyColumns(newColumns);
  };

  const listDom = list.map(
    ({ key, dataIndex, title, fixed, ...rest }, index) => {
      const columnKey = genColumnKey(key, dataIndex, rest.index);
      return (
        <DnDItem
          index={index}
          id={`${columnKey}`}
          key={columnKey}
          end={(id, targetIndex) => {
            move(id, targetIndex);
          }}
        >
          <CheckboxListItem
            setColumnsMap={setColumnsMap}
            columnKey={columnKey || `${index}`}
            columnsMap={columnsMap}
            title={title}
            fixed={fixed}
            className={className}
          />
        </DnDItem>
      );
    }
  );
  return (
    <DndProvider backend={HTML5Backend}>
      {showTitle && (
        <span className={`${className}-list-title`}>{listTitle}</span>
      )}
      {listDom}
    </DndProvider>
  );
};

const GroupCheckboxList = ({
  localColumns,
  className,
  columnsMap,
  setColumnsMap,
  sortKeyColumns,
  setSortKeyColumns
}) => {
  const rightList = [];
  const leftList = [];
  const list = [];

  localColumns.forEach(item => {
    const { fixed } = item;
    if (fixed === "left") {
      leftList.push(item);
      return;
    }
    if (fixed === "right") {
      rightList.push(item);
      return;
    }
    list.push(item);
  });

  const showRight = rightList && rightList.length > 0;
  const showLeft = leftList && leftList.length > 0;

  return (
    <div className={`${className}-list`}>
      <CheckboxList
        title={"Left Fixed Title"}
        list={leftList}
        className={className}
        columnsMap={columnsMap}
        setColumnsMap={setColumnsMap}
        sortKeyColumns={sortKeyColumns}
        setSortKeyColumns={setSortKeyColumns}
      />
      {/* 如果没有任何固定，不需要显示title */}
      <CheckboxList
        list={list}
        title={"No Fixed Title"}
        showTitle={showLeft || showRight}
        className={className}
        columnsMap={columnsMap}
        setColumnsMap={setColumnsMap}
        sortKeyColumns={sortKeyColumns}
        setSortKeyColumns={setSortKeyColumns}
      />
      <CheckboxList
        title={"Right Fixed Title"}
        list={rightList}
        className={className}
        columnsMap={columnsMap}
        setColumnsMap={setColumnsMap}
        sortKeyColumns={sortKeyColumns}
        setSortKeyColumns={setSortKeyColumns}
      />
    </div>
  );
};

const ColumnSetting = props => {
  const localColumns = props.columns || [];
  const { setSortKeyColumns } = props;
  /**
   * 设置全部选中，或全部未选中
   * @param show
   */
  const setAllSelectAction = (show = true) => {
    const columnKeyMap = {};
    localColumns.forEach(({ key, fixed, dataIndex, index }) => {
      const columnKey = genColumnKey(key, dataIndex, index);

      if (columnKey) {
        columnKeyMap[columnKey] = {
          show,
          fixed
        };
      }
    });
    props.setColumnsMap(columnKeyMap);
  };

  // 选中的 key 列表
  const selectedKeys = Object.values(localColumns).filter(
    value => !value || value.show === false
  );

  // 是否已经选中
  const indeterminate =
    selectedKeys.length > 0 && selectedKeys.length !== localColumns.length;

  return (
    <ConfigConsumer>
      {({ getPrefixCls }) => {
        const className = getPrefixCls("pro-table-column-setting");
        const toolBarClassName = getPrefixCls("pro-table-toolbar");
        return (
          <Popover
            arrowPointAtCenter
            title={
              <div
                className={`${className}-title flex justify-between`}
                style={{ minWidth: 300 }}
              >
                <Checkbox
                  indeterminate={indeterminate}
                  checked={
                    selectedKeys.length === 0 &&
                    selectedKeys.length !== localColumns.length
                  }
                  onChange={e => {
                    if (e.target.checked) {
                      setAllSelectAction();
                    } else {
                      setAllSelectAction(false);
                    }
                  }}
                >
                  Columns Display
                </Checkbox>
                {/* eslint-disable-next-line */}
                <a
                  onClick={() => {
                    props.setColumnsMap({});
                    setSortKeyColumns([]);
                  }}
                >
                  reset
                </a>
              </div>
            }
            trigger="click"
            placement="bottomRight"
            content={
              <div style={{ maxHeight: 400, overflow: "scroll" }}>
                <GroupCheckboxList
                  className={className}
                  localColumns={localColumns}
                  columnsMap={props.columnsMap}
                  setColumnsMap={props.setColumnsMap}
                  sortKeyColumns={props.sortKeyColumns}
                  setSortKeyColumns={props.setSortKeyColumns}
                />
              </div>
            }
          >
            <Tooltip title={`Column Setting`}>
              <SettingOutlined
                className={`${toolBarClassName}-item-icon`}
                style={{
                  fontSize: 16
                }}
              />
            </Tooltip>
          </Popover>
        );
      }}
    </ConfigConsumer>
  );
};

export default ColumnSetting;
