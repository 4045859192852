// DEPS
import React from "react";
import { Form, Formik } from "formik";
import { Select, Popconfirm, notification, Button } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import jwt_decode from "jwt-decode";

// COMPONENTS
import {ReactInput, ReactInputNumber, ReactSelectWithBtn, ImageUpload} from "../../components/Form";
import Loading from "../../components/loading";

import { editAgentValidationSchema } from "./validationSchema";

// API
import {
  GET_AGENT_DATA_FOR_EDIT,
  UPDATE_AGENT,
  GET_AGENT_DETAILS_BY_ID,
} from "./graphql";

const { Option } = Select;

const EditAgent = (props) => {
  const formRef = React.useRef();

  const {data: agentData, loading: agentDataLoading} = useQuery(GET_AGENT_DATA_FOR_EDIT, {
    variables: {
      id: parseInt(props.match.params.id),
    },
  });

  const [updateAgent] = useMutation(UPDATE_AGENT, {
    refetchQueries: [
      {
        query: GET_AGENT_DETAILS_BY_ID,
        variables: {
          id: parseInt(props.match.params.id),
        },
      },
    ],

    onError(error) {
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description: "Something went wrong!",
        });
      });
    },
    onCompleted() {
      notification.success({
        message: "Success!",
        description: "Agent details updated!",
      });
      props.history.push("/agents");
    },
  });

  if (agentDataLoading) return <Loading />;

  // if (agentDataError) console.log("Error", agentDataError);

  const handleSubmit = (values) => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <div className="space-y-4 ">
      <Button
        type="link"
        onClick={() => {
          props.history.push("/agents");
        }}
      >
        All agents
      </Button>
      <div className="flex justify-between">
        <h1> Edit agent</h1>
        {/* <button className="cta hover:shadow-lg">Upload agent photo</button> */}
      </div>

      <Formik
        enableReinitialize={false}
        validateOnChange={false}
        validateOnBlur={false}
        innerRef={formRef}
        onSubmit={async (values, { resetForm }) => {
          updateAgent({
            variables: {
              id: parseInt(props.match.params.id),
              updated_by_id: jwt_decode(localStorage.getItem("token")).user_id,
              agent_id_card_photos: values.id_card_photos,
              user_data: {
                first_name: values.first_name,
                last_name: values.last_name,
                profile_picture: values.agent_photo,
              },
              bank_data: {
                bank_name: values.bank_name,
                bank_branch: values.bank_branch,
                bank_account_name: values.bank_account_name,
                bank_account_number: values.bank_account_number,
                ifsc: values.bank_ifsc_code,
                bank_proof: [values.bank_account_proof],
              },
            },
          });
        }}
        initialValues={{
          first_name: agentData.users[0].first_name,
          last_name: agentData.users[0].last_name,
          bank_account_name:
            agentData.users[0].user_bank_account_details[0].bank_account_name,
          bank_account_number:
            agentData.users[0].user_bank_account_details[0].bank_account_number,
          bank_ifsc_code: agentData.users[0].user_bank_account_details[0].ifsc,
          bank_name: agentData.users[0].user_bank_account_details[0].bank_name,
          bank_branch:
            agentData.users[0].user_bank_account_details[0].bank_branch,
          bank_account_proof:
            agentData.users[0].user_bank_account_details[0].bank_proof[0],
          agent_photo: agentData.users[0].profile_picture,
          id_card_photos: agentData.users[0].agent.id_card_photos || [],
        }}
        validationSchema={editAgentValidationSchema}
        formCls="flex flex-col content-center justify-center space-y-8"
      >
        {({ values, setFieldValue }) => (
          <Form>
            {/* Agent ID div*/}
            <div className="border rounded bg-white">
              {/* Agent ID header */}
              <div className="border-b w-full px-4 py-2 bg-green-primary">
                <h2 className="text-white">Agent ID</h2>
              </div>
              {/* Agent ID header */}

              {/* Agent ID form */}
              <div className="p-4 space-y-4">
                {/* Agent ID form - line 1*/}
                <div className="flex space-x-8">
                  <div className="w-1/3">
                    <ReactInput
                      name="first_name"
                      type="text"
                      label="First Name"
                      placeholder=""
                    />
                  </div>

                  <div className="w-2/3 flex space-x-8">
                    <div className="w-1/2">
                      <ReactInput
                        name="last_name"
                        type="text"
                        label="Last Name"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                {/* Agent ID form - line 1*/}
              </div>
              {/* Agent ID form */}
            </div>
            {/* Agent ID div*/}

            {/* Agent bank account div*/}
            <div className="border rounded bg-white">
              {/* Agent bank account header */}
              <div className="border-b w-full px-4 py-2 bg-green-primary">
                <h2 className="text-white">Bank account details</h2>
              </div>
              {/* Agent bank account header */}

              {/* Agent bank account form */}
              <div className="p-4 space-y-4">
                {/* Agent bank account form - line 1 */}
                <div className="flex space-x-8">
                  <div className="w-1/3">
                    <ReactInput
                      name="bank_account_name"
                      type="text"
                      label="Account Name"
                      placeholder=""
                    />
                  </div>

                  <div className="w-2/3 flex space-x-8">
                    <div className="w-1/2">
                      <ReactInputNumber
                        name="bank_account_number"
                        type="text"
                        label="Account Number"
                      />
                    </div>

                    <div className="w-1/2">
                      <ReactInput
                        name="bank_ifsc_code"
                        type="text"
                        label="IFSC Code"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                {/* Agent bank account form - line 1 */}

                {/* Agent bank account form - line 2 */}
                <div
                  className="flex space-x-8"
                  style={{ marginBottom: "30px" }}
                >
                  <div className="w-1/3">
                    <ReactInput
                      name="bank_name"
                      type="text"
                      label="Bank Name"
                      placeholder=""
                    />
                  </div>

                  <div className="w-2/3 flex space-x-8">
                    <div className="w-1/3">
                      <ReactInput
                        name="bank_branch"
                        type="text"
                        label="Bank Branch"
                        placeholder=""
                      />
                    </div>

                    <div className="w-2/3 space-y-2">
                      <label className="text-lg text-gray-800 font-semibold">
                        Bank account proof
                      </label>

                      <div className="w-full items-center flex space-x-8">
                        <ReactSelectWithBtn
                          name="bank_account_proof"
                          type="text"
                          placeholder="Select proof"
                          pre_value={
                            agentData.users[0].user_bank_account_details[0]
                              .bank_proof[0]
                          }
                          pre_images={
                            agentData.users[0].user_bank_account_details[0]
                              .bank_proof[0].image_url
                          }
                          editMode={true}
                        >
                          <Option value="Passbook">Passbook</Option>
                          <Option value="Cancelled cheque">
                            Cancelled cheque
                          </Option>
                        </ReactSelectWithBtn>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Agent bank account form - line 2 */}
              </div>
            </div>

            <div className="flex mt-6">
              <div className="w-1/5 space-y-2 relative">
                <h2 className="text-gray-800 font-semibold">Agent Photo:</h2>
                <ImageUpload
                  buttonText="Upload"
                  multiple={false}
                  name="agent_photo"
                  showError={true}
                  pre_files={agentData.users[0].profile_picture}
                  editMode={true}
                  maxLength={1}
                />
              </div>

              <div className="w-1/2 space-y-2 relative">
                <h2 className="text-gray-800 font-semibold">
                  Agent ID Card Photos (please upload the front side first):
                </h2>
                <ImageUpload
                  buttonText="Upload"
                  multiple={true}
                  name="id_card_photos"
                  showError={true}
                  pre_files={agentData.users[0].agent.id_card_photos || []}
                  editMode={true}
                  maxLength={2}
                  customizeSuccess={true}
                  customOnSuccess={(data) => {
                    if (values["id_card_photos"].length < 3) {
                      if (data !== undefined) {
                        setFieldValue("id_card_photos", data);
                      }
                    }
                  }}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <div className="flex justify-center w-full space-x-8">
        <Popconfirm
          title={`Are you sure you want to cancel?`}
          onConfirm={() => {
            props.history.push("/agents");
          }}
          onCancel={() => {}}
          okText="Yes"
          placement="bottomRight"
          cancelText="No"
        >
          <button className="btn-danger hover:shadow-lg">Cancel</button>
        </Popconfirm>
        <button
          type="submit"
          className="btn-primary hover:shadow-lg"
          onClick={() => handleSubmit()}
        >
          Update Agent
        </button>
      </div>
    </div>
  );
};

export default EditAgent;
