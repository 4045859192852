import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Button, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ErrorPage from "../../components/ErrorPage";
import ReactTable from "../../components/Table";
import { CSVLink } from "react-csv";
import { EXPORT_AGENTS, GET_AGENTS } from "./graphql";

const Agent = (props) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "planId",
      show: true,
      render: (text, data, index) => `${data.first_name} ${data.last_name}`,
      sorter: (a, b) => {},
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Type",
      dataIndex: "role_name",
      key: "role_name",
      show: true,
      render: (text, data, index) => {
        return (
          <p className="capitalize">
            {text.split("_").join(" ").toLowerCase()}
          </p>
        );
      },
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      show: true,
    },
    {
      title: "Agent ID",
      dataIndex: "agent",
      key: "agent",
      show: true,
      render: (text, data, index) =>
        data.agent ? data.agent.agent_display_id : "",
      sorter: (a, b) => {},
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      show: true,
      width: 100,
      render: (text, data, index) => {
        return (
          <div>
            {text ? (
              <Tag color={"green"} key={text}>
                Active
              </Tag>
            ) : (
              <Tag color={"red"} key={text}>
                Inactive
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      show: true,
      fixed: "right",
      width: 100,
      render: (text, data, index) => {
        return (
          <Link to={`/agents/view/${data.id}`}>
            <EyeOutlined />
          </Link>
        );
      },
    },
  ];

  const [filter, setFilter] = useState([
    // {
    //   title: "Agent Type",
    //   dataIndex: "role_id",
    //   key: "role_id",
    //   type: "select",
    //   show: false,
    //   data: [
    //     { id: 1, name: "Field agent" },
    //     { id: 4, name: "Call agent" },
    //     { id: 5, name: "Approver" }
    //   ],
    //   isFixed: true,
    //   filterType: "role_id"
    //   // filter: { partner_type: { partner_type: { _eq: "$selected" } } },
    // },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      type: "select",
      show: false,
      data: [
        { id: true, name: "Active" },
        { id: false, name: "Inactive" },
      ],
      isFixed: true,
      filter: { _in: "$selected" },
    },
  ]);

  const [filterObj, setFilterObj] = useState({});
  const [agentIdOrder, SetAgentIdOrder] = useState(null);
  const [agentNameOrder, SetAgentNameOrder] = useState(null);
  
  useEffect(() => {
    let objectFilters = {};

    (filter || []).forEach((filter) => {
      if ((filter.selected || []).length)
        if (filter.show || filter.isFixed) {
          if (filter.filterType) {
            objectFilters[filter.key] = filter.selected;
          } else {
            objectFilters[filter.key] = { _in: filter.selected.map(item => item === "true") };
          }
        }
    });
    
    setFilterObj(objectFilters);
  }, [filter]);
  
  const [tableData, setTableData] = useState({
    page: 1,
    data: [],
    hasMoreItems: true,
  });
  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    // position: ["none", "none"],
    pageSize: 10,
    current: 1,
    total: 30,
  });
  const [searchText, SetSearchText] = useState("");
  const resetState = () => {
    setTableData({
      page: 1,
      data: [],
      hasMoreItems: true,
    });
    setPagination({
      ...pagination,
      total: 0,
      current: 1,
    });
  };

  const [CSVData, setCSVData] = useState([]);

  const formatCSVData = (agents) => {
    // let CSVData = [];

    let CSVData = (agents.users || []).map((data) => {
      return {
        agent_name: `${data.first_name || ""} ${data.last_name || ""}`,
        agent_type: data.role_name || {},
        agent_status: data.is_active ? "Active" : "Inactive",
        agent_email_id: data.email_id,
        mobile_number: data.mobile_number,
        agentID: (data.agent || {}).agent_display_id,
        address: `${
          (data.agent || {}).address
            ? (data.agent || {}).address.addr_line_1 +
              " " +
              (data.agent || {}).address.addr_line_2 +
              " " +
              (data.agent || {}).address.city +
              " " +
              (data.agent || {}).address.state +
              " " +
              (data.agent || {}).address.pincode
            : ""
        }`,
        c_person_name: `${(data.agent || {}).first_name} ${
          (data.agent || {}).last_name
        }`,
        c_person_number: (data.agent || {}).secondary_mobile_number,
        c_person_relation: (data.agent || {}).relation,
        c_person_address: `${
          (data.agent || {}).secondary_address
            ? (data.agent || {}).secondary_address.addr_line_1 +
              " " +
              (data.agent || {}).secondary_address.addr_line_2 +
              " " +
              (data.agent || {}).secondary_address.city +
              " " +
              (data.agent || {}).secondary_address.state +
              " " +
              (data.agent || {}).secondary_address.pincode
            : ""
        }`,
        created_by: `${
          ((data.agent || {}).created_by || {}).first_name || ""
        } ${((data.agent || {}).created_by || {}).last_name || ""}`,
        created_at: moment(data.created_at).format(
          process.env.REACT_APP_DATE_FORMAT
        ),
        updated_by: `${
          ((data.agent || {}).updated_by || {}).first_name || ""
        } ${((data.agent || {}).updated_by || {}).last_name || ""}`,
        updated_at: moment(data.updated_at).format(
          process.env.REACT_APP_DATE_FORMAT
        ),
      };
    });

    setTimeout(function () {
      setCSVData(CSVData);
      triggerCSVDownload();
    }, 3000);
  };

  const triggerCSVDownload = () => {
    document.getElementById("react-csv").click();
  };

  const [exportPlanCSV, { loading: exportLoading }] = useLazyQuery(EXPORT_AGENTS, {
    variables: {
      where: {
        ...filterObj,
        _and: [
          {
            role_name: {
              _in: filterObj.role_name || [
                "FIELD_AGENT",
                "CALL_AGENT",
                "APPROVER",
              ],
            },
          },
          {
            _or: [
              { first_name: { _ilike: `%${searchText}%` } },
              { last_name: { _ilike: `%${searchText}%` } },
            ],
          },
        ],
      },
      order_by: {
        id: agentIdOrder,
        created_at: agentIdOrder || agentNameOrder ? null : "desc",
        first_name: agentNameOrder,
      },
    },
    fetchPolicy: "network-only",
    onCompleted: formatCSVData,
  });
  const planQueryOnComplete = (agentData) => {
    if (!agentData.users) {
      return;
    }
    if (agentData.users_aggregate) {
      setPagination({
        ...pagination,
        total: agentData.users_aggregate.aggregate.count,
      });
    }
    let hasMoreItems = true;
    if ((agentData.users || []).length === 0) {
      hasMoreItems = false;
    }
    let agents = (agentData.users || []).map((agent) => {
      return { ...agent };
    });
    setTableData({
      ...tableData,
      data: agents,

      // data: [...tableData.data, ...agents],
      hasMoreItems: hasMoreItems,
    });
  };

  const {loading: agentLoading, error: agentError} = useQuery(GET_AGENTS, {
    variables: {
      limit: parseInt(process.env.REACT_APP_API_PAGE_LIMIT),
      offset: process.env.REACT_APP_API_PAGE_LIMIT * (tableData.page - 1),
      order_by: {
        id: agentIdOrder,
        created_at: agentIdOrder || agentNameOrder ? null : "asc",
        first_name: agentNameOrder,
      },
      where: {
        ...filterObj,
        _and: [
          {
            role_name: {
              _in: filterObj.role_name || [
                "FIELD_AGENT",
                "CALL_AGENT",
                "APPROVER",
              ],
            },
          },
          {
            _or: [
              { first_name: { _ilike: `%${searchText}%` } },
              { last_name: { _ilike: `%${searchText}%` } },
            ],
          },
        ],
      },
    },
    fetchPolicy: "network-only",
    onCompleted: planQueryOnComplete,
  });

  const loadMoreItems = (value) => {
    if (!agentLoading && tableData.hasMoreItems) {
      setTableData((data) => {
        return { ...data, page: data.page + 1 };
      });
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    if (sorter.field === "name") {
      let order = sorter.order
        ? sorter.order === "descend"
          ? "desc"
          : "asc"
        : null;
      SetAgentIdOrder(null);

      SetAgentNameOrder(order);
    }
    if (sorter.field === "agent") {
      let order = sorter.order
        ? sorter.order === "descend"
          ? "desc"
          : "asc"
        : null;
      SetAgentNameOrder(null);

      SetAgentIdOrder(order);
    }
    setTableData({
      ...tableData,
      page: pagination.current,
    });
    setPagination(pager);
  };

  if (agentError) {
    return <ErrorPage />;
  }

  return (
    <div>
      <CSVLink
        data={CSVData}
        headers={[
          { label: "Agent ID", key: "agentID" },
          { label: "Name", key: "agent_name" },
          { label: "Type", key: "agent_type" },
          { label: "Status", key: "agent_status" },
          { label: "Email Address", key: "agent_email_id" },
          { label: "Phone Number", key: "mobile_number" },
          { label: "Address", key: "address" },
          { label: "Contact Person Name", key: "c_person_name" },
          { label: "Contact Person Number", key: "c_person_number" },
          { label: "Contact Person Relation", key: "c_person_relation" },
          { label: "Contact Person Address", key: "c_person_address" },
          { label: "Created By", key: "created_by" },
          { label: "Created At", key: "created_at" },
          { label: "Updated By", key: "updated_by" },
          { label: "Updated At", key: "updated_at" },
        ]}
        filename={`Agents - ${moment().format("DD-MM-YYYY HH:mm")}.csv`}
        className="hidden"
        target="_blank"
        id="react-csv"
      >
        Download CSV
      </CSVLink>
      <div className="flex justify-between align-center mb-4">
        <h2 className="text-green-primary">All Agents</h2>
        <div className="flex justify-between">
          <Button
            htmlType="submit"
            className=" rounded-lg flex align-center "
            type="primary"
            size={"large"}
            icon={<PlusOutlined />}
            onClick={() => {
              props.history.push("/agents/create");
            }}
          >
            Create new Agent
          </Button>
          <Button
            className=" rounded-lg flex align-center ml-3 mr-3"
            type="primary"
            ghost
            size={"large"}
            onClick={() => {
              exportPlanCSV();
            }}
            loading={exportLoading}
          >
            Export
          </Button>
        </div>
      </div>
      <div className="w-100 position-relatives">
        <ReactTable
          columns={columns}
          data={tableData.data}
          table={{
            rowKey: (record) => record.id,
            pagination: pagination,
          }}
          filter={filter}
          page={tableData.page}
          hasMoreItems={tableData.hasMoreItems}
          loadItems={() => {
            loadMoreItems(0);
          }}
          handleTableChange={handleTableChange}
          isToggleFullScreen={false}
          isToggleColumn={true}
          isMoreFilter={false}
          isSearch={true}
          loading={agentLoading}
          onToolbarSearch={(value) => {
            resetState();
            SetSearchText(value);
          }}
          onToolbarMore={(index, value) => {
            const filterData = [...filter];
            filterData[index].show = value;
            resetState();
            setFilter(filterData);
          }}
          onFilterSelectChange={(index, key, value) => {
            const filterData = [...filter];
            filterData[index].selected = value;
            resetState();
            setFilter(filterData);
          }}
        />
      </div>
    </div>
  );
};

export default Agent;
