import { DatePicker } from "antd";
import { useFormikContext } from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import "./form.css";

const ReactDatepicker = props => {
  const { errors, setFieldValue } = useFormikContext();

  return (
    <div className="text-red-500 text-left space-y-2">
      {props.label && (
        <label className="text-gray-800 font-semibold text-lg">
          {props.label}
        </label>
      )}
      <div>
        <DatePicker
          // {...field}
          {...props}
          format="DD-MM-YYYY"
          onChange={(date, dateString) => {
            if (props.isVisit) {
              setFieldValue("time", null);
            }
            return setFieldValue(props.name, moment(date).format("DD-MM-YYYY"));
          }}
          className="w-full text-center"
        />
      </div>
      <div style={{ minHeight: 21 }}>
        {errors[props.name] ? (
          <div className="error">{errors[props.name]}</div>
        ) : null}
      </div>
    </div>
  );
};

ReactDatepicker.propTypes = {
  label: PropTypes.string
};
export default ReactDatepicker;
