import { TimePicker } from "antd";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import "./form.css";

const VisitTimepicker = props => {
  const { values, errors, setFieldValue } = useFormikContext();

  return (
    <div className="text-red-500 text-left space-y-2">
      {props.label && (
        <label className="text-gray-800 font-semibold text-lg">
          {props.label}
        </label>
      )}
      <div>
        <TimePicker
          {...props}
          format="HH:mm"
          onChange={(time) =>
            setFieldValue(props.name, moment(time).format("HH:mm"))
          }
          className="w-full text-center"
          disabled={!values.date}
          value={
            values.time
              ? moment(
                  moment().format("DD-MM-YYYY") + " " + values.time,
                  "DD-MM-YYYY HH:mm"
                )
              : null
          }
          disabledHours={() => {
            let disabledHours = [];

            if (!values.date) return disabledHours;
            else {
              let start = moment(
                props.workingHours[
                  moment(values.date, "DD-MM-YYYY").format("dddd")
                ].start
              ).format("HH");

              let end = moment(
                props.workingHours[
                  moment(values.date, "DD-MM-YYYY").format("dddd")
                ].end
              ).format("HH");

              for (var i = 0; i < start; i++) {
                disabledHours.push(i);
              }

              for (var j = 24; j > end; j--) {
                disabledHours.push(j);
              }

              return disabledHours;
            }
          }}
          disabledMinutes={(selectedHour) => {
            let disabledMinutes = [];

            if (selectedHour === -1) {
              for (var i = 0; i < 60; i++) {
                disabledMinutes.push(i);
              }
            } else if (values.date) {
              if (
                selectedHour ===
                parseInt(
                  moment(
                    props.workingHours[
                      moment(values.date, "DD-MM-YYYY").format("dddd")
                    ].start
                  ).format("HH")
                )
              ) {
                let start = moment(
                  props.workingHours[
                    moment(values.date, "DD-MM-YYYY").format("dddd")
                  ].start
                ).format("mm");

                for (var j = 0; j < start; j++) {
                  disabledMinutes.push(j);
                }
              } else if (
                selectedHour ===
                parseInt(
                  moment(
                    props.workingHours[
                      moment(values.date, "DD-MM-YYYY").format("dddd")
                    ].end
                  ).format("HH")
                )
              ) {
                let end = moment(
                  props.workingHours[
                    moment(values.date, "DD-MM-YYYY").format("dddd")
                  ].end
                ).format("mm");

                for (var k = 60; k > end; k--) {
                  disabledMinutes.push(k);
                }
              }
            }
            return disabledMinutes;
          }}
        />
      </div>

      <div style={{ minHeight: 21 }}>
        {errors[props.name] ? (
          <div className="error">{errors[props.name]}</div>
        ) : null}
      </div>
    </div>
  );
};

VisitTimepicker.propTypes = {
  label: PropTypes.string
};
export default VisitTimepicker;
