import { Checkbox } from "antd";
import { useField } from "formik";
import PropTypes from "prop-types";
import React from "react";
import "./form.css";

const ReactRelationalCheckboxGroup = (props) => {
  const [field, meta, helpers] = useField(props);

  const { setValue } = helpers;

  const handleInsert = (selectedValues) => {
    let temp = selectedValues.map((value) => {
      let obj = {};
      obj[props.relation_type] = value;
      obj["is_active"] = true;

      return obj;
    });

    setValue(temp);
  };

  const handleUpdate = (selectedValues) => {
    let tempFieldValue = field.value.map((value) => {
      let obj = {};
      obj.is_active = value.is_active;
      obj[props.relation_type] = value[props.relation_type];
      return obj;
    });

    selectedValues.forEach((value) => {
      let index = tempFieldValue.findIndex((fieldValue) => {
        return fieldValue[props.relation_type] === value;
      });

      if (index === -1) {
        let obj = {};
        obj[props.relation_type] = value;
        obj["is_active"] = true;

        tempFieldValue.push(obj);
      }
    });

    tempFieldValue.forEach((value, i) => {
      let index = selectedValues.findIndex((fieldValue) => {
        return fieldValue === value[props.relation_type];
      });

      if (index === -1) {
        tempFieldValue[i].is_active = false;
      } else {
        tempFieldValue[i].is_active = true;
      }
    });

    setValue(tempFieldValue);
  };

  return (
    <div className="text-red-500 text-left space-y-2">
      {props.label && (
        <label className="text-gray-800 font-semibold text-lg">
          {props.label}
        </label>
      )}
      <div>
        <Checkbox.Group
          {...field}
          {...props}
          className="flex"
          value={field.value
            .filter((value) => {
              return value.is_active === true;
            })
            .map((filteredValue) => {
              return filteredValue[props.relation_type];
            })}
          onChange={(selectedValues) => {
            // setValue(selectedValues);

            props.mode === "Insert"
              ? handleInsert(selectedValues)
              : handleUpdate(selectedValues);
          }}
        />
      </div>
      <div style={{ minHeight: 21 }}>
        {meta.touched && meta.error ? (
          <div className="error">Required</div>
        ) : null}
      </div>
    </div>
  );
};

ReactRelationalCheckboxGroup.propTypes = {
  label: PropTypes.string,
};
export default ReactRelationalCheckboxGroup;
