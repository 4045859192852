import { Select } from "antd";
import { useField, useFormikContext } from "formik";
import PropTypes from "prop-types";
import * as React from "react";

const { Option } = Select;

const ReactDependantSelect = (props) => {
  const [field, meta, helpers] = useField(props);
  const { values } = useFormikContext();

  const { setValue } = helpers;

  return (
    <div className="text-red-500 text-left space-y-2">
      {props.label && (
        <label className="text-gray-800 font-semibold text-base">
          {props.label}
        </label>
      )}
      <Select
        {...field}
        value={field.value === "" ? props.placeholder : field.value}
        {...props}
        onChange={(value) => {
          setValue(value);
        }}
        className={`p-0 mb-0 leading-tight ant-input-bg focus:outline-none focus:shadow-outline rounded ${
          field.value === "" ? "text-gray-500" : "text-gray-700"
        }`}
      >
        {props.data.filter((datapoint) => {
          return datapoint[props.dependancy] === values[props.dependancy];
        }).length > 0
          ? props.data
              .filter((datapoint) => {
                return datapoint[props.dependancy] === values[props.dependancy];
              })[0]
              [props.requiredObject].map((branch) => {
                return (
                  <Option value={branch[props.requiredId]}>
                    {branch[props.requiredName]}
                  </Option>
                );
              })
          : null}
      </Select>
      <div style={{ minHeight: 21 }}>
        {meta.touched && meta.error ? (
          <div className="error text-red-500">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
};

ReactDependantSelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
};

export default ReactDependantSelect;
