import { Select } from "antd";
import { useField, useFormikContext } from "formik";
import PropTypes from "prop-types";
import * as React from "react";

const ReactSelect = (props) => {
  const [field, meta, helpers] = useField(props);
  const { values, setFieldValue } = useFormikContext();

  const { setValue } = helpers;

  return (
    <div className="text-red-500 text-left space-y-2">
      {props.label && (
        <label className="text-gray-800 font-semibold text-base">
          {props.label}
        </label>
      )}
      <Select
        {...field}
        value={field.value === "" ? props.placeholder : field.value}
        {...props}
        onChange={(value) => {
          if (props.resetKeys?.length > 0) {
            props.resetKeys.forEach((key) => setFieldValue(key, null));
          }
          setValue(value);
        }}
        className={`p-0 mb-0 leading-tight ant-input-bg focus:outline-none focus:shadow-outline rounded ${
          field.value === "" ? "text-gray-500" : "text-gray-700"
        }`}
        style={
          props.mode === "multiple" ? { lineHeight: "14px !important" } : null
        }
        disabled={
          props.disabled || props.disabledKey !== undefined
            ? values[props.disabledKey] !== undefined
              ? values[props.disabledKey].includes(props.disabledValue)
              : props.disabled
            : props.disabled
        }
      >
        {props.children}
      </Select>
      <div style={{ minHeight: 21 }}>
        {meta.touched && meta.error ? (
          <div className="error text-red-500">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
};

ReactSelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
};

export default ReactSelect;
