import gql from "graphql-tag";

export const GET_PARTNERS = gql`
  query partners(
    $limit: Int
    $offset: Int
    $where: partners_bool_exp = {}
    $order_by: [partners_order_by!] = {}
  ) {
    partners_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    partners(
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      id
      partner_name
      partner_logo
      partner_display_id
      is_active
      partners_x_partnerTypes(where: { is_active: { _eq: true } }) {
        id
        partner_type_id
        partner_type {
          id
          partner_type
        }
      }
      partner_branches_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
export const EXPORT_PARTNERS = gql`
  query partners(
    $where: partners_bool_exp = {}
    $order_by: [partners_order_by!] = {}
  ) {
    partners(order_by: $order_by, where: $where) {
      id
      partner_display_id
      partner_logo
      partner_name
      is_active
      created_at
      updated_at
      created_by {
        id
        first_name
        last_name
      }
      updated_by {
        id
        first_name
        last_name
      }
      partners_x_partnerTypes {
        partner_type {
          partner_type
        }
      }
      partner_branches_aggregate {
        aggregate {
          count
        }
      }
      partner_branches {
        id
        is_active
        partner_branches_display_id
        partner_branch_name
        partners_branches_x_loanTypes(where: { is_active: { _eq: true } }) {
          loan_type {
            loan_type
          }
        }
      }
    }
  }
`;

export const GET_PARTNERS_FILTER = gql`
  query partner_types {
    partner_types(where: { is_active: { _eq: true } }) {
      id
      partner_type
    }
  }
`;

export const GET_PARTNER_DETAILS = gql`
  query partners_by_pk($id: Int!) {
    partners_by_pk(id: $id) {
      id
      partner_display_id
      partner_logo
      partner_name
      is_active
      created_at
      updated_at
      created_by {
        id
        first_name
        last_name
      }
      updated_by {
        id
        first_name
        last_name
      }
      partners_x_partnerTypes {
        partner_type {
          partner_type
        }
      }
      partner_branches_aggregate {
        aggregate {
          count
        }
      }
      partner_branches {
        id
        is_active
        partner_branches_display_id
        partner_branch_name
        partners_branches_x_loanTypes(where: { is_active: { _eq: true } }) {
          loan_type_id
          loan_type {
            loan_type
          }
        }
      }
    }
  }
`;

const GET_PARTNER_DETAILS_FOR_EDIT = gql`
  query getPartnerDetailsForEdit($id: Int!) {
    partners(where: { id: { _eq: $id } }) {
      id
      partner_name
      partner_logo
      partners_x_partnerTypes(where: { partners_id: { _eq: $id } }) {
        id
        partner_type_id
        is_active
      }
    }
  }
`;

const GET_PARTNER_TYPES = gql`
  query getPartnerTypes {
    partner_types {
      id
      partner_type
      is_active
    }
  }
`;

const UPDATE_PARTNER = gql`
  mutation updatePartner(
    $id: Int!
    $partner_data: partners_set_input!
    $partner_types: [partners_x_partnerTypes_insert_input!]!
  ) {
    update_partners(where: { id: { _eq: $id } }, _set: $partner_data) {
      returning {
        id
        partner_name
        partner_logo
      }
    }
    insert_partners_x_partnerTypes(
      objects: $partner_types
      on_conflict: {
        constraint: partners_x_partnerTypes_partner_type_partners_key
        update_columns: is_active
      }
    ) {
      returning {
        id
        partners_id
        partner_type_id
        is_active
      }
    }
  }
`;
// working_hours;
const GET_PARTNER_BRANCH_DETAILS_FOR_EDIT = gql`
  query getPartnerBranchDetailsForEdit($id: Int!) {
    partner_branches(where: { id: { _eq: $id } }) {
      id
      partner_branch_name
      poc_name
      poc_phone_number
      address

      lat
      long
      partners_id
      partners_branches_x_loanTypes {
        id
        loan_type_id
        is_active
      }
    }
  }
`;

// const UPDATE_PARTNER_BRANCH = gql`
//   mutation updatePartnerBranchDetails(
//     $id: Int!
//     $partner_details: partner_branches_set_input!
//     $partner_loan_types: [partner_branches_x_loanType_insert_input!]!
//   ) {
//     update_partner_branches(
//       where: { id: { _eq: $id } }
//       _set: $partner_details
//     ) {
//       returning {
//         id
//         partner_branch_name
//         poc_name
//         poc_phone_number
//         address
//         lat
//         long
//         working_hours
//       }
//     }
//     insert_partner_branches_x_loanType(
//       objects: $partner_loan_types
//       on_conflict: {
//         constraint: partner_branches_x_loanType_partner_branches_id_loan_type_key
//         update_columns: is_active
//       }
//     ) {
//       returning {
//         id
//         partner_branches_id
//         loan_type_id
//         is_active
//       }
//     }
//   }
// `;

const UPDATE_PARTNER_BRANCH = gql`
  mutation updatePartnerBranchDetails(
    $id: Int!
    $partner_details: partner_branches_set_input!
  ) {
    update_partner_branches(
      where: { id: { _eq: $id } }
      _set: $partner_details
    ) {
      returning {
        id
        partner_branch_name
        partner {
          id
          partner_name
        }
      }
    }
  }
`;

const INACTIVATE_PARTNER = gql`
  mutation inactivatePartner($id: Int!) {
    update_partners(where: { id: { _eq: $id } }, _set: { is_active: false }) {
      returning {
        id
        is_active
      }
    }
    update_partner_branches(
      where: { partners_id: { _eq: $id } }
      _set: { is_active: false }
    ) {
      returning {
        id
        is_active
      }
    }
  }
`;

const INACTIVATE_BRANCH = gql`
  mutation inactivateBranch($id: Int!) {
    update_partner_branches(
      where: { id: { _eq: $id } }
      _set: { is_active: false }
    ) {
      returning {
        id
        is_active
      }
    }
  }
`;

const GET_BRANCH_DETAILS_BY_ID = gql`
  query getBranchDetailsById($id: Int!, $isBranch: Boolean!) {
    partner_branches_by_pk(id: $id) {
      id
      partner_branches_display_id
      partner_branch_name
      address
      lat
      long
      blc {
        id
        first_name
        last_name
        mobile_number
        email_id
      }
      blv {
        id
        first_name
        last_name
        mobile_number
        email_id
      }
      is_active
      created_at
      updated_at
      created_by @include(if: $isBranch) {
        id
        first_name
        last_name
      }
      updated_by @include(if: $isBranch) {
        id
        first_name
        last_name
      }
      partner {
        id
        partner_name
      }
      partners_branches_x_loanTypes(where: { is_active: { _eq: true } }) {
        id
        loan_type {
          id
          loan_type
        }
      }
      plans_x_partner_branches(where: { plan: { is_active: { _eq: true } } }) {
        id
        plan {
          id
          plan_display_id
          plan_name
        }
      }
    }
  }
`;

const INSERT_BLC_BLV = gql`
  mutation insertBlcBlv(
    $obj: [UserInsertInput!]!
    $partner_branch: [InsertParnterBranchInput!]!
  ) {
    insert_partner_branch(obj: $obj, partner_branch: $partner_branch) {
      id
    }
  }
`;

const INSERT_PARTNER = gql`
  mutation insertPartner($obj: partners_insert_input!) {
    insert_partners_one(object: $obj) {
      id
      partner_name
      partner_logo
    }
  }
`;

const GET_SL_PARTNERS_LIST = gql`
  query getPartners {
    partners(
      where: {
        is_active: { _eq: true }
        partners_x_partnerTypes: {
          partner_type: { partner_type: { _in: ["Personal Lender", "NBFC"] } }
        }
      }
    ) {
      id
      partner_name
    }
  }
`;

const GET_LATEST_BRANCH_ID = gql`
  query getLatestBranchId {
    partner_branches(limit: 1, order_by: { id: desc }) {
      id
    }
  }
`;

// const INSERT_PARTNER_BRANCH = gql`
//   mutation insertPartnerBranch(
//     $obj: InsertPartnerBranchPartnerBranchesInsertInput!
//   ) {
//     insert_partner_branch(obj: $obj) {
//       id
//     }
//   }
// `;

const INSERT_PARTNER_BRANCH = gql`
  mutation insertPartnerBranch($obj: partner_branches_insert_input!) {
    insert_partner_branches_one(object: $obj) {
      id
      partner_branch_name
      partners_id
      partner {
        id
        partner_name
      }
      partners_branches_x_loanTypes {
        id
        loan_type_id
        loan_type {
          id
          loan_type
        }
      }
    }
  }
`;

export {
  GET_PARTNER_DETAILS_FOR_EDIT,
  GET_PARTNER_TYPES,
  UPDATE_PARTNER,
  GET_PARTNER_BRANCH_DETAILS_FOR_EDIT,
  UPDATE_PARTNER_BRANCH,
  INACTIVATE_PARTNER,
  INACTIVATE_BRANCH,
  GET_BRANCH_DETAILS_BY_ID,
  INSERT_PARTNER,
  GET_SL_PARTNERS_LIST,
  GET_LATEST_BRANCH_ID,
  INSERT_PARTNER_BRANCH,
  INSERT_BLC_BLV,
};
