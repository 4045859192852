// DEPS
import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";
import moment from "moment";

// COMPONENTS
import { Modal as AntModal, Select, notification } from "antd";
import {
  ReactForm,
  ReactInput,
  ReactInputNumber,
  ReactSelect,
  ImageUpload,
} from "../Form";

// APIS
import { GET_LOAN_BY_ID, GET_LEDGER_LOAN_DETAILS } from "../../pages/loans/graphql";

const { Option } = Select;

const INSERT_FUND_TRANSFER_DETAILS = gql`
  mutation insertFundTransferPayment(
    $loan_id: Int!
    $utr: String
    $amount: float8!
    $screenshot_url: String
    $payment_type: String
    $transaction_type: String
    $LedgerFunds: transfertype
  ) {
    insert_payments_one(
      object: {
        loan_id: $loan_id
        unique_transaction_reference: $utr
        paid_amount: $amount
        payment_info: $screenshot_url
        payment_status: "Success"
        payment_type: $payment_type
        transaction_type: $transaction_type
      }
    ) {
      id
      loan_id
      unique_transaction_reference
      payment_info
    }
    ledger_loans_fund_transfer(funds: $LedgerFunds) {
      code
      body
      message
    }
  }
`;

const UPDATE_LOAN_STATUS = gql`
  mutation updateLoanToFundTransferred($loan_id: Int!) {
    update_loans_by_pk(
      pk_columns: { id: $loan_id }
      _set: { loan_status: "LOAN_AMOUNT_TRANSFERRED" }
    ) {
      id
      loan_status
    }
  }
`;

const UPDATE_VISIT_INTERMEDIATE_STATUS = gql`
  mutation updateVisitIntermediateStatusForFundTransfer($visit_id: Int!) {
    update_visit_intermediate_status(
      new_status: "OD_LOAN_AMOUNT_TRANSFERRED"
      visit_id: $visit_id
    ) {
      id
      intermediate_status
    }
  }
`;

const FundTransferValidationSchema = Yup.object({
  amount: Yup.number().typeError("Please enter a number").required("Required"),
  transaction_photo: Yup.string().nullable(),
  payment_type: Yup.string().ensure().required("Required"),
});

const RecordFundTransfer = (props) => {
  const [insertFundTransferDetails] = useMutation(
    INSERT_FUND_TRANSFER_DETAILS,
    {
      onError(error) {
        error.graphQLErrors.forEach((error) => {
          notification.error({
            message: "Error!",
            description: "Something went wrong. Please try again or contact support for help.",
          });
        });
      },
      onCompleted(data) {
        notification.success({
          message: "Success!",
          description: "Fund Transfer Details Saved",
        });

        if (props.fundTransferDone) {
          updateLoanStatus({
            variables: {
              loan_id: props.loan_id,
            },
          });

          updateVisitIntermediateStatus({
            variables: {
              visit_id: props.visit_id,
            },
          });
        }

        props.setOpen(false);
      },
      refetchQueries: [
        {
          query: GET_LOAN_BY_ID,
          variables: {
            id: props.loan_id,
          },
        },
        {
          query: GET_LEDGER_LOAN_DETAILS,
          variables:{
            loanNumber: props.loan_number,
            sl_loanNumber: props.support_loan_number || "",
            isSupportLoanAvailable: !!props.support_loan_number,
          }
        },
      ],
    }
  );

  const [updateVisitIntermediateStatus] = useMutation(
    UPDATE_VISIT_INTERMEDIATE_STATUS,
    {
      onCompleted(data) {
        notification.success({
          message: "Success!",
          description: "Visit status updated",
        });
        props.setOpen(false);
      },
      onError(error) {
        error.graphQLErrors.forEach((error) => {
          notification.warning({
            message: "Warning!",
            description: "Something went wrong. Visit Status was not updated. Contact support for help",
          });
        });
      },
    }
  );

  const [updateLoanStatus] = useMutation(UPDATE_LOAN_STATUS, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        notification.error({
          message: "Error!",
          description: "Something went wrong. Loan Status was not updated. Contact support for help",
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Loan status updated",
      });
      props.setOpen(false);
    },
    refetchQueries: [
      {
        query: GET_LOAN_BY_ID,
        variables: {
          id: props.loan_id,
        },
      },
    ],
  });

  const formRef = React.useRef();

  const handleSubmit = (values) => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <AntModal
      width="640px"
      className="modal-title-center"
      //   title="Store gold"
      visible={props.isOpen}
      footer={null}
    >
      <div className="space-y-8 mx-auto text-center">
        <h1>Enter transaction details</h1>

        <ReactForm
          formCls="flex flex-col content-center justify-center space-y-4"
          innerRef={formRef}
          initialValues={{
            utr: null,
            amount: props.amount,
            transaction_photo: null,
            payment_type: "",
          }}
          validationSchema={FundTransferValidationSchema}
          onSubmit={async (values) => {
            insertFundTransferDetails({
              variables: {
                loan_id: props.loan_id,
                utr: values.utr,
                amount: values.amount,
                screenshot_url: values.transaction_photo,
                payment_type: values.payment_type,
                transaction_type: props.paymentType,
                LedgerFunds: {
                  amount: values.amount,
                  currency: "Ruppee",
                  payment_time: moment().format(),
                  oro_loan_number: props.paymentType === "Fund Transfer" ? props.loan_number : props.support_loan_number,
                  method: values.payment_type,
                  transaction_id: values.utr,
                }
              },
            });
          }}
        >
          <ReactInput
            name="utr"
            type="text"
            label="UTR"
            placeholder=""
          />

          <ReactInputNumber
            name="amount"
            label="Amount"
            min={0}
            precision={2}
            disabled
          />

          <div className="space-y-2 text-left relative">
            <h2 className="text-gray-800 font-semibold">
              Transaction screenshot:
            </h2>
            <ImageUpload
              buttonText="Upload"
              multiple={false}
              name="transaction_photo"
              showError={true}
              maxLength={1}
            />
          </div>

          <ReactSelect
            name="payment_type"
            type="text"
            placeholder="RTGS / NEFT / IMPS / UPI"
            label="Transaction type"
          >
            <Option value="RTGS">RTGS</Option>
            <Option value="NEFT">NEFT</Option>
            <Option value="IMPS">IMPS</Option>
            <Option value="UPI">UPI</Option>
          </ReactSelect>
        </ReactForm>

        <div className="flex space-x-4 justify-center">
          <button
            className="btn-primary"
            //   onClick={() => props.approveLoan()}
            onClick={() => handleSubmit()}
          >
            Confirm
          </button>
          <button
            className="btn-danger"
            onClick={() => {
              props.setOpen(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </AntModal>
  );
};

export default RecordFundTransfer;
