import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import PropTypes from "prop-types";
import React from "react";
import ColumnSetting from "./ColumnSetting";
import TableFilter from "./filter";

const Toolbar = props => {
  let columnsMap = {};
  props.columns.forEach(item => (columnsMap[item.key] = item));
  return (
    <React.Fragment>
      <TableFilter
        filters={props.filter}
        onSearch={value => {
          props.onSearch(value);
        }}
        onChangeMore={props.onChangeMore}
        onChangeDate={props.onChangeDate}
        onChangeSelectFilter={props.onChangeSelectFilter}
        isMoreFilter={props.isMoreFilter}
        isSearch={props.isSearch}
      />
      <div className="flex justify-end items-center pt-3 pr-3 pb-3">
        {props.isToggleFullScreen && (
          <div onClick={props.fullScreenMode} className="mr-2">
            {props.fullScreen ? (
              <Tooltip title={"Exit FullScreen"}>
                <FullscreenExitOutlined />
              </Tooltip>
            ) : (
              <Tooltip title={"Full Screen"}>
                <FullscreenOutlined />
              </Tooltip>
            )}
          </div>
        )}
        {!props.fullScreen && props.isToggleColumn && (
          <ColumnSetting
            columns={props.columns}
            columnsMap={columnsMap}
            setSortKeyColumns={data => {
              if (data.length === 0) {
                props.setColumns(props.defaultColumns);

                return;
              }
              let leftColumns = [],
                rightColumns = [],
                columns = [];

              data.forEach(key => {
                let columnData = columnsMap[key];
                if (columnData) {
                  if (columnData.fixed === "left") {
                    leftColumns.push(columnData);
                  } else if (columnData.fixed === "right") {
                    rightColumns.push(columnData);
                  } else {
                    columns.push(columnData);
                  }
                }
              });

              props.setColumns([...leftColumns, ...columns, ...rightColumns]);
            }}
            sortKeyColumns={props.columns.map((column, index) => {
              return column.key;
            })}
            // setKeyColumns={() => {}}
            setColumnsMap={data => {
              let leftColumns = [],
                rightColumns = [],
                columns = [];
              if (Object.keys(data).length === 0) {
                props.setColumns(props.defaultColumns);

                return;
              }
              Object.keys(data).forEach((key, index) => {
                let columnData = {
                  ...props.columns[index],
                  ...data[key]
                };
                if (columnData.fixed === "left") {
                  leftColumns.push(columnData);
                } else if (columnData.fixed === "right") {
                  rightColumns.push(columnData);
                } else {
                  columns.push(columnData);
                }
              });

              props.setColumns([...leftColumns, ...columns, ...rightColumns]);
              //columns = columnsM;
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
};
Toolbar.defaultProps = {
  fullScreenMode: () => {},
  isToggleFullScreen: false,
  isToggleColumn: true,
  isMoreFilter: false,
  isSearch: true
};
Toolbar.propTypes = {
  onChangeDate: PropTypes.func,
  onChangeFilter: PropTypes.func,
  onChangeMore: PropTypes.func,
  fullScreenMode: PropTypes.func,
  isToggleFullScreen: PropTypes.bool,
  isToggleColumn: PropTypes.bool,
  setColumns: PropTypes.func,
  filter: PropTypes.array,
  columns: PropTypes.array,
  isMoreFilter: PropTypes.bool,
  isSearch: PropTypes.bool
};

export default Toolbar;
