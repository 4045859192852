import * as Yup from "yup";
import { phoneRegExp, addressSchema } from "../Agent/validationSchema";

const visitValidationSchema = Yup.object({
  first_name: Yup.string()
    .trim()
    .required("Required")
    .matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  // last_name: Yup.string().trim().required("Required"),
  mobile: Yup.string()
    .matches(phoneRegExp, "Invalid mobile number")
    .matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed")
    .length(10, "Invalid mobile number")
    .required("Required"),
  // gold_type: Yup.string().trim().required("Required"),
  channel: Yup.string()
    .trim()
    .matches(/^((?![()<>/;[\]{}'"]).)*$/gm, "Special Characters are not allowed"),
  address: Yup.object().shape(addressSchema),
  lat: Yup.number().typeError("Required").required("Required"),
  lng: Yup.number().typeError("Required").required("Required"),
  date: Yup.string().trim().required("Required"),
  time: Yup.string().trim().required("Required"),
  visit_type: Yup.string().trim().required("Required"),
  partner_branch: Yup.string().trim().required("Required"),
});

export { visitValidationSchema };
