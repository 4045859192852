import { Radio } from "antd";
import { useField } from "formik";
import PropTypes from "prop-types";
import React from "react";
import "./form.css";

const ReactRadioGroup = props => {
  const [field, meta] = useField(props);

  //   const { setValue } = helpers;

  return (
    <div className="text-red-500 text-left space-y-2">
      {props.label && (
        <label className="text-gray-800 font-semibold text-lg">
          {props.label}
        </label>
      )}
      <div>
        <Radio.Group
          {...field}
          {...props}
          className="flex"
          //   onChange={selectedValues => {
          //     setValue(selectedValues);
          //   }}
        >
          {props.children}
        </Radio.Group>
      </div>
      <div style={{ minHeight: 21 }}>
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
};

ReactRadioGroup.propTypes = {
  label: PropTypes.string
};
export default ReactRadioGroup;
