import React from 'react';
import Countdown from 'react-countdown';
import jwtDecode from 'jwt-decode';

const LogoutTimer = () => {

  let decoded = jwtDecode(localStorage.getItem('token'));

  const Completionist = () => <span>Session expired!</span>;

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Logout the user when timer runs out
      return <Completionist />;
    } else {
      // Render a countdown
      return <span>{hours}:{minutes}:{seconds}</span>;
    }
  };

  return (
    <div className="text-white">
      <Countdown
        date={decoded.exp * 1000}
        renderer={renderer}
      />
    </div>
  )
}

export default LogoutTimer
