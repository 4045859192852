// DEPS
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button, Tag, Tooltip } from "antd";
import {
  EyeOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/client";
import { CSVLink } from "react-csv";

// COMPONENTS
import ErrorPage from "../../components/ErrorPage";
import ReactTable from "../../components/Table";

// API
import { EXPORT_VISITS, GET_VISITS } from "./graphql";

let StatusColor = {
  VISIT_CANCELLED: "red",
  VISIT_COMPLETED: "green",
  VISIT_CONFIRMED: "blue",
  VISIT_ASSIGNED: "geekblue",
  VISIT_REQUESTED: "purple",
  RELEASE_REQUESTED: "volcano",
};

const columns = [
  {
    title: "Visit ID",
    dataIndex: "visit_display_id",
    key: "visit_display_id",
    show: true,
    sorter: (a, b) => {},
    sortDirections: ["descend", "ascend"],
  },
  // {
  //   title: "Type",
  //   dataIndex: "visit_loan_type",
  //   key: "visit_loan_type",
  //   show: true,
  //   render: (text, data, index) => {
  //     return <div className="uppercase">{text || ""}</div>;
  //   },
  // },
  {
    title: "OTP",
    dataIndex: "visit_otps",
    key: "visit_otps",
    show: true,
    render: (text, data, index) => {
      return <div>FROM: {`${data.visit_otps[0]?.otp_from.name || ""}`} TO: {`${data.visit_otps[0]?.otp_to.name || ""}`} OTP: {`${data.visit_otps[0]?.otp || ""}`}</div>;
    },
  },
  {
    title: "User",
    dataIndex: "first_name",
    key: "first_name",
    show: true,
    render: (text, data, index) => {
      return <div>{`${data.first_name || ""} ${data.last_name || ""}`}</div>;
    },
  },
  {
    title: "Load ID",
    dataIndex: "loan.loan_number",
    key: "loan.loan_number",
    show: true,
    render: (text, data, index) => {
      return data.loan ? (
        <Link
          to={{
            pathname: `/loans/view/${data.loan.id}`,
            state: {
              loan_status: data.loan.loan_status,
            },
          }}
        >
          <p className="text-green-primary hover:underline cursor-pointer">
            {data?.loan?.loan_number || ""}
          </p>
        </Link>
      ) : null;
    },
  },
  {
    title: "Agent Name",
    dataIndex: "agent.agent.user.first_name",
    key: "agent.agent.user.first_name",
    show: true,
    render: (text, data, index) => `${data.agent?.agent?.user?.first_name || ""} ${data.agent?.agent?.user?.last_name || ""}`
  },
  {
    title: "Requested for",
    dataIndex: "visit_time",
    key: "visit_time",
    show: true,
    render: (text, data, index) => {
      return <div>{moment(text).format("DD.MM.YYYY HH:mm")}</div>;
    },
    sorter: (a, b) => {},
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Status",
    dataIndex: "visit_status",
    key: "visit_status",
    show: true,
    render: (text, data, index) => {
      if (data.intermediate_status === "OD_KYC_DETAILS_ADDED" && !data.customer.kycs[0].is_approved) {
        return (
          <Tag className="capitalize" color="red" key={"PENDING_KYC_APPROVAL"}>
            PENDING KYC APPROVAL
          </Tag>
        );
      }
      if (text === "VISIT_CANCELLED") {
        return (
          <>
            <Tag className="capitalize" color="red" key={"VISIT_CANCELLED"}>
              VISIT CANCELLED
            </Tag>
            {data.cancellation_reason ? (
              <Tooltip title={data.cancellation_reason}>
                <InfoCircleOutlined />
              </Tooltip>
            ) : null}
          </>
        );
      }
      return text ? (
        <div>
          <Tag
            className="capitalize"
            color={
              StatusColor[
                (data.visit_type === "GR" && text === "VISIT_REQUESTED") ||
                text === "RELEASE_REQUESTED"
                  ? "RELEASE_REQUESTED"
                  : text
              ]
            }
            key={
              (data.visit_type === "GR" && text === "VISIT_REQUESTED") ||
              text === "RELEASE_REQUESTED"
                ? "RELEASE_REQUESTED"
                : text
            }
          >
            {(data.visit_type === "GR" && text === "VISIT_REQUESTED") ||
            text === "RELEASE_REQUESTED"
              ? "RELEASE REQUESTED"
              : text.split("_").join(" ")}
          </Tag>
        </div>
      ) : null;
    },
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    show: true,
    fixed: "right",
    width: 100,
    render: (text, data, index) => {
      return (
        <Link to={`/visits/view/${data.id}`}>
          <EyeOutlined />
        </Link>
      );
    },
  },
];

const Visits = (props) => {
  const [filter, setFilter] = useState([
    {
      title: "Type",
      dataIndex: "visit_loan_type",
      key: "visit_loan_type",
      type: "select",
      show: false,
      data: [
        // { id: "OD", name: "OD" },
        { id: "GL", name: "GL" },
      ],
      isFixed: true,
      filter: { visit_loan_type: { _in: "$selected" } },
    },
    {
      title: "Status",
      dataIndex: "visit_status",
      key: "visit_status",
      type: "select",
      show: false,
      data: [
        { id: "VISIT_REQUESTED", name: "Requested" },
        { id: "VISIT_CONFIRMED", name: "Confirmed" },
        { id: "VISIT_ASSIGNED", name: "Assigned" },
        { id: "VISIT_IN_PROGRESS", name: "In Progress" },
        { id: "VISIT_COMPLETED", name: "Completed" },
        { id: "VISIT_CANCELLED", name: "Cancelled" },
      ],
      isFixed: true,
      filter: { _in: "$selected" },
    },
    {
      title: "Requested for",
      dataIndex: "visit_time",
      key: "visit_time",
      show: true,
      type: "date",
      data: [],
      isFixed: true,
      minDate: true,

      fromDate: "",

      toDate: "",
    },
  ]);
  const [filterObj, setFilterObj] = useState({});
  const [visitIdOrder, SetVisitIdOrder] = useState(null);
  const [visitTimeOrder, SetVisitTimeOrder] = useState(null);

  useEffect(() => {
    let objectFilters = {};

    (filter || []).forEach((filter) => {
      if (filter.show || filter.isFixed) {
        if (filter.type === "select") {
          if ((filter.selected || []).length) {
            if (filter.filterType) {
              objectFilters[filter.key] = {
                [filter.filterType]: { id: { _in: filter.selected } },
              };
            } else {
              objectFilters[filter.key] = { _in: filter.selected };
            }
          }
        } else if (filter.type === "date") {
          if (filter.fromDate !== "" && filter.toDate !== "")
            objectFilters[filter.key] = {
              _gte: filter.fromDate,
              _lte: filter.toDate,
            };
        }
      }

      //  {
      //   [filter.filterType]: JSON.parse(
      //     JSON.stringify(filter.filter)
      //       .split("$selected")
      //       .join(filter.selected)
      //   ),
      // };
    });
    // resetState();
    setFilterObj(objectFilters);
  }, [filter]);
  const [tableData, setTableData] = useState({
    page: 1,
    data: [],
    hasMoreItems: true,
  });
  const [searchText, SetSearchText] = useState("");
  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    // position: ["none", "none"],
    pageSize: 10,
    current: 1,
    total: 30,
  });

  const [CSVData, setCSVData] = useState([]);

  const formatCSVData = (visits) => {
    let CSVData = [];

    visits.visits.forEach((data) => {
      let temp = {
        visit_id: data.visit_display_id,
        channel: data.channel,
        type: data.visit_loan_type,
        status: data.visit_status,
        confirmed_by: data.created_by
          ? `${(data.created_by || {}).first_name || ""} ${
              (data.created_by || {}).last_name || ""
            }`
          : "N/A",
        assigned_by: data.assigned_by
          ? `${(data.assigned_by || {}).first_name || ""} ${
              (data.assigned_by || {}).last_name || ""
            }`
          : "N/A",
        assigned_to: data.agent
          ? `${(data.agent || {}).first_name || ""} ${
              (data.agent || {}).last_name || ""
            }`
          : "N/A",
        visit_time: moment(data.visit_time).format(
          process.env.REACT_APP_DATE_FORMAT
        ),
        loan_id: (data.loan || {}).loan_number || "",
        partner_branch: data.partner_branch
          ? data.partner_branch.partner_branch_name
          : "N/A",
        user_name: `${data.first_name || ""} ${data.last_name || ""}`,
        user_mobile_number: `${data.mobile_number || ""}`,
        user_address: data.address
          ? `${
              data.address["address line 1"] ||
              data.address["addr_line_1"] ||
              ""
            }, ${
              data.address["address line 2"] ||
              data.address["addr_line_2"] ||
              ""
            }, ${data.address["city"] || ""}, ${
              data.address["state"] || ""
            }-${data.address["pincode"] || ""}`
          : "",
        user_location: `${data.lat},${data.long}`,
        user_gold_type: data.gold_type,
        agent_name: `${(data.agent || {}).first_name || ""} ${
          (data.agent || {}).last_name || ""
        }`,
        agent_mobile_number: `${(data.agent || {}).mobile_number || ""}`,
        Agent_id: `${((data.agent || {}).agent || {}).agent_display_id || ""}`,
        created_at: moment(data.created_at).format(
          process.env.REACT_APP_DATE_FORMAT
        ),
        updated_at: moment(data.updated_at).format(
          process.env.REACT_APP_DATE_FORMAT
        ),
      };
      CSVData.push(temp);
    });

    setTimeout(function () {
      setCSVData(CSVData);
      triggerCSVDownload();
    }, 3000);
  };

  const triggerCSVDownload = () => {
    document.getElementById("react-csv").click();
  };

  const [exportVisitCSV, { loading: exportLoading }] = useLazyQuery(EXPORT_VISITS, {
    variables: {
      order_by: {
        id: visitIdOrder || "asc",
        created_at: visitIdOrder || visitTimeOrder ? null : "asc",
        visit_time: visitTimeOrder || "asc",
      },
      where: {
        _and: [
          {
            _or: [
              { first_name: { _ilike: `%${searchText}%` } },
              { last_name: { _ilike: `%${searchText}%` } },
              // { id: { _eq: parseInt(searchText.replace("OM", "")) } },
            ],
          },
          filterObj,
        ],
      },
    },
    fetchPolicy: "network-only",
    onCompleted: formatCSVData,
  });

  const visitsQueryOnComplete = (visitData) => {
    if (!visitData.visits) {
      return;
    }
    if (visitData.visits_aggregate) {
      setPagination({
        ...pagination,
        total: visitData.visits_aggregate.aggregate.count,
      });
    }
    let hasMoreItems = true;
    if ((visitData.visits || []).length === 0) {
      hasMoreItems = false;
    }

    setTableData({
      ...tableData,
      // data: [...tableData.data, ...(visitData.visits || [])],
      data: visitData.visits || [],
      hasMoreItems: hasMoreItems,
    });
  };

  const {loading: visitLoading, error: visitError,} = useQuery(GET_VISITS, {
    variables: {
      limit: parseInt(process.env.REACT_APP_API_PAGE_LIMIT),
      offset: process.env.REACT_APP_API_PAGE_LIMIT * (tableData.page - 1),
      order_by: {
        id: visitIdOrder,
        created_at: visitIdOrder || visitTimeOrder ? null : "desc",
        visit_time: visitTimeOrder,
      },
      where: {
        _and: [
          {
            _or: [
              { first_name: { _ilike: `%${searchText}%` } },
              { last_name: { _ilike: `%${searchText}%` } },
              { id: { _eq: parseInt(searchText.replace("OMV", "")) || 0 } },
            ],
          },
          filterObj,
        ],
      },
    },
    fetchPolicy: "network-only",
    onCompleted: visitsQueryOnComplete,
  });

  const loadMoreItems = (value) => {
    if (!visitLoading && tableData.hasMoreItems) {
      setTableData((data) => {
        return { ...data, page: data.page + 1 };
      });
    }
  };
  const resetState = () => {
    setTableData({
      page: 1,
      data: [],
      hasMoreItems: true,
    });
    setPagination({
      ...pagination,
      total: 0,
      current: 1,
    });
  };
  
  if (visitError) {
    return <ErrorPage/>;
  }
  
  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    if (sorter.field === "visit_time") {
      let order = sorter.order
        ? sorter.order === "descend"
          ? "desc"
          : "asc"
        : null;
      SetVisitIdOrder(null);

      SetVisitTimeOrder(order);
    }
    if (sorter.field === "visit_display_id") {
      let order = sorter.order
        ? sorter.order === "descend"
          ? "desc"
          : "asc"
        : null;
      SetVisitTimeOrder(null);

      SetVisitIdOrder(order);
    }
    setTableData({
      ...tableData,
      page: pagination.current,
    });
    setPagination(pager);
  };
  return (
    <div>
      <CSVLink
        data={CSVData}
        headers={[
          { label: "Visit ID", key: "visit_id" },
          { label: "Visit Type", key: "type" },
          { label: "Channel", key: "channel" },
          { label: "Status", key: "status" },
          { label: "Confirmed By", key: "confirmed_by" },
          { label: "Assigned By", key: "assigned_by" },
          { label: "Assigned To", key: "assigned_to" },
          { label: "Visit Time", key: "visit_time" },
          { label: "Loan ID", key: "loan_id" },
          { label: "Partner Branch", key: "partner_branch" },
          { label: "User Name", key: "user_name" },
          { label: "User Mobile Number", key: "user_mobile_number" },
          { label: "User Address", key: "user_address" },
          { label: "Location", key: "user_location" },
          { label: "Gold Type", key: "user_gold_type" },
          { label: "Agent Name", key: "agent_name" },
          { label: "Agent Mobile Number", key: "agent_mobile_number" },
          { label: "Agent ID", key: "Agent_id" },
          { label: "Created At", key: "created_at" },
          { label: "Updated At", key: "updated_at" },
        ]}
        filename={`Visits - ${moment().format("DD-MM-YYYY HH:mm")}.csv`}
        className="hidden"
        target="_blank"
        id="react-csv"
      >
        Download CSV
      </CSVLink>
      <div className="flex justify-between  align-center mb-4">
        <h2 className="text-green-primary">All Visits</h2>
        <div className="flex space-x-4">
          <Button
            htmlType="submit"
            className=" rounded-lg flex align-center "
            type="primary"
            size={"large"}
            icon={<PlusOutlined />}
            onClick={() => {
              props.history.push("/visits/set-meta");
            }}
          >
            Set Holidays and Visit Timings
          </Button>
          <Button
            htmlType="submit"
            className=" rounded-lg flex align-center "
            type="primary"
            size={"large"}
            icon={<PlusOutlined />}
            onClick={() => {
              props.history.push("/visits/create");
            }}
          >
            Create new visit
          </Button>
          <Button
            className=" rounded-lg flex align-center ml-3 mr-3"
            type="primary"
            ghost
            size={"large"}
            onClick={() => {
              exportVisitCSV();
            }}
            loading={exportLoading}
          >
            Export
          </Button>
        </div>
      </div>
      <div className="w-100 position-relatives">
        <ReactTable
          columns={columns}
          data={tableData.data}
          table={{
            // pagination: {
            //   hideOnSinglePage: true,
            //   // position: ["none", "none"],
            //   pageSize: 10,
            //   current: tableData.page,
            //   total: 30,
            //   //total: tableData.total,
            // },
            pagination: pagination,
          }}
          filter={filter}
          page={tableData.page}
          hasMoreItems={tableData.hasMoreItems}
          loadItems={() => {
            loadMoreItems(0);
          }}
          isToggleFullScreen={false}
          isToggleColumn={true}
          isMoreFilter={false}
          isSearch={true}
          loading={visitLoading}
          handleTableChange={handleTableChange}
          onToolbarSearch={(value) => {
            if (parseInt(value.replace("OM", ""))) {
              resetState();
            }
            SetSearchText(value);
          }}
          onToolbarMore={(index, value) => {
            const filterData = [...filter];
            filterData[index].show = value;
            resetState();

            setFilter(filterData);
          }}
          onFilterSelectChange={(index, key, value) => {
            const filterData = [...filter];
            filterData[index].selected = value;
            resetState();

            setFilter(filterData);
          }}
          onFilterDateChange={(index, dataKey, value) => {
            const filterData = [...filter];

            filterData[index].fromDate = value
              ? moment(value[0]).toISOString()
              : null;
            filterData[index].toDate = value
              ? moment(value[1]).toISOString()
              : null;
            resetState();

            setFilter(filterData);
          }}
        />
      </div>
    </div>
  );
};

export default Visits;
