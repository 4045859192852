import { Checkbox } from "antd";
import { useField } from "formik";
import PropTypes from "prop-types";
import React from "react";
import "./form.css";

const ReactCheckboxGroup = props => {
  const [field, meta, helpers] = useField(props);

  const { setValue } = helpers;

  return (
    <div className="text-red-500 text-left space-y-2">
      {props.label && (
        <label className="text-gray-800 font-semibold text-lg">
          {props.label}
        </label>
      )}
      <div>
        <Checkbox.Group
          {...field}
          {...props}
          className="flex"
          onChange={selectedValues => {
            setValue(selectedValues);
          }}
        />
      </div>
      <div style={{ minHeight: 21 }}>
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
};

ReactCheckboxGroup.propTypes = {
  label: PropTypes.string
};
export default ReactCheckboxGroup;
