import { Input } from "antd";
import { useField } from "formik";
import PropTypes from "prop-types";
import React from "react";
import "./form.css";

const { TextArea } = Input;

const ReactTextarea = props => {
  const [field, meta] = useField(props);

  return (
    <div className="text-red-500 text-left space-y-2">
      {props.label && (
        <label className="text-gray-800 font-semibold text-lg">
          {props.label}
        </label>
      )}
      <div>
        <TextArea {...field} {...props} className="w-full" />
      </div>

      <div style={{ minHeight: "21px" }}>
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
};

ReactTextarea.propTypes = {
  label: PropTypes.string
};
export default ReactTextarea;
