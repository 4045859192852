import { useMutation, useQuery } from "@apollo/client";
import { Button, Popconfirm, notification, Tag } from "antd";
import moment from "moment";
import React from "react";
import ErrorPage from "../../components/ErrorPage";
import { GET_PLAN_BY_ID, MAKE_PLAN_ACTIVE } from "./graphql";
import { LeftOutlined } from "@ant-design/icons";

const ViewPlans = (props) => {
  const [ActivatePlan] = useMutation(MAKE_PLAN_ACTIVE, {
    refetchQueries: [
      {
        query: GET_PLAN_BY_ID,
        variables: {
          where: {
            id: { _eq: parseInt(props.match.params.id) },
          },
        },
        // onCompleted: planOnCompleted
      },
    ],
    onCompleted: (data) => {
      notification.success({
        message: "Success!",
        description: `Plan deactivated`,
      });

      props.history.push("/plans");
    },
  });

  const { data: planData, loading: planLoading, error: planError } = useQuery(
    GET_PLAN_BY_ID,
    {
      variables: {
        where: {
          id: { _eq: parseInt(props.match.params.id) },
        },
      },
      // onCompleted: planOnCompleted
    }
  );
  if (!props.match.params.id || planError) {
    return <ErrorPage></ErrorPage>;
  }
  if (planLoading) {
    return <div>Loading</div>;
  }
  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center pl-3">
          <div className="relative">
            <LeftOutlined
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "#16A085",
              }}
            />
          </div>

          <Button
            type="link"
            onClick={() => {
              props.history.push("/plans");
            }}
          >
            All plans
          </Button>
        </div>

        {planData.plans[0].is_active && (
          <Popconfirm
            title={`Are you sure deactivate this plan?`}
            onConfirm={() => {
              ActivatePlan({
                variables: {
                  id: parseInt(props.match.params.id),
                  status: !planData.plans[0].is_active,
                },
              });
            }}
            onCancel={() => {}}
            okText="Yes"
            placement="bottomRight"
            cancelText="No"
          >
            <Button
              type={!planData.plans[0].is_active ? "primary" : ""}
              danger={planData.plans[0].is_active}
              className="rounded-md"
            >
              {`Make as ${planData.plans[0].is_active ? "in" : ""}active`}
            </Button>
          </Popconfirm>
        )}
      </div>
      <div className="mt-3">
        <h2>Plan Details | {planData.plans[0].plan_display_id} </h2>

        <div className="flex text-gray-600 space-x-1">
          <p>{`Created at ${moment(planData.plans[0].created_at).format(
            "DD MMM YYYY HH:mm"
          )} by ${planData.plans[0].created_by.first_name} ${
            planData.plans[0].created_by.last_name
          } `}</p>
          <p>{`${
            planData.plans[0].created_at !== planData.plans[0].updated_at &&
            planData.plans[0].updated_by
              ? `| Updated at ${moment(planData.plans[0].updated_at).format(
                  "DD MMM YYYY HH:mm"
                )} ${planData.plans[0].updated_by.first_name} ${
                  planData.plans[0].updated_by.last_name
                }`
              : ""
          }`}</p>
        </div>
      </div>
      <div className="mt-10">
        <h4 className="mb-2">PLAN OVERVIEW</h4>
        <div className="flex bg-green-primary">
          <div className=" font-semibold text-white p-3 w-1/4">Plan ID</div>
          <div className=" font-semibold text-white p-3 w-1/4">Scheme ID</div>
          <div className=" font-semibold text-white p-3 w-1/2">Plan Name</div>
          <div className=" font-semibold text-white p-3 w-1/2">Plan Type</div>
          <div className=" font-semibold text-white p-3 w-1/2">Validity</div>
          <div className=" font-semibold text-white p-3 w-1/6">Plan type </div>
          <div className=" font-semibold text-white p-3 w-1/6">Plan Status</div>
        </div>
        <div className="flex bg-white">
          <div className="font-semibold p-3 w-1/4 break-all">
            {planData.plans[0].plan_display_id}
          </div>
          <div className="font-semibold p-3 w-1/4 break-all">
            {planData.plans[0].scheme_id}
          </div>
          <div className="font-semibold p-3 w-1/2 break-all">
            {planData.plans[0].plan_name}
          </div>
          <div className="font-semibold p-3 w-1/2 break-all">
            {planData.plans[0].plan_type}
          </div>
          <div className="font-semibold p-3 w-1/2 break-all">
            {planData.plans[0].plan_start_date &&
            planData.plans[0].plan_end_date
              ? `${moment(planData.plans[0].plan_start_date).format(
                  "DD MMM YYYY HH:mm"
                )} - ${moment(planData.plans[0].plan_end_date).format(
                  "DD MMM YYYY HH:mm"
                )}`
              : ""}
          </div>
          <div className="font-semibold p-3 w-1/6 break-all">
            {(planData.plans[0].plans_x_loanTypes || [])
              .map((loanType) => {
                return (loanType.loan_type || {}).loan_type || "";
              })
              .toString()}
          </div>

          <div className="font-semibold p-3 w-1/6 break-all">
            {planData.plans[0].is_active ? (
              <Tag color={"green"}>Active</Tag>
            ) : (
              <Tag color={"red"}>Inactive</Tag>
            )}
          </div>
        </div>
      </div>
      <div className="mt-10">
        <h4 className="mb-2">PLAN DETAILS</h4>
        <div className="flex bg-green-primary">
          <div className=" font-semibold text-white p-3 w-1/4">Min Amount</div>
          <div className=" font-semibold text-white p-3 w-1/4">Max Amount</div>
          {planData.plans[0].plan_type === "Monthly Repayment Plan" ? (
            <>
              <div className=" font-semibold text-white p-3 w-1/4">
                Interest % pa
              </div>
              <div className=" font-semibold text-white p-3 w-1/4">
                Interest slab 2 % pa
              </div>
              <div className=" font-semibold text-white p-3 w-1/4">
                Interest slab 3 % pa
              </div>
            </>
          ) : (
            <div className=" font-semibold text-white p-3 w-1/4">
              Interest % pa
            </div>
          )}
          <div className=" font-semibold text-white p-3 w-1/4">
            Bank Interest % pa
          </div>
          <div className=" font-semibold text-white p-3 w-1/4">LTV</div>
          <div className=" font-semibold text-white p-3 w-1/6">Tenture</div>
          {/* <div className=" font-semibold text-white p-3 w-1/4">
            Rates Applicable
          </div> */}
        </div>
        <div className="flex bg-white">
          <div className="font-semibold p-3 w-1/4 break-all">
            ₹ {planData.plans[0].minimum_amount}
          </div>
          <div className="font-semibold p-3 w-1/4 break-all">
            ₹ {planData.plans[0].maximum_amount}
          </div>
          {planData.plans[0].plan_type === "Monthly Repayment Plan" ? (
            <>
              {" "}
              <div className="font-semibold p-3 w-1/4 break-all">
                {planData.plans[0].interest} %
              </div>
              <div className="font-semibold p-3 w-1/4 break-all">
                {planData.plans[0].interest_slab_2} %
              </div>
              <div className="font-semibold p-3 w-1/4 break-all">
                {planData.plans[0].interest_slab_3} %
              </div>{" "}
            </>
          ) : (
            <div className="font-semibold p-3 w-1/4 break-all">
              {planData.plans[0].interest} %
            </div>
          )}

          <div className="font-semibold p-3 w-1/4 break-all">
            {planData.plans[0].bank_interest} %
          </div>

          <div className="font-semibold p-3 w-1/4 break-all">
            {planData.plans[0].ltv} %
          </div>
          <div className="font-semibold p-3 w-1/6 break-all">
            {planData.plans[0].tenure} month(s)
          </div>
          {/* <div className="font-semibold p-3 w-1/4">
            {((planData.plans_x_rates || {}).rate || {}).gold_quality}
            {(planData.plans_x_rates || [])
              .map(loanType => {
                return (loanType.rate || {}).gold_quality || "";
              })
              .toString()}
          </div> */}
        </div>
      </div>

      {/* <div className="mt-10">
        <h4 className="mb-2">RATE DETAILS</h4>
        <div className="flex bg-green-primary">
          <div className=" font-semibold text-white p-3 w-1/6">Rate ID</div>
          <div className=" font-semibold text-white p-3 w-1/4">Rate Name</div>
          <div className=" font-semibold text-white p-3 w-1/4">
            Gold Quality
          </div>
          <div className=" font-semibold text-white p-3 w-1/4">MA Rate</div>
        </div>
            {/* <div className="flex bg-white">
              <div className="font-semibold p-3 w-1/6 break-all">
                {plan_x_rate.rate.rate_display_id}
              </div>
              <div className="font-semibold p-3 w-1/4 break-all">
                {plan_x_rate.rate.gold_quality}
              </div>
              <div className="font-semibold p-3 w-1/4 break-all">
                ₹ {plan_x_rate.rate.ma_rate}
              </div>
            </div> */}

      {/* </div> */}

      {planData.plans[0].features && (
        <div className="mt-10">
          <h4 className="mb-2">FEATURES</h4>
          <div className="flex space-x-2 bg-white rounded-sm p-4">
            <div>
              <p>•</p>
              {planData.plans[0].features.split("").map((letter) => {
                if (letter === "\n") {
                  return <p>•</p>;
                } else {
                  return "";
                }
              })}
            </div>
            <div style={{ whiteSpace: "pre-wrap" }}>
              {planData.plans[0].features}
            </div>
          </div>
        </div>
      )}
      {planData.plans[0].tncs && (
        <div className="mt-10">
          <h4 className="mb-2">TNCs</h4>
          <div className="flex space-x-2 bg-white rounded-sm p-4">
            <div>
              <p>•</p>
              {planData.plans[0].tncs.split("").map((letter) => {
                if (letter === "\n") {
                  return <p>•</p>;
                } else {
                  return "";
                }
              })}
            </div>
            <div style={{ whiteSpace: "pre-wrap" }}>
              {planData.plans[0].tncs}
            </div>
          </div>
        </div>
      )}

      {planData.plans[0].plans_x_partner_branches.length > 0 ? (
        <div className="mt-10">
          <h4 className="mb-2">PARNTER BRANCHES</h4>
          <div className="flex bg-green-primary">
            <div className=" font-semibold text-white p-3 w-1/6">
              Partner ID
            </div>
            <div className=" font-semibold text-white p-3 w-1/4">
              Partner Name
            </div>
            <div className=" font-semibold text-white p-3 w-1/4">Branch ID</div>
            <div className=" font-semibold text-white p-3 w-1/4">
              Branch Name
            </div>
          </div>
          {planData.plans[0].plans_x_partner_branches.map((branch) => {
            return (
              <div className="flex bg-white">
                <div className="font-semibold p-3 w-1/6 break-all">
                  {branch.partner_branch.partner.partner_display_id}
                </div>
                <div className="font-semibold p-3 w-1/4 break-all">
                  {branch.partner_branch.partner.partner_name}
                </div>
                <div className="font-semibold p-3 w-1/4 break-all">
                  {branch.partner_branch.partner_branches_display_id}
                </div>
                <div className="font-semibold p-3 w-1/4 break-all">
                  {branch.partner_branch.partner_branch_name}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default ViewPlans;
