import React from 'react'

function ActionButton({ label, type="primary", className='', handleClick = hc => hc }) {
    return (
        <button
            onClick={() => handleClick()}
            className={`${type==='primary'?'upgraded-primary-bg text-black': type==='danger' ?
                        'bg-red-500 text-white' : 'text-black upgraded-secondary-bg'}
                        rounded-full px-4 py-1 font-bold mx-2 focus:outline-none cursor-pointer
                        ${className}`}
        >{label}
        </button>
    )
}

export default ActionButton;
