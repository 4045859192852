import gql from "graphql-tag";

export const EXPORT_ORO_OFFERS = gql`
  query get_offers(
    $where: oro_offers_bool_exp = {}
    $order_by: [oro_offers_order_by!] = {}
  ) {
    oro_offers(order_by: $order_by, where: $where) {
      lender_type
      lender_name
      id
      offer_display_id
      customer_name
      customer_mobile_number
      created_at
      comments
      agent_auth_id
      release_amount_known
      updated_at
      offer_plans_aggregate {
        aggregate {
          sum {
            total_eligible_amount
          }
        }
      }
      offers_pledge_cards_aggregate {
        aggregate {
          sum {
            release_amount
          }
        }
      }
      offers_pledge_cards {
        release_amount
        created_at
        gross_weight
        id
        net_weight
        pledge_card_identifier
        pledge_card_url
        stone_deduction
        updated_at
        comments
      }
      agent {
        agent {
          user {
            id
            email_id
            mobile_number
            auth_id
            first_name
            last_name
          }
        }
      }
      user {
        id
        email_id
        mobile_number
        auth_id
        first_name
        last_name
      }
    }
  }
`;

export const GET_ORO_OFFERS = gql`
  query get_offers(
    $limit: Int
    $offset: Int
    $where: oro_offers_bool_exp = {}
    $order_by: [oro_offers_order_by!] = {}
  ) {
    oro_offers_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    filterData: oro_offers(distinct_on: lender_type) {
      lender_type
    }
    oro_offers(
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      lender_type
      lender_name
      id
      offer_display_id
      customer_name
      customer_mobile_number
      created_at
      comments
      agent_auth_id
      release_amount_known
      updated_at
      offer_plans_aggregate {
        aggregate {
          sum {
            total_eligible_amount
          }
        }
      }
      offers_pledge_cards_aggregate {
        aggregate {
          sum {
            release_amount
          }
        }
      }
      offers_pledge_cards {
        release_amount
        created_at
        gross_weight
        id
        net_weight
        pledge_card_identifier
        pledge_card_url
        stone_deduction
        updated_at
        comments
      }
      agent {
        agent {
          user {
            id
            email_id
            mobile_number
            auth_id
            first_name
            last_name
          }
        }
      }
      user {
        id
        email_id
        mobile_number
        auth_id
        first_name
        last_name
      }
    }
  }
`;

export const GET_ORO_OFFER_BY_ID = gql`
  query get_offers_by_id($id: Int!) {
    oro_offers_by_pk(id: $id) {
      lender_type
      lender_name
      id
      offer_display_id
      customer_name
      customer_mobile_number
      created_at
      comments
      agent_auth_id
      release_amount_known
      updated_at
      gold_rate
      audit_log
      created_by
      updated_by
      offer_plans {
        created_at
        gl_eligible_amount
        gl_interest
        gl_ltv
        gl_plan_name
        gl_plan_type
        gl_tenure
        id
        net_ltv
        sl_eligible_amount
        sl_interest
        sl_ltv
        sl_plan_name
        sl_plan_type
        sl_tenure
        total_eligible_amount
        total_interest
        updated_at
      }
      offers_pledge_cards {
        release_amount
        created_at
        gross_weight
        id
        net_weight
        pledge_card_identifier
        pledge_card_url
        stone_deduction
        updated_at
        comments
      }
      agent {
        agent {
          user {
            id
            email_id
            mobile_number
            auth_id
            first_name
            last_name
          }
        }
      }
      user {
        id
        email_id
        mobile_number
        auth_id
        first_name
        last_name
      }
      userByUpdatedBy {
        id
        email_id
        mobile_number
        auth_id
        first_name
        last_name
      }
    }
  }
`;

export const GET_PLAN_DETAILS = gql`
  query get_eligible_plans($amount: float8) {
    all_gl_plans: plans(
      where: {
        is_active: { _eq: true }
        plans_x_loanTypes: { loan_type: { loan_type: { _eq: "GL" } } }
      }
      order_by: { ltv: asc }
    ) {
      bank_interest
      created_at
      created_by_auth_id
      features
      id
      interest
      interest_slab_2
      interest_slab_3
      is_active
      ltv
      maximum_amount
      minimum_amount
      plan_display_id
      plan_end_date
      plan_name
      plan_start_date
      plan_type
      scheme_id
      scheme_name
      tenure
      tncs
      updated_at
      updated_by_auth_id
      plans_x_loanTypes {
        loan_type {
          loan_type
        }
      }
    }
    gl_plans: plans(
      where: {
        maximum_amount: { _gte: $amount }
        minimum_amount: { _lte: $amount }
        is_active: { _eq: true }
        plans_x_loanTypes: { loan_type: { loan_type: { _eq: "GL" } } }
      }
      order_by: { ltv: asc }
    ) {
      bank_interest
      created_at
      created_by_auth_id
      features
      id
      interest
      interest_slab_2
      interest_slab_3
      is_active
      ltv
      maximum_amount
      minimum_amount
      plan_display_id
      plan_end_date
      plan_name
      plan_start_date
      plan_type
      scheme_id
      scheme_name
      tenure
      tncs
      updated_at
      updated_by_auth_id
      plans_x_loanTypes {
        loan_type {
          loan_type
        }
      }
    }
    sl_plans: plans(
      where: {
        maximum_amount: { _gte: $amount }
        minimum_amount: { _lte: $amount }
        is_active: { _eq: true }
        plans_x_loanTypes: { loan_type: { loan_type: { _eq: "SL" } } }
      }
    ) {
      bank_interest
      created_at
      created_by_auth_id
      features
      id
      interest
      interest_slab_2
      interest_slab_3
      is_active
      ltv
      maximum_amount
      minimum_amount
      plan_display_id
      plan_end_date
      plan_name
      plan_start_date
      plan_type
      scheme_id
      scheme_name
      tenure
      tncs
      updated_at
      updated_by_auth_id
      plans_x_loanTypes {
        loan_type {
          loan_type
        }
      }
    }
    all_sl_plans: plans(
      where: {
        is_active: { _eq: true }
        plans_x_loanTypes: { loan_type: { loan_type: { _eq: "SL" } } }
      }
    ) {
      bank_interest
      created_at
      created_by_auth_id
      features
      id
      interest
      interest_slab_2
      interest_slab_3
      is_active
      ltv
      maximum_amount
      minimum_amount
      plan_display_id
      plan_end_date
      plan_name
      plan_start_date
      plan_type
      scheme_id
      scheme_name
      tenure
      tncs
      updated_at
      updated_by_auth_id
      plans_x_loanTypes {
        loan_type {
          loan_type
        }
      }
    }
  }
`;

export const CREATE_ORO_OFFER = gql`
  mutation ($object: oro_offers_insert_input!) {
    insert_oro_offers_one(object: $object) {
      id
      lender_name
      lender_type
    }
  }
`;

export const DELETE_OFFER_DETAILS = gql`
  mutation delete_offers_pledge_cards($offer_id: Int) {
    delete_offers_pledge_cards(where: { offer_id: { _eq: $offer_id } }) {
      affected_rows
    }
    delete_offer_plans(where: { offer_id: { _eq: $offer_id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_ORO_OFFERS = gql`
  mutation update_oro_offers(
    $offer_id: Int
    $offerObject: oro_offers_set_input
    $pledgeObjects: [offers_pledge_cards_insert_input!]!
    $planObjects: [offer_plans_insert_input!]!
  ) {
    update_oro_offers(where: { id: { _eq: $offer_id } }, _set: $offerObject) {
      affected_rows
    }
    insert_offers_pledge_cards(objects: $pledgeObjects) {
      affected_rows
    }
    insert_offer_plans(objects: $planObjects) {
      affected_rows
    }
  }
`;
