import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import Login from "../pages/Auth/login";
import NotFound from "../pages/NotFound";

import history from "../utils/history";

function UnauthenticatedRoutes(props) {
  return (
    <div style={{ margin: "0 auto" }}>
      <Router history={history}>
        <Switch>
          <Route
            path="/"
            exact
            render={() => {
              return (
                <Login {...props} setAuthenticated={props.setAuthenticated} />
              );
            }}
          />
          <Route path="*" component={NotFound}></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default UnauthenticatedRoutes;
