import React, { useState } from "react";

import {
  ReactForm,
  ReactInput,
  ReactInputNumber,
  ReactDatepicker,
  VisitTimePicker,
  ReactSelect,
  ReactGeocoding,
  ReactRadioGroup,
} from "../../components/Form";
import Loading from "../../components/loading";
import ErrorPage from "../../components/ErrorPage";
import { visitValidationSchema } from "./validationSchema";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_PARTNER_BRANCHES,
  INSERT_VISIT,
  GET_CUSTOMER,
  GET_HOLIDAYS,
  GET_WORKING_HOURS,
  GET_ACTIVE_GOLD_ORNAMENT_TYPES,
} from "./graphql";
import moment from "moment";
import { Select, notification, Popconfirm, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import TableFilter from "../../components/Table/filter";
const { Option } = Select;

const CreateVisit = (props) => {
  const formRef = React.useRef();

  const [searchText, SetSearchText] = useState("");
  
  // eslint-disable-next-line
  const [holidays, setHolidays] = useState([]);

  const [insertVisit] = useMutation(INSERT_VISIT, {
    onError(error) {
      error.graphQLErrors.forEach((error) => {
        // if (error.extensions.code === "constraint-violation") {
        // if (
        // error.message.split("constraint")[1].includes("visits_visit_name_key")
        // ) {
        notification.error({
          message: "Error!",
          description:
            "Something went wrong. Please check all details and try again",
        });
        // }
        // }
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Visit created",
      });
      // props.history.push("/visits");
      props.history.push(`/visits/view/${data.insert_visits.returning[0].id}`);
    },
  });

  const {data: workingHoursData, loading: workingHoursLoading, error: workingHoursQueryError} = useQuery(GET_WORKING_HOURS, {
    fetchPolicy: "network-only",
  });

  const {data: goldOrnamentTypes, loading: goldOrnamentTypesLoading, error: goldOrnamentTypesError} = useQuery(GET_ACTIVE_GOLD_ORNAMENT_TYPES);

  const {loading: holidaysLoading, error: holidaysQueryError} = useQuery(GET_HOLIDAYS, {
    onCompleted(data) {
      let holidaysArray = data.oro_meta
        .map((year) =>
          year.data.holidays.map((month) => month.timestamps?.filter((timestamps) => timestamps.length > 0))
        )
        .flat(2)
        .filter((timestamps) => timestamps !== undefined)
        .map((holiday) => moment(holiday).format("DD-MM-YYYY"));
      setHolidays(holidaysArray);
    },
  });

  const {
    loading: branchesDataLoding,
    error: branchesDataError,
    data: branchesData,
  } = useQuery(GET_PARTNER_BRANCHES);

  const {error: customerError, data: customer} = useQuery(GET_CUSTOMER, {
    variables: {
      mobile_number: searchText,
    },
  });

  if (
    branchesDataLoding ||
    holidaysLoading ||
    workingHoursLoading ||
    goldOrnamentTypesLoading
  )
    return <Loading />;

  if (
    branchesDataError ||
    customerError ||
    holidaysQueryError ||
    workingHoursQueryError ||
    goldOrnamentTypesError
  ) {
    return <ErrorPage />;
  }

  const handleSubmit = (values) => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  let initialValues = {
    first_name: "",
    last_name: "",
    mobile: "",
    gold_type: [],
    address: {
      addr_line_1: "",
      addr_line_2: "",
      city: "",
      state: "",
      pincode: "",
      country: "India",
      label: "Home",
      preferred: "yes",
    },
    lat: null,
    lng: null,
    visit_type: "GL",
    loan_subtype: "",
    date: "",
    time: "",
    partner_branch: "",
    support_loan: false,
  };

  if (customer) {
    if (customer.users.length > 0) {
      // Do not allow creation of visit if the customer has an existing visit with visit_status = VISIT_IN_PROGRESS
      // and visit intermediate status < OD_LOAN_AMOUNT_TRANSFERRED i.e. up to pledge card upload
      if (customer.users_aggregate.nodes[0].customer_visits.length > 0)
        notification.error({
          message: "Error!",
          description: "This user already has an active visit",
        });
      else {
        let address = customer.users[0].customer.address.filter((address) => {
          return address.preferred === "yes";
        });

        formRef.current.setFieldValue("first_name", customer.users[0].name);
        // formRef.current.setFieldValue("last_name", customer.users[0].last_name);
        formRef.current.setFieldValue(
          "mobile",
          customer.users[0].mobile_number.split("+91")[1]
        );
        formRef.current.setFieldValue(
          "address",
          customer.users[0].customer.address
            ? customer.users[0].customer.address.length > 0
              ? address[0]
              : null
            : null
        );
        formRef.current.setFieldValue(
          "lat",
          customer.users[0].customer.address
            ? customer.users[0].customer.address.length > 0
              ? address[0].lat
              : null
            : null
        );
        formRef.current.setFieldValue(
          "lng",
          customer.users[0].customer.address
            ? customer.users[0].customer.address.length > 0
              ? address[0].long
              : null
            : null
        );
      }
    }
  }

  return (
    <div className="space-y-4">
      <Button
        type="link"
        icon={<LeftOutlined />}
        className="flex items-center mr-4"
        onClick={() => {
          props.history.push("/visits");
        }}
      >
        All Visits
      </Button>
      <div className="flex justify-between items-center">
        <div className="flex justify-between">
          <h1> Create new visit</h1>
        </div>

        <div className="space-y-2">
          <p> Search for an existing customer</p>
          <TableFilter
            onSearch={(value) => {
              SetSearchText(`+91${value}`);
            }}
            isSearch={true}
            placeholder="Enter mobile number"
          />
        </div>
      </div>

      <ReactForm
        onSubmit={(values, { resetForm }) => {
          let address = values.address;
          address.lat = values.lat;
          address.long = values.lng;

          insertVisit({
            variables: {
              obj: {
                first_name: values.first_name,
                // last_name: values.last_name,
                mobile_number: `+91${values.mobile}`,
                customer_auth_id: customer.users[0]
                  ? customer.users[0].auth_id
                  : null,
                // gold_type: values.gold_type,
                visits_x_gold_ornament_types: {
                  data: values.gold_type.map((gold_type) => {
                    return { gold_ornament_type_id: gold_type };
                  }),
                },
                address: address,
                lat: values.lat,
                long: values.lng,
                visit_loan_type: values.visit_type,
                visit_type: "GP",
                loan_subtype: values.loan_subtype,
                visit_time: moment(
                  values.date + values.time,
                  "DD-MM-YYYY HH:mm"
                ),
                visit_status: "VISIT_CONFIRMED",
                partner_branch_id: values.partner_branch,
                confirmed_at: moment(),
                channel: values.channel,
                support_loan: values.support_loan,
              },
            },
          }).then(() => resetForm({}));
        }}
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={visitValidationSchema}
        formCls="flex flex-col content-center justify-center space-y-8"
      >
        {/* User details div*/}
        <div className="border">
          {/* User details header */}
          <div className="border-b w-full px-4 py-2 bg-green-primary">
            <h2 className="text-white">User details</h2>
          </div>
          {/* User details header */}

          {/* User details form */}
          <div className="p-4 space-y-4">
            {/* User details form - line 1*/}
            <div className="flex space-x-8">
              <div className="w-1/5">
                <ReactInput name="first_name" type="text" label="Name" />
              </div>

              {/* <div className="w-1/5">
                <ReactInput name="last_name" type="text" label="Last Name" />
              </div> */}

              <div className="w-1/5">
                <ReactInput
                  name="mobile"
                  type="text"
                  label="Mobile number"
                  placeholder=""
                />
              </div>

              <div className="w-1/5">
                {/* <ReactInput
                  name="gold_type"
                  type="text"
                  label="Gold type"
                  placeholder=""
                /> */}
                <ReactSelect
                  name="gold_type"
                  type="text"
                  label="Gold types"
                  // placeholder="Choose partner branch"
                  // disabled={true}
                  mode="multiple"
                >
                  {goldOrnamentTypes.gold_ornament_types.map(
                    (gold_ornament_type) => {
                      return (
                        <Option value={gold_ornament_type.id}>
                          {gold_ornament_type.type}
                        </Option>
                      );
                    }
                  )}
                </ReactSelect>
              </div>

              <div className="w-1/5">
                <ReactInput
                  name="channel"
                  type="text"
                  label="Channel"
                  placeholder=""
                />
              </div>
            </div>
            {/* User details form - line 1*/}

            {/* User details form - line 2*/}
            <div className="space-y-2">
              <p className="text-gray-800 font-semibold text-lg">Address</p>
              <div className="w-2/3">
                <ReactInput
                  name="address.addr_line_1"
                  type="text"
                  placeholder="Address line 1"
                />
              </div>
              <div className="w-2/3">
                <ReactInput
                  name="address.addr_line_2"
                  type="text"
                  placeholder="Address line 2"
                />
              </div>
              <div className="w-2/3 flex space-x-8">
                <div className="w-1/3">
                  <ReactInput
                    name="address.city"
                    type="text"
                    placeholder="City"
                  />
                </div>

                <div className="w-1/3">
                  <ReactSelect
                    name="address.state"
                    type="text"
                    placeholder="State"
                  >
                    <Option value="Andhra Pradesh">Andhra Pradesh</Option>
                    <Option value="Andaman and Nicobar Islands">
                      Andaman and Nicobar Islands
                    </Option>
                    <Option value="Arunachal Pradesh">Arunachal Pradesh</Option>
                    <Option value="Assam">Assam</Option>
                    <Option value="Bihar">Bihar</Option>
                    <Option value="Chandigarh">Chandigarh</Option>
                    <Option value="Chhattisgarh">Chhattisgarh</Option>
                    <Option value="Dadra and Nagar Haveli and Daman and Diu">
                      Dadra and Nagar Haveli and Daman and Diu
                    </Option>
                    <Option value="Delhi">Delhi</Option>
                    <Option value="Goa">Goa</Option>
                    <Option value="Gujarat">Gujarat</Option>
                    <Option value="Haryana">Haryana</Option>
                    <Option value="Himachal Pradesh">Himachal Pradesh</Option>
                    <Option value="Jammu and Kashmir">Jammu and Kashmir</Option>
                    <Option value="Jharkhand">Jharkhand</Option>
                    <Option value="Karnataka">Karnataka</Option>
                    <Option value="Kerala">Kerala</Option>
                    <Option value="Ladakh">Ladakh</Option>
                    <Option value="Lakshadweep">Lakshadweep</Option>
                    <Option value="Madhya Pradesh">Madhya Pradesh</Option>
                    <Option value="Maharashtra	">Maharashtra </Option>
                    <Option value="Manipur">Manipur</Option>
                    <Option value="Meghalaya">Meghalaya</Option>
                    <Option value="Mizoram">Mizoram</Option>
                    <Option value="Nagaland">Nagaland</Option>
                    <Option value="Odisha">Odisha</Option>
                    <Option value="Puducherry">Puducherry</Option>
                    <Option value="Punjab">Punjab</Option>
                    <Option value="Rajasthan">Rajasthan</Option>
                    <Option value="Sikkim">Sikkim</Option>
                    <Option value="Tamil Nadu">Tamil Nadu</Option>
                    <Option value="Telangana">Telangana</Option>
                    <Option value="Tripura">Tripura</Option>
                    <Option value="Uttar Pradesh">Uttar Pradesh</Option>
                    <Option value="Uttarakhand">Uttarakhand</Option>
                    <Option value="West Bengal">West Bengal</Option>
                  </ReactSelect>
                </div>

                <div className="w-1/3">
                  <ReactInput
                    name="address.pincode"
                    type="text"
                    placeholder="Pincode"
                  />
                </div>
              </div>
            </div>
            {/* User details form - line 2*/}

            {/* User details form - line 3*/}
            <div className="space-y-2">
              <p className="text-gray-800 font-semibold text-lg">Location</p>
              <ReactGeocoding />
            </div>

            <div className="w-1/3 flex space-x-8">
              <div className="w-1/2">
                <ReactInputNumber name="lat" label="Lat" />
              </div>

              <div className="w-1/2">
                <ReactInputNumber name="lng" label="Long" />
              </div>
            </div>
          </div>
          {/* User details form */}
        </div>
        {/* User details div*/}

        {/* Visit details div*/}
        <div className="border">
          {/* Visit details header */}
          <div className="border-b w-full px-4 py-2 bg-green-primary">
            <h2 className="text-white">Visit details</h2>
          </div>
          {/* Visit details header */}

          {/* Visit details form */}
          <div className="p-4 space-y-4">
            {/* Visit details form - line 1*/}
            <div className="flex space-x-8">
              <div className="w-1/4 space-y-3">
                <p className="text-gray-800 font-semibold text-lg">
                  Visit date and time
                </p>
                <div className="flex space-x-8">
                  <div className="w-1/2">
                    <ReactDatepicker
                      name="date"
                      isVisit={true}
                      disabledDate={function disabledDate(current) {
                        return (
                          (current &&
                            current.valueOf() < moment().subtract(1, "d")) ||
                          Object.keys(workingHoursData.oro_meta[0].data)
                            .filter((day) => {
                              return (
                                workingHoursData.oro_meta[0].data[day].start ===
                                undefined
                              );
                            })
                            .includes(moment(current).format("dddd"))
                        );
                      }}
                    />
                  </div>
                  <div className="w-1/2">
                    <VisitTimePicker
                      name="time"
                      workingHours={workingHoursData.oro_meta[0].data}
                    />
                  </div>
                </div>
              </div>

              <div className="w-1/5">
                <ReactSelect
                  name="visit_type"
                  type="text"
                  label="Visit type"
                  placeholder="GL"
                  disabled={true}
                >
                  <Option value="GL">GL</Option>
                </ReactSelect>
              </div>

              <div className="w-1/5">
                <ReactSelect
                  name="loan_subtype"
                  type="text"
                  label="Loan subtype"
                  placeholder="Fresh Loan / Transfer Loan"
                >
                  <Option value="FRESH_LOAN">Fresh Loan</Option>
                  <Option value="TRANSFER_LOAN">Transfer Loan</Option>
                </ReactSelect>
              </div>

              <div className="w-1/5">
                <ReactSelect
                  name="partner_branch"
                  type="text"
                  label="Partner branch"
                  placeholder="Choose partner branch"
                  // disabled={true}
                >
                  {branchesData.partner_branches.map((branch) => {
                    return (
                      <Option value={branch.id}>
                        {branch.partner_branch_name}
                      </Option>
                    );
                  })}
                </ReactSelect>
              </div>

              <div className="w-1/5">
                <ReactRadioGroup
                  name="support_loan"
                  label="Applicable for support loan"
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                  defaultValue={false}
                />
              </div>
            </div>
          </div>
          {/* Visit details form */}
        </div>
        {/* Visit details div*/}
      </ReactForm>

      <div className="flex justify-center w-full space-x-8">
        <Popconfirm
          title={`Are you sure you want to cancel?`}
          onConfirm={() => {
            props.history.push("/visits");
          }}
          onCancel={() => {}}
          okText="Yes"
          placement="bottomRight"
          cancelText="No"
        >
          <button className="btn-danger hover:shadow-lg">Cancel</button>
        </Popconfirm>
        <button
          className="btn-primary hover:shadow-lg"
          onClick={() => handleSubmit()}
          type="submit"
        >
          Create & Confirm Visit
        </button>
      </div>
    </div>
  );
};

export default CreateVisit;
